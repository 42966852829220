const plmQuestions = {
    questionOne: {
        sectionNumber: 1,
        sectionName: 'Capability',
        questionNumber: 1,
        questionName: 'questionOne',
        questionText: 'I have received adequate training in the PLM framework to put the skills into practice',
        answer: 0,
    },
    questionTwo: {
        sectionNumber: 1,
        sectionName: 'Capability',
        questionNumber: 2,
        questionName: 'questionTwo',
        questionText: 'I can work through The Personal Framework with a person using my service without thinking. It feels natural and easy,',
        answer: 0,
    },
    questionThree: {
        sectionNumber: 1,
        sectionName: 'Capability',
        questionNumber: 3,
        questionName: 'questionThree',
        questionText: 'I feel confident giving relevant lifestyle advice to co-create a Lifestyle Prescription with a person using my service',
        answer: 0,
    },
    questionFour: {
        sectionNumber: 1,
        sectionName: 'Capability',
        questionNumber: 4,
        questionName: 'questionFour',
        questionText: 'I feel confident in assessing and building a person’s activation and readiness to make changes',
        answer: 0,
    },
    questionFive: {
        sectionNumber: 1,
        sectionName: 'Capability',
        questionNumber: 5,
        questionName: 'questionFive',
        questionText: 'I understand the principles of person-centred care and how to apply these alongside The Personal Framework',
        answer: 0,
    },
    questionSix: {
        sectionNumber: 2,
        sectionName: 'Opportunity',
        questionNumber: 6,
        questionName: 'questionSix',
        questionText: 'I have enough time to engage in implementing PLM',
        answer: 0,
    },
    questionSeven: {
        sectionNumber: 2,
        sectionName: 'Opportunity',
        questionNumber: 7,
        questionName: 'questionSeven',
        questionText: 'I have everything I need to implement PLM, including learning resources',
        answer: 0,
    },
    questionEight: {
        sectionNumber: 2,
        sectionName: 'Opportunity',
        questionNumber: 8,
        questionName: 'questionEight',
        questionText: 'Colleagues that I work with know that I am implementing PLM and actively support me to identify opportunities',
        answer: 0,
    },
    questionNine: {
        sectionNumber: 2,
        sectionName: 'Opportunity',
        questionNumber: 9,
        questionName: 'questionNine',
        questionText: 'I have processes in place that remind and support me to implement The Personal Framework in a consultation',
        answer: 0,
    },
    questionTen: {
        sectionNumber: 2,
        sectionName: 'Opportunity',
        questionNumber: 10,
        questionName: 'questionTen',
        questionText: 'I feel confident using any data I have available to me to identify patients in advance, as well as spotting opportunities for a lifestyle approach in the moment',
        answer: 0,
    },
    questionEleven: {
        sectionNumber: 3,
        sectionName: 'Motivation',
        questionNumber: 11,
        questionName: 'questionEleven',
        questionText: 'It is important to me to become competent at PLM',
        answer: 0,
    },
    questionTwelve: {
        sectionNumber: 3,
        sectionName: 'Motivation',
        questionNumber: 12,
        questionName: 'questionTwelve',
        questionText: 'I intend to use the The Personal Framework with every patient when appropriate',
        answer: 0,
    },
    questionThirteen: {
        sectionNumber: 3,
        sectionName: 'Motivation',
        questionNumber: 13,
        questionName: 'questionThirteen',
        questionText: 'I am encouraged to continue using the The Personal Framework by positive feedback from patients',
        answer: 0,
    },
    questionFourteen: {
        sectionNumber: 3,
        sectionName: 'Motivation',
        questionNumber: 14,
        questionName: 'questionFourteen',
        questionText: 'I feel encouraged through the PLM Community to keep learning and increase my capabilities in lifestyle medicine',
        answer: 0,
    },
    questionFifteen: {
        sectionNumber: 3,
        sectionName: 'Motivation',
        questionNumber: 15,
        questionName: 'questionFifteen',
        questionText: 'I regularly review the number of PLM consultations I am completing and reflect on what I need to do next to continue my implementation',
        answer: 0,
    },
    questionSixteen: {
        sectionNumber: 4,
        sectionName: 'Behaviour - thinking about the past two weeks',
        questionNumber: 16,
        questionName: 'questionSixteen',
        questionText: 'I am consulting at least once a day using the The Personal Framework',
        answer: 0,
    },
    questionSeventeen: {
        sectionNumber: 4,
        sectionName: 'Behaviour - thinking about the past two weeks',
        questionNumber: 17,
        questionName: 'questionSeventeen',
        questionText: 'I am using the The Personal Framework in consultations with a variety of presenting conditions ',
        answer: 0,
    },
    questionEighteen: {
        sectionNumber: 4,
        sectionName: 'Behaviour - thinking about the past two weeks',
        questionNumber: 18,
        questionName: 'questionEighteen',
        questionText: 'I am applying the principles of person-centred care when consulting using The Personal Framework ',
        answer: 0,
    },
    questionNinteen: {
        sectionNumber: 4,
        sectionName: 'Behaviour - thinking about the past two weeks',
        questionNumber: 19,
        questionName: 'questionNinteen',
        questionText: 'I feel confident that I am increasing patient activation through the implementation of PLM',
        answer: 0,
    },
    questionTwenty: {
        sectionNumber: 4,
        sectionName: 'Behaviour - thinking about the past two weeks',
        questionNumber: 20,
        questionName: 'questionTwenty',
        questionText: 'I am interacting with colleagues in the PLM Community to support my continuous learning and development',
        answer: 0,
    }
}


export default plmQuestions