import React, { Component } from 'react'
import { connect } from 'react-redux';
import { getPurchaseProduct, getCRMContact, updateCRMContact } from '../../actions/purchaseActions'
import { updateName, clearAuthErrors } from '../../actions/authActions'
import FormSection from '../layout/FormSection'
import TextFieldGroup from '../common/TextFieldGroup'
import Validator from 'validator'
import isEmpty from '../../utils/is-empty'

class ContactInfo extends Component {
    constructor() {
        super()
        this.state = {
            contactLoaded: false,
            contactEdit: false,
            firstName: '',
            lastName: '',
            phone: '',
            jobTitle: '',
        }
    }

    componentDidMount() {
        this.setState({
            firstName: this.props.auth.user.firstName,
            lastName: this.props.auth.user.lastName,
        })
        this.props.clearAuthErrors()
        this.props.getCRMContact(this.props.auth.user.email)
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (!this.state.contactLoaded) {
            if (!nextProps.purchase.contactLoading && Object.keys(nextProps.purchase.contact).length > 0) {
                // initial contact load
                this.setState({
                    phone: nextProps.purchase.contact.phone,
                    jobTitle: nextProps.purchase.contact.jobTitle,
                    contactLoaded: true
                }, () => {
                    // Fast track to next section if all already good
                    if (this.validateContact(this.state).isValid) {
                        this.props.onComplete()
                    }
                })
            }

        }
    }

    validateContact = (data) => {
        let errors = {};

        data.firstName = !isEmpty(data.firstName) ? data.firstName : '';
        data.lastName = !isEmpty(data.lastName) ? data.lastName : '';
        data.phone = !isEmpty(data.phone) ? data.phone : '';
        data.jobTitle = !isEmpty(data.jobTitle) ? data.jobTitle : '';

        //firstName
        if (!Validator.isLength(data.firstName, { min: 1, max: 30 })) {
            errors.firstName = 'First name must be between 1 and 30 characters'
        }
        if (Validator.isEmpty(data.firstName)) {
            errors.firstName = 'First name is required'
        }

        //lastName
        if (!Validator.isLength(data.lastName, { min: 1, max: 30 })) {
            errors.lastName = 'Last name must be between 1 and 30 characters'
        }
        if (Validator.isEmpty(data.lastName)) {
            errors.lastName = 'Last name is required'
        }


        //jobTitle
        if (!Validator.isLength(data.jobTitle, { min: 1, max: 30 })) {
            errors.jobTitle = 'Role must be between 1 and 30 characters'
        }
        if (Validator.isEmpty(data.jobTitle)) {
            errors.jobTitle = 'Role is required'
        }


        // Phone
        if (!Validator.isMobilePhone(data.phone) && !/^[0]{2}(\+?33|0)[67]\d{8}$/.test(data.phone)) { //apparently some french numbers need the second bit
            errors.phone = 'Must be valid phone number'
        }
        if (Validator.isEmpty(data.phone)) {
            errors.phone = 'Phone number is required'
        }
        return {
            errors,
            isValid: isEmpty(errors)
        }
    }

    render() {
        const { contact, contactLoading, errors } = this.props.purchase
        const { user, actionLoading } = this.props.auth


        const validation = this.validateContact(this.state)
        const contactErrors = {
            ...validation.errors,
            ...errors.contact ? errors.contact : {}
        }

        return (
            <FormSection
                title="Tell us about yourself"
                locked={this.props.locked}
                loading={contactLoading || actionLoading}
                editToggle={() => { this.setState({ contactEdit: !this.state.contactEdit }) }}
                editActive={this.state.contactEdit}
                onSubmit={() => {
                    this.props.updateName({
                        firstName: this.state.firstName,
                        lastName: this.state.lastName
                    })
                    this.props.updateCRMContact({
                        firstName: this.state.firstName,
                        lastName: this.state.lastName,
                        phone: this.state.phone,
                        jobTitle: this.state.jobTitle
                    })
                }}
                changesMade={
                    user.firstName !== this.state.firstName
                    || user.lastName !== this.state.lastName
                    || contact.phone !== this.state.phone
                    || contact.jobTitle !== this.state.jobTitle
                }
                valid={validation.isValid}
                onComplete={this.props.onComplete}
                isComplete={this.props.isComplete}
                error={contactErrors.contact}
                color="yellow"
            >



                <div className="row">
                    <div className="col-sm-6">
                        <TextFieldGroup
                            name="firstName"
                            label="First Name"
                            error={contactErrors.firstName}
                            onChange={e => {
                                this.setState({ firstName: e.target.value })
                            }}
                            readOnly={!this.state.contactEdit}
                            value={this.state.firstName}
                        />
                    </div>
                    <div className="col-sm-6">
                        <TextFieldGroup
                            name="lastName"
                            label="Last Name"
                            error={contactErrors.lastName}
                            onChange={e => {
                                this.setState({ lastName: e.target.value })
                            }}
                            readOnly={!this.state.contactEdit}
                            value={this.state.lastName}
                        />
                    </div>
                    <div className="col-sm-6">
                        <TextFieldGroup
                            name="email"
                            label="Email"
                            readOnly
                            value={user.email}
                        />
                    </div>
                    <div className="col-sm-6">
                        <TextFieldGroup
                            name="phone"
                            label="Contact Number"
                            error={contactErrors.phone}
                            onChange={e => {
                                this.setState({ phone: e.target.value.replace(/\D/g, '') })
                            }}
                            readOnly={!this.state.contactEdit}
                            value={this.state.phone}
                        />
                    </div>
                    <div className="col-sm-6">
                        <TextFieldGroup
                            name="jobTitle"
                            label="Healthcare Professional Role"
                            error={contactErrors.jobTitle}
                            onChange={e => {
                                this.setState({ jobTitle: e.target.value })
                            }}
                            readOnly={!this.state.contactEdit}
                            value={this.state.jobTitle}
                        />
                    </div>
                </div>

            </FormSection>
        )
    }
}

const mapStateToProps = state => ({
    purchase: state.purchase,
    auth: state.auth
})
export default connect(mapStateToProps, { getPurchaseProduct, getCRMContact, updateName, clearAuthErrors, updateCRMContact })(ContactInfo);