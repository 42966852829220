import React from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types';
import './PUPanel.css'

const PUPanel = (props) => {
    const styles = {
        padding: {
            paddingTop: '25px',
            paddingBottom: '20px',
            marginBottom: '32px',

        },
        panel: {
            backgroundColor: 'white',
            height: '100%',
            borderRadius: '4px',
            // boxShadow: '0 1px 4px 0 rgba(0,0,0,.18)',
            boxShadow: '0 8px 12px -5px rgba(0,0,0,.4)',
            animationFillMode: 'backwards'
        },
        panelBody: {
            paddingLeft: props.bodyPadding && '25px',
            paddingRight: props.bodyPadding && '25px',
            paddingBottom: props.locked ? '0px' : '25px',
            transition: 'padding 1s, max-height 1s ease-in-out 0.5s',
            maxHeight: props.locked === true ? '0px' : props.locked === false && '2000px',
        },
        panelHeader: {
            position: 'relative',
            top: props.locked ? '0px' : '-25px',
            transition: 'top 0.5s, margin 0.5s, width 0.5s, opacity 0.5s',
            marginLeft: props.locked ? '0px' : '32px',
            width: props.locked ? '100%' : '60%',
            paddingLeft: '30px',
            paddingRight: '20px',
            paddingTop: '26px',
            paddingBottom: '26px',
            borderRadius: '4px',
            opacity: props.locked ? '0.5' : '1',
        },

    }

    return (
        <div style={styles.padding} className={'pupanel-panel ' + props.col} >
            <div style={styles.panel} >
                <div style={styles.panelHeader} className={classnames(
                    { "bg-light-mint": props.color === "green" },
                    { "bg-light-pink": props.color === "pink" },
                    { "bg-light-yellow": props.color === "yellow" },
                    { "bg-light-grey": props.color === "grey" },
                    { "bg-light-teal": props.color === "teal" },
                    { "bg-light-orange": props.color === "orange" },
                    { "bg-light-blue": props.color === "blue" }
                )}>
                    <h3 className={classnames("db-title-block",
                        { "dark-mint-block": props.color === "green" },
                        { "dark-pink-block": props.color === "pink" },
                        { "dark-yellow-block": props.color === "yellow" },
                        { "dark-grey-block": props.color === "grey" },
                        { "dark-teal-block": props.color === "teal" },
                        { "dark-orange-block": props.color === "orange" },
                        { "dark-blue-block": props.color === "blue" }
                    )}
                    >
                        {props.title}
                    </h3>
                </div>
                <div style={styles.panelBody} className="pupanel-panelbody" >

                    {props.children}
                </div>
            </div>
        </div>
    )
}
PUPanel.propTypes = {
    color: PropTypes.string.isRequired,
}
PUPanel.defaultProps = {
    col: 'col-12',
    bodyPadding: true,
    color: 'green'
};
export default PUPanel