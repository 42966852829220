import React, { Component } from 'react'
// import { Link } from 'react-router-dom'
import { logoutUser } from '../../actions/authActions'
import { connect } from 'react-redux';
import Background from '../../img/circles_bg.svg'
// import PracticeUnboundYellow from "../../img/PracticeUnboundYellow.png";
// import Card from '../layout/Card'
import PUPanel from '../layout/PUPanel'

class LogoutScreen extends Component {
    componentDidMount() {
        this.props.logoutUser()
    }

    componentDidUpdate(lastProps) {
        if (!lastProps.auth.isAuthenticated && this.props.auth.isAuthenticated) {
            window.location.href = '/'
        }
    }

    render() {
        return (
            <div style={{
                backgroundImage: 'url("' + Background + '")',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                overflow: 'hidden',
                backgroundPosition: 'center',
                minHeight: 'calc(100vh - 54px)'
            }}>
                <div className="container pb-5" >
                    <div className="row justify-content-center">
                        <div className="col-lg-8 col-md-10 pt-5">
                            <PUPanel title="You are logged out">
                                <p >You have successfully logged out.</p>
                                <p>Please note we might have logged you out automatically due to lack of activity.</p>
                            </PUPanel>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
const mapStateToProps = (state) => ({
    auth: state.auth
})

export default connect(mapStateToProps, { logoutUser })(LogoutScreen);