import React, { Component } from 'react'
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone'
import Plus from '../common/Plus'
import classnames from 'classnames'
import styles from './Upload.module.css'

class Upload extends Component {
    constructor(props) {
        super(props)
        this.state = {
            error: ''
        }
        this.onDrop = this.onDrop.bind(this);

    }

    onDrop = (acceptedFiles, rejectedFiles) => {
        if (rejectedFiles.length > 0) {
            this.setState({ error: 'There was a problem with this file, please choose another' })
        }

        if (acceptedFiles.length > 0) {
            const file = acceptedFiles[0]
            if (this.props.fileTypes || this.props.isImage) {
                if ((this.props.isImage ? ['image/png', 'image/jpeg', 'image/jpg'] : this.props.fileTypes).indexOf(file.type) === -1) {
                    return this.setState({ error: this.props.isImage ? 'Image must be a .png or .jpg' : this.props.fileTypeError })
                }
            }

            this.props.onChange(acceptedFiles[0])

            if (this.props.isImage && this.props.onImageLoad) {
                var fr = new FileReader();
                fr.onload = () => {
                    this.props.onImageLoad(fr.result)
                }
                fr.readAsDataURL(acceptedFiles[0]);
            }
            this.setState({ error: '' })
        } else {
            if (this.props.onImageLoad) {
                this.props.onImageLoad(null)
            }
        }
    }

    render() {

        return (
            <div style={{ padding: '3px' }}>
                {(!this.props.value || !this.props.hideAfterSelection) && (
                    <Dropzone
                        multiple={false}
                        onDrop={this.onDrop}>
                        {({ getRootProps, getInputProps, isDragActive }) => {
                            return (
                                <div
                                    {...getRootProps()}
                                    className={classnames('tc-mid-grey', [styles.dropzone], [styles.dropzoneContainer], { [styles.dropzoneActive]: isDragActive })
                                    }
                                >
                                    <input {...getInputProps()} />
                                    <h3><Plus size={'2x'} /></h3>
                                    <h6>{this.props.placeHolder}</h6>
                                </div>
                            )
                        }}
                    </Dropzone>
                )
                }
                {
                    this.props.value && (
                        <div
                            style={{
                                backgroundImage: this.props.isImage && this.props.displayImage && this.props.loadedImage && `url(${this.props.loadedImage})`,
                                backgroundPosition: 'center',
                                backgroundRepeat: 'no-repeat',
                                backgroundSize: 'contain',
                                textAlign: (this.props.isImage && this.props.displayImage) ? 'right' : 'center'
                            }}
                            className={classnames({ [styles.dropzoneContainer]: this.props.isImage && this.props.displayImage })}>
                            <span style={{
                                padding: '.25em .4em',
                                verticalAlign: 'baseline',
                                borderRadius: '.25rem',
                                color: '#fff',
                                backgroundColor: '#6c757d'
                            }}>{this.props.value.name}
                                <button
                                    type="button"
                                    className="close tc-white"
                                    style={{ float: 'none' }}
                                    aria-label="Close"
                                    onClick={() => {
                                        this.props.onChange(null)
                                        if (this.props.onImageLoad) {
                                            this.props.onImageLoad(null)
                                        }

                                    }}>
                                    <span aria-hidden="true">&times;</span>
                                </button> </span>
                        </div>
                    )
                }

                <small className="text-danger mb-3">{this.state.error}{this.props.error}</small>

            </div >
        )
    }
}
Upload.defaultProps = {
    isImage: true,
    hideAfterSelection: false,
    placeHolder: "Click or drag image"
}
Upload.propTypes = {
    isImage: PropTypes.bool.isRequired,
    hideAfterSelection: PropTypes.bool,
    fileTypes: PropTypes.array,
    fileTypeError: PropTypes.string,
    placeHolder: PropTypes.string,
    onChange: PropTypes.func.isRequired
}
export default Upload