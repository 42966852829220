// import React from 'react';
import React, { Component } from 'react'
import SideNavItem from './SideNavItem'
import './SideDrawer.css';

class sideDrawer extends Component {
    constructor(props) {
        super(props)
        this.state = { open: true }
    }
    render() {
        let drawerClasses = 'side-drawer';
        if (this.props.show) {
            drawerClasses = 'side-drawer open';
        }

        const listItem = this.props.listItems.map((li, index) =>
            <li key={index}>
                <SideNavItem
                    key={index}
                    open={this.props.sidebarOpen}
                    {...li}
                    isMobile={this.props.isMobile}
                    closeSideNavs={this.props.closeSideNavs}

                />
            </li>
        );

        return (
            <div>
                <nav
                    className={drawerClasses}
                >
                    <div
                        style={{
                            marginTop: '70px',
                            position: 'relative',
                            listStyle: 'none',
                            display: 'block',
                            justifyContent: 'start'
                        }}
                    >
                        <div className="mx-4 mb-4 text-dark">
                            {this.props.headerComponent}

                        </div>
                        {listItem}
                    </div>
                </nav>

            </div>
        )
    }
}

export default sideDrawer;
