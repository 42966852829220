import React, { Component } from 'react'
import { connect } from 'react-redux';
// import { Link } from 'react-router-dom'
import { getPurchaseProduct, getCrmOrganisation, getCrmContract, getOrganisationMemberships, clearPurchaseError } from '../../actions/purchaseActions'
import ContactInfo from './ContactInfo'
import OrganisationSelect from './OrganisationSelect';
import OrganisationConfirm from './OrganisationConfirm';
import PUPanel from '../layout/PUPanel'


class PLMLeadSignup extends Component {
    constructor() {
        super()
        this.state = {
            contactComplete: false,
            organisationSelectComplete: false,
            organisationConfirmComplete: false,
        }
    }


    render() {

        return (
            <div className="container py-5">
                <PUPanel>


                </PUPanel>
                <ContactInfo
                    locked={false}
                    isComplete={this.state.contactComplete}
                    onComplete={() => {
                        this.setState({ contactComplete: true })
                        this.props.clearPurchaseError('contact')
                    }}
                />

                <OrganisationSelect
                    locked={!this.state.contactComplete}
                    isComplete={this.state.organisationSelectComplete}
                    onComplete={() => {
                        this.setState({ organisationSelectComplete: true })
                        this.props.clearPurchaseError('organisations')
                    }}
                />

                <OrganisationConfirm
                    locked={!this.state.organisationSelectComplete}
                    isComplete={this.state.organisationConfirmComplete}
                    onComplete={() => {
                        this.setState({ organisationConfirmComplete: true })
                        this.props.clearPurchaseError('organisation')
                        // this.props.history.push(`/purchase/${product.name}/confirm`)
                    }}
                    onUncomplete={() => { this.setState({ organisationConfirmComplete: false }) }}
                />

            </div>
        )
    }
}

const mapStateToProps = state => ({
    purchase: state.purchase,
})
export default connect(mapStateToProps, { getPurchaseProduct, getCrmOrganisation, getCrmContract, getOrganisationMemberships, clearPurchaseError })(PLMLeadSignup);