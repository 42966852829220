import React, { Component } from 'react'
import TextFieldGroup from './TextFieldGroup'

class PasswordPage extends Component {
    constructor() {
        super()
        this.state = {
            passwordEntered: '',
            passwordCorrect: false
        }
    }

    componentDidMount() {
        console.log(sessionStorage.plmConferenceSlides)
        if (this.props.password === sessionStorage.plmConferenceSlides && !this.state.passwordCorrect) {
            this.setState({ passwordCorrect: true })
        }
    }

    componentDidUpdate() {
        if (this.props.password === this.state.passwordEntered && !this.state.passwordCorrect) {
            this.setState({ passwordCorrect: true })
            sessionStorage.setItem('plmConferenceSlides', this.state.passwordEntered)
        }
    }

    render() {
        if (this.state.passwordCorrect) {
            return this.props.children
        }
        return (
            <div className="container pt-5">
                <div className="row justify-content-center">
                    <div style={{ minWidth: '150px' }}>

                        <TextFieldGroup
                            placeholder="Password"
                            name="resources-password"
                            value={this.state.passwordEntered}
                            onChange={e => {
                                this.setState({ passwordEntered: e.target.value })
                            }}
                            type="password"
                        />
                    </div>
                </div>
            </div>



        )
    }
}
PasswordPage.defaultProps = {
    password: '123'
}
export default PasswordPage 