import api from '../config/axios';
import setAuthToken from '../utils/setAuthToken';
import jwt_decode from 'jwt-decode';
import {
    SET_AUTH_CURRENT_USER,
    SET_AUTH_FORGOTEMAIL_SENT,
    SET_AUTH_LOADING,
    SET_EMAIL_VERIFIED,
    SET_REQUEST_VERIFIED_SENT,
    CLEAR_PRODUCT_MEMBERSHIP,
    CLEAR_PROFILE,
    CLEAR_PRODUCT,
    SET_PASSWORD_EXPIRED,
    CLEAR_AUTH_ERRORS,
    GET_AUTH_ERRORS,
    SET_AUTH_ACTION_LOADING,
    SET_AUTH_ACTION_SUCCESS,
    SET_PERSONAL_CONTRACTS_LOADING,
    GET_PERSONAL_CONTRACTS
} from './types';
import { clearOrganisations } from './organisationActions'
import { getAuthProfile } from './profileActions'
import { getProducts, getProduct } from './productActions'
import isEmpty from '../utils/is-empty'
import ReactGA from 'react-ga4';
import store from '../store';


export const checkForToken = () => dispatch => {
    if (localStorage.jwtToken) {
        if (localStorage.jwtToken === "undefined") { return dispatch(logoutUser()); }
        dispatch(checkTokenExpired(localStorage.jwtToken));
    } else {
        dispatch(logoutUser());
    }
}


export const checkTokenExpired = (token) => dispatch => new Promise((resolve, reject) => {
    const decoded = jwt_decode(token);
    const currentTime = Date.now() / 1000;
    if (decoded.exp < currentTime) {
        dispatch(logoutUser());
        reject()
    } else {
        dispatch(setToken(token, true));
        resolve()
    }
})


export const setToken = (token, refresh) => dispatch => {
    localStorage.setItem('jwtToken', token)
    setAuthToken(token);
    const decoded = jwt_decode(token);
    dispatch(setCurrentUser(decoded))

    if (refresh) {
        dispatch({ type: SET_AUTH_LOADING, payload: true });
        api.authAPI.get('/api/auth/users/refresh')
            .then(res => {
                const { token } = res.data;
                dispatch(setToken(token));

            })
            .catch(err => {
                dispatch(handleError(err))
                dispatch({ type: SET_AUTH_LOADING, payload: false });
            })
    } else {
        dispatch(refreshAuthData())
        if (process.env.REACT_APP_GOOGLE_ANALYTICS === "true") {
            ReactGA.set({ userId: decoded.id });
        }
    }

}

export const refreshAuthData = () => dispatch => {

    dispatch(getAuthProfile())
    dispatch(getProducts())
    dispatch(getPersonalContracts())
    const product = store.getState().products.product
    if (product) {
        if (product.name) {
            dispatch(getProduct(product.name))
        }
    }

}

// log user out
export const logoutUser = () => dispatch => {
    localStorage.removeItem('jwtToken');
    if (process.env.REACT_APP_GOOGLE_ANALYTICS === "true") {
        ReactGA.set({ userId: null });
    }
    setAuthToken(false);
    dispatch(setCurrentUser({}));
    dispatch(clearOrganisations())
    dispatch(getProducts())
    dispatch({ type: CLEAR_PRODUCT })
    dispatch({ type: CLEAR_PRODUCT_MEMBERSHIP })
    dispatch({ type: CLEAR_AUTH_ERRORS })
    dispatch({ type: CLEAR_PROFILE })
}

//set current user
export const setCurrentUser = decoded => {
    return {
        type: SET_AUTH_CURRENT_USER,
        payload: decoded
    }
}

// Login - get user token
export const loginUser = (userData) => dispatch => {
    dispatch({ type: SET_AUTH_LOADING, payload: true });
    dispatch({ type: CLEAR_AUTH_ERRORS });
    api.authAPI.post('/api/auth/users/login', userData)
        .then(res => {
            const { token } = res.data;
            dispatch(setToken(token));

        })
        .catch(err => {
            dispatch(handleError(err))
            dispatch({ type: SET_AUTH_LOADING, payload: false });
        })
}

// Expired password
export const expiredPassword = (passwordData) => dispatch => {
    dispatch({ type: CLEAR_AUTH_ERRORS })
    dispatch({ type: SET_AUTH_LOADING, payload: true });
    api.authAPI
        .post('/api/auth/users/expired-password', passwordData)
        .then(res => {
            const { token } = res.data;
            dispatch(setToken(token));
        })
        .catch(err => {
            dispatch(handleError(err))
            dispatch({ type: SET_AUTH_LOADING, payload: false });
        });
}

// Forgotten password
export const forgottenPassword = (userData) => dispatch => {
    dispatch({ type: SET_AUTH_LOADING, payload: true });
    dispatch({ type: CLEAR_AUTH_ERRORS })
    api.authAPI
        .post('/api/auth/users/forgot', userData)
        .then(res => {
            dispatch({
                type: SET_AUTH_FORGOTEMAIL_SENT,
                payload: true
            })
        })
        .catch(err => {
            dispatch(handleError(err))
            dispatch({ type: SET_AUTH_LOADING, payload: false });
        });
}

// Forgotten reset
export const forgottenReset = (userData) => dispatch => {
    dispatch({ type: SET_AUTH_LOADING, payload: true });
    dispatch({ type: CLEAR_AUTH_ERRORS })
    api.authAPI
        .post('/api/auth/users/forgotreset', userData)
        .then(res => {
            const { token } = res.data;
            dispatch(setToken(token));
        })
        .catch(err => {
            dispatch(handleError(err))
            dispatch({ type: SET_AUTH_LOADING, payload: false });
        });
}

//Register for account
export const registerUser = (userData) => dispatch => {
    dispatch({ type: SET_AUTH_LOADING, payload: true });
    dispatch({ type: CLEAR_AUTH_ERRORS });
    api.authAPI.post('/api/auth/users/register', userData)
        .then(res => {
            const { token } = res.data;
            dispatch(setToken(token));
        })
        .catch(err => {
            dispatch(handleError(err))
            dispatch({ type: SET_AUTH_LOADING, payload: false });
        })
}

// Verify email
export const verifyEmail = (token) => dispatch => {
    dispatch({ type: SET_AUTH_LOADING, payload: true });
    dispatch({ type: CLEAR_AUTH_ERRORS })
    //dispatch(logoutUser());
    api.authAPI
        .post('/api/auth/users/verify-complete', token)
        .then(res => {
            dispatch({ type: SET_EMAIL_VERIFIED, payload: true });
        })
        .catch(err => {
            dispatch(handleError(err))
            dispatch({ type: SET_AUTH_LOADING, payload: false });
        });
}

// Request verify email
export const requestVerifyEmail = () => dispatch => {
    dispatch({ type: SET_AUTH_LOADING, payload: true });
    dispatch({ type: CLEAR_AUTH_ERRORS })
    api.authAPI
        .post('/api/auth/users/verify-request')
        .then(res => {
            dispatch({ type: SET_REQUEST_VERIFIED_SENT });
        })
        .catch(err => {
            dispatch(handleError(err))
            dispatch({ type: SET_AUTH_LOADING, payload: false });
        });
}


// Update password
export const updatePassword = (passwordData) => dispatch => {
    dispatch({ type: CLEAR_AUTH_ERRORS })
    dispatch({ type: SET_AUTH_ACTION_LOADING, payload: true });
    api.authAPI
        .post('/api/auth/users/update-password', passwordData)
        .then(res => {
            dispatch({ type: SET_AUTH_ACTION_SUCCESS, payload: true });
        })
        .catch(err => {
            dispatch(handleError(err))
            dispatch({ type: SET_AUTH_ACTION_LOADING, payload: false });
        });
}

export const updateAvatar = (data) => dispatch => {
    dispatch({ type: SET_AUTH_ACTION_LOADING, payload: true });
    dispatch({ type: CLEAR_AUTH_ERRORS })
    const formData = new FormData()
    formData.append('avatar', data);
    api.authAPI
        .post('/api/auth/avatars', formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
        .then(res => {
            const { token } = res.data;
            dispatch(setToken(token));
            dispatch({ type: SET_AUTH_ACTION_LOADING, payload: false })
            setAuthActionSuccess(true)
        })
        .catch(err => {
            dispatch(handleError(err))
            dispatch({ type: SET_AUTH_ACTION_LOADING, payload: false });
        });
}




export const updateName = (data) => dispatch => {
    dispatch({ type: SET_AUTH_ACTION_LOADING, payload: true });
    dispatch({ type: CLEAR_AUTH_ERRORS })
    api.authAPI
        .post('/api/auth/users/name', data)
        .then(res => {
            const { token } = res.data;
            dispatch(setToken(token))
            dispatch({ type: SET_AUTH_ACTION_LOADING, payload: false })
            setAuthActionSuccess(true)
        })
        .catch(err => {
            dispatch(handleError(err))
            dispatch({ type: SET_AUTH_ACTION_LOADING, payload: false });
        });
}


export const setAuthActionSuccess = (data) => dispatch => {
    dispatch({ type: SET_AUTH_ACTION_SUCCESS, payload: data })
}


// Delete Account
export const deleteAccount = () => dispatch => {
    dispatch({ type: CLEAR_AUTH_ERRORS })
    dispatch({ type: SET_AUTH_LOADING, payload: true });
    api.authAPI
        .delete('/api/auth/users')
        .then(res => {
            dispatch(logoutUser())
        })
        .catch(err => {
            dispatch(handleError(err))
            dispatch({ type: SET_AUTH_LOADING, payload: false });
        });
}


const handleError = (err) => dispatch => {
    let payload
    console.dir(err)

    //token expired
    if (err.response) {
        if (err.response.statusText === "Unauthorized" && err.response.status === 401 && err.response.data === "Unauthorized") {
            dispatch(logoutUser())
        }
    }


    if (isEmpty(err.response)) {
        payload = { error: err.message }
    } else {
        if (isEmpty(err.response.data)) {
            payload = { error: err.message }
        } else {
            payload = err.response.data
        }
    }

    if (payload.passwordExpired === true) {
        return dispatch({
            type: SET_PASSWORD_EXPIRED
        })
    }
    dispatch({
        type: GET_AUTH_ERRORS,
        payload: payload
    })
}



// Login - get user token
export const logInAs = (userData) => dispatch => {
    dispatch({ type: SET_AUTH_LOADING, payload: true });
    dispatch({ type: CLEAR_AUTH_ERRORS });
    api.authAPI.post('/api/auth/users/login-as', userData)
        .then(res => {
            const { token } = res.data;
            dispatch(setToken(token));

        })
        .catch(err => {
            dispatch(handleError(err))
            dispatch({ type: SET_AUTH_LOADING, payload: false });
        })
}

export const clearAuthErrors = () => dispatch => {
    dispatch({ type: CLEAR_AUTH_ERRORS })
}

export const getPersonalContracts = () => dispatch => {
    dispatch({ type: SET_PERSONAL_CONTRACTS_LOADING, payload: true });
    dispatch({ type: CLEAR_AUTH_ERRORS });
    api.platformAPI
        .get('/api/platform/personalcontracts')
        .then(res => {
            dispatch({ type: GET_PERSONAL_CONTRACTS, payload: res.data })
        })
        .catch(err => {
            dispatch(handleError(err))
            dispatch({ type: SET_PERSONAL_CONTRACTS_LOADING, payload: false });
        });
}

