import React, { Component } from 'react'
import { connect } from 'react-redux';
import { getElearningLaunchUrl, getElearningResultsAndUpdate, clearLaunchUrl } from '../../actions/elearningActions'
import ModuleTile from './ModuleTile'
import Button from '../common/Button'
import RefreshIcon from '../common/RefreshIcon'
import PUPanel from '../layout/PUPanel'
import ProgressBar from '../common/ProgressBar'
import detectIe from '../../utils/detectIe'
import processRichText from '../../utils/processRichText'
import Spinner from "../common/Spinner";
import CertificateGenerator from '../common/CertificateGenerator'
// import Block from '../layout/Block'

const ElearningRow = props => {
    if (!props.units) return null
    const completedUnits = props.units.filter(m => {
        if (!m.results) return false
        if (m.results.complete === 'complete') return true
        return false
    }).length
    const totalUnits = props.units.length
    const showWFGoCert = (completedUnits === totalUnits) && props.elearningRowTitle === 'Processing Letters'

    return (
        <>
            <div className="row">
                <div className="col-12">
                    <h2><strong>{props.elearningRowTitle}</strong></h2>
                    {props.elearningRowSubtitle && <p>{props.elearningRowSubtitle}</p>}
                    {showWFGoCert && <div className="mb-3">
                        <CertificateGenerator

                            title="Download certificate"
                            productName={props.productName}
                            complete={true}
                            center
                        /></div>}
                </div>

            </div>

            <div className="row mb-5 " >
                {props.units.map((m) =>
                    <div className="col-12 col-md-6 col-lg-4 col-xl-3" key={m.number}
                        style={{ maxWidth: '340px' }}
                    >
                        <ModuleTile
                            {...m}
                        />
                    </div>
                )}
            </div>
        </>
    )
}

class ElearningScreen extends Component {
    constructor() {
        super()
        const detectIE = detectIe()
        this.isIE = detectIE && detectIE <= 11;
    }
    componentDidMount() {
        if (this.isIE) window.addEventListener('blur', this.onBlur);
    }
    componentWillUnmount() {
        window.removeEventListener('blur', this.onBlur);
        window.removeEventListener('focus', this.onFocus);
    }

    onBlur = () => {
        //only gets called if IE
        const { launchUrl } = this.props.elearning
        if (launchUrl) {
            this.props.clearLaunchUrl()
            //This is when the elearning has opened in a new tab
            window.addEventListener('focus', this.onFocus);
        }

    }

    onFocus = () => {
        const { product } = this.props.products
        const { resultsLoading } = this.props.elearning
        if (!resultsLoading) {
            //Toggle this on and off depending on whether you want a get results on every focus or just first after blur
            // window.removeEventListener('focus', this.onFocus);
            this.props.getElearningResultsAndUpdate(product.name)
        }

    }

    launch = (product, number) => {
        this.props.getElearningLaunchUrl(product, number, !this.isIE)
    }

    render() {
        const { product, lna, learnerOpmDataLoading, learnerOpmData } = this.props.products
        const { launchUrlLoading, launchUrl, resultsLoading, errors } = this.props.elearning
        const results = this.props.elearning.results || []
        const modules = product.modules || []
        // console.log('results', results)
        // console.log('modules', modules)

        if (launchUrl) {

            if (this.isIE) {
                //IE opens in new tab
                window.open(launchUrl, '_blank')
            } else {
                window.location.replace(launchUrl)
            }
        }
        let courseList = []
        if (modules) {
            modules.forEach(m => {
                if (!courseList.includes(m.course)) { courseList.push(m.course) }
            })
        }

        let freeStuff = []
        if (product.purchased !== true) {
            if (modules) {
                freeStuff = modules.filter(p => p.freeTrial)
            }
        }

        let combinedModules = []
        if (modules) {
            combinedModules = modules.map(m => {
                return {
                    ...m,
                    result: results.filter(r => r.product === product.name && r.moduleNumber === m.number)[0] || {}
                }
            })
        }


        const total = combinedModules.length
        const complete = combinedModules.filter(m => m.result.complete === 'complete').length
        const inProgress = combinedModules.filter(m => m.result.complete === 'incomplete').length


        if (product.purchased) {
            if (product.enforceDataBeforeCourse) {

                if (learnerOpmDataLoading) {
                    return <Spinner block large color="yellow" />
                }

                if (learnerOpmData) {
                    if (learnerOpmData.submittedData === false) {
                        return <div className="container pt-5">
                            <div className="row">
                                <PUPanel
                                    title="Get started with data"
                                >
                                    {processRichText(product.enforeDataBeforeCourseText && product.enforeDataBeforeCourseText.content)}
                                </PUPanel>
                            </div>
                        </div>
                    }
                }
            }
        }

        // const summary = product.modules.map(m => {
        //     let mod = {
        //         ...m,
        //         ...results.filter(r => r.moduleNumber === m.number)[0]
        //     }
        //     if (mod.complete === "complete") {
        //         mod.completeSort = 1
        //     } else if (mod.complete === "incomplete") {
        //         mod.completeSort = 0
        //     } else {
        //         mod.completeSort = -1
        //     }
        //     return mod
        // }).sort((a, b) => {
        //     if (a.completeSort < b.completeSort) return 1
        //     if (a.completeSort > b.completeSort) return -1
        //     return 0
        // })

        // could use this for a continue where you left off
        // const lastAccessed = summary.reduce((accumulated, current) => {
        //     if (!current.lastAccessDate) return accumulated
        //     if (current.lastAccessDate > accumulated.lastAccessDate) return current
        //     return accumulated
        // }, summary[0]);


        return (
            <div className="container pb-5">
                <div className="row">
                    <div className="col-12">
                        <Button className="float-right" colour="none" onClick={() => { this.props.getElearningResultsAndUpdate(product.name) }}><RefreshIcon /></Button>
                    </div>
                </div>
                <ProgressBar
                    complete={complete}
                    inProgress={inProgress}
                    total={total}
                    showCertificate={product.showAllModuleCertificate}
                    productName={product.name}
                />
                <small className="text-danger">{errors.error}</small>

                {freeStuff.length > 0 && <ElearningRow
                    elearningRowTitle="Freemium Content"
                    elearningRowSubtitle="Click the tile below to launch your free e-learning content."
                    productName={product.name}
                    units={freeStuff.map((m) => {
                        return {
                            ...m,
                            purchased: product.purchased,
                            recommended: lna && lna.resultModules.includes(m.number),
                            results: results.filter(r => r.product === product.name && r.moduleNumber === m.number)[0],
                            resultsLoading: resultsLoading,
                            showCertificate: m.showCertificate,
                            loading: launchUrlLoading || launchUrl,
                            onClick: () => {
                                this.launch(product.name, m.number)
                            }
                        }
                    })}
                />
                }



                {modules && courseList.map((c, index) => {
                    return (
                        <div key={index}>
                            <ElearningRow
                                elearningRowTitle={c}
                                productName={product.name}
                                units={modules.filter(m => m.course === c).map((m, key, arr) => {
                                    return {
                                        ...m,
                                        purchased: product.purchased,
                                        recommended: lna && lna.resultModules.includes(m.number),
                                        results: results.filter(r => r.product === product.name && r.moduleNumber === m.number)[0],
                                        resultsLoading: resultsLoading,
                                        loading: (launchUrlLoading || launchUrl),
                                        onClick: () => {
                                            this.launch(product.name, m.number)
                                        }
                                    }
                                })}
                            />
                        </div>
                    )
                })}

            </div >


        );
    }
}

const mapStateToProps = state => ({
    organisations: state.organisations,
    products: state.products,
    elearning: state.elearning
})
export default connect(mapStateToProps, { getElearningLaunchUrl, getElearningResultsAndUpdate, clearLaunchUrl })(ElearningScreen);