import React from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import styles from './TextFieldGroup.module.css'

const TextFieldGroup = ({
    name,
    large,
    placeholder,
    value,
    error,
    valid,
    info,
    type,
    onChange,
    onFocus,
    onBlur,
    disabled,
    readOnly,
    label,
    labelStyle,
    textCenter,
    bold,
    autoFocus,
    className,
    labelMargin,
    paddingX,
    maxWidth,
    autoComplete,
    errorColor,
    noResize,
    height
}) => {
    if (type === "textarea") {
        return (
            <div className={classnames("form-group mb-0", className)}>
                {label && <p className="mb-0 pl-1" style={{ ...labelStyle, fontWeight: 'bold' }}>{label}</p>}

                <textarea rows="5"
                    type={type}
                    className={classnames(`form-control ${noResize && 'tw-resize-none'}`,
                        { 'is-invalid': error, },
                        { 'is-valid': valid, },
                        [styles.text],
                        { [styles.textFocus]: !readOnly },
                        { [styles.textFocusReadOnly]: readOnly },
                    )}
                    style={{height}}
                    placeholder={placeholder}
                    name={name}
                    role="form"
                    aria-label={name}
                    title={label}
                    value={value}
                    onChange={onChange}
                    disabled={disabled}
                    readOnly={readOnly}
                    autoComplete={autoComplete}
                />
                {info && <small className='form-text text-muted'>{info}</small>}
                {error ? <div className="invalid-feedback mt-0" style={{ color: errorColor === 'white' && 'white' }}>{error}</div> : (
                    <div style={{ fontSize: '80%' }}>&nbsp;</div>
                )}
            </div>
        )
    }
    return (
        <div style={{ paddingTop: '3px', paddingBottom: '3px' }} className={classnames("form-group mb-0", className, { 'px-3 py-0 mb-0': paddingX })}>
            {label && <p className={classnames('pl-1', { 'mb-0': !labelMargin }, { 'mb-2': labelMargin })} style={{ ...labelStyle, fontWeight: 'bold' }}>{label}</p>}
            <input
                spellCheck="False"
                style={{
                    height: large && '52px',
                    fontSize: large && '20px',
                    textAlign: textCenter && 'center',
                    fontWeight: bold && 'bold',
                    color: readOnly && 'hsl(0,0%,60%)',
                    maxWidth: maxWidth && maxWidth
                }}
                type={type}
                className={classnames('form-control',
                    { 'is-invalid': error, },
                    { 'is-valid': valid, },
                    { [styles.textFocus]: !readOnly },
                    { [styles.textFocusReadOnly]: readOnly },
                )}
                placeholder={placeholder}
                name={name}
                role="form"
                aria-label={name}
                title={label}
                value={value}
                onChange={onChange}
                autoFocus={autoFocus}
                onFocus={onFocus}
                onBlur={onBlur}
                disabled={disabled}
                readOnly={readOnly}
                autoComplete={autoComplete}
            />
            {info && <small className='form-text text-muted'>{info}</small>}
            {error ? <div className="invalid-feedback mt-0" style={{ color: errorColor === 'white' && 'white' }}>{error}</div> : (
                <div style={{ fontSize: '80%' }}>&nbsp;</div>
            )}
        </div>
    )
}

TextFieldGroup.propTypes = {
    name: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    value: PropTypes.string,
    info: PropTypes.string,
    error: PropTypes.string,
    type: PropTypes.string.isRequired,
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
}

TextFieldGroup.defaultProps = {
    type: 'text'
}

export default TextFieldGroup