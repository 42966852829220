import React from 'react'
import Select from 'react-select'
import CreatableSelect from 'react-select/creatable';
import classnames from 'classnames'

const SelectComp = (props) => {
    let options = [...props.options]
    if (props.creatable) {
        options.push({ label: 'If other please type...', value: 'Other', isDisabled: true })
    }

    const selectSharedProps = {
        role: "form",
        ariaLabel: props.label,
        styles: {
            control: (provided, state) => {
                return {
                    ...provided,
                    boxShadow: props.error ? '0 0 0 0.2rem rgba(220,53,69,.25);' : state.isFocused ? '0 0 0 0.2rem rgba(244,179,33,0.5);' : '',
                    backgroundColor: state.isDisabled ? '#e9ecef' : provided.backgroundColor,
                    border: props.error ? '1px solid #dc3545' : state.isFocused || state.isSelected ? '1px solid #f4b321' : '',
                    '&:hover': {
                        borderColor: props.error ? '#dc3545' : state.isFocused || state.isSelected ? '#f4b321' : 'rgb(206, 212, 218)'
                    }
                }
            },
            option: (provided, state) => {
                return {
                    ...provided,
                    backgroundColor: state.isSelected ? '#be8c1a' : 'transparent',
                    '&:hover': {
                        backgroundColor: !state.isDisabled && 'rgba(244,179,33,0.5)',
                        color: !state.isDisabled && 'black'
                    }
                }
            },
        },
        value: props.value,
        placeholder: props.placeholder,
        onChange: props.onChange,
        options,
        isDisabled: props.readOnly,
        formatCreateLabel: (input) => { return `(Other) ${input}` },
        labelMargin: props.labelMargin
        // ,maxwidth: props.maxwidth
    }
    return (
        <div style={{ paddingTop: '3px', paddingBottom: '3px' }}
            className={props.className}
        >
            {props.label && <p className={classnames('pl-1', { 'mb-0': !props.labelMargin }, { 'mb-2': props.labelMargin })} style={{ ...props.labelStyle, fontWeight: 'bold' }}>{props.label}</p>}
            {props.creatable ? <CreatableSelect {...selectSharedProps} /> : <Select {...selectSharedProps} />}
            {props.hideError ? null : props.error ? <div style={{ fontSize: '80%' }} className="text-danger mt-0"><span style={{ color:props.errorColor &&'white'}}>{props.error}</span></div> : (
                <div style={{ fontSize: '80%' }}>&nbsp;</div>
            )}
        </div>
    )
}
export default SelectComp