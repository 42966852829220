import { useState, useEffect } from "react"

export function useScreenSize() {
  const [screenSize, getDimension] = useState(window.innerWidth)
  const setDimension = () => getDimension(window.innerWidth)
  useEffect(() => {
    window.addEventListener("resize", setDimension)
    return () => {
      window.removeEventListener("resize", setDimension)
    }
  }, [screenSize])  
  return screenSize
}
