import React from 'react'
import appointmenttypes from '../../img/appointmenttypes.PNG'
import ltcs from '../../img/ltcs.PNG'
import numbers from '../../img/numbers.PNG'
import proportion from '../../img/proportion.PNG'
import plmstaff from '../../img/plmstaff.PNG'
import PUPanel from '../layout/PUPanel'
import BottomBanner from '../layout/PLM/Comps/BottomBanner'
import { Link } from 'react-router-dom'

export default function PLMDataSignup() {

    return (
        <>
            <div className='container pt-5'>
                <div className="row">
                    <PUPanel title="What is the Data Pilot?" col='col-12'>
                        <p>
                            UK GP Practices using SystmOne & EMIS (Vision coming soon) can participate in the data pilot. In order to initiate the data pilot the organisation lead for your practice will be required to agree our Information Governance on behalf of the practice. License holders will then be required to implement the PLM clinical system template/guideline provided within the Resources tab and submit your data regularly (clinical specific process outlined within the Resources tab) to our secure data warehouse. In return we will transform your data into a Data Dashboard providing you with the following data sets. An example of the dashboard is displayed below.
                        </p>

                        <p><Link to='/contact-us'>Contact our helpdesk</Link> to find out more and get started.</p>
                    </PUPanel>
                    <PUPanel col='col-md-6' title="Summary" color="yellow">
                        <img alt="Data Dashboard summary preview" className='img-fluid' src={numbers} />
                    </PUPanel>
                    <PUPanel col='col-md-6' title="Appointment Types">
                        <img alt="Data Dashboard appointment types preview" className='img-fluid' src={appointmenttypes} />
                    </PUPanel>
                    <PUPanel color="pink" col='col-md-6' title="Proportion of PLM Appointments">
                        <img alt="Data Dashboard proportion of appointments preview" className='img-fluid' src={proportion} />
                    </PUPanel>
                    <PUPanel col='col-md-6' title="Presenting Conditions" color="yellow">
                        <img alt="Data Dashboard presenting conditions preview" className='img-fluid' src={ltcs} />
                    </PUPanel>


                    <PUPanel col='col-md-6' title="Appointments by Practitioner">
                        <img alt="Data Dashboard appointments by practitioner preview" className='img-fluid' src={plmstaff} />
                    </PUPanel>

                </div>

            </div>
            <BottomBanner header="Have more questions? Talk to us">
                {/* <p style={{ fontSize: '18px' }}>Whether you are interested in one of our products or would like to know more about working with Practice Unbound, please give us a call on 0300 303 8145 or email <a style={{ fontWeight: 'bold', color: 'white' }} href="mailto:info@practiceunbound.org.uk">info@practiceunbound.org.uk</a></p> */}
                <p style={{ fontSize: '18px' }}>Whether you are interested in one of our products or would like to know more about working with Practice Unbound, please<Link style={{ color: 'white' }} to="/contact-us"> contact the team</Link></p>
            </BottomBanner>
        </>
    )
}
