import React from 'react'
import Button from '../common/Button'
import ToDo from './ToDo'

function ToDos({ product, organisationProductMembership, bottomButton, showLinks, small }) {


    let toDos = []
    if (product.learningNeeds) {
        toDos.push({
            done: organisationProductMembership.lna,
            disabled: false,
            title: "Learning Needs",
            link: '/admin/' + organisationProductMembership.organisationproductmembershipid + '/learning-needs-analysis',
            text: "Answer a few questions to help us personalise the learning experience for you and your practice."
        })
    }

    if (product.enforceAuditCheckBeforeCourse || product.showCheckAudit) {
        toDos.push({
            done: organisationProductMembership.auditChecked === true,
            disabled: (product.enforceLna && !organisationProductMembership.lna),
            disabledText: "Please complete your Learning Needs first",
            title: "Check Audit",
            link: '/admin/' + organisationProductMembership.organisationproductmembershipid + '/check-audit',
            text: "Prior to your agreed Go Live date please ensure that you conduct an audit check"
        })
    }

    if (organisationProductMembership.users && (!organisationProductMembership.userLimit || organisationProductMembership.userLimit > 1)) {
        toDos.push({
            //users is either an array or int
            done: organisationProductMembership.users > 1 || organisationProductMembership.users.length > 1 || organisationProductMembership.usersOptOut === true,
            disabled: (product.enforceLna && !organisationProductMembership.lna) || (product.enforceAuditCheckBeforeCourse && !organisationProductMembership.auditChecked),
            disabledText: (product.enforceLna && !organisationProductMembership.lna) ? "Please complete your Learning Needs first" : "You need to check your audit works first",
            title: "Add additional users",
            link: '/admin/' + organisationProductMembership.organisationproductmembershipid + '/licences',
            text: "Manage users and product access"
        })
    }
    if (organisationProductMembership.invites && organisationProductMembership.product.inviteCollaborators) {
        toDos.push({
            done: organisationProductMembership.invites.length > 0 || organisationProductMembership.invitesOptOut === true,
            disabled: (product.enforceLna && !organisationProductMembership.lna),
            disabledText: "Please complete your Learning Needs first",
            title: "Invite others",
            link: '/admin/' + organisationProductMembership.organisationproductmembershipid + '/invite',
            text: "Invite other practices to join your learning journey"
        })
    }
    if (product.showIg) {
        toDos.push({
            done: organisationProductMembership.igSigned === true,
            disabled: (product.enforceLna && !organisationProductMembership.lna),
            disabledText: "Please complete your Learning Needs first",
            title: "Information governance",
            link: '/admin/' + organisationProductMembership.organisationproductmembershipid + '/ig',
            text: "Sign our data sharing agreements to get started with your data dashboard"
        })
    }

    if (product.enforceDataBeforeCourse) {
        toDos.push({
            done: organisationProductMembership.submittedData === true,
            disabled: (product.enforceLna && !organisationProductMembership.lna),
            disabledText: "Please complete your Learning Needs first",
            title: "Submit data",
            link: '/product/' + organisationProductMembership.organisationproductmembershipid + '/resources/data',
            text: "You will need to submit and review your data before continuing to your learning"
        })
    }


    const toDoTotal = toDos.length
    const toDoDone = toDos.filter(t => t.done).length

    let finishedUrl = '/product/' + organisationProductMembership.product.name
    if (product.showGetStartedTab) {
        finishedUrl = '/product/' + organisationProductMembership.product.name + '/get-started'
    } else if (product.showElearningTab) {
        finishedUrl = '/product/' + organisationProductMembership.product.name + '/course'
    }

    return (
        <div>
            {toDos.map((t, index, array) => {
                return <ToDo
                    small={small}
                    key={t.title}
                    done={t.done}
                    disabled={t.disabled}
                    title={t.title}
                    link={showLinks && t.link}
                    text={t.text}
                    last={array.length - 1 === index}
                />
            })}
            {bottomButton && toDoDone === toDoTotal && (
                <div><h4 className="text-center mt-4"><strong>Looks like you are all set!</strong></h4>
                    <Button
                        link={finishedUrl}
                        large
                        inline
                        center
                    >
                        Done
                    </Button>
                </div>
            )}
        </div>
    )
}
ToDos.defaultProps = {
    bottomButton: true,
    showLinks: true
}
export default ToDos