import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faFileArchive } from '@fortawesome/free-solid-svg-icons'
library.add(faFileArchive)

const DownloadIcon = () => {
    return (
        <FontAwesomeIcon icon="file-archive" />
    )
}
export default DownloadIcon