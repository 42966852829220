import React, { Component } from 'react'
import { withRouter } from 'react-router-dom';
import TextFieldGroup from '../common/TextFieldGroup'
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { forgottenReset } from '../../actions/authActions';
import LoginSplash from '../layout/LoginSplash'
import Button from '../common/Button'

class ForgotReset extends Component {
    constructor() {
        super();
        this.state = {
            password: '',
            password2: '',
        };
        this.onChange = this.onChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }
    onChange(e) {
        this.setState({ [e.target.name]: e.target.value })
    }
    onSubmit(e) {
        e.preventDefault();
        const userData = {
            password: this.state.password,
            password2: this.state.password2,
            token: decodeURIComponent(this.props.match.params.token)
        }
        this.props.forgottenReset(userData, this.props.history)
    }
    render() {
        const { auth } = this.props;
        const { errors } = auth;
        return (
            <LoginSplash>
                <p>Give yourself a new password and click submit to login</p>
                <form onSubmit={this.onSubmit} className="mt-3">
                    <div style={{ maxWidth: '300px' }} className="mx-auto">
                        <TextFieldGroup
                            placeholder="New password"
                            name='password'
                            type='password'
                            value={this.state.password}
                            onChange={this.onChange}
                            error={errors.password}
                        />
                        <TextFieldGroup
                            placeholder="Repeat password"
                            name='password2'
                            type='password'
                            value={this.state.password2}
                            onChange={this.onChange}
                            error={errors.password2}
                        />
                    </div>
                    <small className="d-block text-muted">Minimum of 8 characters</small>
                    <small className="d-block text-muted">Must contain both upper & lower case</small>
                    <small className="d-block text-muted">Must contain both letters & numbers</small>
                    <Button
                        className="mt-3"
                        center
                        fixedWidth
                        loading={auth.loading}
                        type="submit"
                    >Submit
                </Button>

                    <small className="text-danger">{errors.error}</small>
                </form>
            </LoginSplash>
        )
    }
}

ForgotReset.propTypes = {
    forgottenReset: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired
}
const mapStateToProps = state => ({
    auth: state.auth
})

export default connect(mapStateToProps, { forgottenReset })(withRouter(ForgotReset));