import React, { useState } from 'react'
import styles from './Plm.module.css'
import { Link } from 'react-router-dom'
import PUPanel from '../../../layout/PUPanel'
import aggregateArray from '../../../../utils/aggregateArray'
import pivotArray from '../../../../utils/pivotArray'
import GoogleChart from '../../../common/GoogleChart'
import moment from 'moment'
import Checkbox from '../../../common/Checkbox'


const PrevAssessments = ({ assessments, showPrevAssessments }) => {

    const [lineCurve, setLineCurve] = useState(true)

    const questionsGrouped = assessments.map(a => {
        let sectionName = 0
        if (a.questionnumber >= 1 && a.questionnumber <= 5) sectionName = 'Capability'
        if (a.questionnumber >= 6 && a.questionnumber <= 10) sectionName = 'Opportunity'
        if (a.questionnumber >= 11 && a.questionnumber <= 15) sectionName = 'Motivation'
        if (a.questionnumber >= 16 && a.questionnumber <= 20) sectionName = 'Behaviour'

        return {
            ...a,
            sectionName
        }

    })

    const questionsAggregated = aggregateArray(questionsGrouped, ['eventdate', 'submissionid', 'sectionName'], ['answervalue'])

    const pivotedArray = pivotArray(questionsAggregated.map(q =>
        [q.eventdate, q.sectionName, q.answervalue / q.answervaluecount]
    ), 'Date')

    // Move date formatting down - stops grouping
    const momentData = pivotedArray.map((e, i) => {
        return (
            i === 0 ? [e[0], e[1], e[2], e[3], e[4], "Positive"] : [moment(e[0]).format('DD-MMM-YYYY'), e[1], e[2], e[3], e[4], 3.1]
        )
    })

    // Create static line as positive results reference
    const chartData = [...momentData, ["", null, null, null, null, 3.1]]

    return (

        <div className="row">


            <PUPanel title="Your Results"
                color="teal"
            // bodyPadding={false}
            >

                <p>Here are your results from completing the Self- Assessment. The graph tracks how your results are changing over time in each of the 4 COM-B categories (Capability, Opportunity, Motivation and Behaviour).</p>
                <p>The score for each category is calculated by taking a mean score from each of the 4 COM-B categories and mapping them against the date you completed the Self-Assessment.</p>
                <p>If your scores aren’t increasing in the way you were hoping, you can click the “My Scores aren’t increasing” button at the top of the page to download our supporting document. This document outlines top tips which may help you. If you want to re-submit your scores, click the "Add New Scores" button at the top of the screen.</p>
                <p>If you need support, please contact our <Link to='/contact-us'>helpdesk</Link>.</p>


                <div className={styles.formStyle}>
                    <GoogleChart
                        seriesOptions={{
                            4: { lineDashStyle: [4, 2] }
                        }}
                        curveType={lineCurve === true ? 'function' : ''}
                        height={500}
                        card={false}
                        legend
                        // dataHeaders={['','cats and dogs']}
                        type="LineChart"
                        colors={['#16c194', '#e04f9a', '#0c989d', '#be8c1a', '#f4b321', '#169f7b',]}
                        data={chartData}
                        maxV={5.5}                        
                    />
                </div>

                <Checkbox
                    icon
                    value={lineCurve}
                    onChange={() => { setLineCurve(!lineCurve) }}
                >
                        Curve Lines
                </Checkbox>

            </PUPanel>
        </div>

        // </TransitionGroup>
    )
}

export default PrevAssessments




