import React from 'react'
import styles from './TextEditHover.module.css'
import EditIcon from './EditIcon'
import classnames from 'classnames'

export default function TextEditHover(props) {
    return (
        <div style={{ position: 'relative', display: 'inline-block' }}>
            <div className={classnames(styles.edit)}
                onClick={props.onClick}
            >
                <EditIcon />
                <div className={styles.text}><small><strong>EDIT</strong></small></div>
            </div>
            {props.active ? props.activeText : props.inactiveText}
        </div>
    )
}
