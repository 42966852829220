import React, { Component } from 'react'
import ReactModal from 'react-modal'
import Spinner from './Spinner'
import classnames from 'classnames'


class ModalRoute extends Component {
    constructor(props) {
        super(props);
        this.state = {
            width: 0,
            height: 0
        };
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this)
    }
    componentDidMount() {
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }
    updateWindowDimensions() {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
    }


    measureScrollbarWidth() {
        let scrollBarWidth = 0;
        if (document.body) {
            let scrollbox = document.createElement('div');
            scrollbox.style.overflow = 'scroll';
            scrollbox.style.display = 'inline-block';//fixes IE clientwidth 0
            document.body.appendChild(scrollbox);
            scrollBarWidth = scrollbox.offsetWidth - scrollbox.clientWidth;
            document.body.removeChild(scrollbox);
        }
        if (scrollBarWidth < 30) {
            return scrollBarWidth
        } else {
            return 0
        }

    }


    render() {
        const grey = 'rgba(80,80,80,0.5)'
        const verticalSpace = 111
        const styles = {
            modalMiddleS: {
                content: { width: `${576 - 80}px` },
            },
            modalMiddleM: {
                content: { width: `${768 - 80}px` },
            },
            modalMiddleL: {
                content: { width: `${992 - 80}px` },
            },
            modalMiddleXS: {
                content: {
                    left: '10%',
                    right: '10%',
                    marginRight: '-10%',
                    transform: 'translate(-5%)'
                },
            },
            //THIS ONE SHOULDNT SCROLL
            modalTopRight: {
                content: {
                    top: '81px',
                    right: '10px',
                    bottom: 'auto',
                    width: '300px',
                    marginLeft: 'auto',
                },
                overlay: {
                    backgroundColor: 'rgba(255,255,255,0)',
                }
            },
            noPadding: {
                content: {
                    padding: '0px'
                }
            },
            panelHeader: {
                position: 'relative',
                top: '-20px',
                left: '20px',
                width: this.state.width >= 768 ? '60%' : '80%',
                paddingLeft: '30px',
                paddingRight: '20px',
                paddingTop: '26px',
                paddingBottom: '26px',
                borderRadius: '4px',
            },
        }
        let modalSizeStyle = {}
        if (this.props.topRight) {
            modalSizeStyle = styles.modalTopRight
        } else if (this.state.width >= 992 && this.props.size === 'large') {
            modalSizeStyle = styles.modalMiddleL
        } else if (this.state.width >= 768 && this.props.size === 'medium') {
            modalSizeStyle = styles.modalMiddleM
        } else if (this.state.width >= 576 && this.props.size === 'small') {
            modalSizeStyle = styles.modalMiddleS
        } else {
            modalSizeStyle = styles.modalMiddleXS
        }

        const mergedStyles = {
            content: {
                top: verticalSpace + 'px',
                bottom: 'auto',
                left: '0px',
                right: '0px',
                marginRight: 'auto',
                marginLeft: 'auto',
                marginBottom: verticalSpace + 'px',
                ...modalSizeStyle.content,
                zIndex: 10001,
            },
            overlay: {
                backgroundColor: grey,
                overflowY: 'auto',
                ...modalSizeStyle.overlay,
                zIndex: 10000
            }
        }

        return (
            <ReactModal
                isOpen={this.props.isOpen}
                onRequestClose={this.props.onRequestClose}
                contentLabel="route modal"
                appElement={document.getElementById('root')}
                closeTimeoutMS={150}
                style={mergedStyles}
                onAfterOpen={() => {

                    if (document.getElementsByClassName("ReactModal__MeasureInternalHeight").length > 0) {
                        const modalHeight = document.getElementsByClassName("ReactModal__MeasureInternalHeight")[0].offsetHeight
                        if ((modalHeight + (verticalSpace * 2) + 6) > this.state.height) {
                            //this adds some padding to stop the body content moving due to width increasing
                            document.body.style.paddingRight = `${this.measureScrollbarWidth() || 0}px`;
                            document.body.style.overflow = 'hidden';//overflowY doesnt work in safari

                            // commented out as was breaking the AOS
                            // document.documentElement.style.overflow = 'hidden';//overflowY doesnt work in safari
                        }
                    }

                    if (document.getElementsByClassName("ReactModal__Overlay").length > 0) {
                        document.getElementsByClassName("ReactModal__Overlay")[0].scrollTop = 0
                    }
                }}
                onAfterClose={() => {
                    document.body.style.overflow = 'auto'//overflowY doesnt work in safari

                    // commented out as was breaking the AOS
                    // document.documentElement.style.overflow = 'auto'//overflowY doesnt work in safari


                    document.body.style.paddingRight = '0px'
                }}
            >
                <div className="ReactModal__MeasureInternalHeight">
                    <div

                        style={{
                            margin: '5px',
                            backgroundColor: 'white',
                            borderRadius: '4px',
                            boxShadow: '0 1px 4px 0 rgba(0,0,0,.18)',
                            position: 'relative',
                            marginTop: this.props.title && '25px',
                            zIndex: 10002
                        }}
                    >
                        {this.props.title && (
                            <div 
                                style={{
                                    ...styles.panelHeader
                                }} 
                                className={classnames(
                                    { "bg-light-mint": this.props.color === "green" },
                                    { "bg-light-pink": this.props.color === "pink" },
                                    { "bg-light-yellow": this.props.color === "yellow" },
                                    { "bg-light-teal": this.props.color === "teal" },                                    
                                    { "bg-light-blue": this.props.color === "blue" },                                    
                                )}
                            >
                                <h3 
                                    className={classnames("db-title-block",
                                        { "dark-mint-block": this.props.color === "green" },
                                        { "dark-pink-block": this.props.color === "pink" },
                                        { "dark-yellow-block": this.props.color === "yellow" },
                                        { "dark-teal-block": this.props.color === "teal" },
                                        { "dark-blue-block": this.props.color === "blue" },
                                    )}
                                >
                                    {this.props.title}
                                </h3>
                            </div>
                        )}
                        {this.props.onRequestClose && (
                            <button
                                type="button"
                                className="close"
                                aria-label="Close"
                                style={{ position: 'absolute', top: '6px', right: '12px', zIndex: 10001 }}
                                onClick={this.props.onRequestClose}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        )}

                        <div
                            style={{
                                padding: '25px',
                                marginTop: this.props.title && '-20px',
                                zIndex: 10003,
                            }}>
                            {this.props.loading ? <Spinner /> : this.props.children}
                        </div>
                    </div>
                </div>
            </ReactModal >
        )
    }
}
ModalRoute.defaultProps = {
    isOpen: true,
    size: 'medium',
    color: 'green'
}
export default ModalRoute