import React, { Component } from 'react'
import Modal from '../common/Modal'
import { connect } from 'react-redux';
import { deleteAccount, clearAuthErrors } from '../../actions/authActions'
import Button from '../common/Button'
import Checkbox from '../common/Checkbox'


class DeleteAccountModal extends Component {
    constructor(props) {
        super(props)
        this.state = {
            enableButton: false,
        }
    }

    render() {
        const { auth } = this.props
        const { errors } = auth
        return (
            <Modal
                size="small"
                isOpen={this.props.show}
                onRequestClose={() => {
                    this.props.clearAuthErrors()
                    this.setState({ enableButton: false })
                    this.props.modalClose()
                }}
            >
                <Checkbox
                    name="enabledButton"
                    value={this.state.enableButton}
                    onChange={() => { this.setState({ enableButton: !this.state.enableButton }) }}
                >
                    I understand that this will delete my account
                    </Checkbox>
                <Button
                    fixedWidth
                    className="mx-auto mt-2"
                    colour="pink"
                    disabled={!this.state.enableButton}
                    loading={auth.loading}
                    onClick={this.props.deleteAccount}
                >Delete Account
                </Button>
                <small className="text-danger">{errors.error}</small>
            </Modal >
        )
    }
}

const mapStateToProps = (state) => ({
    auth: state.auth,
})

export default connect(mapStateToProps, { deleteAccount, clearAuthErrors })(DeleteAccountModal);