import React, { Component } from 'react'
import { connect } from 'react-redux'
import Spinner from '../common/Spinner'
import { Route, Switch } from 'react-router-dom';
import { getOrganisationsOwned } from '../../actions/organisationActions'
import CompOrganisationSelect from './CompOrganisationSelect'
import PageOrganisationLandingScreen from './PageOrganisationLandingScreen'
import PageUsers from './PageUsers'
import RouterProductSubRouter from './RouterProductSubRouter';
import AdminEnviroment from './AdminEnviroment';
import UsersIcon from '../../img/Users_Yellow.png';
import ContractsIcon from '../common/ContractsIcon'


import queryString from 'query-string'
import PageContracts from './PageContracts';

class RouterOrganisationRouter extends Component {
    componentDidMount() {
        const parsed = queryString.parse(this.props.location.search);

        if (parsed.organisation) {
            this.props.getOrganisationsOwned(parsed.organisation)
        } else if (sessionStorage.organisation && sessionStorage.organisation !== 'undefined' && this.props.auth.ownedOrganisations.includes(JSON.parse(sessionStorage.organisation).code)) {
            this.props.getOrganisationsOwned(JSON.parse(sessionStorage.organisation).code)
        }
        else {
            this.props.getOrganisationsOwned()
        }
    }


    render() {
        const { match } = this.props;
        const { organisation, organisationLoading, organisationProductMemberships } = this.props.organisations

        let listItems = []
        let titles = []

        organisationProductMemberships.forEach(product => {
            let subItems = []

            if (product.product.enforceAuditCheckBeforeCourse || product.product.showCheckAudit) {
                subItems.push({
                    subItemTitle: 'Check Audit',
                    subItemHref: match.url + '/' + product.organisationproductmembershipid + '/check-audit',
                    disabled: (product.product.enforceLna && !product.lna)
                })
            }

            subItems.push({
                subItemTitle: 'Add Users',
                subItemHref: match.url + '/' + product.organisationproductmembershipid + '/licences',
                disabled: (product.product.enforceLna && !product.lna) || (product.product.enforceAuditCheckBeforeCourse && !product.auditChecked)
            })

            if (product.product.showIg) {
                subItems.push({
                    subItemTitle: 'Information Governance',
                    subItemHref: match.url + '/' + product.organisationproductmembershipid + '/ig',
                    disabled: (product.product.enforceLna && !product.lna)
                })
            }
            if (product.product.offerDataAnonymity) {
                subItems.push({
                    subItemTitle: 'Anonymise data',
                    subItemHref: match.url + '/' + product.organisationproductmembershipid + '/anonymise-data',
                    disabled: (product.product.enforceLna && !product.lna)
                })
            }
            if (product.product.showElearningTab) {
                subItems.push({
                    subItemTitle: 'e-Learning Progress',
                    subItemHref: match.url + '/' + product.organisationproductmembershipid + '/results',
                    disabled: (product.product.enforceLna && !product.lna) || (product.product.enforceAuditCheckBeforeCourse && !product.auditChecked)
                })
            }

            let productTitle = product.product.title
            titles.push(productTitle)
            const countTitles = titles.filter(t => t === productTitle).length
            if (countTitles > 1) {
                productTitle = productTitle + ' ' + countTitles
            }


            listItems.push({
                title: productTitle,
                subItem: subItems,
                href: match.url + '/' + product.organisationproductmembershipid,
                id: product.organisationproductmembershipid,
                icon: product.product.iconUrl
            })

        })
        listItems.push({
            title: 'Manage Organisation',
            href: match.url + '/users',
            id: 'usersLink',
            icon: UsersIcon,
            alt: 'Image of user'
        })

        listItems.push({
            title: 'Contracts',
            href: match.url + '/contracts',
            id: 'contractsLink',
            icon: ContractsIcon,
            alt: 'Image of contracts'
        })



        return (
            <AdminEnviroment
                headerComponent={<Route path={match.url + '/'} component={CompOrganisationSelect} />}
                listItems={listItems}
            >
                <div>
                    {organisation === null || organisationLoading ? (
                        <Spinner large color="yellow" fullScreen />
                    ) : (
                        <Switch>
                            <Route exact path={match.url + '/'} component={PageOrganisationLandingScreen} />
                            <Route exact path={match.url + '/users'} component={PageUsers} />
                            <Route exact path={match.url + '/contracts'} component={PageContracts} />
                            <Route path={match.url + '/:product'} component={RouterProductSubRouter} />
                        </Switch>
                    )}
                </div>
            </AdminEnviroment >
        )
    }
}

const mapStateToProps = (state) => ({
    organisations: state.organisations,
    auth: state.auth
})

export default connect(mapStateToProps, { getOrganisationsOwned })(RouterOrganisationRouter);