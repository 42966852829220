import React from 'react'
import classnames from 'classnames'
import styles from './ImageCircleCard.module.css'
import Button from '../common/Button'
import SocialsIcon from '../common/SocialsIcon'

function ImageCircleCard(props) {

    const header = (
        <div>
            {props.header && <h4><strong>{props.header}</strong></h4>}
            {props.subHeader && <h5>{props.subHeader}</h5>}
        </div>
    )
    const image = props.image && (<div className="mx-auto my-4 px-3" style={{ maxWidth: '300px' }}>
        <div style={{
            backgroundImage: `url("${props.image}")`,
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            borderRadius: props.showImageAsSquare ? '0%' : '50%',
            width: '100%',
            paddingTop: '100%',
        }} />
    </div>)
    const button = props.buttonLink && (
        <Button
            className="mt-3"
            large
            inverted={(props.color.toLowerCase() !== 'white' && props.color.toLowerCase() !== 'none')}
            colour={(props.color.toLowerCase() !== 'white' && props.color.toLowerCase() !== 'none') ? props.color.toLowerCase() : 'green'}
            inline
            link={props.buttonLink}
        >{props.buttonText}</Button>
    )

    const socials = (
        <div>
            {props.linkedIn && (
                <SocialsIcon
                    className={`tw-text-white hover:tw-text-white`}
                    social="linkedin"
                    link={props.linkedIn}
                />
            )}
            {props.twitter && (
                <SocialsIcon
                    className={`tw-text-white hover:tw-text-white`}
                    social="twitter"
                    link={props.twitter}
                />
            )}
        </div>
    )
    return (
        <div
        style={{
        height:props.height100 ?'calc(100% - 24px)' :''
    }}
            className={classnames(
                { "card": props.color.toLowerCase() !== 'none' },
                { [styles.card]: props.color.toLowerCase() !== 'none' },
                "d-block",
                { "text-center": props.textAlign === "center" },
                { "text-right": props.textAlign === "right" },
                { [styles.cardHover]: props.onClick || props.link },
                { [styles.greenHover]: (props.onClick || props.link) && props.color === "green" },
                { [styles.pinkHover]: (props.onClick || props.link) && props.color === "pink" },
                { [styles.tealHover]: (props.onClick || props.link) && props.color === "teal" },
                { [styles.yellowHover]: (props.onClick || props.link) && props.color === "yellow" },
                { [styles.colorYellow]: props.color.toLowerCase() === "yellow" },
                { [styles.colorGreen]: props.color.toLowerCase() === "green" },
                { [styles.colorPink]: props.color.toLowerCase() === "pink" },
                { [styles.colorTeal]: props.color.toLowerCase() === "teal" },
                { [styles.colorNone]: props.color.toLowerCase() === "none" },
            )}
        >
            {props.horizontal === true ? (
                <div className="row">
                    <div className="col-12">
                        {header}
                    </div>
                    <div className="col-6">
                        {image}
                    </div>
                    <div className="col-6">
                        {props.paragraph}
                        {props.embed && <div dangerouslySetInnerHTML={{ __html: props.embed }} />}
                        {button}
                    </div>
                </div>
            ) : (
                    <div>
                        {header}
                        {image}
                        <div>{props.paragraph}</div>
                        {props.embed && <div dangerouslySetInnerHTML={{ __html: props.embed }} />}
                        {button}
                        {socials}
                    </div>
                )}
        </div>
    )
}
ImageCircleCard.defaultProps = {
    color: 'yellow',
    textAlign: 'center'
};
export default ImageCircleCard