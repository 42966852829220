import SectionPage from './SectionPage'


const Community = ({ progress, updateProgress, updating, setUpdating, postError, postErrorRes }) => {
  return (
    <SectionPage 
      progress={progress}
      updateProgress={updateProgress}
      updating={updating} 
      setUpdating={setUpdating}
      postError={postError}
      postErrorRes={postErrorRes}
      tickboxText="I have joined the community"
      sectionName="community"
      title="Join the PLM Community"   
    >
      <div className="mb-4 mt-3">
        <h5 className="font-weight-bold">Introduction</h5>
        <p>The community is a useful tool, providing you with access to like-minded peers who are implementing or have implemented a lifestyle medicine approach within their healthcare setting. The community can be used for advice seeking and support, to share information and ideas as well as providing regular communications and updates on the Prescribing Lifestyle Medicine course.</p>
        <p>Participation within the community forum is an expectation of certification.</p>
        <h5 className="font-weight-bold">What do I need to do?</h5>
        <ol >
          <li>Select the <a href='https://plm-community.practiceunbound.org.uk/' target='_blank'>Community</a> tab from the top of the page.</li>
          <li>To participate in the community you will need to create a profile upon initial access.</li>
          <li>Once you have an active profile we strongly recommend you actively participate in the community. It's a fantastic way to: 
            <span>
              <ul>
                <li><strong>Help others: </strong>Share your knowledge and experience with PLM and the certificate by creating a post about it.</li>
                <li><strong>Connect with your peers: </strong>Ask questions and get valuable advice from using the lifestyle medicine approach.</li>
                <li><strong>Get the support you need: </strong>By sharing your thoughts and experiences, you'll help us understand how to best support you and others in the community.</li>
              </ul>
            </span>
          </li>
          <li>Once you have completed the above steps please indicate this using the tick box, once this has been verified the box will turn green.</li>
        </ol>
        <p>You should revisit the community often as it is full of helpful information and a great place to seek advice and share.</p>
      </div>
    </SectionPage>
  )
}

export default Community
