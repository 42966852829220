import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const ElearningIcon = () => {
    return (
        <span style={{ fontSize: '54px', color: 'white' }}>
            <FontAwesomeIcon icon="graduation-cap" />
        </span>
    )
}
export default ElearningIcon