import React, { Component } from 'react'
import Modal from '../common/Modal'
import { connect } from 'react-redux';
import { updatePassword, clearAuthErrors, setAuthActionSuccess } from '../../actions/authActions'
import TextFieldGroup from '../common/TextFieldGroup'
import Button from '../common/Button'


class UpdatePasswordModal extends Component {
    constructor(props) {
        super(props)
        this.state = {
            password: '',
            newPassword: '',
            newPassword2: '',
            showModal: false
        }
        this.onSubmit = this.onSubmit.bind(this);
        this.onChange = this.onChange.bind(this);
    }

    onChange(e) {
        this.setState({ [e.target.name]: e.target.value })
    }

    onSubmit(e) {
        e.preventDefault();
        this.props.updatePassword(this.state)
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.auth.actionSuccess) {
            setTimeout(() => {
                this.setState({
                    password: '',
                    newPassword: '',
                    newPassword2: '',
                    showModal: false
                }, () => {
                    //additional timeout prevents button rerendering during modal close
                    setTimeout(() => {
                        this.props.setAuthActionSuccess(false)
                    }, 100)
                })
            }, 1000)
        }
    }

    render() {
        const { auth } = this.props
        const { errors } = auth
        return (
            <div>
                <Button center colour='none' onClick={() => { this.setState({ showModal: true }) }}>Update Password</Button>
                <Modal
                    size="small"
                    isOpen={this.state.showModal}
                    onRequestClose={() => {
                        this.props.clearAuthErrors()
                        this.setState({ showModal: false })
                    }}
                >
                    <div className="p-2">
                        <form onSubmit={this.onSubmit}>
                            <TextFieldGroup
                                placeholder="Current password"
                                name='password'
                                type='password'
                                value={this.state.password}
                                onChange={this.onChange}
                                error={errors.password}
                            />
                            <TextFieldGroup
                                placeholder="New password"
                                name='newPassword'
                                type='password'
                                value={this.state.newPassword}
                                onChange={this.onChange}
                                error={errors.newPassword}
                            />
                            <TextFieldGroup
                                placeholder="Repeat password"
                                name='newPassword2'
                                type='password'
                                value={this.state.newPassword2}
                                onChange={this.onChange}
                                error={errors.newPassword2}
                            />
                            <Button
                                fixedWidth
                                className="mx-auto"
                                loading={auth.actionLoading}
                                done={auth.actionSuccess}
                                type="submit"
                            >Update
                </Button>
                            <small className="text-danger">{errors.error}</small>
                        </form>
                    </div>
                </Modal>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    auth: state.auth,
})

export default connect(mapStateToProps, { updatePassword, clearAuthErrors, setAuthActionSuccess })(UpdatePasswordModal);