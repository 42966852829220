import React, { Component } from 'react'
import SideNav from './sidebar/SideNav'
import './AdminEnviroment.css'
import SideDrawer from './sidebar/SideDrawer'
import SideNavMobile from './sidebar/SideNavMobile'


class AdminEnviroment extends Component {

    constructor(props) {
        super(props);
        this.state = { sidebarOpen: true, isMobile: false, sideDrawerOpen: false, width: 0 }
        this.toggleSideNav = this.toggleSideNav.bind(this);
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
        this.closeSideNavs = this.closeSideNavs.bind(this);
    }
    // Desktop sideBar logic ------------------------------------------------------------------- 
    toggleSideNav() {
        const currentState = this.state.sidebarOpen
        this.setState({ sidebarOpen: !currentState })
    }
    componentDidMount() {
        this.updateWindowDimensions();

        window.addEventListener("resize", this.updateWindowDimensions);
    }
    componentWillUnmount() {
        window.removeEventListener("resize", this.updateWindowDimensions);
    }

    updateWindowDimensions() {
        this.setState({
            width: window.innerWidth, height: window.innerHeight,
            isMobile: window.innerWidth <= 768
        })
    }

    // Mobile sideBar logic ------------------------------------------------------------------- 
    drawerToggleClickHandler = () => {
        this.setState((prevState) => {
            return { sideDrawerOpen: !prevState.sideDrawerOpen };
        });
    };
    backdropClickHandler = () => {
        this.setState({ sideDrawerOpen: false });
    };

    // Logic for both ------------------------------------------------------------------- 
    closeSideNavs() {
        this.setState({ sideDrawerOpen: false });
    }


    render() {

        return (
            <div>
                {
                    this.state.isMobile ? (
                        <div>
                            <SideNavMobile click={this.drawerToggleClickHandler} />
                            <SideDrawer
                                listItems={this.props.listItems}
                                headerComponent={this.props.headerComponent}
                                show={this.state.sideDrawerOpen}
                                isMobile={this.state.isMobile}
                                closeSideNavs={this.closeSideNavs}
                            />
                            {
                                this.state.sideDrawerOpen &&
                                <div
                                    className="backdrop"
                                    onClick={this.backdropClickHandler}
                                    style={{
                                        position: 'fixed',
                                        width: '100%',
                                        height: '100%',
                                        top: '0',
                                        left: '0',
                                        background: 'rgba(180,180,180,0.5)',
                                        zIndex: '100',
                                    }}
                                />
                            }
                        </div>
                    ) : (
                            <SideNav
                                listItems={this.props.listItems}
                                headerComponent={this.props.headerComponent}
                                drawerClickHandler={this.drawerToggleClickHandler}
                                toggleSideNav={this.toggleSideNav}
                                updateWindowDimensions={this.updateWindowDimensions}
                                sidebarOpen={this.state.sidebarOpen}
                                width={this.state.width}
                                height={this.state.height}
                                isMobile={this.state.isMobile}
                                closeSideNavs={this.closeSideNavs}
                            />
                        )
                }

                < div
                    style={{
                        paddingTop: this.state.isMobile ? '60px' : '1.5rem',
                        transition: !this.state.isMobile && 'margin-left 0.15s ease-out, width 0.15s ease-out',
                        width: this.state.isMobile ? '100%' : this.state.sidebarOpen ? 'calc(100% - 320px)' : 'calc(100% - 80px)',
                        marginLeft: this.state.isMobile ? 'auto' : this.state.sidebarOpen ? '320px' : '80px',
                    }}
                    className="container" >

                    {this.props.children}
                </div >
            </div >

        )
    }
}

export default AdminEnviroment;