import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import Background from '../../img/circles_bg.svg'
import { logoutUser } from '../../actions/authActions'
import { connect } from 'react-redux';
import PUPanel from '../layout/PUPanel'

class StripeSuccess extends Component {
    componentDidMount() {
        this.props.logoutUser()
        setTimeout(this.props.logoutUser, 1000)
    }
    render() {

        return (
            <div style={{
                backgroundImage: 'url("' + Background + '")',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                overflow: 'hidden',
                backgroundPosition: 'center',
                minHeight: 'calc(100vh - 54px)'
            }}>
                <div className="container pb-5" >
                    <div className="row justify-content-center">
                        <div className="col-lg-8 col-md-10 pt-5">
                            {this.props.location.pathname === '/purchase/plmaccreditationandcourse/success' ? (
                                <PUPanel color='blue' title="Purchase Complete">
                                    <p className="lead">Thank you for enrolling onto Prescribing Lifestyle Medicine and The Crossfields Certificate in Lifestyle Medicine. </p>
                                    <p>You should now receive an email from ‘Practice Unbound’ that tells you how to access Prescribing Lifestyle Medicine and how to start the process of completing your online course and Certificate in Lifestyle Medicine. </p>
                                    <p>If you have not received an email after 30 minutes, please contact us here and we can look into it for you. In the meantime, you can go back to the Prescribing Lifestyle Medicine Homepage <Link to='/product/prescribinglifestylemedicine'>here</Link>.</p>
                                    <p>We look forward to hearing about how you progress through the course and subsequent Certificate in Lifestyle Medicine.</p>
                                </PUPanel>
                            ) : this.props.location.pathname === '/purchase/plmaccreditationonly/success' ? (
                                <PUPanel color="blue" title="Purchase Complete">
                                    <p className="lead">Thank you for enrolling onto The Crossfields Certificate in Lifestyle Medicine. </p>
                                    <p>You should now receive an email from ‘Practice Unbound’ that tells you how to access the platform and how to start the process of completing your Certificate in Lifestyle Medicine.</p>
                                    <p>If you have not received an email after 30 minutes, please contact us here and we can look into it for you.</p>
                                    <p>In the meantime, you can read about the Certificate in Lifestyle Medicine <Link to='/product/prescribinglifestylemedicine/accreditation'>here</Link>, and we look forward to receiving your submissions.</p>
                                </PUPanel>
                            ) : (
                                <PUPanel title="Success!">
                                    <p className="lead">Congratulations! Your purchase was successful.</p>
                                    <p>Details on how to login have been sent to all of the users purchased for - if you are one of them check your inbox for next steps.</p>
                                </PUPanel>
                            )}

                        </div>
                    </div>
                </div>
            </div >

        )
    }
}
export default connect(() => { return {} }, { logoutUser })(StripeSuccess);