import { combineReducers } from 'redux';
import authReducer from './authReducer';
import profileReducer from './profileReducer';
import productReducer from './productReducer';
import organisationReducer from './organisationReducer'
import elearningReducer from './elearningReducer'
import blogReducer from './blogReducer'
import pagesReducer from './pagesReducer'
import purchaseReducer from './purchaseReducer';
import assessmentReducer from './assessmentReducer';


//This is the strategy to combine all reducers into one single reducer, then pass it to the provider
export default combineReducers({
    auth: authReducer,
    profile: profileReducer,
    products: productReducer,
    organisations: organisationReducer,
    elearning: elearningReducer,
    blogs: blogReducer,
    pages: pagesReducer,
    purchase: purchaseReducer,
    assessments: assessmentReducer
})