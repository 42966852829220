import api from '../config/axios';
import {
    GET_ORGANISATIONS,
    SET_ORGANISATION_LOADING,
    GET_ORGANISATION_CONTRACTS,
    SET_ORGANISATION_CONTRACTS_LOADING,
    CLEAR_ORGANISATIONS,
    CLEAR_ORGANISATION,
    GET_ORGANISATION_PRODUCT_MEMBERSHIPS,
    GET_ORGANISATION_PRODUCT_MEMBERSHIP,
    CLEAR_ORGANISATION_PRODUCT_MEMBERSHIP,
    SET_ORGANISATION_PRODUCT_MEMBERSHIP_LOADING,
    SET_ORGANISATION_PRODUCT_MEMBERSHIPS_LOADING,
    GET_ORGANISATION,
    GET_ORGANISATION_ERRORS,
    CLEAR_ORGANISATION_ERRORS,
    CHECK_USER_EXISTS_LOADING,
    SET_CHECK_USER_EXISTS,
    SET_ORGANISATION_ACTION_SUCCESS,
    SET_ORGANISATION_ACTION_LOADING,
    SET_ORGANISATION_PRODUCT_MEMBERSHIP_ACTION_SUCCESS,
    SET_ORGANISATION_PRODUCT_MEMBERSHIP_ACTION_LOADING,
    GET_ORGANISATIONS_SET_SINGLE,
    UPDATE_ORGANISATION_PRODUCT_MEMBERSHIP
} from './types';
import isEmpty from '../utils/is-empty'
import { setToken } from './authActions'
import { logoutUser } from './authActions'

export const getOrganisationsByProduct = (product) => dispatch => {
    dispatch({ type: CLEAR_ORGANISATIONS });
    dispatch({ type: CLEAR_ORGANISATION_ERRORS });
    api.platformAPI.get('/api/platform/organisations/product/' + product)
        .then(res => {
            dispatch({
                type: GET_ORGANISATIONS_SET_SINGLE,
                payload: res.data
            });
        })
        .catch(err => {
            dispatch(handleError(err))
        })
}
export const getOrganisationsOwned = (chooseOrg) => dispatch => {
    dispatch({ type: CLEAR_ORGANISATIONS });
    dispatch({ type: CLEAR_ORGANISATION_ERRORS });
    api.platformAPI.get('/api/platform/organisations/owned')
        .then(res => {
            if (res.data.length > 0) {
                if (chooseOrg && res.data.map(d => d.code).includes(chooseOrg)) {
                    dispatch(getOrganisationOwned(chooseOrg))
                } else {
                    dispatch(getOrganisationOwned(res.data[0].code))
                }
            }
            dispatch({
                type: GET_ORGANISATIONS,
                payload: res.data
            });

        })
        .catch(err => {
            dispatch(handleError(err))
        })
}


export const getOrganisationOwned = (code) => dispatch => {
    dispatch({ type: SET_ORGANISATION_LOADING, payload: true })
    dispatch({ type: SET_ORGANISATION_PRODUCT_MEMBERSHIPS_LOADING, payload: true })
    dispatch({ type: CLEAR_ORGANISATION_ERRORS });
    dispatch(getOrganisationContracts(code))
    api.platformAPI.get('/api/platform/organisations/owned/' + code)
        .then(res => {
            dispatch({
                type: GET_ORGANISATION,
                payload: res.data.organisation
            });
            dispatch({
                type: GET_ORGANISATION_PRODUCT_MEMBERSHIPS,
                payload: res.data.organisationProductMemberships
            });
        })
        .catch(err => {
            dispatch(handleError(err))
            dispatch({ type: SET_ORGANISATION_LOADING, payload: false })
            dispatch({ type: SET_ORGANISATION_PRODUCT_MEMBERSHIPS_LOADING, payload: false })
        })
}


export const clearOrganisationProductMembership = () => dispatch => {
    dispatch({ type: CLEAR_ORGANISATION_PRODUCT_MEMBERSHIP });

}

export const getOrganisationProductMembershipOwned = (code, id) => dispatch => {
    dispatch({ type: SET_ORGANISATION_PRODUCT_MEMBERSHIP_LOADING, payload: true })
    dispatch({ type: CLEAR_ORGANISATION_ERRORS });
    api.platformAPI.get('/api/platform/organisations/ownedproducts/' + code + '/' + id)
        .then(res => {
            dispatch({
                type: GET_ORGANISATION_PRODUCT_MEMBERSHIP,
                payload: res.data
            });
        })
        .catch(err => {
            dispatch(handleError(err))
            dispatch({ type: SET_ORGANISATION_PRODUCT_MEMBERSHIP_LOADING, payload: false })
        })
}

export const changeOrganisationProductMembershipUsers = (code, id, data) => dispatch => {
    dispatch({ type: SET_ORGANISATION_PRODUCT_MEMBERSHIP_ACTION_LOADING, payload: true });
    dispatch({ type: CLEAR_ORGANISATION_ERRORS });
    api.platformAPI.post('/api/platform/organisations/ownedproducts/users/' + code + '/' + id, data)
        .then(res => {
            dispatch({
                type: GET_ORGANISATION_PRODUCT_MEMBERSHIP,
                payload: res.data
            });
            dispatch({
                type: SET_ORGANISATION_PRODUCT_MEMBERSHIP_ACTION_SUCCESS,
                payload: true
            });
        })
        .catch(err => {
            dispatch(handleError(err))
            dispatch({ type: SET_ORGANISATION_PRODUCT_MEMBERSHIP_ACTION_LOADING, payload: false });
        })
}

export const clearOrganisations = () => dispatch => {
    dispatch({ type: CLEAR_ORGANISATIONS });
}

export const clearOrganisation = () => dispatch => {
    dispatch({ type: CLEAR_ORGANISATION });
}

export const setOrganisation = (organisation) => dispatch => {
    dispatch({ type: GET_ORGANISATION, payload: organisation });
}

export const checkMemberEmailLoading = () => dispatch => {
    dispatch({ type: CHECK_USER_EXISTS_LOADING, payload: true });
}

export const setOrganisationActionSuccess = (data) => dispatch => {
    dispatch({ type: SET_ORGANISATION_ACTION_SUCCESS, payload: data });
}

export const setOrganisationProductMembershipActionSuccess = (data) => dispatch => {
    dispatch({ type: SET_ORGANISATION_PRODUCT_MEMBERSHIP_ACTION_SUCCESS, payload: data });
}



export const checkMemberEmail = (email, organisationCode) => dispatch => {
    dispatch({ type: CHECK_USER_EXISTS_LOADING, payload: true });
    dispatch({ type: CLEAR_ORGANISATION_ERRORS });
    api.platformAPI.post('/api/platform/organisations/checkemail/' + organisationCode, { email })
        .then(res => {
            dispatch({
                type: SET_CHECK_USER_EXISTS,
                payload: res.data
            });
        })
        .catch(err => {
            dispatch(handleError(err))
            dispatch({ type: CHECK_USER_EXISTS_LOADING, payload: false });
        })
}



export const createNewUser = (data, organisationCode, orgProdMemId) => dispatch => {
    dispatch({ type: SET_ORGANISATION_ACTION_LOADING, payload: true });
    dispatch({ type: CLEAR_ORGANISATION_ERRORS })
    api.platformAPI.post('/api/platform/organisations/adduser/' + organisationCode, { ...data, orgProdMemId })
        .then(res => {
            dispatch({
                type: SET_ORGANISATION_ACTION_SUCCESS,
                payload: true
            });
            dispatch({
                type: GET_ORGANISATION,
                payload: res.data.organisation
            });
            dispatch({
                type: GET_ORGANISATION_PRODUCT_MEMBERSHIPS,
                payload: res.data.organisationProductMemberships
            });
            if (orgProdMemId) {
                dispatch({
                    type: GET_ORGANISATION_PRODUCT_MEMBERSHIP,
                    payload: res.data.organisationProductMembership
                });
            }
        })
        .catch(err => {
            dispatch(handleError(err))
            dispatch({ type: SET_ORGANISATION_ACTION_LOADING, payload: false });
        })
}

export const addOrganisationMembership = (email, organisationCode, orgProdMemId, jobTitle) => dispatch => {
    dispatch({ type: SET_ORGANISATION_ACTION_LOADING, payload: true });
    dispatch({ type: CLEAR_ORGANISATION_ERRORS })
    api.platformAPI.post('/api/platform/organisations/addmembership/' + organisationCode, { email, orgProdMemId, jobTitle })
        .then(res => {
            dispatch({
                type: SET_ORGANISATION_ACTION_SUCCESS,
                payload: true
            });
            dispatch({
                type: GET_ORGANISATION,
                payload: res.data.organisation
            });
            dispatch({
                type: GET_ORGANISATION_PRODUCT_MEMBERSHIPS,
                payload: res.data.organisationProductMemberships
            });
            if (orgProdMemId) {
                dispatch({
                    type: GET_ORGANISATION_PRODUCT_MEMBERSHIP,
                    payload: res.data.organisationProductMembership
                });
            }
        })
        .catch(err => {
            dispatch(handleError(err))
            dispatch({ type: SET_ORGANISATION_ACTION_LOADING, payload: false });
        })
}


export const removeOrganisationMembership = (user, organisationCode) => dispatch => {
    dispatch({ type: SET_ORGANISATION_ACTION_LOADING, payload: true });
    dispatch({ type: CLEAR_ORGANISATION_ERRORS });
    api.platformAPI.post('/api/platform/organisations/removeuser/' + organisationCode, { user })
        .then(res => {
            dispatch({
                type: SET_ORGANISATION_ACTION_SUCCESS,
                payload: res.data
            });
            dispatch({
                type: GET_ORGANISATION,
                payload: res.data.organisation
            });
            dispatch({
                type: GET_ORGANISATION_PRODUCT_MEMBERSHIPS,
                payload: res.data.organisationProductMemberships
            });
        })
        .catch(err => {
            dispatch(handleError(err))
            dispatch({ type: SET_ORGANISATION_ACTION_LOADING, payload: false });
        })
}

export const signIg = (code, id) => dispatch => {
    dispatch({ type: SET_ORGANISATION_PRODUCT_MEMBERSHIP_ACTION_LOADING, payload: true });
    dispatch({ type: CLEAR_ORGANISATION_ERRORS });
    api.platformAPI.post('/api/platform/organisations/ownedproducts/ig/' + code + '/' + id)
        .then(res => {
            dispatch({
                type: GET_ORGANISATION_PRODUCT_MEMBERSHIP,
                payload: res.data
            });
            dispatch({ type: SET_ORGANISATION_PRODUCT_MEMBERSHIP_ACTION_SUCCESS, payload: true })
        })
        .catch(err => {
            dispatch(handleError(err))
            dispatch({ type: SET_ORGANISATION_PRODUCT_MEMBERSHIP_ACTION_LOADING, payload: false });
        })
}

export const setAnonymiseData = (code, id, payload) => dispatch => {
    dispatch({ type: SET_ORGANISATION_PRODUCT_MEMBERSHIP_ACTION_LOADING, payload: true });
    dispatch({ type: CLEAR_ORGANISATION_ERRORS });
    api.platformAPI.post('/api/platform/organisations/ownedproducts/anonymise-data/' + code + '/' + id, payload)
        .then(res => {
            dispatch({
                type: GET_ORGANISATION_PRODUCT_MEMBERSHIP,
                payload: res.data
            });
            dispatch({ type: SET_ORGANISATION_PRODUCT_MEMBERSHIP_ACTION_SUCCESS, payload: true })
        })
        .catch(err => {
            dispatch(handleError(err))
            dispatch({ type: SET_ORGANISATION_PRODUCT_MEMBERSHIP_ACTION_LOADING, payload: false });
        })
}






export const setAuditChecked = (code, id, payload) => dispatch => {
    dispatch({ type: SET_ORGANISATION_PRODUCT_MEMBERSHIP_ACTION_LOADING, payload: true });
    dispatch({ type: CLEAR_ORGANISATION_ERRORS });
    api.platformAPI.post('/api/platform/organisations/ownedproducts/check-audit/' + code + '/' + id, payload)
        .then(res => {
            dispatch({
                type: GET_ORGANISATION_PRODUCT_MEMBERSHIP,
                payload: res.data
            });
            dispatch({
                type: UPDATE_ORGANISATION_PRODUCT_MEMBERSHIP,
                payload: res.data
            });
            dispatch({ type: SET_ORGANISATION_PRODUCT_MEMBERSHIP_ACTION_SUCCESS, payload: true })
        })
        .catch(err => {
            dispatch(handleError(err))
            dispatch({ type: SET_ORGANISATION_PRODUCT_MEMBERSHIP_ACTION_LOADING, payload: false });
        })
}



export const sendInvites = (code, id, data) => dispatch => {
    dispatch({ type: SET_ORGANISATION_PRODUCT_MEMBERSHIP_ACTION_LOADING, payload: true });
    dispatch({ type: CLEAR_ORGANISATION_ERRORS });
    api.platformAPI.post('/api/platform/organisations/ownedproducts/invites/' + code + '/' + id, data)
        .then(res => {
            dispatch({
                type: GET_ORGANISATION_PRODUCT_MEMBERSHIP,
                payload: res.data
            });
            dispatch({ type: SET_ORGANISATION_PRODUCT_MEMBERSHIP_ACTION_SUCCESS, payload: true })
        })
        .catch(err => {
            dispatch(handleError(err))
            dispatch({ type: SET_ORGANISATION_PRODUCT_MEMBERSHIP_ACTION_LOADING, payload: false });
        })
}


export const invitesOptOut = (code, id) => dispatch => {
    dispatch({ type: CLEAR_ORGANISATION_ERRORS });
    api.platformAPI.post('/api/platform/organisations/ownedproducts/invitesOptOut/' + code + '/' + id)
        .then(res => {
            dispatch({
                type: GET_ORGANISATION_PRODUCT_MEMBERSHIP,
                payload: res.data
            });
        })
        .catch(err => {
            dispatch(handleError(err))
        })
}

export const usersOptOut = (code, id) => dispatch => {
    dispatch({ type: CLEAR_ORGANISATION_ERRORS });
    api.platformAPI.post('/api/platform/organisations/ownedproducts/usersOptOut/' + code + '/' + id)
        .then(res => {
            dispatch({
                type: GET_ORGANISATION_PRODUCT_MEMBERSHIP,
                payload: res.data
            });
        })
        .catch(err => {
            dispatch(handleError(err))
        })
}


export const submitLNA = (orgCode, opm, data) => dispatch => {
    dispatch({ type: SET_ORGANISATION_PRODUCT_MEMBERSHIP_ACTION_LOADING, payload: true })
    api.platformAPI.post(`/api/platform/lnas/${orgCode}/${opm}`, data)
        .then(res => {
            dispatch({ type: GET_ORGANISATION_PRODUCT_MEMBERSHIP, payload: res.data })
            dispatch({ type: UPDATE_ORGANISATION_PRODUCT_MEMBERSHIP, payload: res.data })
            dispatch({ type: SET_ORGANISATION_PRODUCT_MEMBERSHIP_ACTION_SUCCESS, payload: true })
        })
        .catch(err => {
            dispatch({ type: SET_ORGANISATION_PRODUCT_MEMBERSHIP_ACTION_LOADING, payload: false })
            dispatch(handleError(err))
        })
}


export const getPracticeByCode = (orgCode) => dispatch => {
    dispatch({ type: CLEAR_ORGANISATION })
    dispatch({ type: SET_ORGANISATION_LOADING, payload: true })
    dispatch({ type: CLEAR_ORGANISATION_ERRORS })
    api.platformAPI.get(`/api/platform/organisations/setuppractice/${orgCode}`)
        .then(res => {
            dispatch({ type: GET_ORGANISATION, payload: res.data })
        })
        .catch(err => {
            dispatch({ type: SET_ORGANISATION_LOADING, payload: false })
            dispatch(handleError(err))
        })
}

export const makePracticeLead = (orgCode) => dispatch => {
    dispatch({ type: CLEAR_ORGANISATION_ERRORS })
    dispatch({ type: SET_ORGANISATION_ACTION_LOADING, payload: true })
    api.platformAPI.post(`/api/platform/organisations/setuppractice/${orgCode}`)
        .then(res => {
            dispatch(setToken(localStorage.jwtToken, true)) //refresh token
            dispatch({ type: GET_ORGANISATION, payload: res.data })
            dispatch({ type: SET_ORGANISATION_ACTION_SUCCESS, payload: true })
        })
        .catch(err => {
            dispatch(handleError(err))
            dispatch({ type: SET_ORGANISATION_ACTION_LOADING, payload: false })
        })
}



export const promoteUser = (data, organisationCode) => dispatch => {
    dispatch({ type: SET_ORGANISATION_ACTION_LOADING, payload: true });
    dispatch({ type: CLEAR_ORGANISATION_ERRORS })
    api.platformAPI.post('/api/platform/organisations/promoteuser/' + organisationCode, data)
        .then(res => {
            dispatch({
                type: SET_ORGANISATION_ACTION_SUCCESS,
                payload: true
            });
            dispatch({
                type: GET_ORGANISATION,
                payload: res.data.organisation
            });
            dispatch({
                type: GET_ORGANISATION_PRODUCT_MEMBERSHIPS,
                payload: res.data.organisationProductMemberships
            });
        })
        .catch(err => {
            dispatch(handleError(err))
            dispatch({ type: SET_ORGANISATION_ACTION_LOADING, payload: false });
        })
}

export const demoteUser = (data, organisationCode) => dispatch => {
    dispatch({ type: SET_ORGANISATION_ACTION_LOADING, payload: true });
    dispatch({ type: CLEAR_ORGANISATION_ERRORS })
    api.platformAPI.post('/api/platform/organisations/demoteuser/' + organisationCode, data)
        .then(res => {
            dispatch({
                type: SET_ORGANISATION_ACTION_SUCCESS,
                payload: true
            });
            dispatch({
                type: GET_ORGANISATION,
                payload: res.data.organisation
            });
            dispatch({
                type: GET_ORGANISATION_PRODUCT_MEMBERSHIPS,
                payload: res.data.organisationProductMemberships
            });
        })
        .catch(err => {
            dispatch(handleError(err))
            dispatch({ type: SET_ORGANISATION_ACTION_LOADING, payload: false });
        })
}


export const getOrganisationContracts = (organisationCode) => dispatch => {
    dispatch({ type: SET_ORGANISATION_CONTRACTS_LOADING, payload: true });
    api.platformAPI.get('/api/platform/organisations/crmcontracts/' + organisationCode)
        .then(res => {
            dispatch({
                type: GET_ORGANISATION_CONTRACTS,
                payload: res.data
            });

        })
        .catch(err => {
            dispatch(handleError(err))
            dispatch({ type: SET_ORGANISATION_CONTRACTS_LOADING, payload: false });
        })
}




const handleError = (err) => dispatch => {
    let payload
    console.dir(err)

    //token expired
    if (err.response) {
        if (err.response.statusText === "Unauthorized" && err.response.status === 401 && err.response.data === "Unauthorized") {
            dispatch(logoutUser())
        }
    }

    if (isEmpty(err.response)) {
        payload = { error: err.message }
    } else {
        if (isEmpty(err.response.data)) {
            payload = { error: err.message }
        } else {
            payload = err.response.data
        }
    }

    dispatch({
        type: GET_ORGANISATION_ERRORS,
        payload: payload
    })
}

export const clearOrganisationErrors = () => dispatch => {
    dispatch({ type: CLEAR_ORGANISATION_ERRORS })
}