const detectIdle = (callback, threshold) => {
    let timer

    function resetTimer() {
        clearInterval(timer);
        localStorage.lastActivity = new Date()
        timer = setInterval(incrementTimer, 1000);
    }

    // Define the events that
    // would reset the timer
    window.onload = resetTimer;
    // window.onmousemove = resetTimer;
    window.onmousedown = resetTimer;
    window.ontouchstart = resetTimer;
    window.onclick = resetTimer;
    window.onkeypress = resetTimer;
    window.onscroll = resetTimer;

    const incrementTimer = () => {
        const secondsPassed = Math.floor((new Date() - new Date(localStorage.lastActivity)) / 1000)
        if (Number.isInteger(secondsPassed / 60) && secondsPassed > 0) {
            console.log(`Idle for ${secondsPassed / 60} minutes`)
        }

        if (secondsPassed >= threshold) {
            console.log("Time up")
            callback()
            resetTimer()
        }
    }
    resetTimer()
}
export default detectIdle