import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faMoneyCheck } from '@fortawesome/free-solid-svg-icons'
library.add(faMoneyCheck)

const ContractsIcon = () => {
    return (
        <span style={{ color: '#be8c1a ' }}>
            <FontAwesomeIcon icon="money-check" />
        </span>
    )
}
export default ContractsIcon