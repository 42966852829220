import React, { Component } from 'react'
import { connect } from 'react-redux';
import { searchResources, setSearchResourcesLoading } from '../../actions/productActions'
import TextFieldGroup from '../common/TextFieldGroup'
import Spinner from '../common/Spinner'
import { CSSTransition, TransitionGroup } from 'react-transition-group'

let searchTimeout = null
class ResourcesRouter extends Component {
    constructor(props) {
        super(props)
        this.state = {
            focus: false,
            search: ''
        }
    }
    render() {
        const { resourcesSearch, resourcesSearchLoading, product } = this.props.products
        return (
            <div style={{ position: 'relative' }}>
                <TextFieldGroup
                    name="search"
                    autocomplete="none"
                    inline
                    placeholder="search..."
                    onFocus={() => { this.setState({ focus: true }) }}
                    onBlur={() => { this.setState({ focus: false }) }}
                    onChange={
                        (e) => {
                            this.props.setSearchResourcesLoading()
                            const search = e.target.value
                            this.setState({ search })
                            clearTimeout(searchTimeout);
                            searchTimeout = setTimeout(() => {
                                if (search.length > 0) {
                                    this.props.searchResources(product.name, search)
                                }
                            }, 1000);
                        }
                    } />
                <TransitionGroup appear={true}>
                    {(this.state.focus && this.state.search.length > 1) && (
                        <CSSTransition
                            classNames="fadeGrow"
                            timeout={300}
                        >
                            <div
                                style={{
                                    position: 'absolute',
                                    borderRadius: '4px',
                                    boxShadow: '0 1px 4px 0 rgba(0,0,0,.15)',
                                    backgroundColor: 'white',
                                    marginTop: '-12px',
                                    zIndex: 1,
                                    width: '300px'
                                }}
                            >
                                {resourcesSearchLoading ? (
                                    <div className="text-center p-4">
                                        <Spinner color="yellow" />
                                    </div>
                                ) : resourcesSearch.length === 0 ? (
                                    <h6 className="text-center text-muted my-0 p-4">No Results</h6>
                                ) : (
                                    <div className="list-group list-group-flush">
                                        {
                                            resourcesSearch.map(r => {
                                                const href = '/product/' + product.name + '/resources/' + r.pageSlug + '#' + r.sectionTitle
                                                return (
                                                    <a
                                                        key={href}
                                                        href={href}
                                                        className="list-group-item list-group-item-action"
                                                        onMouseDown={() => {
                                                            window.location.href = href
                                                        }}
                                                    >
                                                        <strong className="d-block">{r.sectionTitle} / {r.pageTitle}</strong>
                                                        <small className="my-0 py-0">{r.section}</small>
                                                    </a>
                                                )
                                            })

                                        }
                                    </div>
                                )}
                            </div>
                        </CSSTransition>
                    )}
                </TransitionGroup>
            </div>
        )
    }
}
const mapStateToProps = state => ({
    products: state.products,
})
export default connect(mapStateToProps, { searchResources, setSearchResourcesLoading })(ResourcesRouter);