import {
    GET_ELEARNING_LAUNCH_URL,
    SET_ELEARNING_LAUNCH_URL_LOADING,
    GET_ELEARNING_RESULTS,
    SET_ELEARNING_RESULTS_LOADING,
    ADD_ELEARNING_RESULT,
    CLEAR_ELEARNING_ERRORS,
    GET_ELEARNING_ERRORS,
} from '../actions/types'

const initialState = {
    launchUrl: null,
    launchUrlEncoded: null,
    launchUrlLoading: false,
    results: [],
    resultsLoading: false,
    errors: {}
}

export default function elearningReducer(state = initialState, action) {
    switch (action.type) {
        case GET_ELEARNING_ERRORS:
            return {
                ...state,
                errors: action.payload
            }
        case CLEAR_ELEARNING_ERRORS:
            return {
                ...state,
                errors: {}
            }
        case SET_ELEARNING_LAUNCH_URL_LOADING:
            return {
                ...state,
                launchUrlLoading: action.payload
            }
        case GET_ELEARNING_LAUNCH_URL:
            return {
                ...state,
                launchUrl: action.payload.url,
                launchUrlEncoded: action.payload.encodedUrl,
                launchUrlLoading: false
            }
        case SET_ELEARNING_RESULTS_LOADING:
            return {
                ...state,
                resultsLoading: action.payload
            }
        case GET_ELEARNING_RESULTS:
            return {
                ...state,
                results: action.payload,
                resultsLoading: false
            }


        case ADD_ELEARNING_RESULT:
            const addIndex = state.results.map(x => { return x._id }).indexOf(action.payload._id)
            if (addIndex === -1) {
                state.results.push(action.payload)
                return { ...state, resultsLoading: false }
            } else {
                state.results.splice(addIndex, 1, action.payload)
                return { ...state, resultsLoading: false }
            }
        default:
            return state;
    }
}