import React from 'react'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import UserRow from './UserRow'

export default function UserList(props) {
    return (
        <div className="mb-4">
            <h4 className='mb-2'><strong>{props.title}</strong></h4>
            <TransitionGroup appear>
                {props.users && props.users.map(user => {

                    return (
                        <CSSTransition
                            key={user.id}
                            classNames="fade"
                            timeout={400}
                        >

                            <UserRow
                                user={user}
                                minus={user.minus}
                                minusAction={props.minusAction}
                                promote={user.promote}
                                promoteAction={props.promoteAction}
                                demote={user.demote}
                                demoteAction={props.demoteAction}
                                className="mb-4"

                            />
                        </CSSTransition>
                    )


                })
                }
            </TransitionGroup>
        </div>
    )
}

