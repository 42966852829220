import SectionPage from './SectionPage'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'


const Assessment = ({ progress, updateProgress, updating, setUpdating, postErrorRes, errorRes }) => {
  return (
    <SectionPage 
      progress={progress}
      updateProgress={updateProgress}
      updating={updating} 
      setUpdating={setUpdating}
      postErrorRes={postErrorRes}
      errorRes={errorRes}
      sectionName="assessment"  
      title="Complete Assessment" 
      uploadText="Click or drag your completed assessment here" 
      fileType={"text"} 
      fileTypeError="file type needs to be a word doc"
    >
      <div className="mb-4 mt-3">
        <div className="mb-4 mt-3">
          <h5 className="font-weight-bold">Introduction</h5>
          <p>As part of the evaluation process, you will undergo an assessment of your understanding of a lifestyle medicine approach and its wider implications through a short question exercise.</p>
          <p>The assessment is composed of four questions, and you should provide your answers using the document provided.</p>          
          <h5 className="font-weight-bold">What do I need to do?</h5>          
          <p>The <a href='https://practiceunbound.blob.core.windows.net/documents/short-answer-assesment-v4.docx'>short answer assessment</a> should be attempted after you have completed the four Prescribing Lifestyle Medicine eLearning modules <Link to="/product/prescribinglifestylemedicine/accreditation/elearning">(see step 1)</Link> and either during or after you have undertaken 85 hours of lifestyle medicine consultations <Link to="/product/prescribinglifestylemedicine/accreditation/interactions">(see step 3)</Link>.</p>
          <p>Once you have completed the assessment you will need to upload it for approval, using the submission box below. You will find further information within the <a href='https://practiceunbound.blob.core.windows.net/documents/user-guide-short-answer-assesment-v5.pdf'>user guide</a>.</p>
          <p><em>*Please note that this step is distinct from the self-assessment section of the PLM course which you should use to support your continuous learning.</em></p>
        </div>        
      </div>
    </SectionPage>
  )
}

export default Assessment