import React, { Component } from 'react'
import styled, { keyframes } from 'styled-components'

class FullScreen extends Component {
    constructor() {
        super()
        this.elementClosed = React.createRef();
        this.elementOpen = React.createRef();
        this.initState = {
            openInit: false,
            opening: false,
            open: false,
            closing: false,
            closed: true,
            startTop: 0,
            startRight: 0,
            startBottom: 0,
            startLeft: 0,
            endTop: 0,
            endRight: 0,
            endBottom: 0,
            endLeft: 0,
            diffWidth: 0,
            diffHeight: 0,
            diffLeft: 0,
            diffTop: 0
        }
        this.state = this.initState

        this.duration = 400
        this.perspective = '800px'
        this.currentScrollPosition = 0 //didnt use state as it caused wasteful rerenders
    }


    componentDidMount() {
        window.addEventListener("scroll", this.updateScrollPosition);
        window.addEventListener("keydown", this.keyDown);
        this.updateScrollPosition()
    }
    componentWillUnmount() {
        window.removeEventListener("scroll", this.updateScrollPosition);
    }

    measureScrollbarWidth() {
        let scrollBarWidth = 0;
        if (document.body) {
            let scrollbox = document.createElement('div');
            scrollbox.style.overflow = 'scroll';
            scrollbox.style.display = 'inline-block';//fixes IE clientwidth 0
            document.body.appendChild(scrollbox);
            scrollBarWidth = scrollbox.offsetWidth - scrollbox.clientWidth;
            document.body.removeChild(scrollbox);
        }
        if (scrollBarWidth < 30) {
            return scrollBarWidth
        } else {
            return 0
        }

    }

    updateScrollPosition = () => {
        this.currentScrollPosition = window.pageYOffset //didnt use state as it caused wasteful rerenders
    }

    keyDown = (e) => {
        if (e.key === "Escape" && this.state.open) {
            this.closingAction()
        }
    }

    openInitAction = () => {
        this.setState({
            openInit: true,
            opening: false,
            open: false,
            closing: false,
            closed: false,
        }, this.openStateSnapshot)
    }

    openStateSnapshot = () => {
        if (this.elementClosed.current && this.elementOpen.current) {

            const closed = this.elementClosed.current
            const open = this.elementOpen.current

            const startTop = closed.getBoundingClientRect().top
            const startLeft = closed.getBoundingClientRect().left
            const startRight = window.innerWidth - closed.getBoundingClientRect().left - closed.offsetWidth - (this.measureScrollbarWidth() || 0)

            const startBottom = window.innerHeight - closed.getBoundingClientRect().top - closed.offsetHeight
            const endTop = open.getBoundingClientRect().top

            const endLeft = open.getBoundingClientRect().left
            const endRight = window.innerWidth - open.getBoundingClientRect().left - open.offsetWidth
            const endBottom = window.innerHeight - open.getBoundingClientRect().top - open.offsetHeight

            this.setState({
                openInit: true,
                opening: false,
                open: false,
                closing: false,
                closed: false,
                startTop,
                startLeft,
                startBottom,
                startRight,
                startWidth: closed.offsetWidth,
                startHeight: closed.offsetHeight,
                startScrollPosition: window.pageYOffset,
                endTop,
                endLeft,
                endBottom,
                endRight,
                endWidth: open.offsetWidth,
                endHeight: open.offsetHeight,
                diffWidth: parseFloat(closed.offsetWidth) / parseFloat(open.offsetWidth),
                diffHeight: parseFloat(closed.offsetHeight) / parseFloat(open.offsetHeight),
                diffTop: startTop - endTop,
                diffLeft: startLeft - endLeft
            }, this.openingAction)
        }
    }

    openingAction = () => {
        this.setState({
            openInit: true,
            opening: true,
            open: false,
            closing: false,
            closed: false,
        }, () => {
            setTimeout(this.openAction, this.duration - 20)
        })
    }

    openAction = () => {
        this.setState({
            openInit: true,
            opening: false,
            open: true,
            closing: false,
            closed: false,
        })
    }
    closingAction = () => {

        this.setState({
            openInit: false,
            opening: false,
            open: false,
            closing: true,
            closed: false,
        })
        setTimeout(this.closedAction, this.duration - 20)
    }
    closedAction = () => {
        this.setState(this.initState)
        if (this.props.onClose) {
            this.props.onClose()
        }
    }


    render() {
        const rotateY = 180
        const easing = 'ease-in-out'

        const scaleFrontX = 1 / this.state.diffWidth
        const scaleFrontY = 1 / this.state.diffHeight
        const translateFrontX = -this.state.diffLeft
        const translateFrontY = -this.state.diffTop - (this.state.startScrollPosition - this.currentScrollPosition)

        const scaleBackX = this.state.diffWidth
        const scaleBackY = this.state.diffHeight
        const translateBackX = this.state.diffLeft
        const translateBackY = this.state.diffTop + (this.state.startScrollPosition - this.currentScrollPosition)

        const translateFrontInAnimation = keyframes`
        from {
            transform: matrix(1,0,0,1,0,0)
        }
        to {
            transform: matrix(${scaleFrontX}, 0, 0, ${scaleFrontY}, ${translateFrontX}, ${translateFrontY})
            
        }
        `;

        const translateFrontOutAnimation = keyframes`
        from {
            transform: matrix(${scaleFrontX}, 0, 0, ${scaleFrontY}, ${translateFrontX}, ${translateFrontY})
        }

        to {
            transform: matrix(1,0,0,1,0,0)
        }
        `;

        const rotateFrontInAnimation = keyframes`
        from {
            transform: rotateY(${rotateY + 180}deg);
            opacity: 1;
        }
        49% {
            opacity: 1;
        }
        50%{
            opacity: 0;
        }
        to {
            transform: rotateY(180deg); 
            opacity: 0;
        }
        `;

        const rotateFrontOutAnimation = keyframes`
        from {
            transform: rotateY(180deg); 
            opacity: 0;
        }
        49% {
            opacity: 0;
        }
        50%{
            opacity: 1;
        }
        to {
            transform: rotateY(${rotateY + 180}deg);
            opacity: 1;
        }
        `;



        const translateBackInAnimation = keyframes`
        from {
            transform: matrix(${scaleBackX}, 0, 0, ${scaleBackY}, ${translateBackX}, ${translateBackY});
        }
        to {
            transform: matrix(1,0,0,1,0,0)
        }
        `;

        const translateBackOutAnimation = keyframes`
        from {
            transform: matrix(1,0,0,1,0,0)
        }

        to {
            transform: matrix(${scaleBackX}, 0, 0, ${scaleBackY}, ${translateBackX}, ${translateBackY});
        }
        `;

        const rotateBackInAnimation = keyframes`
        from {
            transform: rotateY(${rotateY}deg);
            opacity: 0;
        }
        49% {
            opacity: 0;
        }
        50%{
            opacity: 1;
        }
        to {
            transform: rotateY(0deg); 
            opacity: 1;
        }
        `;

        const rotateBackOutAnimation = keyframes`
        from {
            transform: rotateY(0deg);
            opacity: 1;
        }
        49% {
            opacity: 1;
        }
        50%{
            opacity: 0;
        }

        to {
            transform: rotateY(${rotateY}deg);
            opacity: 0;
        }
        `;

        const FrontTranslate = styled.div`
            animation: ${this.state.opening ? translateFrontInAnimation : this.state.closing && translateFrontOutAnimation} ${this.duration}ms ${easing};
            position: fixed;
            top: ${(this.state.startTop || 0) + ((this.state.startScrollPosition || 0) - (this.currentScrollPosition || 0))}px;
            left: ${this.state.startLeft || 0}px;
            right: ${this.state.startRight || 0}px;
            bottom: ${this.state.startBottom || 0}px;
            transform-origin: top left;
            transform-style: preserve-3d;
            perspective: ${this.perspective};
            `;


        const FrontRotate = styled.div`
            animation: ${this.state.opening ? rotateFrontInAnimation : this.state.closing && rotateFrontOutAnimation} ${this.duration}ms ${easing};
            transform-origin: center center;
            transform-style: preserve-3d;
            perspective: ${this.perspective};
            opacity: 0;
            `;


        const BackTranslate = styled.div`
            animation: ${this.state.opening ? translateBackInAnimation : this.state.closing && translateBackOutAnimation} ${this.duration}ms ${easing};
            transform-origin: top left;
            transform-style: preserve-3d;
            perspective: ${this.perspective};
            `;


        const BackRotate = styled.div`
            animation: ${this.state.opening ? rotateBackInAnimation : this.state.closing && rotateBackOutAnimation} ${this.duration}ms ${easing};
            transform-origin: center center;
            transform-style: preserve-3d;
            perspective: ${this.perspective};
            opacity: ${this.state.open ? 1 : 0};
            `;
        return (
            <div>

                {/* Original front content */}
                <div

                    onClick={this.props.disabled ? null : this.openInitAction}
                    ref={this.elementClosed}
                >
                    <div style={{

                        cursor: this.props.disabled ? "default" : "pointer",
                        opacity: (this.state.opening || this.state.open || this.state.closing) ? 0 : 1
                    }}>
                        {this.props.frontContent}
                    </div>

                </div>


                <div
                    onClick={e => {
                        if (!this.elementOpen.current.contains(e.target)) {
                            this.closingAction()
                        }
                    }}

                    style={{
                        position: 'fixed',
                        top: this.props.topPadding,
                        left: '0px',
                        right: '0px',
                        bottom: '0px',
                        overflowY: 'auto',
                        display: (this.state.openInit || this.state.closing) ? 'block' : 'none',
                        zIndex: 1,
                        backgroundColor: this.state.open ? "rgba(0,0,0,0.25)" : "rgba(0,0,0,0)",
                        transition: "background-color 0.3s",
                        WebkitTransition: "background-color 0.3s"
                    }}>


                    {/* Copy of front that grows in */}
                    <FrontTranslate>
                        <FrontRotate>
                            {this.props.frontContent}
                        </FrontRotate>
                    </FrontTranslate>

                    {/* Back content */}
                    <div className="container h-100">
                        <div className="row justify-content-center align-items-center h-100">
                            <div className="col-md-10 col-lg-8 col-xl-7"
                                style={{
                                    paddingTop: '100px',
                                    paddingBottom: '60px',
                                }}
                            >
                                <BackTranslate>
                                    <BackRotate>
                                        <div ref={this.elementOpen}>
                                            <button
                                                type="button"
                                                className="close"
                                                aria-label="Close"
                                                style={{ position: 'absolute', top: '40px', right: '36px', zIndex: 1 }}
                                                onClick={this.closingAction}>
                                                <span aria-hidden="true">&times;</span>
                                            </button>
                                            {this.props.backContent}
                                        </div>
                                    </BackRotate>
                                </BackTranslate>

                            </div>
                        </div>
                    </div>



                </div>


            </div >
        )
    }
}

FullScreen.defaultProps = {
    topPadding: '100px'
}
export default FullScreen