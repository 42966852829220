import React from 'react'
import classnames from 'classnames'
import Button from '../common/Button'
import Check from '../common/Check'
import styles from './Card.module.css'
import ElearningIcon from '../common/ElearningIcon'
import ResourcesIcon from '../common/ResourcesIcon'
import DataIcon from '../common/DataIcon'
import { Link } from 'react-router-dom'
//import ImageAbsolute from '../layout/ImageAbsolute'
import InfoIcon from '../common/InfoIcon'

const Wrapper = props => {
    if (props.square) {
        return (
            <div
                style={{
                    width: '100%',
                    paddingTop: '100%', /* 1:1 Aspect Ratio */
                    position: 'relative',
                    overflow: 'hidden'
                }}

            >
                <div
                    className={classnames(
                        { "bg-light-mint": props.color === "green" },
                        { "bg-light-pink": props.color === "pink" },
                        { "bg-light-yellow": props.color === "yellow" },
                        { "bg-light-grey": props.color === "grey" },
                        { "bg-white": props.color === "green-inverse" },
                    )}
                    style={{
                        position: 'absolute',
                        top: '0px',
                        left: '0px',
                        right: '0px',
                        bottom: '0px',
                        overflow: 'hidden',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                        borderRadius: '4px',
                        paddingTop: '24px'
                    }}
                >

                    {props.children}
                </div>

                <div style={{
                    position: 'absolute',
                    top: '-15%',
                    left: '-15%',
                    width: '130%',
                    height: '130%',
                    transform: 'rotate(30deg)',
                    transformOrigin: 'center',
                    background: `linear-gradient(
                       to right, 
                       rgba(255, 255, 255, 0.0) 0%,
                       rgba(255, 255, 255, 0.0) 60%,
                       rgba(255, 255, 255, 0.27) 85%,
                       rgba(255, 255, 255, 0.0) 100%
                     )`
                }} />
                <div style={{
                    position: 'absolute',
                    top: '8px',
                    right: '8px'
                }} >
                    <InfoIcon
                        color={props.color === 'green-inverse' ? 'green' : props.color}
                    />
                </div>

            </div>
        )
    } else {
        return props.children
    }
}

const Card = props => {
    const content = (
        <div
            onClick={props.disabled !== true ? props.onClick : undefined}
            className={classnames(props.className, { "mb-3": props.noMargin !== true })}
            style={{
                cursor: ((props.onClick || props.link) && props.disabled !== true) && 'pointer'
            }}

        >
            <div
                style={{ position: 'relative' }}
                className={classnames(
                    "card",
                    "d-block",
                    styles.card,
                    { [styles.cardTrans]: props.trans },
                    { [styles.cardHover]: props.disabled !== true && (props.onClick || props.onClickPretend || props.link) },
                    { [styles.greenHover]: props.disabled !== true && ((props.onClick || props.onClickPretend || props.link) && props.color === "green") },
                    { [styles.pinkHover]: props.disabled !== true && ((props.onClick || props.onClickPretend || props.link) && props.color === "pink") },
                    { [styles.yellowHover]: props.disabled !== true && ((props.onClick || props.onClickPretend || props.link) && props.color === "yellow") },
                    { [styles.greenHover]: props.disabled !== true && ((props.onClick || props.onClickPretend || props.link) && props.color === "green-inverse") },
                    { 'card-subtle': (props.subtle) }
                )}
            >

                {props.loading && props.image && (
                    <div
                        className={classnames([styles.animate])}
                        style={{
                            width: '100%',
                            paddingTop: '66%', /* 1:1 Aspect Ratio */
                        }} />
                )}
                {props.image && !props.loading && (

                    <div style={{
                        backgroundImage: `url("${props.image}")`,
                        backgroundPosition: 'center',
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 'cover',
                        width: '100%',
                        paddingTop: '66%',
                    }} />


                )}
                {props.dot && (
                    <div style={{
                        height: '200px',
                        width: '100%',
                        display: 'block',
                        position: 'relative'
                    }}>
                        <div style={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)'
                        }}>
                            <div
                                className={classnames(
                                    { "bg-light-mint": props.color === "green" },
                                    { "bg-light-pink": props.color === "pink" },
                                    { "bg-light-yellow": props.color === "yellow" },
                                )}
                                style={{
                                    height: '140px',
                                    width: '140px',
                                    borderRadius: '50%',
                                    marginLeft: 'auto',
                                    marginRight: 'auto',
                                }} />
                        </div>
                        <div style={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)'
                        }}>
                            {props.icon === "elearning" && <ElearningIcon />}
                            {props.icon === "resources" && <ResourcesIcon />}
                            {props.icon === "data" && <DataIcon />}

                        </div>
                    </div>
                )}
                {props.check && (
                    <div
                        style={{
                            position: 'absolute',
                            fontWeight: 'bold',
                            top: '3px',
                            right: '7px',
                            fontSize: '28px'
                        }}
                    >
                        <Check color="green" />
                    </div>
                )}

                {props.enquiry && (
                    <span
                        style={{
                            position: 'absolute',
                            fontSize: '80%',
                            fontWeight: 'bold',
                            top: '3px',
                            right: '7px'
                        }}
                    >ENQUIRY SENT</span>
                )}
                {props.comingSoon && (
                    <div
                        style={{
                            position: 'absolute',
                            fontSize: '80%',
                            fontWeight: 'bold',
                            top: '7px',
                            right: '7px'
                        }}
                        className={classnames(
                            "card",
                            "p-2",
                            styles.card,
                            styles.cardTrans,
                        )}
                    >COMING SOON</div>
                )}
                <Wrapper square={props.square} color={props.color}>
                    {props.title && (
                        <div
                            style={{ minHeight: '80px', overflow: 'hidden', position: 'relative' }}
                            className={classnames(
                                { "bg-light-mint": props.color === "green" },
                                { "bg-light-pink": props.color === "pink" },
                                { "bg-light-yellow": props.color === "yellow" },
                                { "bg-light-grey": props.color === "grey" },
                                { "bg-light-mint": props.color === "green-inverse" },
                            )}>
                            {props.smallTitle ? (
                                <h5
                                    style={{
                                        margin: '16px 32px',
                                        float: 'left'
                                    }}
                                    className={classnames("db-title-block",
                                        { "dark-mint-block": props.color === "green" },
                                        { "dark-pink-block": props.color === "pink" },
                                        { "dark-yellow-block": props.color === "yellow" },
                                        { "dark-grey-block": props.color === "grey" },
                                        { "dark-mint-block": props.color === "green-inverse" },
                                    )}>
                                    {props.title}
                                </h5>
                            ) : (
                                    <h3
                                        style={{
                                            margin: '16px 32px',
                                            float: 'left'
                                        }}
                                        className={classnames("db-title-block",
                                            { "dark-mint-block": props.color === "green" },
                                            { "dark-pink-block": props.color === "pink" },
                                            { "dark-yellow-block": props.color === "yellow" },
                                            { "dark-grey-block": props.color === "grey" },
                                            { "dark-mint-block": props.color === "green-inverse" },
                                        )}>
                                        {props.title}
                                    </h3>
                                )}

                        </div>
                    )}

                    {(props.children && !props.square) ? (
                        <div className="card-body">
                            {props.children}
                        </div>
                    ) : (props.children && props.square) && (
                        <div className="p-4">
                            {props.children}
                        </div>
                    )}
                </Wrapper>
                {/* had to use position absolute to get proper centering in IE when the button is wider than the card - flexbox would have been much easier!*/}
                {props.button && (

                    <div style={{ position: 'absolute', bottom: props.smallButton ? '-34px' : '-48px', left: '50%', transform: 'translateX(-50%)' }}>
                        {(props.button && !props.loading && !props.disabled) && (
                            <Button
                                className="mt-2"
                                colour={props.color}
                                small={props.smallButton}
                            >
                                {props.button}
                            </Button>
                        )}
                        {(props.button && props.loading && !props.disabled) && (
                            <Button
                                className={classnames("mt-2", [styles.animate])}
                                fixedWidth
                                small={props.smallButton}
                            >
                                {props.button}
                            </Button>
                        )}
                    </div>
                )}
            </div>
            {props.button && <div style={{ height: props.smallButton ? '26px' : '33px' }} />}

        </div>
    )
    if (props.link && props.disabled !== true) {
        return (
            <Link to={props.link} style={{
                textDecoration: 'inherit',
                color: 'inherit',
            }}>
                {content}
            </Link>
        )
    }
    return content
}
Card.defaultProps = {
    color: 'green',
    img: 1
};
export default Card