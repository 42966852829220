import { useState, useEffect } from 'react'
// import TopBannerPlain from "./Comps/TopBannerPlain"
import ImageTextBlock from "./Comps/ImageTextBlock"
import BottomBanner from "./Comps/BottomBanner"
import { Link } from "react-router-dom"
import circles from '../../../img/circles.svg'
import DivFade from '../DivFade'
import plmLogo from '../../../img/plm-logo-no-bg.png'
import Button from "../../common/Button"
import CrossfieldsApprovedCentreLogo from '../../../img/crossfields-approved-centre-logo-light-BG.png'
import BannerFlex from "../BannerFlex"
import puColours from "../../../utils/puColours"
import backgroundCircles from '../../../img/circles.svg'
// import OurAccreditations from './Comps/OurAccreditations'
import PCIlogo from '../../../img/PCI_Accredited_Mark_RGB.png'
import CPDLogo from '../../../img/CPDmember.png'
import CrossfieldsLogo from '../../../img/crossfields-approved-centre-logo-light-BG.png'
import { useScreenSize } from '../../../utils/hooks'


const Certification = ({ hasPlm }) => {

  const screenSize = useScreenSize()
  
  const bannerShapes = [
    // {
    //   color: puColours.darkOrange,
    //   className: 'hover:tw-drop-shadow-2xl tw-rounded-full tw-transition-all tw-duration-500 tw-w-[125px]',     
    //   shadowSize: '2xl',
    //   content: <OfferContent />,
    //   top: screenSize > 768 ? '-55%' : screenSize > 550 ? '0%' : '-30%',
    //   left: screenSize > 768 ? '32%' : screenSize > 550 ? '-32%' : '32%',
    //   // width: '50%'
    //   // width: 'tw-w-[125px]'
    // },
    {
      color: puColours.darkOrange,
      parentClassName: 'position-absolute tw-group tw-w-[125px]',
      top: screenSize > 768 ? '-55%' : screenSize > 550 ? '0%' : '-30%',
      left: screenSize > 768 ? '32%' : screenSize > 550 ? '-32%' : '32%',
      childClassName: 'group-hover:tw-drop-shadow-xl tw-rounded-full tw-transition-all tw-duration-500',      
      borderRadius: 'lg',
      content: <OfferContent hasPlm={hasPlm}/>,
      // width: screenSize > 768 ? '45%' : screenSize > 550 ? '45%' : '40%',      
      // width: 'tw-w-[125px]'
    },
    {
      color: 'white',
      parentClassName: 'position-absolute tw-group tw-w-[125px]',
      childClassName: 'group-hover:tw-drop-shadow-xl tw-rounded-lg tw-transition-all tw-duration-500',      
      borderRadius: 'lg',
      content: <CrossfieldsLogoComp />,
      top: screenSize > 768 ? '29%' : screenSize > 550 ? '0%' : '34%',
      left: screenSize > 768 ? '32%' : screenSize > 550 ? '32%' : '-6%',
      // width: screenSize > 768 ? '45%' : screenSize > 550 ? '45%' : '40%',      
      // width: 'tw-w-[125px]'
    },
    {
      color: 'white',
      parentClassName: 'position-absolute tw-group tw-w-[125px]',
      childClassName: 'group-hover:tw-drop-shadow-xl tw-rounded-full tw-transition-all tw-duration-500',      
      // backgroundImage: plmLogo,
      content: <PlmLogoContent />,
      top: screenSize > 768 ? '114%' : screenSize > 550 ? '0%' : '34%',
      left: screenSize > 768 ? '32%' : screenSize > 550 ? '95%' : '65%',      
      // width: '50%'
      // width: 'tw-w-[125px]'
    },
  ]

  return <>

    <BannerFlex
      backgroundCirclesObj={{
        width: '800px',
        left: '-410px',
        image: backgroundCircles,
        className: 'tw-animate-pulse-slow'
      }}
      backgroundColour='white'
      shapes={bannerShapes}
      colSpan={{ contentSection: 'col-12 col-md-7', shapesSection: 'col-md-5' }}
      classNames={{block: 'tw-pb-12 sm:tw-pb-0', contentSection: `pt-5 pl-lg-5 ${screenSize > 768 && 'mb-5'}`, shapesSection: '' }}
    >
      <section className="py-5 tw-pb-">
        <h1 className="font-weight-bold mb-4 tw-text-3xl" style={{textWrap: 'balance'}}>Certificate In Lifestyle Medicine</h1>
        {/* <h3 className="font-weight-bold mb-4">Endorsed by CFI</h3> */}
        <p>
          Prescribing Lifestyle Medicine learners can now go on to study a Certificate in Lifestyle Medicine. Accredited by Crossfields Institute as a Quality Mark Programme, successful completion will provide you with post-nominals: CertLM.
        </p>
        <p>
          The Crossfields Institute (CFI) is an awarding organisation regulated by Ofqual (the Office of Qualifications and Examinations Regulation). They monitor and review the Prescribing Lifestyle Medicine Online Course underpinning the quality and delivery of the programme.
        </p>
        {!hasPlm ?
        <p>
          To check that Prescribing Lifestyle Medicine and the Certificate in Lifestyle Medicine is for you, check our <Link style={{ color: '#EB6915' }} to="/product/prescribinglifestylemedicine/about-plm?#who-is-plm-for">list of appropriate roles</Link>.
        </p> :
        <div className='mb-4'/>  
        }
        <p>Take a look at our <Link to="/product/prescribinglifestylemedicine/accreditation-faqs">Frequently Asked Questions here</Link>.</p>
      </section>

    </BannerFlex>

    {/* <DivFade style={{ background: 'white' }}>
      <div className="container pb-4">
        <h2 className={`pt-5  mt-lg-0 mb-0 mb-lg-4 text-center`}>
          <strong>Accreditations and Quality Marks</strong>
        </h2>
        <div className={`row`}>
          {items.map((item, index) => (
          <div key={`accredition: ${index}`} className={`mb-3 col-6 col-sm-4 col-md-2 ${item.className} hover:tw-shadow-md tw-transition-all tw-duration-300`}>
            <div style={{ maxWidth: item.maxWidth }} className="mx-auto">
              <a rel="noopener noreferrer" href={item.link} target="_blank">
                <img className="img-fluid" alt={item.imageAlt} src={item.imageSrc} />
              </a>
            </div>
          </div>
          ))}
        </div>
      </div>
    </DivFade> */}

    <ImageTextBlock
      contentBackgroundColor='rgb(25, 57, 88)'
      contentBackgroundSize='contain'
      imageUrl='https://practiceunbound.blob.core.windows.net/stock-images/Homepage_Block_Shutterstock_1050440903-min.jpg'
      contentSide='right'
    >
      <div className="text-white pt-5 pb-4 pl-5">
        <h3 className="mb-4 mt-4" style={{ fontWeight: 'bold' }}>What's involved?</h3>
        <div className="mb-5">
          <p className="font-weight-bold mb-4">The Certificate in Lifestyle Medicine will require learners to complete the existing PLM Online course before undertaking additional learning and assessment:</p>
          <ul className='tw-list-disc tw-pl-10'>
            <li>Complete all 4 e-learning modules with a minimum score of 80% on self-assessment within each module</li>
            <li>Submit a reflective journal, and answer the questions set out in each of the four modules</li>
            <li>Record a minimum of 85 hours of supporting evidence to demonstrate the application of PLM in your field of practice</li>
            <li>Participate in the Community Area of the course</li>
            <li>Read case studies, reflect upon change within your own practice and submit a final written case study</li>
          </ul>
        </div>
      </div>
    </ImageTextBlock>

    <DivFade>
      <div className="container">
        <div className="row">
          <div className="col-md-1" />
          <div className="col-12 col-md-10 m-0">
            <div className="pt-5 pb-3 px-4">
              <div className="mb-5" style={{ maxWidth: '800px' }}>
                <h5 className="font-weight-bold mb-4" >Learners will be expected to commit a minimum of 120 hours to study and practice. This can be broken down into:</h5>
                <ul>
                  <li>PLM Online Course Study Time: 15 hours</li>
                  <li>Using PLM in practice: 85 hours</li>
                  <li>Assessment and Case Study: 20 hours</li>
                  <li>The Certificate in Lifestyle Medicine cost: £{hasPlm ? 370 : 695} + VAT (this provides access and enrolment to the PLM Online Course). 
                    {hasPlm ? 
                    <a style={{ color: '#EB6915' }} href="/purchase/plmaccreditationonly">Click here to purchase the Certificate in Lifestyle Medicine.</a> : 
                    <a style={{ color: '#EB6915' }} href="/product/prescribinglifestylemedicine/enrol-plm">Click here to enrol.</a>
                    }
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-md-1" />
        </div>
      </div>
    </DivFade>

    <DivFade
      style={{
        backgroundColor: puColours.darkOrange,
        color: "white",
        position: 'relative',
        overflow: 'hidden'
      }}
    >
      {/* <img className='d-none d-md-block tw-animate-slow-spin' alt="" src={circles} style={{ bottom: '-300px', left: '-160px', position: 'absolute', filter: 'brightness(0) invert(87%) sepia(26%) saturate(7240%) hue-rotate(324deg) brightness(99%) contrast(90%)' }} />
      <img className='tw-animate-slow-reverse-spin' alt="" src={circles} style={{ top: '-300px', right: '-160px', position: 'absolute', filter: 'brightness(0) invert(87%) sepia(26%) saturate(7240%) hue-rotate(324deg) brightness(99%) contrast(90%)' }} />
      {hasPlm ? <>
        <div style={{ position: 'relative', paddingTop: '96px', paddingBottom: '96px' }}>
          <div><h4 className='text-center mb-4 font-weight-bold'>Purchase the Certificate in Lifestyle Medicine here for £370 + VAT</h4></div>
          <Button link='/product/prescribinglifestylemedicine/enrol-plm' colour="none-outline-blue" center inline>buy now</Button>
        </div>
      </> : <>
        <div className="container" style={{ position: 'relative', paddingTop: '96px', paddingBottom: '96px' }}>
          <div className="row">
            <div className="col-1" />
            <div className="col-12 col-md-10">
              <h2 className='text-center mb-4 font-weight-bold'>Already completed Prescribing Lifestyle Medicine Online?</h2>
              <h4 className='text-center mb-4'>If you have already completed Prescribing Lifestyle Medicine Online, email us at <a href="mailto:info@practiceunbound.org.uk" style={{ color: 'rgb(25, 57, 88)' }}>info@practiceunbound.org.uk</a> to find out how to convert this into a CertLM qualification and access your discounted price.</h4>
            </div>
            <div className="col-1" /> */}

      <img className='d-none d-md-block tw-animate-slow-spin' alt="" src={circles} style={{ bottom: '-300px', left: '-160px', position: 'absolute', filter: 'brightness(0) invert(87%) sepia(26%) saturate(7240%) hue-rotate(324deg) brightness(99%) contrast(90%)' }} />
      <img className='tw-animate-slow-reverse-spin' alt="" src={circles} style={{ top: '-300px', right: '-160px', position: 'absolute', filter: 'brightness(0) invert(87%) sepia(26%) saturate(7240%) hue-rotate(324deg) brightness(99%) contrast(90%)' }} />
      {hasPlm ? (
      <div style={{ position: 'relative', paddingTop: '96px', paddingBottom: '96px' }}>
        <div><h4 className='text-center mb-4 font-weight-bold'>Purchase the Certificate in Lifestyle Medicine here for £370 + VAT</h4></div>
        <Button link='/purchase/plmaccreditationonly' colour="none-outline-blue" center inline>buy now</Button>
      </div>
      ) : (
      <div className="container" style={{ position: 'relative', paddingTop: '96px', paddingBottom: '96px' }}>
        <div className="row">
          <div className="col-1" />
          <div className="col-12 col-md-10">
            <h2 className='text-center mb-4 font-weight-bold'>Already completed Prescribing Lifestyle Medicine Online?</h2>
            <h4 className='text-center mb-4'>If you have already completed Prescribing Lifestyle Medicine Online, email us at <a href="mailto:info@practiceunbound.org.uk" style={{ color: 'rgb(25, 57, 88)' }}>info@practiceunbound.org.uk</a> to find out how to convert this into a CertLM qualification and access your discounted price.</h4>
          </div>
          <div className="col-1" />
        </div>
      </div>
      )}
    </DivFade>

    <BottomBanner header="Have more questions? Talk to us" iconHoverColour="#034F52">
      <p style={{ fontSize: '18px' }}>Whether you are interested in one of our products or would like to know more about working with Practice Unbound, please<Link style={{ color: 'white' }} to="/contact-us"> contact the team</Link></p>
    </BottomBanner>
  </>
}

const OfferContent = ({ hasPlm }) => (
  <Link className="anchor-none" to="/product/prescribinglifestylemedicine/enrol-plm">
    <div style={{ textAlign: 'center', color: 'white', display: 'flex', flexDirection: 'column', justifyContent: 'center', height: '100%' }}>
      <div style={{ fontWeight: 'bold', fontSize: '30px', lineHeight: '30px' }}>£{hasPlm ? 370 : 695}</div>
      <div style={{ fontWeight: 'normal', fontSize: '9px', lineHeight: '9px', marginBottom: '3px' }}>+VAT</div>
      <div style={{ fontWeight: 'normal', fontSize: '9px', lineHeight: '9px' }}>for 12 months access to PLM <br />and Certificate in Lifestyle<br />Medicine</div>
    </div>
  </Link>
)
const CrossfieldsLogoComp = () => <a target="_blank" rel="noreferrer" href="https://crossfieldsinstitute.com/"><img alt="Crossfields Logo" className="img-fluid" src={CrossfieldsApprovedCentreLogo} /></a>
const PlmLogoContent = () => <Link to="/product/prescribinglifestylemedicine/home"><img alt="PLM logo" className="img-fluid tw-rounded-full tw-shadow-md" src={plmLogo} /></Link>

const items = [
  {
    maxWidth: '120px',
    link: "https://www.personalisedcareinstitute.org.uk/",
    imageAlt: "PCI logo",
    imageSrc: PCIlogo
  },
  {
    maxWidth: "65px",
    link: "http://www.rcgp.org.uk/courses-and-events/rcgp-educational-accreditation-for-education-providers.aspx",
    imageAlt: "RCGP Accredited",
    imageSrc: "https://practiceunbound.blob.core.windows.net/stock-images/Accreditation-Mark_ 2012JPEG.jpg"
  },
  {
    maxWidth: "125px",
    link: "https://bslm.org.uk/",
    imageAlt: "BSLM logo",
    imageSrc: "https://practiceunbound.blob.core.windows.net/stock-images/britishsocietylifestylemedicinelogo.jpg"
  },
  {
    maxWidth: '150px',
    link: "https://www.imperial.ac.uk/school-public-health/study/undergraduate/lmap/",
    imageAlt: "Imperial Logo",
    imageSrc: "https://practiceunbound.blob.core.windows.net/stock-images/Imperial_Logo.png",
    className: 'mt-4'
  },
  {
    maxWidth: '120px',
    link: "https://cpduk.co.uk/",
    imageAlt: "CPDLogo",
    imageSrc: CPDLogo
  },
  {
    maxWidth: '120px',
    link: "https://crossfieldsinstitute.com/",
    imageAlt: "CrossfieldsLogo",
    imageSrc: CrossfieldsLogo
  },
]


export default Certification