import api from '../config/axios';
import {
    GET_ELEARNING_LAUNCH_URL,
    SET_ELEARNING_LAUNCH_URL_LOADING,
    GET_ELEARNING_RESULTS,
    ADD_ELEARNING_RESULT,
    SET_ELEARNING_RESULTS_LOADING,
    GET_ELEARNING_ERRORS,
    CLEAR_ELEARNING_ERRORS,

} from './types';
import isEmpty from '../utils/is-empty'
import { logoutUser } from './authActions'

export const clearLaunchUrl = () => dispatch => {
    dispatch({
        type: GET_ELEARNING_LAUNCH_URL, payload: {
            url: null,
            encodedUrl: null
        }
    });
}

export const getElearningLaunchUrl = (product, moduleNumber, redirect) => dispatch => {
    dispatch({ type: CLEAR_ELEARNING_ERRORS });
    dispatch({ type: SET_ELEARNING_LAUNCH_URL_LOADING, payload: true });
    api.elearningAPI.get(`/api/elearning/launch/${product}/${moduleNumber}?redirect=${redirect}`)
        .then(res => {
            dispatch({
                type: GET_ELEARNING_LAUNCH_URL,
                payload: res.data
            });

        })
        .catch(err => {
            dispatch(handleError(err))
            dispatch({ type: SET_ELEARNING_LAUNCH_URL_LOADING, payload: false });
        })
}

export const updateElearningResults = (product, moduleNumber) => dispatch => {
    dispatch({ type: CLEAR_ELEARNING_ERRORS });
    dispatch({ type: SET_ELEARNING_RESULTS_LOADING, payload: true });
    api.elearningAPI.get(`/api/elearning/results/${product}/${moduleNumber}`)
        .then(res => {
            dispatch({ type: ADD_ELEARNING_RESULT, payload: res.data })
            dispatch({ type: SET_ELEARNING_RESULTS_LOADING, payload: false });
        })
        .catch(err => {
            dispatch(handleError(err))
            dispatch({ type: SET_ELEARNING_RESULTS_LOADING, payload: false });
        })
}

export const getElearningResults = (product) => dispatch => {
    dispatch({ type: CLEAR_ELEARNING_ERRORS });
    dispatch({ type: SET_ELEARNING_RESULTS_LOADING, payload: true });
    api.elearningAPI.get(`/api/elearning/results/${product}`)
        .then(res => {
            dispatch({ type: GET_ELEARNING_RESULTS, payload: res.data })
        })
        .catch(err => {
            dispatch({ type: SET_ELEARNING_RESULTS_LOADING, payload: false });
            dispatch(handleError(err))
        })
}
export const getElearningResultsAndUpdate = (product) => dispatch => {
    dispatch({ type: CLEAR_ELEARNING_ERRORS });
    dispatch({ type: SET_ELEARNING_RESULTS_LOADING, payload: true });
    api.elearningAPI.get(`/api/elearning/results/${product}/update`)
        .then(res => {
            dispatch({ type: GET_ELEARNING_RESULTS, payload: res.data })
        })
        .catch(err => {
            dispatch({ type: SET_ELEARNING_RESULTS_LOADING, payload: false });
            dispatch(handleError(err))
        })
}



const handleError = (err) => dispatch => {
    let payload
    console.dir(err)

    //token expired
    if (err.response) {
        if (err.response.statusText === "Unauthorized" && err.response.status === 401 && err.response.data === "Unauthorized") {
            dispatch(logoutUser())
        }
    }


    if (isEmpty(err.response)) {
        payload = { error: err.message }
    } else {
        if (isEmpty(err.response.data)) {
            payload = { error: err.message }
        } else {
            payload = err.response.data
        }
    }

    dispatch({
        type: GET_ELEARNING_ERRORS,
        payload: payload
    })
}

export const clearElearningErrors = () => dispatch => {
    dispatch({ type: CLEAR_ELEARNING_ERRORS })
}