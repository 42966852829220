import {
    SET_PRODUCT_LOADING,
    SET_PRODUCTS_LOADING,
    GET_PRODUCT,
    GET_PRODUCTS,
    CLEAR_PRODUCTS,
    CLEAR_PRODUCT,
    GET_PRODUCT_MEMBERSHIP,
    SET_PRODUCT_MEMBERSHIP_LOADING,
    CLEAR_PRODUCT_MEMBERSHIP,
    GET_PRODUCT_ERRORS,
    CLEAR_PRODUCT_ERRORS,
    GET_PRODUCT_CONTENT_RESOURCES_PAGE,
    GET_PRODUCT_CONTENT_RESOURCES_PAGE_LOADING,
    GET_PRODUCT_CONTENT_RESOURCES_LIST,
    GET_PRODUCT_CONTENT_RESOURCES_LIST_LOADING,
    GET_PRODUCT_CONTENT_RESOURCES_SEARCH,
    GET_PRODUCT_CONTENT_RESOURCES_SEARCH_LOADING,
    CLEAR_RESOURCES,
    CLEAR_RESOURCE,
    GET_LNA,
    CLEAR_LNA,
    CLEAR_LEARNER_OPM_DATA,
    SET_LEARNER_OPM_DATA_LOADING,
    GET_LEARNER_OPM_DATA,
    SET_LNA_LOADING,
    PRODUCT_NOT_FOUND,
    SET_PRODUCT_SUB_PAGE_LOADING,
    GET_PRODUCT_SUB_PAGE,
    GET_PRODUCT_SUB_PAGE_ERRORS,
    CLEAR_PRODUCT_SUB_PAGE_ERRORS
} from '../actions/types'

const initialState = {
    product: null,
    productLoading: true,
    products: [],
    productsLoading: true, //make true by default for initial render
    productMembership: null,
    productMembershipLoading: false,
    resourcesContentList: {},
    resourcesContentListLoading: false,
    resourcesContentPage: {},
    resourcesContentPageLoading: false,
    resourcesSearch: [],
    resourcesSearchLoading: false,
    lna: null,
    lnaLoading: false,
    learnerOpmData: null,
    learnerOpmDataLoading: false,
    errors: {},
    productNotFound: false,
    page: {},
    pageLoading: false,
    pageErrors: {}
}

export default function productReducer(state = initialState, action) {
    switch (action.type) {
        case SET_PRODUCT_LOADING:
            return {
                ...state,
                productLoading: action.payload
            }
        case SET_PRODUCTS_LOADING:
            return {
                ...state,
                productsLoading: action.payload
            }

        case GET_PRODUCTS:

            action.payload.sort((a, b) => {

                const sorting = (prod) => {
                    if (prod === 'workflowgo') return 1                    
                    if (prod === 'gpbilling') return 2                    
                    if (prod === 'pathologygo') return 3
                    if (prod === 'prescribinglifestylemedicine') return 4
                    if (prod === 'prescribinglifestylemedicinepractice') return 5
                    if (prod === 'groupconsultations') return 6
                    if (prod === 'workflownetwork') return 7
                    if (prod === 'pharmacy') return 8
                    return 8
                }

                if (sorting(a.name) < sorting(b.name)) return -1
                if (sorting(a.name) > sorting(b.name)) return 1
                return 0
            })

            let products = action.payload
            if (action.payload.filter(p => p.purchased && p.name === "prescribinglifestylemedicinepractice").length > 0) {
                products = products.filter(p => p.name !== 'prescribinglifestylemedicine')
            }

            return {
                ...state,
                products: products,
                productsLoading: false
            }
        case CLEAR_PRODUCTS:
            return {
                ...state,
                products: initialState.products
            }
        case CLEAR_PRODUCT:
            return {
                ...state,
                product: null,
                productContent: {},
                productLoading: false,
                resourcesContent: {},
                resourcesContentLoading: false
            }
        case GET_PRODUCT:
            return {
                ...state,
                productLoading: false,
                product: action.payload,
            }
        case SET_PRODUCT_MEMBERSHIP_LOADING:
            return {
                ...state,
                productMembershipLoading: action.payload
            }
        case GET_PRODUCT_MEMBERSHIP:
            return {
                ...state,
                productMembership: action.payload,
                productMembershipLoading: false
            }
        case CLEAR_PRODUCT_MEMBERSHIP:
            return {
                ...state,
                productMembership: null,
                productMembershipLoading: false
            }
        case GET_PRODUCT_ERRORS:
            return {
                ...state,
                errors: action.payload
            }
        case CLEAR_PRODUCT_ERRORS:
            return {
                ...state,
                errors: {}
            };
        case GET_PRODUCT_CONTENT_RESOURCES_PAGE:
            return {
                ...state,
                resourcesContentPage: action.payload,
                resourcesContentPageLoading: false
            }
        case GET_PRODUCT_CONTENT_RESOURCES_PAGE_LOADING:
            return {
                ...state,
                resourcesContentPageLoading: action.payload,
            }
        case GET_PRODUCT_CONTENT_RESOURCES_LIST:
            return {
                ...state,
                resourcesContentList: action.payload,
                resourcesContentListLoading: false
            }
        case GET_PRODUCT_CONTENT_RESOURCES_LIST_LOADING:
            return {
                ...state,
                resourcesContentListLoading: action.payload,
            }


        case GET_PRODUCT_CONTENT_RESOURCES_SEARCH:
            return {
                ...state,
                resourcesSearch: action.payload,
                resourcesSearchLoading: false
            }
        case GET_PRODUCT_CONTENT_RESOURCES_SEARCH_LOADING:
            return {
                ...state,
                resourcesSearchLoading: action.payload,
            }
        case CLEAR_RESOURCES:
            return {
                ...state,
                resourcesContentList: {},
                resourcesContentListLoading: false,
                resourcesContentPage: {},
                resourcesContentPageLoading: false,
                resourcesSearch: [],
                resourcesSearchLoading: false,
            }
        case CLEAR_RESOURCE:
            return {
                ...state,
                resourcesContentPage: {},
            }
        case GET_LNA:
            return {
                ...state,
                lna: action.payload,
                lnaLoading: false
            }

        case SET_LNA_LOADING:
            return {
                ...state,
                lnaLoading: action.payload
            }
        case CLEAR_LNA:
            return {
                ...state,
                lna: null
            }


        case GET_LEARNER_OPM_DATA:
            return {
                ...state,
                learnerOpmData: action.payload,
                learnerOpmDataLoading: false
            }

        case SET_LEARNER_OPM_DATA_LOADING:
            return {
                ...state,
                learnerOpmDataLoading: action.payload
            }
        case CLEAR_LEARNER_OPM_DATA:
            return {
                ...state,
                learnerOpmData: null
            }

        case SET_PRODUCT_SUB_PAGE_LOADING:
            return {
                ...state,
                pageLoading: action.payload,
            };
        case GET_PRODUCT_SUB_PAGE:
            return {
                ...state,
                page: action.payload,
                pageLoading: false,
                pageErrors: {}
            };
        case GET_PRODUCT_SUB_PAGE_ERRORS:
            return {
                ...state,
                pageErrors: action.payload
            }
        case CLEAR_PRODUCT_SUB_PAGE_ERRORS:
            return {
                ...state,
                pageErrors: {}
            };


        case PRODUCT_NOT_FOUND:
            return {
                ...state,
                productNotFound: action.payload
            }
        default:
            return state;
    }
}