import PUPanel from '../layout/PUPanel'

const ErrorPanel = ({errorRes, children}) => {
  return (
    <div className="container p-5">

        <PUPanel title="Network Error">
            {errorRes.data?.error && <h4 className='font-weight-bold mb-4'>{errorRes.data?.error}</h4>}
            {errorRes?.statusText && <p>(Status: 500 - {errorRes?.statusText})</p>}
            {children}
        </PUPanel>
    </div>
  )
}

export default ErrorPanel