import React, { Component } from 'react'

export default class ImageFadeIn extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isMounted: false,
            backgroundImageLoaded: false
        }
        this.image = new Image()
        this.image.src = props.imageSrc
        this.image.onload = () => {
            if (this.state.isMounted) {
                this.setState({ backgroundImageLoaded: true })
            }
        }
    }
    componentDidMount() {
        this.setState({ isMounted: true })
    }
    componentWillUnmount() {
        this.setState({ isMounted: false })
        this.image.onload = () => { }
        delete this.image
    }
    render() {
        const { props } = this
        if (this.image && this.state.backgroundImageLoaded) {
            return (
                <div style={{
                    height: '100%',
                    width: '100%', overflow: 'hidden'
                }}>
                    <div
                        className={props.className}
                        style={{
                            height: '100%',
                            width: '100%',
                            backgroundImage: 'url("' + this.image.src + '")',
                            animation: 'background-zoom 2s',
                            ...props.style,
                        }}
                    />
                </div>
            )
        }
        return null
    }
}
