import React, { Component } from 'react'
import { connect } from 'react-redux'
import { getOrganisationsOwned, getOrganisationOwned } from '../../actions/organisationActions'
import Select from '../common/Select'



class CompOrganisationSelect extends Component {
    constructor(props) {
        super(props);
        this.updateOrganisation = this.updateOrganisation.bind(this)
    }

    updateOrganisation(organisation) {
        sessionStorage.setItem('organisation', JSON.stringify({ name: organisation.label, code: organisation.value }))
        this.props.getOrganisationOwned(organisation.value)
        this.props.history.push('/admin')
    }

    render() {
        const { organisations, organisation } = this.props.organisations

        let organisationOptions = []
        organisations.forEach(org => {
            organisationOptions.push({ label: org.name, value: org.code })
        })
        let organisationSelection = {}
        if (organisation) {
            organisationSelection = { label: organisation.name, value: organisation.code }
        }

        if (organisationOptions.length > 1) {
            return (
                < Select
                    hideError
                    label="Choose organisation:"
                    className="mb-3 text-black"
                    value={organisationSelection}
                    onChange={e => { this.updateOrganisation(e) }}
                    options={organisationOptions}
                />
            )
        }
        if (organisationOptions.length === 1) {
            return (
                <div className="p-2 text-center text-black">
                    <h4><strong>{organisationOptions[0].label}</strong></h4>
                </div>
            )
        }
        return null

    }
}

const mapStateToProps = (state) => ({
    organisations: state.organisations
})

export default connect(mapStateToProps, { getOrganisationsOwned, getOrganisationOwned })(CompOrganisationSelect);