import React, { Component } from 'react'
import { connect } from 'react-redux'
import { getBanner } from './actions/pagesActions'
import processRichText from './utils/processRichText'
import Modal from './components/common/Modal'

class Banner extends Component {
    constructor() {
        super()
        this.state = {
            dismissBanner: {}
        }
        this.dismissBanner = this.dismissBanner.bind(this)
    }

    componentDidMount() {
        this.props.getBanner()
        if (localStorage.getItem('dismissBanner')) {
            this.setState({ dismissBanner: JSON.parse(localStorage.getItem('dismissBanner')) })
        }
        this.setState({ isAuthenticated: this.props.auth.isAuthenticated })
    }

    dismissBanner(id) {
        this.setState({
            dismissBanner: {
                ...this.state.dismissBanner,
                [id]: true
            }
        }, () => {
            localStorage.setItem('dismissBanner', JSON.stringify(this.state.dismissBanner))
        })
    }

    render() {
        const { banner } = this.props.pages
        const { isAuthenticated, products } = this.props.auth

        let type = null
        let clinicalSystem = null
        if (this.props.orgs.organisation) {
            type = this.props.orgs.organisation.type
            clinicalSystem = this.props.orgs.organisation.clinicalSystem
        }

        const filteredBanners = banner.filter(b => {

            const productNames = products.map(p => p.product)
            const { criteria } = b.fields
            const workflowArray = ['workflow', 'workflowgo', 'workflowsolo']
            const plmArray = ['prescribinglifestylemedicine', 'prescribinglifestylemedicinepractice']

            if (!criteria) return true
            if (criteria.includes('Logged in') && !isAuthenticated) return false
            if (criteria.includes('Workflow') && !productNames.some(i => workflowArray.includes(i))) return false
            if (criteria.includes('Workflow Network') && !productNames.includes('workflownetwork')) return false
            if (criteria.includes('Practice Pharmacist') && !productNames.includes('pharmacy')) return false
            if (criteria.includes('Pathology Go') && !productNames.includes('pathologygo')) return false
            if (criteria.includes('Pathology Variation') && !productNames.includes('pathologyvariation')) return false
            if (criteria.includes('PLM') && !productNames.some(i => plmArray.includes(i))) return false
            if (criteria.includes('Group Consultations') && !productNames.includes('groupconsultations')) return false
            if (criteria.includes('CCG') && type !== 'CCG') return false
            // There isn't currently a PCN type, there is a network
            if (criteria.includes('PCN') && type !== 'pcn') return false
            if (criteria.includes('GP practice') && type !== 'GP Practice') return false
            if (criteria.includes('Practice Group') && type !== 'Practice Group') return false
            if (criteria.includes('Network') && type !== 'Network') return false
            if (criteria.includes('SystmOne') && clinicalSystem !== 'SystmOne') return false
            if (criteria.includes('EMIS') && clinicalSystem !== 'EMIS Web') return false
            if (criteria.includes('Vision') && clinicalSystem !== 'Vision') return false
            if (criteria.includes('Microtest') && clinicalSystem !== 'Microtest') return false

            return true
        })

        return (
            <div>
                {filteredBanners.map((b) => (

                    <Modal
                        key={b.sys.id}
                        isOpen={!this.state.dismissBanner[b.sys.id]}
                        onRequestClose={() => this.dismissBanner(b.sys.id)}
                        title={b.fields.title}
                        color={b.fields.colour}
                    >
                        {processRichText(b.fields.content.content)}
                    </Modal>

                ))}
            </div>
        )
    }
}

const mapStateToProps = state => ({
    pages: state.pages,
    auth: state.auth,
    orgs: state.organisations
});

export default connect(mapStateToProps, { getBanner })(Banner);




