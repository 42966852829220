import React from 'react'
import detectIe from '../../utils/detectIe'

export default function DivFade(props) {
    const ieVersion = detectIe();
    return (
        <div
            data-aos={(ieVersion === false || ieVersion > 10) && "fade"}
            className={props.className}
            style={props.style}
        >
            {props.children}
        </div>
    )
}
