import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import PUPanel from '../layout/PUPanel'
import ToDos from './ToDos'


class PageProductLandingPage extends Component {

    render() {
        const { organisationProductMembership } = this.props.organisations
        const { product } = organisationProductMembership

        return (
            <div>
                <PUPanel title="Admin">
                    <p className="lead">Getting your organisation and users set up in just a few steps.</p>
                    <p>The steps to complete are listed below - you can access incomplete tasks by clicking below or by using the navigation bar on the left hand side.</p>
                    <ToDos
                        organisationProductMembership={organisationProductMembership}
                        product={product}
                    />
                    <p className="mt-3">If you are having any problems completing these steps, please <Link to="/contact-us">contact our helpdesk</Link></p>
                </PUPanel>
            </div >
        )
    }
}

const mapStateToProps = (state) => ({
    organisations: state.organisations
})

export default connect(mapStateToProps, {})(PageProductLandingPage);