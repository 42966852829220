import React, { useState, useEffect } from 'react'
import Button from '../../../common/Button'
import PrevAssessments from './PrevAssessments'
import { Link } from 'react-router-dom'
import PlmAssessmentForm from './PlmAssessmentForm'
import { CSSTransition, TransitionGroup } from 'react-transition-group'

export default function Plm({ assessments, submitAssessment }) {

    const hasDonePrevAssessment = assessments.length ? true : false
    const [showPrevAssessments] = useState(hasDonePrevAssessment)
    const [showForm, setShowForm] = useState(false)

    const helpUrl = "https://practiceunbound.blob.core.windows.net/documents/plm-my-scores-arent-increasing.pdf"

    useEffect(() => {
        setShowForm(!hasDonePrevAssessment)
        // eslint-disable-next-line
    }, [])

    return (

        <div className="container py-5">

            <div className='card p-3 mb-5 row'>
                <p>
                    We have developed the <strong>Prescribing Lifestyle Medicine (PLM)</strong> self-assessment tool to support your continuous learning.
                </p>
                <p>
                    For more information on continuous learning and the role of personal reflective practice and communities of practice please see the “Continual Learning” topic in Module 4. This tool is based on the COM-B model and will:
                </p>

                <ul>
                    <li><p className="mb-0">provide you with information to use in your reflective practices</p></li>
                    <li><p className="mb-0">help you track your confidence, opportunity, motivation and behaviours around PLM</p></li>
                    <li><p className="mb-0">show you live how your results are changing over time</p></li>
                </ul>

                <p><strong>We suggest that you complete this survey before you start the modules and then subsequently at regular intervals. We suggest at least twice a year.</strong></p>
                <p>If you need any help with accessing or completing the survey, or have concerns about your progress, please make use of the <Link to='/contact-us'>Helpdesk</Link> and the <a href='https://plm-community.practiceunbound.org.uk/' target='_blank' rel="noopener noreferrer">PLM Online Community</a>.</p>
            </div >

            {
                showForm ? (
                    <div className='mb-5 pl-4 row justify-content-between' >
                        <div>
                            {hasDonePrevAssessment && (
                                <Button onClick={() => setShowForm(false)} underline colour="none" icon="back" className='mt-5'>
                                    cancel
                                </Button>
                            )}
                        </div>

                        <div className='card pr-4 pt-2 mr-4 '>
                            <ul style={{ listStyle: 'none', width: '12rem' }}>
                                <li className='row justify-content-between'><strong>1</strong><span>Strongly disagree</span></li>
                                <li className='row justify-content-between'><strong>2</strong><span>Disagree</span></li>
                                <li className='row justify-content-between'><strong>3</strong><span>Neither</span></li>
                                <li className='row justify-content-between'><strong>4</strong><span>Agree</span></li>
                                <li className='row justify-content-between'><strong>5</strong><span>Strongly Agree</span></li>
                            </ul>
                        </div>
                    </div>
                ) : (
                        <div className='row mb-5 justify-content-between'>
                            <div className='col-sm-2 mx-2 mb-3'>
                                <Button
                                    onClick={() => setShowForm(true)}
                                    colour="teal"
                                    center
                                >
                                    add new scores
                                </Button>
                            </div>
                            <div className='col-sm-3'>
                                <a
                                    href={helpUrl}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    style={{ textDecoration: 'none' }}
                                    className="mb-3"
                                >
                                    <Button
                                        colour="teal"
                                        inverted
                                        center
                                    >
                                        my scores aren’t increasing
                                    </Button>
                                </a>
                            </div>

                        </div>
                    )
            }

            <TransitionGroup appear={true}>

                {showForm ?
                    <CSSTransition
                        key="form"
                        classNames="fade"
                        timeout={200}
                    >

                        <PlmAssessmentForm
                            assessments={assessments}
                            submitAssessment={submitAssessment}
                            showForm={showForm}
                        />

                    </CSSTransition>
                    :

                    <CSSTransition
                        key="prevAssessments"
                        classNames="fade"
                        timeout={200}
                    >

                        <PrevAssessments
                            assessments={assessments}
                            showPrevAssessments={showPrevAssessments}
                        />

                    </CSSTransition>
                }

            </TransitionGroup>
        </div >
    )
}
