import React, { Component } from 'react';
import { Router, Route, Switch } from 'react-router-dom';
import { checkForToken, logoutUser } from './actions/authActions'
import { getProducts } from './actions/productActions'
import { getAnonProfile } from './actions/profileActions'
import { Provider } from 'react-redux';
import store from './store';
import { createBrowserHistory as createHistory } from 'history'
import ReactGA from 'react-ga4';
import { Helmet } from "react-helmet";
import TagManager from 'react-gtm-module'


//css
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
// import './Nls.scss'; //run the build file if needed
import './Nls.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import AOS from 'aos'
import 'aos/dist/aos.css'

//Components
import AdminRoute from './components/common/AdminRoute'
import Navbar from './components/layout/Navbar'
import Footer from './components/layout/Footer'
import ForgotResetScreen from './components/auth/ForgotResetScreen'
import LoginScreen from './components/auth/LoginScreen'
import LogInAs from './components/auth/LogInAs'
import LogoutScreen from './components/auth/LogoutScreen'
import AccountScreen from './components/account/AccountScreen'
import LearnerRouter from './components/learner/LearnerRouter'
import OrganisationRouter from './components/admin/RouterOrganisationRouter';
import Error404Screen from './components/errors/Error404Screen';
import PrivateRoute from './components/common/PrivateRoute'
import AuthRoute from './components/common/AuthRoute'
import ScrollUp from './components/common/ScrollUp'
import CookieConsent from './CookieConsent'
import Banner from './Banner'

//font awesome
import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faSpinner,
  faAngleRight,
  faCheckCircle,
  faAngleLeft,
  faArrowLeft,
  faArrowRight,
  faUserCircle,
  faCheck,
  faEllipsisV,
  faEdit,
  faSave,
  faTasks,
  faLock,
  faGraduationCap,
} from '@fortawesome/free-solid-svg-icons'
import PLMBursaryForm from './components/common/PLMBursaryForm';

import detectIdle from './utils/detectIdle'
import StripeOneTime from './components/purchase/StripeOneTime';
import StripeSuccess from './components/purchase/StripeSuccess';
import ContactScreen from './components/pages/ContactScreen';
import HomePageRedirect from './HomePageRedirect';
detectIdle(
  () => {

    if (store.getState().auth.isAuthenticated) {
      window.location.href = '/logout'
      store.dispatch(logoutUser())
    }

  }, 1200 //20 mins
)

library.add(faSpinner)
library.add(faAngleRight)
library.add(faAngleLeft)
library.add(faArrowLeft)
library.add(faArrowRight)
library.add(faUserCircle)
library.add(faCheck)
library.add(faEllipsisV)
library.add(faEdit)
library.add(faSave)
library.add(faCheckCircle)
library.add(faTasks)
library.add(faLock)
library.add(faGraduationCap)

//Initialise Google Analytics
if (process.env.REACT_APP_GOOGLE_ANALYTICS === "true") {
  ReactGA.initialize('G-S2SWYK96PZ');
}
const history = createHistory()
history.listen(location => {

  if (process.env.REACT_APP_GOOGLE_ANALYTICS === "true") {
    ReactGA.set({ page: location.pathname })
    ReactGA.send({ hitType: "pageview", page: location.pathname });
  }
})

const tagManagerArgs = {
  gtmId: 'GTM-K5JZPQ3'
}
TagManager.initialize(tagManagerArgs)

//load token from localstorage
store.dispatch(checkForToken())
store.dispatch(getAnonProfile())
store.dispatch(getProducts())

AOS.init({
  delay: '100',
  offset: '200px',
  duration: '800',
  once: true
});



class App extends Component {
  componentDidMount() {
    if (process.env.REACT_APP_GOOGLE_ANALYTICS === "true") {
      ReactGA.send({ hitType: "pageview", page: window.location.pathname });
    }
  }

  render() {

    if (window.top !== window.self) {
      return <div>Iframing is blocked</div>
    }

    return (
      <Provider store={store}>
        <Router history={history}>
          <Switch>
            <Route exact path="/plm-bursary" component={PLMBursaryForm} />
            <Route path="/" render={({ location }) =>
              <div id="app">
                <Helmet>
                  <meta name="description" content="We’ve helped over 1,700 practices and Primary Care Networks in England, Scotland and Wales save time, money and improve patient outcomes."
                  />
                  <meta name="twitter:card" content="summary" />
                  <title>Practice Unbound - Primary Care Solutions, Innovation & GP Consultancy</title>
                </Helmet>
                <Navbar />
                <Route path="/" component={ScrollUp} />
                <div id="main-wrapper" role="main" aria-label="main content area">

                  <Switch location={location}>
                    <PrivateRoute exact path="/" component={HomePageRedirect} />
                    <Route path="/contact-us/:campaign" component={ContactScreen} />
                    <Route path="/contact-us" component={ContactScreen} />
                    <Route path="/product/:product" component={LearnerRouter} />
                    <Route exact path="/purchase/prescribinglifestylemedicine"
                      render={props => {
                        return <StripeOneTime {...props} product="prescribinglifestylemedicine" />
                      }}
                    />
                    <Route exact path="/purchase/plmaccreditationonly"
                      render={props => {
                        return <StripeOneTime {...props} product="plmaccreditationonly" dob={true} />
                      }}
                    />
                    <Route exact path="/purchase/plmaccreditationandcourse"
                      render={props => {
                        return <StripeOneTime {...props} product="plmaccreditationandcourse" dob={true} />
                      }}
                    />
                    <Route exact path="/purchase/plmaccreditationandcourseandlive"
                      render={props => {
                        return <StripeOneTime {...props} product="plmaccreditationandcourseandlive" dob={true} />
                      }}
                    />
                    <Route exact path="/purchase/prescribinglifestylemedicine/success"
                      render={props => {
                        return <StripeSuccess {...props} product="prescribinglifestylemedicine" />
                      }}
                    />
                    <Route exact path="/purchase/plmaccreditationonly/success"
                      render={props => {
                        return <StripeSuccess {...props} product="prescribinglifestylemedicine" />
                      }}
                    />
                    <Route exact path="/purchase/plmaccreditationandcourse/success"
                      render={props => {
                        return <StripeSuccess {...props} product="prescribinglifestylemedicine" />
                      }}
                    />
                    <Route exact path="/purchase/plmaccreditationandcourseandlive/success"
                      render={props => {
                        return <StripeSuccess {...props} product="prescribinglifestylemedicine" />
                      }}
                    />
                    <Route exact path="/login" component={LoginScreen} />
                    <AdminRoute exact path="/l/i/a/:user" component={LogInAs} />
                    <Route exact path="/logout" component={LogoutScreen} />
                    <AuthRoute exact path="/forgotten-reset/:token" component={ForgotResetScreen} />
                    <PrivateRoute exact path="/account" component={AccountScreen} />
                    <PrivateRoute path="/admin" component={OrganisationRouter} />
                    <Route component={Error404Screen} />
                  </Switch>


                </div>


                <div id="footers">
                  <Switch>
                    <Route path="/product/:product/data" />
                    <Route path="/">
                      <Footer />
                    </Route>
                  </Switch>
                </div>
                <CookieConsent />
                <Banner />
              </div>
            } />
          </Switch>
        </Router>
      </Provider>
    );
  }
}

export default App;
