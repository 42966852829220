import React, { Component } from 'react'
import { connect } from 'react-redux'
import { getOrganisationProductMembershipOwned, clearOrganisationProductMembership } from '../../actions/organisationActions'
import { Route, Switch } from 'react-router-dom';
import PageIg from './PageIg'
import AssignLicences from './PageAssignLicences'
import ProductLandingPage from './PageProductLandingPage';
import LNA from './PageLNA';
import Spinner from '../common/Spinner'
import Button from '../common/Button'
import PageInvite from './PageInvite'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import PageResults from './PageResults';
import PageAnonymiseData from './PageAnonymiseData';
import PageAuditCheck from './PageAuditCheck';

class RouterProductSubRouter extends Component {
    constructor() {
        super()
        this.updateProduct = this.updateProduct.bind(this)
    }
    componentDidMount() {
        this.updateProduct()
    }

    componentDidUpdate(prevProps) {
        if ((prevProps.match.params.product !== this.props.match.params.product)
            || (prevProps.organisations.organisationProductMemberships !== this.props.organisations.organisationProductMemberships)
        ) {
            if (this.props.organisations.organisationProductMemberships.length > 0) {
                this.updateProduct()

            }
        }
    }

    updateProduct() {
        const currentOpmId = this.props.match.params.product
        const { organisationProductMemberships } = this.props.organisations || []
        if (organisationProductMemberships.length === 0) {
            return this.props.clearOrganisationProductMembership()
        }
        const filtered = organisationProductMemberships.filter(p => p.organisationproductmembershipid === currentOpmId)
        if (filtered.length === 0) {
            this.props.clearOrganisationProductMembership()
            this.props.history.push('/admin/')
            return
        }
        if (filtered[0]) {
            this.props.getOrganisationProductMembershipOwned(this.props.organisations.organisation.code, filtered[0]._id)
        }

        sessionStorage.setItem('product', currentOpmId)

    }

    render() {
        const { match, location } = this.props
        const { organisationProductMembership, organisationProductMembershipLoading, organisationProductMembershipsLoading } = this.props.organisations
        if (organisationProductMembership === null || organisationProductMembershipLoading || organisationProductMembershipsLoading) {
            return <Spinner block />
        }

        let product = {}
        if (organisationProductMembership.product) {
            product = organisationProductMembership.product
        }

        // these are the variable which will act as the params for the redirect url for the 'Got back to Product button'
        let backToProductScreenButton
        // If product object exists in Redux
        // redirect based on order- getStarted page, then Course then home
        if (product.showGetStartedTab) backToProductScreenButton = "/get-started"
        else if (product.showElearningTab) backToProductScreenButton = "/course"
        else backToProductScreenButton = ''

        return (
            <div style={{ position: 'relative' }}>


                <div style={{ height: '70px' }} className="row">
                    <div className="col-12">
                        {(location.pathname !== '/admin/' + organisationProductMembership.organisationproductmembershipid && location.pathname !== '/admin/' + organisationProductMembership.organisationproductmembershipid + '/') && (
                            <Button
                                colour="none"
                                className="float-left"
                                underline
                                inline
                                icon="back"
                                link={'/admin/' + organisationProductMembership.organisationproductmembershipid}
                            >Back</Button>
                        )}
                        <Button
                            link={`/product/${product.name}${backToProductScreenButton}`}
                            className="float-right"
                            inline
                            small
                            center
                        ><span className="d-none d-sm-inline">Go to {product.title}</span>
                            <span className="d-inline d-sm-none">To Product Home</span>
                        </Button>
                    </div>

                </div>
                {/* <h1 className="font-weight-bold display-6">{organisationProductMembership.product.title}</h1> */}

                <TransitionGroup appear={true}>
                    <CSSTransition
                        key={location.key}
                        classNames="fadeRouter"
                        timeout={150}
                    >
                        <Switch location={location}>
                            <Route exact path={match.url + '/'} component={ProductLandingPage} />
                            <Route exact path={match.url + '/ig'} component={PageIg} />
                            <Route exact path={match.url + '/anonymise-data'} component={PageAnonymiseData} />
                            <Route exact path={match.url + '/check-audit'} component={PageAuditCheck} />
                            <Route exact path={match.url + '/licences'} component={AssignLicences} />
                            <Route exact path={match.url + '/learning-needs-analysis'} component={LNA} />
                            <Route exact path={match.url + '/invite'} component={PageInvite} />
                            <Route exact path={match.url + '/results'} component={PageResults} />
                        </Switch>
                    </CSSTransition>
                </TransitionGroup>
            </div>
        )
    }
}
const mapStateToProps = (state) => ({
    organisations: state.organisations,
})

export default connect(mapStateToProps, { clearOrganisationProductMembership, getOrganisationProductMembershipOwned })(RouterProductSubRouter);