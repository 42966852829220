import api from '../config/axios';
import {
    SET_PURCHASE_PRODUCT_LOADING,
    CLEAR_PURCHASE_ERROR,
    GET_PURCHASE_PRODUCT,
    GET_PURCHASE_ERROR,
    SET_PURCHASE_CONTACT_LOADING,
    GET_PURCHASE_CONTACT,
    SET_PURCHASE_MY_ORGANISATIONS_LOADING,
    GET_PURCHASE_MY_ORGANISATIONS,
    SET_PURCHASE_SEARCH_ORGANISATIONS_LOADING,
    GET_PURCHASE_SEARCH_ORGANISATIONS,
    SET_PURCHASE_ORGANISATION_LOADING,
    GET_PURCHASE_ORGANISATION,
    GET_PURCHASE_CONTRACT,
    SET_PURCHASE_CONTRACT_LOADING,
    SET_PURCHASE_ORGANISATION_CONFIRMED,
    SET_PURCHASE_SUBMIT_LOADING,
    SET_PURCHASE_SUBMIT_SUCCESS,
    GET_ORGANISATION_CONTRACTS,
    SET_PURCHASE_OPTOUT_LOADING,
    SET_PURCHASE_OPTOUT_SUCCESS,
    FIRE_BILLSBY

} from './types';
import { checkForToken } from './authActions'

export const fireBillsby = (sid) => dispatch => {
    api.platformAPI.post('api/platform/billsby/frontend/subscription', {
        subscriptionUniqueId: sid
    })
        .then(res => {

            dispatch(checkForToken())

            dispatch({
                type: FIRE_BILLSBY,
                payload: res.data
            });
        })
}


export const getPurchaseProduct = (prod) => dispatch => {
    dispatch({ type: SET_PURCHASE_PRODUCT_LOADING, payload: true });
    dispatch({ type: CLEAR_PURCHASE_ERROR, payload: "product" });
    api.platformAPI.get('/api/platform/products/' + prod)
        .then(res => {
            dispatch({
                type: GET_PURCHASE_PRODUCT,
                payload: res.data
            });
        })
        .catch(err => {
            dispatch({ type: GET_PURCHASE_ERROR, payload: { type: "product", message: err.response ? err.response.data : err } })
            dispatch({ type: SET_PURCHASE_PRODUCT_LOADING, payload: false });
        })
}

export const getCRMContact = () => dispatch => {
    dispatch({ type: SET_PURCHASE_CONTACT_LOADING, payload: true });
    dispatch({ type: CLEAR_PURCHASE_ERROR, payload: "contact" });

    api.platformAPI.get('/api/platform/purchase/contact')
        .then(res => {
            dispatch({
                type: GET_PURCHASE_CONTACT,
                payload: res.data
            });
        })
        .catch(err => {
            dispatch({ type: GET_PURCHASE_ERROR, payload: { type: "contact", message: err.response ? err.response.data : err } })
            dispatch({ type: SET_PURCHASE_CONTACT_LOADING, payload: false });
        })

}

export const updateCRMContact = (data) => dispatch => {
    dispatch({ type: SET_PURCHASE_CONTACT_LOADING, payload: true });
    dispatch({ type: CLEAR_PURCHASE_ERROR, payload: "contact" });

    api.platformAPI.post('/api/platform/purchase/contact', data)
        .then(res => {
            dispatch({
                type: GET_PURCHASE_CONTACT,
                payload: res.data
            });
        })
        .catch(err => {
            dispatch({ type: GET_PURCHASE_ERROR, payload: { type: "contact", message: err.response ? err.response.data : err } })
            dispatch({ type: SET_PURCHASE_CONTACT_LOADING, payload: false });
        })

}

export const getOrganisationMemberships = () => dispatch => {
    dispatch({ type: SET_PURCHASE_MY_ORGANISATIONS_LOADING, payload: true });
    dispatch({ type: CLEAR_PURCHASE_ERROR, payload: "myOrganisations" });

    api.platformAPI.get('/api/platform/purchase/organisations')
        .then(res => {
            dispatch({
                type: GET_PURCHASE_MY_ORGANISATIONS,
                payload: res.data
            });
        })
        .catch(err => {
            dispatch({ type: GET_PURCHASE_ERROR, payload: { type: "myOrganisations", message: err.response ? err.response.data : err } })
            dispatch({ type: SET_PURCHASE_MY_ORGANISATIONS_LOADING, payload: false });
        })

}



export const organisationSearch = (search) => dispatch => {
    if (!search) return
    dispatch({ type: SET_PURCHASE_SEARCH_ORGANISATIONS_LOADING, payload: true });
    dispatch({ type: CLEAR_PURCHASE_ERROR, payload: "searchOrganisations" });

    api.platformAPI.get('/api/platform/purchase/organisations/search/' + search)
        .then(res => {
            dispatch({
                type: GET_PURCHASE_SEARCH_ORGANISATIONS,
                payload: res.data
            });
        })
        .catch(err => {
            dispatch({ type: GET_PURCHASE_ERROR, payload: { type: "searchOrganisations", message: err.response ? err.response.data : err } })
            dispatch({ type: SET_PURCHASE_SEARCH_ORGANISATIONS_LOADING, payload: false });
        })

}


export const getCrmOrganisation = (code) => dispatch => {
    dispatch({ type: SET_PURCHASE_ORGANISATION_LOADING, payload: true });
    dispatch({ type: SET_PURCHASE_ORGANISATION_CONFIRMED, payload: false });
    dispatch({ type: CLEAR_PURCHASE_ERROR, payload: "organisation" });

    api.platformAPI.get('/api/platform/purchase/crmOrganisation/' + code)
        .then(res => {
            dispatch({
                type: GET_PURCHASE_ORGANISATION,
                payload: res.data
            });
        })
        .catch(err => {
            dispatch({ type: GET_PURCHASE_ERROR, payload: { type: "organisation", message: err.response ? err.response.data : err } })
            dispatch({ type: SET_PURCHASE_ORGANISATION_LOADING, payload: false });
        })

}


export const updateCrmOrganisation = (code, data) => dispatch => {
    dispatch({ type: SET_PURCHASE_ORGANISATION_LOADING, payload: true });
    dispatch({ type: CLEAR_PURCHASE_ERROR, payload: "organisation" });

    api.platformAPI.post('/api/platform/purchase/crmOrganisation/' + code, data)
        .then(res => {
            dispatch({
                type: GET_PURCHASE_ORGANISATION,
                payload: res.data
            });
        })
        .catch(err => {
            dispatch({ type: GET_PURCHASE_ERROR, payload: { type: "organisation", message: err.response ? err.response.data : err } })
            dispatch({ type: SET_PURCHASE_ORGANISATION_LOADING, payload: false });
        })

}





export const getCrmContract = (code, product) => dispatch => {
    dispatch({ type: SET_PURCHASE_CONTRACT_LOADING, payload: true });
    dispatch({ type: CLEAR_PURCHASE_ERROR, payload: "contract" });

    api.platformAPI.get('/api/platform/purchase/crmContract/' + code + '/' + product)
        .then(res => {
            dispatch({
                type: GET_PURCHASE_CONTRACT,
                payload: res.data
            });
        })
        .catch(err => {
            dispatch({ type: GET_PURCHASE_ERROR, payload: { type: "contract", message: err.response ? err.response.data : err } })
            dispatch({ type: SET_PURCHASE_CONTRACT_LOADING, payload: false });
        })

}




export const subscribeAction = (code, product, useSignature, signature) => dispatch => {
    dispatch({ type: SET_PURCHASE_SUBMIT_LOADING, payload: true });
    dispatch({ type: CLEAR_PURCHASE_ERROR, payload: "contract" });
    api.platformAPI.post('/api/platform/purchase/subscribe/' + code + '/' + product, { useSignature, signature })
        .then(res => {
            dispatch(checkForToken())
            dispatch({
                type: SET_PURCHASE_SUBMIT_SUCCESS,
                payload: true
            });
        })
        .catch(err => {
            dispatch({ type: GET_PURCHASE_ERROR, payload: { type: "contract", message: err.response ? err.response.data : err } })
            dispatch({ type: SET_PURCHASE_SUBMIT_LOADING, payload: false });
        })

}




export const contractAction = (code, product, useSignature, signature) => dispatch => {
    dispatch({ type: SET_PURCHASE_SUBMIT_LOADING, payload: true });
    dispatch({ type: CLEAR_PURCHASE_ERROR, payload: "contract" });
    api.platformAPI.post('/api/platform/purchase/contract/' + code + '/' + product, { useSignature, signature })
        .then(res => {
            dispatch(checkForToken())
            dispatch({
                type: SET_PURCHASE_SUBMIT_SUCCESS,
                payload: true
            });
        })
        .catch(err => {
            dispatch({ type: GET_PURCHASE_ERROR, payload: { type: "contract", message: err.response ? err.response.data : err } })
            dispatch({ type: SET_PURCHASE_SUBMIT_LOADING, payload: false });
        })

}



export const optOutAction = (opm, data) => dispatch => {
    dispatch({ type: SET_PURCHASE_OPTOUT_LOADING, payload: true });
    dispatch({ type: CLEAR_PURCHASE_ERROR, payload: "optout" });
    api.platformAPI.post('/api/platform/purchase/optout/' + opm, data)
        .then(res => {
            dispatch({
                type: SET_PURCHASE_OPTOUT_SUCCESS,
                payload: true
            });
            dispatch({
                type: GET_ORGANISATION_CONTRACTS,
                payload: res.data
            });
        })
        .catch(err => {
            dispatch({ type: GET_PURCHASE_ERROR, payload: { type: "optout", message: err.response ? err.response.data : err } })
            dispatch({ type: SET_PURCHASE_OPTOUT_LOADING, payload: false });
        })

}



export const setPurchaseOptOutSuccess = (data) => dispatch => {
    dispatch({ type: SET_PURCHASE_OPTOUT_SUCCESS, payload: data })
}

export const setPurchaseSubmitSuccess = (data) => dispatch => {
    dispatch({ type: SET_PURCHASE_SUBMIT_SUCCESS, payload: data })
}

export const clearPurchaseError = (area) => dispatch => {
    dispatch({ type: CLEAR_PURCHASE_ERROR, payload: area });
}


export const setPurchaseOrganisationConfirmed = (confirmed) => dispatch => {
    dispatch({ type: SET_PURCHASE_ORGANISATION_CONFIRMED, payload: confirmed });

}