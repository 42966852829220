import React from 'react'
import SocialsIcon from '../../../common/SocialsIcon'
import DivFade from '../../DivFade'
import puColours from '../../../../utils/puColours'

export default function BottomBanner(props) {

    let backgroundColour = '#16C194'
    switch (props.backgroundColour) {
        case "leafGreen":
            backgroundColour = '#60AE4C'
        break
        case "darkOrange":
            backgroundColour = '#EB6915'
        break
        default:
            backgroundColour = '#EB6915C'
        break
    }

    return (
        <DivFade
            className="bg-dark-teal text-white"
            style={{ backgroundColor: backgroundColour }}
        >
            <div className="container" style={{ paddingTop: props.paddingTop ? props.paddingTop : '72px', paddingBottom: props.paddingBottom ? props.paddingBottom :  '72px' }}>
                <div className="row justify-content-between">
                    <div className="col-sm-8 mb-3">
                        <h3 className="mb-4" style={{ fontWeight: 'bold' }}>{props.header}</h3>
                        {props.children}
                    </div>
                    <div className="col-sm-2 d-flex justify-content-center align-items-center">
                        <span style={{ fontSize: '32px', marginRight: '16px' }}><SocialsIcon className={`tw-text-white hover:tw-text-[${props.iconHoverColour || 'white'}] tw-mx-2`} link={"https://twitter.com/PracticeUnbound"} social="twitter" /></span>
                        <span style={{ fontSize: '32px' }}><SocialsIcon className={`tw-text-white hover:tw-text-[${props.iconHoverColour || 'white'}] tw-mx-2`} link={"https://www.linkedin.com/company/practice-unbound"} social="linkedin" /></span>
                    </div>
                </div>
            </div>
        </DivFade >
    )
}
