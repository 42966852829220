import React, { Component } from 'react';
import '../AdminEnviroment.css'
import { NavLink } from 'react-router-dom'


class SideNavSubItem extends Component {
    render() {
        return (
            <NavLink
                aria-label={this.props.itemTitle + " " + this.props.subItemTitle}
                to={this.props.disabled ? '#' : this.props.subItemHref}
                onClick={this.props.closeSideNavs}
            >
                <div
                    style={{ width: '100%' }}
                    className={!this.props.disabled ? 'sideNavSubItemRow' : null}
                >
                    <div
                        style={{

                            width: '100%',
                            fontSize: '14px',
                            marginLeft: '56px',
                            fontWeight: '300',
                            opacity: this.props.disabled && '0.4',
                            cursor: this.props.disabled && 'default'
                        }}
                    >
                        {this.props.subItemTitle}
                    </div>
                </div >
            </NavLink >
        )
    }
}

class SideNavItem extends Component {
    render() {
        return (
            <div
                style={{
                    width: '100%',
                    overflow: 'hidden',
                    // whiteSpace: 'nowrap',
                    marginBottom: '20px'
                }}
                className="sideNavRowItem"
            >
                <NavLink to={this.props.href}
                    onClick={this.props.closeSideNavs}>
                    <div className="sideNavProductRow" style={{
                        margin: '0x',
                        padding: '0',
                        border: !this.props.open && 'none',
                    }}>

                        <div
                            style={{
                                fontSize: '16px',

                                paddingTop: '5px',
                                paddingBottom: '5px',
                                paddingLeft: '10px',
                                paddingRight: '10px',
                                display: 'table-cell',
                            }}
                        >
                            {typeof this.props.icon === "function" ? (
                                <div
                                    style={{
                                        width: '30px',
                                        height: '30px',
                                        display: 'inline-block',
                                        verticalAlign: 'middle',
                                        textAlign: 'center'
                                    }} >
                                    {this.props.icon()}
                                </div>
                            ) : (

                                    <div
                                        style={{
                                            backgroundImage: `url(${this.props.icon})`,
                                            backgroundPosition: 'center',
                                            backgroundRepeat: 'no-repeat',
                                            backgroundSize: 'contain',
                                            width: '30px',
                                            height: '30px',
                                            display: 'inline-block',
                                            verticalAlign: 'middle',
                                        }} />
                                )}


                            {(this.props.open || this.props.isMobile) &&
                                (
                                    < div
                                        style={{
                                            fontWeight: '600',
                                            marginLeft: '14px',
                                            display: 'inline-block',
                                            verticalAlign: 'middle',
                                            width: '210px'
                                        }}
                                    >
                                        {this.props.title}
                                    </div>
                                )}
                        </div>
                    </div>
                </NavLink>
                {(this.props.open || this.props.isMobile) && this.props.subItem &&
                    (this.props.subItem.map((li, index) => {
                        return (
                            <SideNavSubItem
                                itemTitle={this.props.title}
                                key={index}
                                {...li}
                                closeSideNavs={this.props.closeSideNavs}
                            />)
                    })
                    )
                }

            </div >

        )
    }
}
export default SideNavItem
