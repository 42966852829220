import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { loginUser, expiredPassword, logoutUser, forgottenPassword, clearAuthErrors } from '../../actions/authActions'
import TextFieldGroup from '../common/TextFieldGroup'
import Button from '../common/Button'


class LoginScreen extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            password: '',
            newPassword: '',
            newPassword2: '',
            showForgot: false
        };

        this.onChange = this.onChange.bind(this);
        this.onLoginSubmit = this.onLoginSubmit.bind(this);
        this.onExpiredSubmit = this.onExpiredSubmit.bind(this);
        this.onForgotSubmit = this.onForgotSubmit.bind(this);
        this.openForgot = this.openForgot.bind(this);
        this.closeForgot = this.closeForgot.bind(this);
    }
    onChange(e) {
        this.setState({ [e.target.name]: e.target.value })
    }
    onLoginSubmit(e) {
        e.preventDefault();
        const userData = {
            email: this.state.email,
            password: this.state.password,
        }
        this.props.loginUser(userData)
    }

    onExpiredSubmit(e) {
        e.preventDefault();
        const userData = {
            email: this.state.email,
            password: this.state.password,
            newPassword: this.state.newPassword,
            newPassword2: this.state.newPassword2,
        }
        this.props.expiredPassword(userData)
    }

    onForgotSubmit(e) {
        e.preventDefault();
        const userData = {
            email: this.state.email,
        }
        this.props.forgottenPassword(userData)
    }


    openForgot() {
        this.setState({
            showForgot: true,
        })
        this.props.clearAuthErrors()
    }
    closeForgot() {
        this.setState({
            showForgot: false
        })
    }


    render() {
        const { auth } = this.props;
        const { errors } = auth

        const loginForm = (

            <form onSubmit={this.onLoginSubmit}>
                <div style={{ maxWidth: '300px' }} className="mx-auto">
                    <TextFieldGroup
                        placeholder="Email Address"
                        name='email'
                        type='email'
                        value={this.state.email}
                        onChange={this.onChange}
                        error={errors.email}
                    />
                    <TextFieldGroup
                        placeholder="Password"
                        name='password'
                        type='password'
                        value={this.state.password}
                        onChange={this.onChange}
                        error={errors.password}
                    />
                </div>
                <div className="mb-2">
                    <Button
                        fixedWidth
                        className="mx-auto"
                        loading={auth.loading || auth.isAuthenticated}
                        type="submit"
                    >Login
                </Button>
                </div>
                <small className="text-danger">{errors.error}</small>
                <Button center underline colour="none" onClick={this.openForgot}>Trouble signing in?</Button>
            </form>

        )

        const expiredForm = (
            <form onSubmit={this.onExpiredSubmit}>
                <Button colour="none" icon="back" onClick={this.props.logoutUser}>back</Button>
                <p>This password has expired, in order to continue please reset it below</p>
                <div style={{ maxWidth: '300px' }} className="mx-auto">
                    <TextFieldGroup
                        placeholder="Old password"
                        name='password'
                        type='password'
                        value={this.state.password}
                        onChange={this.onChange}
                        error={errors.password}
                    />
                    <TextFieldGroup
                        placeholder="New password"
                        name='newPassword'
                        type='password'
                        value={this.state.newPassword}
                        onChange={this.onChange}
                        error={errors.newPassword}
                    />
                    <TextFieldGroup
                        placeholder="Repeat password"
                        name='newPassword2'
                        type='password'
                        value={this.state.newPassword2}
                        onChange={this.onChange}
                        error={errors.newPassword2}
                    />
                </div>
                <small className="d-block text-muted">Minimum of 8 characters</small>
                <small className="d-block text-muted">Must contain both upper & lower case</small>
                <small className="mb-3 d-block text-muted">Must contain both letters & numbers</small>
                <div>
                    <Button
                        fixedWidth
                        loading={auth.loading}
                        type="submit"
                        className="mx-auto"
                    >Login
                </Button>
                </div>
                <small className="text-danger">{errors.error}</small>
            </form >
        )

        let forgotContent;
        if (auth.forgotEmailSent) {
            forgotContent = <p>We have sent you an email with a link that will let you reset your password</p>
        } else {
            forgotContent = (
                <form onSubmit={this.onForgotSubmit}>
                    <Button colour="none" icon="back" onClick={this.closeForgot}>back</Button>
                    <p>If you have forgotten your password you can recover it using the email address your account is linked to</p>
                    <div style={{ maxWidth: '300px' }} className="mx-auto">
                        <TextFieldGroup
                            placeholder="Email Address"
                            name='email'
                            type='email'
                            value={this.state.email}
                            onChange={this.onChange}
                            error={errors.email}
                        />
                    </div>
                    <div>
                        <Button
                            fixedWidth
                            loading={auth.loading}
                            className="mx-auto"
                            type="submit"
                        >Submit
                </Button>
                    </div>
                    <small className="text-danger">{errors.error}</small>
                </form>
            )
        }


        return (
            <div style={{ padding: '5px' }}>
                <div style={{ display: this.state.showForgot === true ? 'block' : 'none' }}>{forgotContent}</div>
                <div style={{ display: (this.state.showForgot === false && this.props.auth.passwordExpired === true) ? 'block' : 'none' }}>{expiredForm}</div>
                <div style={{ display: (this.state.showForgot === false && this.props.auth.passwordExpired !== true) ? 'block' : 'none' }}>{loginForm}</div>
            </div>
        )
    }
}


LoginScreen.propTypes = {
    loginUser: PropTypes.func.isRequired,
    expiredPassword: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
}
const mapStateToProps = state => ({
    auth: state.auth,
})

export default connect(mapStateToProps, { loginUser, expiredPassword, logoutUser, forgottenPassword, clearAuthErrors })(LoginScreen);