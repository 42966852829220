import React, { Component } from 'react'
import { connect } from 'react-redux';
import { getProductSubPageContent, clearProductSubPageErrors } from '../../actions/productActions'
import ContentBlockPage from '../layout/ContentBlockPage'
import Spinner from '../common/Spinner'
import Error404Screen from '../errors/Error404Screen'

class LearnerSubPages extends Component {
    componentDidMount() {
        this.props.clearProductSubPageErrors()
        this.props.getProductSubPageContent(this.props.match.params.pageSlug)
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.match.params.pageSlug !== this.props.match.params.pageSlug) {
            this.props.clearProductSubPageErrors()
            this.props.getProductSubPageContent(nextProps.match.params.pageSlug)
        }
    }

    render() {
        const { pageLoading, page } = this.props.products
        if (pageLoading === false && Object.keys(page).length === 0) { return <Error404Screen /> }
        return pageLoading ? <Spinner fullScreen color="yellow" large /> : <ContentBlockPage content={page} />
    }
}
const mapStateToProps = state => ({
    products: state.products
})
export default connect(mapStateToProps, { getProductSubPageContent, clearProductSubPageErrors })(LearnerSubPages);