import React from 'react'
import CheckIcon from './CheckIcon'
import styles from './RadioButton.module.css'
import classnames from 'classnames'


export default function RadioButton(props) {
    const flexStyle = styles.flex
    if (props.buttons) {
        return (
            <div className={classnames('mb-3',{ [flexStyle]: props.flex })}>
                <p className={classnames({'font-weight-bold':props.boldLabel},'mt-2 pr-3 ml-3')}>{props.label}</p>
                <div style={{ display: 'table' }} className='text-center d-flex align-items-center justify-content-around px-2'>
                    {props.options.map((o, index) => {
                        const checked = o.value === props.selectedValue
                        return (
                            <button
                                className={classnames(styles.editMargins)}
                                style={{
                                    marginLeft: '-4px',
                                    marginRight: '-4px',
                                    boxShadow: 'none',
                                    background: 'none',
                                    border: 'none',
                                    outline: 'none',
                                    flexBasis: '100%',//all equal sized
                                    // display: 'table-cell',
                                    // textAlign: 'center'
                                }}
                                key={o.value}
                                onClick={() => {
                                    props.onChange({
                                        target: {
                                            name: props.name,
                                            value: o.value
                                        }
                                    })
                                }}
                            >
                                <div className="text-center">
                                    <CheckIcon checked={checked} />
                                    <div className={classnames(styles.editLabel)}>
                                        {o.label}
                                    </div>
                                </div>
                            </button>
                        )
                    }
                    )}
                </div>
            </div>
        )
    }


    return (
        <div className="mb-3">
            <p>{props.label}</p>
            <div className="text-center d-flex justify-content-around px-4">
                {props.options.map((o, index) => {
                    return (
                        <div key={o.value} className="form-check form-check-inline">
                            <input
                                style={{
                                    height: props.large && '25px',
                                    width: props.large && '25px',
                                    flex: props.large && '0 0 25px'
                                }}
                                className="form-check-input"
                                type="radio"
                                name={props.name}
                                id={props.name + o.value}
                                value={o.value}
                                onChange={props.onChange}
                            />
                            <label className="form-check-label" htmlFor={props.name + o.value}>
                                {o.label}
                            </label>
                        </div>
                    )
                }
                )}
            </div>
        </div>
    )
}