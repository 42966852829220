import React from 'react'
import Spinner from './Spinner'
import classnames from 'classnames'
import { NavLink } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import DownloadIcon from './DownloadIcon'
import CogIcon from './CogIcon'
import styles from './Button.module.css'
import './Button.css'
//import PropTypes from 'prop-types';

const Button = (props) => {
    let icon
    switch (props.icon) {
        case "back":
            icon = <FontAwesomeIcon aria-label="arrow pointing left" icon="arrow-left" />
            break
        case "next":
            icon = <FontAwesomeIcon aria-label="arrow pointing right" icon="arrow-right" />
            break
        case "left":
            icon = <FontAwesomeIcon aria-label="chevron pointing left" icon="chevron-left" />
            break
        case "right":
            icon = <FontAwesomeIcon aria-label="chevron pointing right" icon="chevron-right" />
            break
        case "user":
            icon = <FontAwesomeIcon icon="user-circle" aria-label="image of User" size="2x" />
            break
        case "kebab":
            icon = <FontAwesomeIcon icon="ellipsis-v" size="2x" />
            break
        case "edit":
            icon = <FontAwesomeIcon aria-label="edit symbol" icon="edit" />
            break
        case "settings":
            icon = <CogIcon aria-label="cog icon" />
            break
        case "save":
            icon = <FontAwesomeIcon aria-label="save" icon="save" />
            break
        case "download":
            icon = <DownloadIcon />
            break
        default:
            break
    }

    const disabled = props.loading || props.disabled || props.done
    const className =
        classnames(
            'btn',
            styles.btn,
            { [styles.colorStandard]: props.colour === undefined },
            { [styles.colorStandard]: props.colour === 'green' && props.inverted !== true },
            { [styles.colorOrange]: props.colour === 'orange' && props.inverted !== true },
            { [styles.colorStandardOutline]: props.colour === 'green-outline' && props.inverted !== true },
            { [styles.colorStandardHollow]: props.colour === 'hollow-green' && props.inverted !== true },
            { [styles.colorOrangeOutline]: props.colour === 'orange-outline' && props.inverted !== true },
            { [styles.colorNone]: props.colour === 'none' },
            { [styles.colorNone]: props.colour === 'white' },
            { [styles.colorWhite]: props.colour === 'white' },
            { [styles.colorNoneOutline]: props.colour === 'none-outline' },
            { [styles.colorNoneOutlineBlue]: props.colour === 'none-outline-blue' },
            { [styles.StandardOutlineBlue]: props.colour === 'std-outline-blue' },
            { [styles.colorHollowOrange]: props.colour === 'hollow-orange' },
            { [styles.colorYellow]: props.colour === 'yellow' && props.inverted !== true },            
            { [styles.colorYellowOutline]: props.colour === 'yellow-outline' && props.inverted !== true },
            { [styles.colorYellowHollow]: props.colour === 'hollow-yellow' && props.inverted !== true },
            { [styles.colorPink]: props.colour === 'pink' && props.inverted !== true },
            { [styles.colorTeal]: props.colour === 'teal' && props.inverted !== true },
            { [styles.colorGreen]: props.colour === 'otherGreen' && props.inverted !== true },
            { [styles.colorStandardInverted]: props.colour === 'green' && props.inverted === true },
            { [styles.colorYellowInverted]: props.colour === 'yellow' && props.inverted === true },
            { [styles.colorPinkInverted]: props.colour === 'pink' && props.inverted === true },
            { [styles.colorTealInverted]: props.colour === 'teal' && props.inverted === true },
            { [styles.fixedWidth]: props.fixedWidth },
            { [styles.block]: !props.inline },
            { [styles.inlineBlock]: props.inline },
            { [styles.underline]: props.underline },
            { [styles.sizeSmall]: props.small },
            { [styles.sizeNormal]: !props.small && !props.large },
            { [styles.sizeLarge]: props.large },
            { [styles.sizeXL]: props.xl },
            { [styles.wrap]: props.wrap },
            { [styles.cursor]: !disabled },
            { [styles.disabled]: disabled },
            { [styles.aStyles]: props.link },
            { [styles.bold]: props.bold },
            { [styles.noPadding]: props.noPadding },
            { [styles.chevronLeft]: props.icon === 'left' },
            { [styles.chevronRight]: props.icon === 'right' },
            { [styles.compact]: props.compact === true },
            { [styles.lowercase]: props.lowercase === true },
            { [styles.alignLeft]: props.alignLeft === true },
            props.className
        )

    let content
    if (props.loading) {
        content = <Spinner />
    } else if (props.done) {
        content = <FontAwesomeIcon icon="check" />
    }
    else if (icon && props.children && props.iconRight) {
        content = (
            <span>{props.children}<span style={{ marginLeft: '3px' }}>{icon}</span></span>
        )
    } else if (icon && props.children) {
        content = (
            <span><span style={{ marginRight: '3px' }}>{icon}</span>{props.children}</span>
        )
    } else if (icon) {
        content = <span>{icon}</span>
    } else {
        content = props.children
    }
    // New prop to render a button which is faded and appears disabled for use with the HoveLinkButton component 
    if (props.hoverDisable) {
        return (
            <span className={classnames({ [styles.linkCenter]: props.center })}>
                <button
                    className={className}
                    to={props.link}
                    style={{ color: 'rgb(175, 175, 175)', cursor: 'default' }}
                >{content}</button>
            </span >
        )
    }

    if (props.link && !disabled) {
        if (props.link.length >= 4) {
            if (props.link.substring(0, 4) === "http" || props.link.substring(0, 4) === "mail") {
                return (
                    <span className={classnames({ [styles.linkCenter]: props.center })}>
                        <a
                            className={className}
                            href={props.link}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            {content}
                        </a >

                    </span>
                )
            }
        }
        return (
            <span className={classnames({ [styles.linkCenter]: props.center })}>
                <NavLink
                    className={className}
                    to={props.link}
                >

                    {content}


                </NavLink >

            </span>
        )
    } else {
        if (props.center) {
            // outer div is to fix IE11 flexbox
            return (
                <div>
                    <div style={{

                        flexDirection: 'row',
                        justifyContent: 'center',
                        display: 'flex',
                    }}>
                        <button
                            type={props.type || "button"}
                            className={className}
                            onClick={props.onClick}
                            disabled={disabled}
                        >
                            {content}
                        </button >

                    </div>
                </div>
            )
        } 
        
        else if (props.icon === 'left') {
            return (
                <button
                    type={props.type || "button"}
                    className={className}
                    onClick={props.onClick}
                    disabled={disabled}
                >
                    <FontAwesomeIcon aria-label="arrow pointing left" icon="chevron-circle-left" />
                </button >
            )
        } 
                
        else {
            return (

                <button
                    type={props.type || "button"}
                    className={className}
                    onClick={props.onClick}
                    disabled={disabled}
                >
                    {content}

                </button >

            )
        }
    }
}

export default Button
