import api from '../config/axios';
import {
    SET_ASSESSMENTS_LOADING,
    GET_ASSESSMENTS,
    CLEAR_ASSESSMENTS,
    GET_ASSESSMENTS_ERRORS,
    CLEAR_ASSESSMENTS_ERRORS,
} from './types';
import { logoutUser } from './authActions'


export const getAssessments = (productcode) => dispatch => {
    dispatch({ type: CLEAR_ASSESSMENTS_ERRORS })
    dispatch({ type: SET_ASSESSMENTS_LOADING, payload: true })
    api.platformAPI
        .get('/api/platform/assessmentform/' + productcode)
        .then(res => {
            dispatch({
                type: GET_ASSESSMENTS, payload: res.data
            })
            dispatch({ type: CLEAR_ASSESSMENTS_ERRORS })
        })
        .catch(err => {
            dispatch({ type: SET_ASSESSMENTS_LOADING, payload: false })
            dispatch({ type: GET_ASSESSMENTS_ERRORS, payload: err })
            dispatch(handleError(err))
        });
}


export const submitAssessment = (productcode,data) => dispatch => {
    dispatch({ type: CLEAR_ASSESSMENTS_ERRORS })
    dispatch({ type: SET_ASSESSMENTS_LOADING, payload: true })
    api.platformAPI
        .post('/api/platform/assessmentform/' + productcode,data)
        .then(res => {
            dispatch({
                type: GET_ASSESSMENTS, payload: res.data
            })
            dispatch({ type: CLEAR_ASSESSMENTS_ERRORS })
        })
        .catch(err => {
            dispatch({ type: SET_ASSESSMENTS_LOADING, payload: false })
            dispatch({ type: GET_ASSESSMENTS_ERRORS, payload: err })
            dispatch(handleError(err))
        });
}


export const clearAssessments = () => dispatch => {
    dispatch({ type: CLEAR_ASSESSMENTS })
}

const handleError = (err) => dispatch => {
    //let payload
    console.dir(err)
    //token expired
    if (err.response) {
        if (err.response.statusText === "Unauthorized" && err.response.status === 401 && err.response.data === "Unauthorized") {
            dispatch(logoutUser())
        }
    }
}
