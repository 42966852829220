import { Link } from 'react-router-dom/cjs/react-router-dom.min'
import SectionPage from './SectionPage'


const CaseStudy = ({ progress, updateProgress, updating, setUpdating, postErrorRes, errorRes }) => {
  return (
    <SectionPage 
      progress={progress}
      updateProgress={updateProgress}
      updating={updating} 
      setUpdating={setUpdating}
      postErrorRes={postErrorRes}
      errorRes={errorRes}
      sectionName="casestudy"    
      title="Submit Case Study" 
      uploadText="Click or drag your case study file here"
    >
      <div className="mb-4 mt-3">
        <div className="mb-4 mt-3">
          <h5 className="font-weight-bold">Introduction</h5>
          <p>Case studies provide detailed insight into the application of a lifestyle medicine approach and will help consolidate your learning through application, reflection, and evaluation.</p>
          <p>Ensure that you have read the case studies provided within the Prescribing Lifestyle Medicine eLearning modules (as well as within the community) and reflect upon change within your own provision and practice. </p>          
          <h5 className="font-weight-bold">What do I need to do?</h5>          
          <p>Download the <a href='https://practiceunbound.blob.core.windows.net/documents/case-study-template-v5.pptx'>case study template</a> to support you in the creation of your own. It is important to note that the case study template is provided as a starting point and guide, but you should feel free to expand and elaborate as required.</p>
          <p>Upon completion of your case study please upload it using the submission box below - this will then be reviewed. If you need guidance on submitting your case study please refer to the user guide for <Link to="/product/prescribinglifestylemedicine/accreditation/interactions">Step 3</Link> or <Link to="/product/prescribinglifestylemedicine/accreditation/interactions">Step 4</Link> since the guidance is the same.</p>
        </div>
      </div>
    </SectionPage>
  )
}

export default CaseStudy