import React, { Component } from 'react'
import { connect } from 'react-redux'
import Checkbox from '../common/Checkbox'
import Button from '../common/Button';
import { setOrganisationProductMembershipActionSuccess, setAnonymiseData } from '../../actions/organisationActions'
import PUPanel from '../layout/PUPanel'
import processRichText from '../../utils/processRichText'

class PageIG extends Component {
    constructor() {
        super()
        this.state = {
            anonymiseData: false
        }
        this.onChange = this.onChange.bind(this);
    }

    componentDidMount() {
        this.setState({
            anonymiseData: this.props.organisations.organisationProductMembership.anonymiseData
        })
    }

    componentWillUnmount() {
        this.props.setOrganisationProductMembershipActionSuccess(false)
    }

    onChange(e) {
        if (e.target.type === "checkbox") {
            this.setState({ [e.target.name]: e.target.checked })
        } else {
            this.setState({ [e.target.name]: e.target.value })
        }
    }


    render() {
        const { organisationProductMembership, organisation, organisationProductMembershipActionLoading } = this.props.organisations

        return (
            <div>

                <PUPanel title="Anonymise Data">

                    {organisationProductMembership.product.offerDataAnonymity && (
                        < div >
                            {processRichText(organisationProductMembership.product.offerDataAnonymityText && organisationProductMembership.product.offerDataAnonymityText.content)}

                            <Checkbox
                                onChange={this.onChange}
                                name="anonymiseData"
                                value={this.state.anonymiseData}
                                className="mb-3"
                            >
                                Anonymise my data
                            </Checkbox>
                            {organisationProductMembership.anonymiseData !== this.state.anonymiseData && (
                                <Button
                                    loading={organisationProductMembershipActionLoading}
                                    fixedWidth
                                    center
                                    onClick={() => {
                                        this.props.setAnonymiseData(
                                            organisation.code,
                                            organisationProductMembership._id,
                                            { anonymiseData: this.state.anonymiseData }
                                        )
                                    }}
                                >Save</Button>
                            )}

                        </div>
                    )}
                </PUPanel>

            </div >
        )
    }
}

const mapStateToProps = (state) => ({
    organisations: state.organisations
})

export default connect(mapStateToProps, { setOrganisationProductMembershipActionSuccess, setAnonymiseData })(PageIG);