import React, { Component } from 'react'
import { connect } from 'react-redux';
import { dataFrontendURI } from '../../config/parameters'

const Iframe = (props) => {
    return (
        <div className="w-100" style={{
            height: 'calc(100vh - 132px)',//put the overflow in a parent div for iPads
        }}>
            < iframe
                title="data"
                id="data"
                src={props.url}
                style={{
                    width: '100%',
                    height: '100%',
                    border: 'none'
                }}
            />
        </div>
    )
}

class DataScreen extends Component {
    render() {
        const { organisation, organisationLoading } = this.props.organisations
        const { product } = this.props.products

        let url = `${dataFrontendURI}/${product.name}/?`
        if (localStorage.jwtToken) {
            url += `&token=${localStorage.jwtToken}`
        }
        if (product.purchased === true) {
            url += '&purchased=true'
        } else {
            url += '&purchased=false'
        }

        if (organisation) {
            if (organisation.code) {
                url += `&organisation=${organisation.code}`
            }
        }
        if (organisationLoading) {
            return <div>Loading</div>
        }
        return <Iframe url={url} />
    }
}
const mapStateToProps = state => ({
    products: state.products,
    organisations: state.organisations
})
export default connect(mapStateToProps, {})(DataScreen);