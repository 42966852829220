import React from 'react'
import RadioButton from '../../../common/RadioButton'
import PUPanel from '../../../layout/PUPanel'
import styles from './Plm.module.css'

const PlmAssessmentFormSection = ({ sectionInputData, setInputData, color }) => {
    return (
        <PUPanel
            title={`${sectionInputData.questionOne.sectionNumber}: ${sectionInputData.questionOne.sectionName}`}
            color={color}
        >
            <div className={styles.formStyle}>

                {sectionInputData.questionOne &&
                    <section className="px-3 py-2">
                        <RadioButton
                            buttons
                            boldLabel
                            flex
                            selectedValue={sectionInputData.questionOne.answer}
                            name={sectionInputData.questionOne.questionName}
                            label={sectionInputData.questionOne.questionText}
                            onChange={event => {
                                const { value, name } = event.target
                                setInputData(prevInputData => ({
                                    ...prevInputData,
                                    [name]: {
                                        ...sectionInputData.questionOne,
                                        answer: value,
                                    }
                                }))
                            }}
                            center
                            options={[
                                { label: '1', value: 1 },
                                { label: '2', value: 2 },
                                { label: '3', value: 3 },
                                { label: '4', value: 4 },
                                { label: '5', value: 5 },
                            ]}
                        />
                    </section>
                }

                {sectionInputData.questionTwo &&
                    <section className="px-3 py-2">
                        <RadioButton
                            buttons
                            boldLabel
                            flex
                            selectedValue={sectionInputData.questionTwo.answer}
                            name={sectionInputData.questionTwo.questionName}
                            label={sectionInputData.questionTwo.questionText}
                            onChange={event => {
                                const { value, name } = event.target
                                setInputData(prevInputData => ({
                                    ...prevInputData,
                                    [name]: {
                                        ...sectionInputData.questionTwo,
                                        answer: value,
                                    }
                                }))
                            }}
                            center
                            options={[
                                { label: '1', value: 1 },
                                { label: '2', value: 2 },
                                { label: '3', value: 3 },
                                { label: '4', value: 4 },
                                { label: '5', value: 5 },
                            ]}
                        />
                    </section>
                }
                {sectionInputData.questionThree &&
                    <section className="px-3 py-2">
                        <RadioButton
                            buttons
                            boldLabel
                            flex
                            selectedValue={sectionInputData.questionThree.answer}
                            name={sectionInputData.questionThree.questionName}
                            label={sectionInputData.questionThree.questionText}
                            onChange={event => {
                                const { value, name } = event.target
                                setInputData(prevInputData => ({
                                    ...prevInputData,
                                    [name]: {
                                        ...sectionInputData.questionThree,
                                        answer: value,
                                    }
                                }))
                            }}
                            center
                            options={[
                                { label: '1', value: 1 },
                                { label: '2', value: 2 },
                                { label: '3', value: 3 },
                                { label: '4', value: 4 },
                                { label: '5', value: 5 },
                            ]}
                        />
                    </section>
                }
                {sectionInputData.questionFour &&
                    <section className="px-3 py-2">
                        <RadioButton
                            buttons
                            boldLabel
                            flex
                            selectedValue={sectionInputData.questionFour.answer}
                            name={sectionInputData.questionFour.questionName}
                            label={sectionInputData.questionFour.questionText}
                            onChange={event => {
                                const { value, name } = event.target
                                setInputData(prevInputData => ({
                                    ...prevInputData,
                                    [name]: {
                                        ...sectionInputData.questionFour,
                                        answer: value,
                                    }
                                }))
                            }}
                            center
                            options={[
                                { label: '1', value: 1 },
                                { label: '2', value: 2 },
                                { label: '3', value: 3 },
                                { label: '4', value: 4 },
                                { label: '5', value: 5 },
                            ]}
                        />
                    </section>
                }
                {sectionInputData.questionFive &&
                    <section className="px-3 py-2">
                        <RadioButton
                            buttons
                            boldLabel
                            flex
                            selectedValue={sectionInputData.questionFive.answer}
                            name={sectionInputData.questionFive.questionName}
                            label={sectionInputData.questionFive.questionText}
                            onChange={event => {
                                const { value, name } = event.target
                                setInputData(prevInputData => ({
                                    ...prevInputData,
                                    [name]: {
                                        ...sectionInputData.questionFive,
                                        answer: value,
                                    }
                                }))
                            }}
                            center
                            options={[
                                { label: '1', value: 1 },
                                { label: '2', value: 2 },
                                { label: '3', value: 3 },
                                { label: '4', value: 4 },
                                { label: '5', value: 5 },
                            ]}
                        />
                    </section>
                }
            </div>
        </PUPanel>
    )
}

export default PlmAssessmentFormSection
