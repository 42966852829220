import React, { Component } from 'react'
import parameters from '../../config/parameters'
import Spinner from './Spinner'
import CertificateIcon from './CertificateIcon'
import Button from './Button'
import html2canvas from 'html2canvas';
import { jsPDF } from "jspdf";

export class CertificateGenerator extends Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            success: true,
            errors: '',
        }

        this.getCertificate = this.getCertificate.bind(this);
    }

    getCertificate() {
        // Show  spinner while png loads and empty previous state errors 
        this.setState({ errors: '', loading: true })

        const baseUrl = parameters.elearningApiURI
        // Point to the certificate router in the api 
        const certificate = '/api/certificate/'
        // Get the product and module number from props
        const productName = this.props.productName
        const moduleNumber = this.props.moduleNumber
        const moduleName = this.props.moduleName

        let finalUrl = ''
        let fileName = ''
        if (moduleNumber) {
            // Combined, final endpoint 
            finalUrl = baseUrl + certificate + productName + '/' + moduleNumber
        } else {
            // Combined, final endpoint 
            finalUrl = baseUrl + certificate + productName
        }

        if (moduleName) {
            fileName = `${productName}_${moduleName}.pdf`
        } else {
            fileName = `${productName}.pdf`
        }


        // Get the user jwt from local storae for authorisation
        const bearer = localStorage.jwtToken;
        // Make the request 
        // AJAX 

        let xhr = new XMLHttpRequest();

        xhr.onreadystatechange = () => {
            // Only run if the request is complete
            if (xhr.readyState !== 4) return;
            // Process returned data
            if (xhr.status >= 200 && xhr.status < 300) {
                // console.log(xhr.response)
                var outerDiv = document.createElement('div');
                outerDiv.style.height = 0
                outerDiv.style.overflow = 'hidden'
                var innerDiv = document.createElement('div');
                innerDiv.innerHTML = xhr.response;

                outerDiv.appendChild(innerDiv)

                document.body.appendChild(outerDiv);

                html2canvas(innerDiv, {
                    allowTaint: true,
                    height: 789,
                    width: 1122,
                    logging: true,
                    useCORS: true,
                    scale: 1
                })
                    .then(canvas => {
                        var imageURL = canvas.toDataURL("image/jpg");
                        // let a = document.createElement("a");
                        // a.href = imageURL;
                        // a.download = fileName;
                        // a.click();

                        // Bring the page zoom level back to 100%
                        // const scale = window.innerWidth / window.outerWidth;
                        // if (scale !== 1) {
                        //     document.body.style.zoom = scale;
                        // }


                        var doc = new jsPDF({
                            orientation: 'l',
                            unit: 'mm',
                            format: 'a4',
                            hotfixes: ["px_scaling"]
                        });
                        doc.addImage(imageURL, 'JPG', 0, 0);
                        doc.save(fileName);

                        outerDiv.remove()
                        this.setState({ loading: false })
                    });
            }
        };
        xhr.open('GET', finalUrl);
        // Headers must be set after open
        xhr.setRequestHeader("Content-Type", "application/json")
        xhr.setRequestHeader("authorization", bearer)
        xhr.send();



    }

    render() {
        const complete = this.props.complete
        // const complete = false
        if (this.props.inline) {
            return (
                <div className="d-flex align-items-center"
                    style={{ height: '27px' }}
                >
                    <button
                        disabled={!complete}
                        className={complete ? 'tc-light-pink' : 'tc-mid-grey'}
                        style={{
                            outline: 'none',
                            border: 'none',
                            background: 'none',
                            display: 'flex',
                            alignItems: 'center'
                        }}
                        onClick={this.getCertificate}

                    >
                        <span style={{ fontSize: '24px', }}>{this.state.loading ? <Spinner /> : <CertificateIcon />}</span>
                        {this.props.title && <small className="ml-2"><strong>{this.props.title}</strong></small>}
                    </button>

                    {/* <small className="ml-1"><strong>Text</strong></small> */}
                    {this.state.errors && <small className="text-danger">{this.state.errors}</small>}
                </div>

            )
        }

        return (
            <div>
                <Button
                    center={this.props.center}
                    onClick={this.getCertificate}
                    loading={this.state.loading}
                >{this.props.title && this.props.title + ' '}
                    <CertificateIcon />
                </Button>
                {this.state.errors && <small className="text-danger">{this.state.errors}</small>}
            </div>
        )

    }
}

export default CertificateGenerator
