import React, { Component } from 'react'
import { connect } from 'react-redux'
import Checkbox from '../common/Checkbox'
import Button from '../common/Button';
import { Link } from 'react-router-dom'
import { changeOrganisationProductMembershipUsers, signIg, setOrganisationProductMembershipActionSuccess } from '../../actions/organisationActions'
import moment from 'moment'
import PUPanel from '../layout/PUPanel'
import IGIcon from '../common/IGIcon'
import NextButton from './NextButton'

class PageIG extends Component {
    constructor() {
        super()
        this.state = {
            igTick: false,
            showReSign: false
        }
        this.onChange = this.onChange.bind(this);
    }

    componentWillUnmount() {
        this.props.setOrganisationProductMembershipActionSuccess(false)
    }

    onChange(e) {
        if (e.target.type === "checkbox") {
            this.setState({ [e.target.name]: e.target.checked })
        } else {
            this.setState({ [e.target.name]: e.target.value })
        }
    }


    render() {
        const { organisationProductMembership, organisation, organisationProductMembershipActionLoading } = this.props.organisations

        return (
            <div>

                <PUPanel title="Information Governance">
                    <p className="lead">
                        As a part of this product you have the opportunity to submit data to track progress, impact and create opportunities for further learning.

                    </p>
                    <p>
                        If you would like to get set up with data extractions, please read the documents listed below and when you are ready check the box to provide your signature.
                    </p>
                    <p>
                        Please note, if you are the lead for more than one organisation you will need to sign this on behalf of each. You can change which organisation you are administering using the menu on the left of the page.
                    </p>
                    <p>
                        If you are a returning customer who has previously signed the IG agreements, please sign them again for your current contract as this will re-start the data extraction process for SystmOne organisations.
                    </p>
                    {organisationProductMembership.product.showResourcesTab && (
                        <p>Once you have signed the agreements, head over to the <Link to={`/product/${organisationProductMembership.product.name}/resources`}>resources</Link> section for next steps on submitting data.</p>
                    )}
                    {organisationProductMembership.product.igDocuments && (
                        <div>
                            {organisationProductMembership.product.igDocuments.map(ig =>
                                <div key={ig.title}>
                                    <a href={ig.documentUrl} target="_blank" rel="noopener noreferrer"><h5><strong><IGIcon /> {ig.title}</strong></h5></a>
                                </div>
                            )}
                            {this.state.showReSign || !organisationProductMembership.igSigned || organisationProductMembershipActionLoading ? (
                                <div>
                                    <Checkbox
                                        onChange={this.onChange}
                                        name="igTick"
                                        value={this.state.igTick}
                                        className="mb-3"
                                    >I agree to the terms outlined in the above document(s) on behalf of {organisation.name}</Checkbox>
                                    <Button
                                        fixedWidth
                                        center
                                        disabled={!this.state.igTick}
                                        loading={organisationProductMembershipActionLoading}
                                        onClick={() => {
                                            this.props.signIg(organisation.code, organisationProductMembership._id)
                                            this.setState({ showReSign: false })
                                        }}
                                    >Sign</Button>
                                </div>
                            ) : (
                                <div>
                                    <h6 className="mb-3">
                                        Agreement signed by <strong>{organisationProductMembership.igSignedBy}</strong><small className="text-muted"> - {organisationProductMembership.igSignedDate && moment(organisationProductMembership.igSignedDate).format('DD-MMMM-YY')}</small>
                                    </h6>
                                    <Button
                                        className="ml-auto"
                                        small
                                        colour="pink"
                                        onClick={() => { this.setState({ showReSign: true }) }}
                                    >re Sign</Button>
                                </div>
                            )}
                        </div>
                    )}
                </PUPanel>
                <NextButton currentPage={'ig'} />
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    organisations: state.organisations
})

export default connect(mapStateToProps, { changeOrganisationProductMembershipUsers, signIg, setOrganisationProductMembershipActionSuccess })(PageIG);