module.exports = {
    teal: '#0C989D',
    darkBlue: '#113B5B',
    blue: '#2372B5',
    green: '#60AE4C',
    darkOrange: '#EB6915' ,
    lightGrey: '#ECECED',
    yellow: '#f4b321',
    darkMint: '#169F7B',
    lightMint: '#16C194',
    turqoise: '#20DACD',
    pink:'#E04F9A',
    darkPink: '#B33F7B'
}