import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classnames from 'classnames'
import PracticeUnboundLoadingYellow from '../../img/PracticeUnboundLoadingYellow.gif'

const Spinner = (props) => {
    const spinner = (
        <span
            className={classnames(
                { 'tc-light-yellow': props.color === "yellow" },
                { 'tc-mid-grey': props.color === "grey" }
            )}
            style={{
                // IE10 bug: spinner stretching. Set max-width and max-height properties
                fontSize: props.large && '54px', maxwWidth: '54px'
            }}>
            <FontAwesomeIcon icon="spinner" spin />
        </span>
    )
    if (props.image) {
        return <img style={{ height: '54px', maxwWidth: '54px' }} alt="loading" src={PracticeUnboundLoadingYellow} />
    }
    if (props.fullScreen) {
        return (
            <div
                style={{ maxwWidth: '54px !important', position: 'fixed', top: 0, left: 0, right: 0, bottom: 0, backgroundColor: 'rgba(255,255,255,0.8)', zIndex: '999999999999999' }}
                className="d-flex flex-column justify-content-center align-items-center">
                <img style={{ height: '54px', maxwWidth: '54px' }} alt="loading" src={PracticeUnboundLoadingYellow} />{/*spinner*/}
            </div>
        )
    }
    if (props.block) {
        return (
            <div
                className="container text-center"
                style={{ paddingTop: '156px', paddingBottom: '156px', maxwWidth: '54px !important' }}
            >
                <img style={{ height: '54px', maxWidth: '54px', maxHeight: '54px !important' }} alt="loading" src={PracticeUnboundLoadingYellow} />{/*spinner*/}
            </div>
        )
    }
    return spinner
}
export default Spinner