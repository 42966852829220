import React, { Component } from 'react'
import { connect } from 'react-redux'
import { optOutAction, setPurchaseOptOutSuccess } from '../../actions/purchaseActions'
import parameters from '../../config/parameters'
import PUPanel from '../layout/PUPanel'
import Spinner from '../common/Spinner'
import Checkbox from '../common/Checkbox'
import { Link } from 'react-router-dom'
import moment from 'moment'
import Button from '../common/Button'
import Modal from '../common/Modal'
import TextFieldGroup from '../common/TextFieldGroup'


class ContractSection extends Component {
    constructor() {
        super()
        this.state = {
            expanded: false
        }
    }

    render() {
        const { c, organisation, showOptOut, allowedProducts } = this.props
        const allowOnlineContracting = allowedProducts.includes(c.productcode)
        return (
            <div className="card p-3">
                <div className="row" >
                    <div className="col-12 text-center">
                        <h4><strong>{c.productname}</strong></h4>
                    </div>
                    <div className="col-12 text-center">
                        <h5>{c.status}</h5>
                    </div>



                    <table className="table table-sm">
                        <tbody>
                            <tr>
                                <td>
                                    Organisation:
                                </td>
                                <td>
                                    <strong>{organisation.name}</strong>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Contracting organisation:
                                </td>
                                <td>
                                    <strong>{c.contractingOrganisationName} - {c.contractingOrganisationType}</strong>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Contract Type:
                                </td>
                                <td>
                                    <strong>{c.contractType}</strong>
                                </td>
                            </tr>


                            <tr>
                                <td>
                                    Contract Start Date:
                                </td>
                                <td>
                                    <strong>{moment(c.contractStartDate).format('DD/MMM/YYYY')}</strong>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Contract End Date:
                                </td>
                                <td>
                                    <strong>{moment(c.contractEndDate).format('DD/MMM/YYYY')}</strong>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Subscription Start Date:
                                </td>
                                <td>
                                    <strong> {c.isSubscription && c.subscriptionStartDate && moment(c.subscriptionStartDate).format('DD/MMM/YYYY')}</strong>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Subscription End Date:
                                </td>
                                <td>
                                    <strong> {c.isSubscription && c.subscriptionEndDate && moment(c.subscriptionEndDate).format('DD/MMM/YYYY')}</strong>
                                </td>
                            </tr>




                            {c.invoiceLastCreated && (
                                <tr>
                                    <td>
                                        Invoice last created:
                                    </td>
                                    <td>
                                        <strong>{moment(c.invoiceLastCreated).format('DD/MMM/YYYY')}</strong>
                                    </td>
                                </tr>
                            )}
                            {c.nextInvoiceDue && (
                                <tr>
                                    <td>
                                        Next invoice due:
                                    </td>
                                    <td>
                                        <strong>{c.nextInvoiceDue && c.nextInvoiceDue !== '-' && moment(c.nextInvoiceDue).format('DD/MMM/YYYY')}</strong>
                                    </td>
                                </tr>
                            )}
                            {c.optOutDate && (
                                <tr>
                                    <td>
                                        Opted out of subscription:
                                    </td>
                                    <td>
                                        <strong> {moment(c.optOutDate).format('DD/MMM/YYYY')}</strong>
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                    {allowOnlineContracting && (
                        <div className="col-12 mb-3">
                            {(c.optOutDate && (c.inContract || c.inSubscription)) ? (
                                <div>
                                    <Button
                                        inline center
                                        link={`/purchase/${c.productcode}?organisation=${organisation.code}`}
                                    >Opt back into Subscription</Button>
                                </div>
                            ) : (!c.isSubscription) && (
                                <div>
                                    <Button
                                        inline center
                                        link={`/purchase/${c.productcode}?organisation=${organisation.code}`}
                                    >Start a Subscription</Button>
                                </div>
                            )}
                        </div>
                    )}


                    <div className="col-12" style={{
                        overflowY: 'hidden',
                        maxHeight: this.state.expanded ? '200px' : '0px',
                        transition: 'max-height 0.6s linear'
                    }}>
                        <div className="mb-3">
                            {c.pdf && (
                                <Button
                                    center
                                    small
                                    icon="download"
                                    onClick={() => {
                                        const blob = this.b64toBlob(c.pdf.documentbody, 'application/pdf');
                                        var objectUrl = URL.createObjectURL(blob);
                                        window.open(objectUrl);
                                    }}>Download Contract</Button>
                            )
                            }
                        </div>
                        <div className="col-sm-12 mb-3">
                            {(c.isSubscription && !c.optOutDate && showOptOut) && <div>
                                <Button
                                    colour="none"
                                    small
                                    center
                                    onClick={this.props.optOutClick}
                                >Cancel subscription</Button>
                            </div>

                            }
                        </div>
                    </div>
                    <div className="col-12">
                        {(
                            (c.isSubscription && !c.optOutDate && showOptOut)
                            || c.pdf
                        ) && <Button small colour="none" inline center onClick={() => {
                            this.setState({ expanded: !this.state.expanded })
                        }}>{this.state.expanded ? "See Less" : "See More"}</Button>
                        }
                    </div>




                </div>
            </div>
        )
    }
}


class PageContracts extends Component {
    constructor() {
        super()
        this.state = {
            optOutOpen: false,
            optOutOpm: {},
            tickedOptions: [],
            otherText: ''
        }
    }
    // TODO - move this into a comp
    componentDidUpdate() {
        if (window.scanDomBillsby) {
            window.scanDomBillsby();
        }

    }

    componentDidMount() {
        this.props.setPurchaseOptOutSuccess(false)
    }

    b64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
        const byteCharacters = atob(b64Data);
        const byteArrays = [];

        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            const slice = byteCharacters.slice(offset, offset + sliceSize);

            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }

            const byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
        }

        const blob = new Blob(byteArrays, { type: contentType });
        return blob;
    }


    render() {
        const { organisationContracts, organisationContractsLoading, organisation } = this.props.organisations
        if (organisationContractsLoading) {
            return <Spinner block />
        }
        const contractsToShow = organisationContracts.filter(o => o.markedAsClean)
        // const productsToBuy = this.props.products.products
        // .filter(p => p.allowOnlineContracting&& p.comingSoon === false && !this.props.organisations.organisationProductMemberships
        //     .filter(opm => opm)
        //     .map(opm => opm.product.name).includes(p.name))
        const optOutErrors = this.props.purchase.errors.optout || {}

        return (
            <PUPanel title="Contracts" >
                {/* Looking for a 'personal' purchase? Those will be listed against your profile rather than against an organisation. Click the profile icon in the top right */}

                {
                    contractsToShow.length === 0 ? (
                        <div>
                            <p className="lead">
                                Nothing here yet!
                            </p>
                            <p><strong>Just made a purchase?</strong> It can take up to 24 hours for new purchases to be activated. Somebody from our team will be in touch to help you get started.</p>
                            <p>
                                Expecting to see a contract that is not here? Not all of our contracts are yet available for online viewing, but we are working on it.
                            </p>
                            <p>
                                If you have any enquiries about your contracts please <Link to="/contact-us">get in touch</Link> with a member of our team.
                            </p>
                        </div>
                    ) : (
                        <>
                            <div className="row justify-content-center">
                                <div className="col-12">
                                    <p>
                                        Expecting to see a contract that is not here? Not all of our contracts are yet available for online viewing, but we are working on it.
                                    </p>
                                    <p><strong>Just made a purchase?</strong> It can take up to 24 hours for new purchases to be activated. Somebody from our team will be in touch to help you get started.</p>
                                    <p>
                                        If you have any enquiries about your contracts please <Link to="/contact-us">get in touch</Link> with a member of our team.
                                    </p>

                                </div>


                                {contractsToShow.filter(c => c.contractType === 'Subscription').map(c => {

                                    let showOptOut = false
                                    if (moment(c.lastAnnivDate).add(9, 'months') > moment()) {
                                        showOptOut = true
                                    }

                                    return (
                                        <div key={c.opmId} className="col-xl-6 mb-3">
                                            <ContractSection
                                                allowedProducts={this.props.products.products.filter(p => p.allowOnlineContracting).map(p => p.name)}
                                                c={c} organisation={organisation} showOptOut={showOptOut}
                                                optOutClick={
                                                    () => {
                                                        this.setState({
                                                            optOutOpen: true,
                                                            optOutOpm: c
                                                        })
                                                    }
                                                }
                                            />
                                        </div>
                                    )
                                }
                                )}


                            </div>


                        </>

                    )
                }





                < Modal
                    isOpen={this.state.optOutOpen}
                    onRequestClose={() => { this.setState({ optOutOpen: false, optOutOpm: {}, tickedOptions: [], otherText: '' }) }}
                    title="We're sorry you're leaving us"
                >
                    <p className="lead">
                        Help us improve by letting us know why
                    </p>

                    {[

                        'Have fully implemented and see no value in subscription',
                        'There isn’t enough content/value to make subscribing worthwhile',
                        'Affordability issues',
                        'Felt customer support was poor',
                        'Online guidance wasn’t clear enough',
                        'Didn’t know how to implement the programme',
                        'Quality of eLearning modules',
                        'Quality of Data Dashboard',
                        'Quality of clinical system tools',
                        'Quality of other resources',
                        'Other (please state below)'

                    ].map(o =>
                        <div>
                            <Checkbox
                                key={o}
                                name={o}
                                value={this.state.tickedOptions.includes(o)}
                                onChange={e => {
                                    if (e.target.checked) {
                                        this.setState({ tickedOptions: this.state.tickedOptions.filter(t => t !== o).concat(o) })
                                    } else {
                                        this.setState({ tickedOptions: this.state.tickedOptions.filter(t => t !== o) })
                                    }
                                }}
                                icon>{o}</Checkbox>
                        </div>
                    )}
                    <TextFieldGroup
                        label="Anything else:"
                        name="otherText"
                        placeholder='Please add any additional information – this helps us to improve the programme for other users'
                        type="textarea"
                        className="mt-3"
                        value={this.state.otherText}
                        onChange={e => {
                            this.setState({ otherText: e.target.value })
                        }} />
                    <p>

                        By clicking below you confirm that you do not wish for the subscription for {this.state.optOutOpm.productname} to renew for the organisation {this.props.organisations.organisation.name}.
                    </p>
                    <p>
                        Your current billing period will give you access until {moment(this.state.optOutOpm.subscriptionWouldEndDate).format('DD-MMM-YYYY')}
                    </p>



                    {optOutErrors.optout && <small className="text-danger">{optOutErrors.optout}</small>}
                    <Button
                        fixedWidth
                        center
                        loading={this.props.purchase.optOutLoading}
                        done={this.props.purchase.optOutSuccess}
                        onClick={() => {
                            this.props.optOutAction(this.state.optOutOpm.opmId, {
                                tickedOptions: this.state.tickedOptions,
                                otherText: this.state.otherText
                            })
                        }}
                    >Cancel subscription</Button>
                    {this.props.purchase.optOutSuccess && (
                        <>
                            <p className="mt-3">
                                We have received your cancellation notice, a member of the team will be in touch in the next 24 hours to help you close your account.

                            </p>
                            <p>
                                If you unsubscribed by mistake, just get in touch with our helpdesk team who will be happy to help you. You can get in touch with the helpdesk team by emailing <a href={`mailto:${parameters.helpdeskEmail}`}>{parameters.helpdeskEmail}</a> or phoning {parameters.contactUsPhone} Mon – Fri 9am – 5pm.
                            </p>

                            <Button inline center small onClick={
                                () => {
                                    this.setState(
                                        {
                                            optOutOpen: false,
                                            optOutOpm: {},
                                            tickedOptions: [],
                                            otherText: ''
                                        }, () => {
                                            //additional timeout prevents button rerendering during modal close
                                            setTimeout(() => {
                                                this.props.setPurchaseOptOutSuccess(false)
                                            }, 100)
                                        })
                                }
                            }>
                                Close
                            </Button>
                        </>
                    )}
                    <p className="mt-4">
                        We are always here to support any queries or issues you may have with the programme. Please contact us on:
                    </p>
                    <ul>
                        <li>
                            <p className="mb-0">E-mail - support@practiceunbound.org.uk</p>
                        </li>
                        <li>
                            <p className="mb-0">Phone - 0300 303 8145</p>
                        </li>
                        <li>
                            <p className="mb-0"><Link to='/contact-us'>Contact form</Link></p>
                        </li>
                    </ul>



                </Modal >
            </PUPanel >
        )
    }

}

const mapStateToProps = (state) => ({
    organisations: state.organisations,
    purchase: state.purchase,
    auth: state.auth,
    products: state.products
})

export default connect(mapStateToProps, { optOutAction, setPurchaseOptOutSuccess })(PageContracts);