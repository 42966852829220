import React, { Component } from 'react'
import Button from '../common/Button'
import CertificateGenerator from '../common/CertificateGenerator'
import Spinner from '../common/Spinner'
import Check from '../common/Check'
import InProgress from '../common/InProgress'
import Lock from '../common/Lock'
// import StarIcon from '../common/StarIcon'
import PlayIcon from '../common/PlayIcon'
import ComingSoonIcon from '../common/ComingSoonIcon'
import processRichText from '../../utils/processRichText'
import FullScreen from '../common/FullScreen'
import Card from '../layout/Card'
import PUPanel from '../layout/PUPanel'

const FloatingLayer = (props) => {
    return (
        <div style={{
            position: 'absolute',
            backgroundColor: 'rgba(255,255,255,0.85)',
            top: '0',
            right: '0',
            left: '0',
            bottom: '0',
        }}>
            <div style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                textAlign: 'center',
                transform: 'translate(-50%, -50%)'
            }} className="text-muted">
                {props.children}
            </div>
        </div>
    )
}

const Progress = props => {
    if (!props.progress) return null
    return (
        <h6 style={{ minWidth: '100px', textAlign: 'center' }}>
            <strong style={{ lineHeight: '38px' }}>
                {props.loading ? <Spinner color="yellow" /> : (
                    <span>
                        {props.progress.toUpperCase()}{" "}
                        {props.progress === "complete" && <Check plain color="green" />}
                        {props.progress === "incomplete" && <InProgress />}
                    </span>
                )}
            </strong>
        </h6>
    )
}

export default class ModuleTile extends Component {
    constructor(props) {
        super(props)
        this.state = {
            active: false
        }
        this.launchAction = this.launchAction.bind(this)

    }

    componentDidUpdate(prevProps) {
        if (!this.props.loading && prevProps.loading) {
            this.setState({ active: false })
        }
    }


    launchAction() {
        this.setState({ active: true })
        this.props.onClick()

    }

    render() {
        let progress = 'not started'
        if (this.props.results) {
            if (this.props.results.complete === 'incomplete') {
                progress = 'in progress'
            }
            if (this.props.results.complete === 'complete') {
                progress = 'complete'
            }
        }



        let buttonLoading = false
        let buttonDisabled = false
        if (this.state.active && this.props.loading) {
            buttonLoading = true
        }
        if (!this.state.active && this.props.loading) {
            buttonDisabled = true
        }
        if (!this.props.purchased && !this.props.freeTrial) {
            buttonDisabled = true
        }



        let floatingLayerContent
        if (this.props.comingSoon) {
            floatingLayerContent = (
                <FloatingLayer>
                    <div className="d-flex flex-row align-items-middle">
                        <ComingSoonIcon /> <strong className="ml-2">COMING SOON</strong>
                    </div>
                </FloatingLayer>)
        }
        else if (!this.props.purchased && !this.props.freeTrial) {
            floatingLayerContent = (
                <FloatingLayer>
                    <div className="text-center"><Lock />
                    </div>
                    <strong>PURCHASE REQUIRED</strong></FloatingLayer>
            )
        }

        const Tags = <ul className="mt-2 mb-0 d-inline-block list-inline">
            {this.props.recommended && <li className="list-inline-item"><span className="badge badge-secondary">Recommended</span></li>}

            {this.props.duration && (
                <li className="list-inline-item"><span className="badge badge-secondary">{this.props.duration}</span></li>
            )}
            {this.props.tags && this.props.tags.map(t =>
                <li key={t} className="list-inline-item"><span className="badge badge-secondary">{t}</span></li>
            )}
        </ul>


        return (

            <FullScreen
                disabled={(!this.props.purchased && !this.props.freeTrial) || this.props.comingSoon}
                frontContent={
                    <div style={{ position: 'relative' }}>
                        <Card
                            title={this.props.name}
                            square
                            smallTitle
                            onClickPretend
                            noMargin
                            className="mb-4"
                            color={progress === "complete" ? "green" : progress === "in progress" ? "yellow" : "grey"}
                        >
                            {Tags}
                        </Card>
                        {floatingLayerContent}
                    </div>
                }
                backContent={
                    <PUPanel title={this.props.name}>
                        <div style={{ position: 'relative' }}>

                            {this.props.description && processRichText(this.props.description.content)}
                            {Tags}
                            <div className="row justify-content-around">
                                <Progress progress={progress} loading={this.props.resultsLoading} />
                                {this.props.showCertificate && this.props.results && this.props.purchased && this.props.results.complete === 'complete' && (
                                    <CertificateGenerator
                                        productName={this.props.results.product}
                                        moduleNumber={this.props.results.moduleNumber}
                                        complete={this.props.results.complete}
                                        moduleName={this.props.name}
                                    />
                                )}
                                {(this.props.purchased || this.props.freeTrial) && !this.props.comingSoon && (
                                    <Button
                                        center
                                        fixedWidth
                                        loading={buttonLoading}
                                        disabled={buttonDisabled}
                                        onClick={this.launchAction}
                                    >
                                        Launch
                    <PlayIcon className="ml-2" />
                                    </Button>
                                )}
                            </div>



                        </div >
                    </PUPanel>
                }
            />
        )
    }
}
