import React from 'react'
import { Link } from "react-router-dom";
import styles from './NavItem.module.css'
import Avatar from '../common/Avatar'

function NavItem(props) {
    if (!props.items) return null

    return props.items.map((p, i, a) =>
        <Link
            key={p.link}
            to={p.link}
            style={{ color: 'black' }}>
            <div
                className={styles.cardHover}
                style={{
                    width: `${props.width}px`,
                    paddingTop: '18px',
                    paddingBottom: '18px',
                    paddingLeft: '8px',
                    paddingRight: '8px',
                    position: 'relative'
                }}
            >{
                    p.isAvatar ? (
                        <div
                            style={{
                                display: 'inline-block',
                                verticalAlign: 'middle',
                            }}
                        >
                            <Avatar size={"60"} image={p.image} />
                        </div>
                    ) : (
                            <div
                                style={{
                                    backgroundImage: `url(${p.image})`,
                                    backgroundPosition: 'center',
                                    backgroundRepeat: 'no-repeat',
                                    backgroundSize: 'contain',
                                    width: '60px',
                                    height: '60px',
                                    display: 'inline-block',
                                    verticalAlign: 'middle',
                                }} />
                        )
                }


                <div
                    style={{
                        height: !p.subtitle ? '60px' : p.subtitle.length > 200 ? '80px' : '60px',
                        width: 'calc(100% - 60px)',
                        display: 'inline-block',
                        verticalAlign: 'middle',
                        paddingLeft: '18px',
                    }}
                >
                    <div style={{
                        display: 'flex',
                        alignItems: 'center',
                        height: '100%'
                    }}>
                        <div>
                            <h6
                                className='raleway'
                                style={{
                                    fontWeight: 'bold',
                                    marginBottom: '0px'
                                }}
                            >
                                {p.title}
                            </h6>
                            <h6 className="text-muted"
                                style={{ marginBottom: '0px' }}
                            ><small>{p.subtitle}</small></h6>
                        </div>
                    </div>

                </div>
                {p.purchased && <div style={{
                    position:
                        'absolute',
                    right: '4px',
                    bottom: '4px'
                }} className="badge badge-success">Purchased</div>
                }
                {p.viewButton && <div style={{
                    position: 'absolute',
                    right: '8px',
                    top: '38px',
                    fontWeight: 'bold',
                    fontSize: '80%',
                    textTransform: 'uppercase',
                    letterSpacing: '1.1px'
                }}>VIEW</div>
                }
            </div>
            {i < a.length - 1 && <hr style={{ marginTop: '2px', marginBottom: '2px' }} />}
        </Link>
    )


}

NavItem.defaultProps = {
    width: 500
}
export default NavItem