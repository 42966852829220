import React, { Component } from 'react'
import { connect } from 'react-redux';
import { getAssessments, submitAssessment } from '../../actions/assessmentActions'
import Plm from './AssessmentPages/PLM/Plm'
import { animateScroll as scroll } from "react-scroll";
import Spinner from '../common/Spinner'

class AssessmentScreen extends Component {
    // state of has initalrender happened?

    // ComponentDidUpdate?
    //  If state.initialrender=false and thisProps.loading=false then initialrender=true///



    componentDidMount() {
        this.props.getAssessments(this.props.products.product.name === 'prescribinglifestylemedicinepractice' ? 'prescribinglifestylemedicine' : this.props.products.product.name)
        scroll.scrollToTop()
    }

    render() {
        const { assessments } = this.props
        // IF initalrender=false then loading
        if (assessments.loading) return <Spinner block />
        if (this.props.products.product.name === 'prescribinglifestylemedicinepractice' || this.props.products.product.name === 'prescribinglifestylemedicine') {
            return <Plm
                submitAssessment={this.props.submitAssessment}
                assessments={this.props.assessments.assessments} />
            // return (
            //     <div>
            //         PLM
            //     </div>
            // )
        }
        return (
            <div>
                Product not set up
            </div>
        )
    }
}
const mapStateToProps = state => ({
    assessments: state.assessments,
    products: state.products,
})
export default connect(mapStateToProps, { getAssessments, submitAssessment })(AssessmentScreen);