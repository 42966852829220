import React from 'react'
import "react-table/react-table.css";
import ReactTable, { ReactTableDefaults } from 'react-table';
import withFixedColumns from 'react-table-hoc-fixed-columns';
import 'react-table-hoc-fixed-columns/lib/styles.css' // important: this line must be placed after react-table css import

const ReactTableFixedColumns = withFixedColumns(ReactTable)

const Table = props => {

    return (

        <ReactTableFixedColumns
            className="-striped -highlight"
            showPagination={false}
            minRows={1}
            column={{
                ...ReactTableDefaults.column,
                style: {
                    textAlign: 'center',
                    fontSize: '14px'
                },
                headerStyle: { fontSize: '14px' }
            }}
            {...props}
        />

    )
}

export default Table