import React, { Component } from 'react'
import Modal from '../common/Modal'
import { connect } from 'react-redux';
import { updateAvatar, clearAuthErrors, setAuthActionSuccess } from '../../actions/authActions'
import Upload from '../common/Upload'
import Button from '../common/Button'
import Avatar from '../common/Avatar'



class AvatarModal extends Component {
    constructor(props) {
        super(props)
        this.state = {
            showModal: false,
            avatar: null
        }
    }


    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.auth.actionSuccess) {
            setTimeout(() => {
                this.setState({
                    showModal: false
                }, () => {
                    //additional timeout prevents button rerendering during modal close
                    setTimeout(() => {
                        this.props.setAuthActionSuccess(false)
                    }, 100)
                })
            }, 1000)
        }
    }

    render() {
        const { auth } = this.props
        const { errors } = auth
        return (
            <div>
                <Avatar size="large" image={auth.avatar} onClick={
                    () => {
                        this.setState({ showModal: true })
                        this.props.setAuthActionSuccess(false)
                    }} floatMessage="edit" />

                <Modal
                    size="small"
                    isOpen={this.state.showModal}
                    onRequestClose={() => {
                        this.props.clearAuthErrors()
                        this.setState({ showModal: false })
                    }}
                >
                    <Upload
                        isImage
                        onChange={e => this.setState({ avatar: e })}
                        value={this.state.avatar}
                    />
                    <Button
                        fixedWidth
                        center
                        loading={auth.actionLoading}
                        done={auth.actionSuccess}
                        onClick={() => this.props.updateAvatar(this.state.avatar)}
                    >upload
    </Button>
                    <small className="text-danger">{errors.error}</small>
                </Modal >
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    auth: state.auth,
})

export default connect(mapStateToProps, { updateAvatar, clearAuthErrors, setAuthActionSuccess })(AvatarModal);