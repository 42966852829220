import React, { Component } from 'react'
import { enviroment } from '../../config/parameters'

export default class EnviromentBanner extends Component {
    constructor() {
        super()
        this.state = {
            show: false
        }
    }

    componentDidMount() {
        if (sessionStorage.HideEnviromentPrompt || enviroment === "prod") {
            this.setState({ show: false })
        }
        else if (enviroment === "beta") { this.setState({ show: true }) }
        else if (enviroment === "authoring") { this.setState({ show: true }) }
        else if (enviroment === "dev") { this.setState({ show: true }) }
        else { return null }
    }

    closeAlert = () => {
        sessionStorage.setItem('HideEnviromentPrompt', true)
        this.setState({ show: false })
    }

    render() {
        if (this.state.show) {
            return (
                < div style={{ zIndex: 10, position: 'fixed', top: '90px', right: '30px' }}
                    className="alert alert-info alert-dismissible fade show" role="alert" >
                    <button onClick={() => this.closeAlert()} type="button" className="close" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                    You are in the < strong > {enviroment}</strong > enviroment
                </div >
            )
        }
        else { return null }
    }
}
