import { useState } from 'react'
import { Link } from 'react-router-dom'
import PUPanel from '../../PUPanel'
import ProgressBar from '../../../common/ProgressBar'
import CheckIcon from '../../../common/CheckIcon'
import InfoIcon from '../../../common/InfoIcon'
import Modal from '../../../common/Modal'
import crossfieldsLogo from '../../../../img/crossfields-approved-centre-logo-light-BG.png'

const Overview = ({ progress, setProgress }) => {

    const [infoOpen, setInfoOpen] = useState(false)

    function openInfo() {
        setInfoOpen(true)
    }

    const { elearning_status, community_status, interactions_status, assessment_status, casestudy_status } = progress

    const status = {
        elearning: elearning_status === "complete" ? "yellow" : elearning_status === "validated" ? "green" : "unchecked",
        community: community_status === "complete" ? "yellow" : community_status === "validated" ? "green" : "unchecked",
        interactions: interactions_status === "complete" ? "yellow" : interactions_status === "validated" ? "green" : "unchecked",
        assessment: assessment_status === "complete" ? "yellow" : assessment_status === "validated" ? "green" : "unchecked",
        casestudy: casestudy_status === "complete" ? "yellow" : casestudy_status === "validated" ? "green" : "unchecked"
    }

    return (
        <div className='pt-4 pl-4'>
            <PUPanel
                title={`Overview`}
                color='pink'
            >
                <div>
                    <div className="mb-4 mt-3">
                        <img alt="Crossfields Logo" src={crossfieldsLogo} className="float-right img-fluid p-2" style={{ maxWidth: '130px' }} />
                        <h4 className="font-weight-bold mb-4">Certificate in Lifestyle Medicine (CertLM)
                            <br />Endorsed by CFI</h4>
                        <p className="mt-3">In this section you will find step by step guidance on achieving a Certificate in Lifestyle Medicine (CertLM) which is an accredited Crossfield's Institute quality mark programme.</p>
                        <p>The process of certification concentrates on the methodology and practical implementation of a lifestyle medicine approach. You will achieve certification through assessment and demonstration of your application of a lifestyle medicine approach.</p>
                        <p>We have separated certification criteria into five practical steps. Whilst we have laid out the criteria for certification in this way, you should ensure you understand each step before completing them in an order suitable to you.</p>
                    </div>
                    <div className="d-flex mt-5 position-relative">
                        <h4 className="font-weight-bold mb-4 mr-3 text-capitalize">current progress</h4>
                        <InfoIcon noShadow onClick={openInfo} />
                    </div>

                    <section className="px-3 pb-4" style={{ maxWidth: '500px' }}>

                        <div className="row">
                            <Link className="res-tab-tab col-9" to={`/product/prescribinglifestylemedicine/accreditation/elearning`}>
                                1. complete e-learning modules
                            </Link>
                            <div className="col-3 my-2 my-sm-0 d-flex justify-content-center position-relative">

                                <CheckIcon status={status.elearning} />
                            </div>
                        </div>
                        <div className="row">
                            <Link className="res-tab-tab col-9" to={`/product/prescribinglifestylemedicine/accreditation/community`}>
                                2. join the plm community
                            </Link>
                            <div className="col-3 my-2 my-sm-0 d-flex justify-content-center">
                                <CheckIcon status={status.community} />
                            </div>
                        </div>
                        <div className="row">
                            <Link className="res-tab-tab col-9" to={`/product/prescribinglifestylemedicine/accreditation/interactions`}>
                                3. log 85 hours of plm interactions
                            </Link>
                            <div className="col-3 my-2 my-sm-0 d-flex justify-content-center">
                                <CheckIcon status={status.interactions} />
                            </div>
                        </div>
                        <div className="row">
                            <Link className="res-tab-tab col-9" to={`/product/prescribinglifestylemedicine/accreditation/assessment`}>
                                4. complete assessment
                            </Link>
                            <div className="col-3 my-2 my-sm-0 d-flex justify-content-center">
                                <CheckIcon status={status.assessment} />
                            </div>
                        </div>
                        <div className="row">
                            <Link className="res-tab-tab col-9" to={`/product/prescribinglifestylemedicine/accreditation/casestudy`}>
                                5. submit case study
                            </Link>
                            <div className="col-3 my-2 my-sm-0 d-flex justify-content-center">
                                <CheckIcon status={status.casestudy} />
                            </div>
                        </div>
                        <div className="py-2" />
                    </section>
                    <ProgressBar
                        reverseOrder
                        removeBar
                        keyLabels={{
                            grey: "incomplete",
                            yellow: "complete",
                            green: "validated"
                        }}
                    // complete={}
                    // inProgress={}
                    // total={}
                    // showCertificate={}
                    // productName={'PLM-Cert'}
                    />

                    <div className='mt-4'>
                        <p>If you require any further guidance or support please contact our Helpdesk:</p>
                        <ul className='font-weight-bold'>
                            <li>0300 303 8145</li>
                            <li>support@practiceunbound.org.uk</li>
                        </ul>
                    </div>

                </div>
            </PUPanel>
            <Modal
                isOpen={infoOpen}
                onRequestClose={() => setInfoOpen(prev => !prev)}
                title={"Progress"}
            // color={b.fields.colour}
            >
                <p>As you complete each step of the lifestyle medicine certificate you will be assessed on whether you have met the required criteria and receive indication when this has been successful. This means your progress will be indicated in one of three ways:</p>
                <ul>
                    <li><strong className=''>Incomplete</strong> - you have not completed the step.</li>
                    <li><strong className='tc-dark-yellow'>Complete</strong> - where you have completed the required actions of the step (e.g completed and submitting your documentation) and we are currently assessing.<br /> If there is any issue in assessment, or if we have further questions, we will contact you directly.</li>
                    <li><strong className='tc-dark-mint'>Validated</strong> - where we have completed assessment of your documentation and can confirm the requirements have been met for certification. You will need each step to be in a validated state before obtaining your certificate.</li>
                </ul>
                <p>Some steps may take longer to review and assess than others, depending on complexity. Review and validation of submitted documentation can take up to 28 days. Once you have a validated status for all five of the certification steps, we will contact you to provide your certificate.</p>
            </Modal>
        </div>
    )
}



export default Overview