import isEmpty from '../utils/is-empty'

import {
    SET_AUTH_CURRENT_USER,
    SET_AUTH_FORGOTEMAIL_SENT,
    SET_AUTH_LOADING,
    SET_PASSWORD_EXPIRED,
    SET_EMAIL_VERIFIED,
    SET_REQUEST_VERIFIED_SENT,
    GET_AUTH_ERRORS,
    CLEAR_AUTH_ERRORS,
    SET_AUTH_ACTION_LOADING,
    SET_AUTH_ACTION_SUCCESS,
    SET_PERSONAL_CONTRACTS_LOADING,
    GET_PERSONAL_CONTRACTS
} from '../actions/types'

const initialState = {
    isAuthenticated: false,
    passwordExpired: false,
    isAdmin: false,
    isSuperAdmin: false,
    user: {},
    avatar: null,
    products: [],
    contracts: [],
    contractsLoading: false,
    forgotEmailSent: false,
    verifiedEmailSent: false,
    emailVerified: false,
    loading: false,
    actionLoading: false,
    actionSuccess: false,
    errors: {}
}

export default function authReducer(state = initialState, action) {
    switch (action.type) {
        case SET_AUTH_LOADING:
            return {
                ...state,
                loading: action.payload
            }
        case SET_AUTH_CURRENT_USER:

            if (!window.billsbyData) window.billsbyData = {}
            window.billsbyData.firstName = action.payload.firstName
            window.billsbyData.lastName = action.payload.lastName
            window.billsbyData.email = action.payload.email
            if (window.scanDomBillsby) {
                window.scanDomBillsby();
            }


            return {
                ...state,
                isAuthenticated: !isEmpty(action.payload),
                passwordExpired: action.payload.passwordexpired,
                isAdmin: (action.payload.role === "admin" || action.payload.role === "superadmin"),
                isSuperAdmin: (action.payload.role === "superadmin"),
                user: action.payload,
                avatar: action.payload.avatar || null,
                emailVerified: !state.emailVerified && action.payload.emailVerified,
                products: action.payload.products || [],
                ownedOrganisations: action.payload.ownedOrganisations || [],
                loading: false
            }
        case SET_AUTH_FORGOTEMAIL_SENT:
            return {
                ...state,
                forgotEmailSent: action.payload,
                loading: false
            }
        case SET_PASSWORD_EXPIRED:
            return {
                ...state,
                passwordExpired: true
            }
        case SET_EMAIL_VERIFIED:
            return {
                ...state,
                emailVerified: action.payload,
                loading: false
            }
        case SET_REQUEST_VERIFIED_SENT:
            return {
                ...state,
                verifiedEmailSent: true,
                loading: false
            }
        case GET_AUTH_ERRORS:
            return {
                ...state,
                errors: action.payload
            }
        case CLEAR_AUTH_ERRORS:
            return {
                ...state,
                errors: {}
            };
        case SET_AUTH_ACTION_LOADING:
            return {
                ...state,
                actionLoading: action.payload
            }
        case SET_AUTH_ACTION_SUCCESS:
            return {
                ...state,
                actionSuccess: action.payload,
                actionLoading: false
            }

        case GET_PERSONAL_CONTRACTS:
            return {
                ...state,
                contractsLoading: false,
                contracts: action.payload
            }

        case SET_PERSONAL_CONTRACTS_LOADING:
            return {
                ...state,
                contractsLoading: action.payload
            }

        default:
            return state;
    }
}