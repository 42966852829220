import React from 'react'
import MinusIcon from '../../common/MinusIcon'
import Plus from '../../common/Plus'
import Avatar from '../../common/Avatar'
import Button from '../../common/Button'

export default function UserRow(props) {
    const { user } = props
    return (


        <div className='mb-2' style={{
            maxWidth: '460px',
            boxShadow: '3px 3px #D3D3D3',
            border: '1px solid #C0C0C0',
            borderRadius: '3px'
        }} >
            <div className="row py-3 mx-0" >
                <div className="col-12 col-sm-2 d-flex justify-content-center align-items-center">
                    <Avatar size="small" image={user.avatar} />
                </div>
                <div className="col-12 col-sm-5 mt-2 mt-sm-0 text-sm-left text-center justify-content-center d-flex flex-column">
                    <h6 className="mb-0">{`${user.firstName || ''} ${user.lastName || ''}`}</h6>
                    <small className="text-muted">{user.email}</small>
                </div>
                <div className="col-12 col-sm-5 d-flex justify-content-center  align-items-center d-flex flex-wrap">
                    {props.minus && (
                        <Button colour="pink" small className='mt-2 mt-sm-0'
                            onClick={() => { props.minusAction(user.id) }}
                        >Remove <MinusIcon /></Button>
                    )}
                    {props.demote && (
                        <Button colour="pink" small className='mt-2 mt-sm-0'
                            onClick={() => { props.demoteAction(user.id) }}
                        >Demote <MinusIcon /></Button>
                    )}
                    {props.promote && (
                        <Button colour="green" small className='mt-2'
                            onClick={() => { props.promoteAction(user.id) }}
                        >Make Lead <Plus /></Button>
                    )}
                </div>
            </div>
        </div >

    )
}

