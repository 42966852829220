import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faAward } from '@fortawesome/free-solid-svg-icons'
library.add(faAward)

const CertificateIcon = (props) => {
    return (
        <FontAwesomeIcon className={props.className} icon="award" />
    )
}
export default CertificateIcon