import React, { Component } from 'react'
import { connect } from 'react-redux'
import PUPanel from '../layout/PUPanel'
import Table from '../common/Table'
import Check from '../common/Check'
import InProgress from '../common/InProgress'

class PageResults extends Component {


    render() {
        const { organisationProductMembership } = this.props.organisations


        let modules = []
        if (organisationProductMembership.product) {
            if (organisationProductMembership.product.modules) {
                modules = organisationProductMembership.product.modules
            }
        }

        const { users } = organisationProductMembership

        let elearningUsers = users.map(u => {
            return {
                ...u,
                id: String(u.id),
                displayName: (u.firstName && u.lastName) ? `${u.firstName} ${u.lastName}` : u.email
            }
        })
        organisationProductMembership.elearningResults.forEach(r => {
            if (r.user) {
                let i = elearningUsers.map(u => u.id).indexOf(r.user)
                if (i === -1) {

                    //just take current assigned users - ignore rest

                    // elearningUsers.push({
                    //     ...r.user,
                    //     ['module' + r.moduleNumber]: {
                    //         complete: r.complete,
                    //         // success: r.success,
                    //         // totaltime: r.totaltime,
                    //         // score: r.score,
                    //         // firstAccessDate: r.firstAccessDate,
                    //         // lastAccessDate: r.lastAccessDate,
                    //         completedDate: r.completedDate,
                    //         // created: r.created
                    //     }
                    // })
                } else {
                    elearningUsers[i] = {
                        ...elearningUsers[i],
                        ['module' + r.moduleNumber]: {
                            complete: r.complete,
                            // success: r.success,
                            // totaltime: r.totaltime,
                            // score: r.score,
                            // firstAccessDate: r.firstAccessDate,
                            // lastAccessDate: r.lastAccessDate,
                            completedDate: r.completedDate,
                            // created: r.created
                        }
                    }
                }
            }

        })



        return (
            <PUPanel title="E-learning Progress">
                <p className="lead">
                    Here is a summary of the progress members of your organisation have made.
                </p>
                <div className="row justify-content-center">
                    <div className="mx-3"><Check plain color="green" /> Complete</div>
                    <div className="mx-3 mb-2"><InProgress color="grey" /> In Progress</div>
                </div>



                <Table
                    columns={[
                        {
                            Header: 'User',
                            headerStyle: { textAlign: 'center' },
                            style: { textAlign: 'left' },
                            id: 'user',
                            accessor: 'displayName',
                            minWidth: 160,
                            fixed: 'left',
                        },
                        ...modules.map(m => {
                            return {
                                Header: m.name,
                                id: 'module' + m.number,
                                sortable: false,
                                accessor: row => {

                                    return <div>
                                        {row['module' + m.number] && (row['module' + m.number].complete === 'complete' ? <Check plain color="green" /> : row['module' + m.number].complete === 'incomplete' && <InProgress color="grey" />)}
                                    </div>
                                }
                            }
                        })
                    ]
                    }
                    defaultSorted={[
                        {
                            id: "user"
                        }
                    ]}
                    data={elearningUsers}
                />

            </PUPanel>
        )
    }
}

const mapStateToProps = (state) => ({
    organisations: state.organisations,
})

export default connect(mapStateToProps, {})(PageResults);