import SectionPage from './SectionPage'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'


const Elearning = ({ progress, updateProgress, updating, setUpdating, postErrorRes, errorRes }) => {
  return (
    <SectionPage 
      progress={progress}
      updateProgress={updateProgress}
      updating={updating} 
      setUpdating={setUpdating}
      postErrorRes={postErrorRes}
      errorRes={errorRes}
      tickboxText="I have completed the e-learning modules"
      sectionName="elearning"    
      title="Complete e-Learning modules"
    >
      <div className="mb-4 mt-3">
        <h5 className="font-weight-bold">Introduction</h5>
        <p>Before you begin applying a lifestyle medicine approach, it is important to complete the e-Learning modules of the core Prescribing Lifestyle Medicine course.</p>
        <p>The e-Learning modules will teach you how to implement a lifestyle medicine approach in a practical way.</p>
        <h5 className="font-weight-bold">What do I need to do?</h5>
        <p>Select the <Link to='/product/prescribinglifestylemedicinepractice/course'>Course</Link> tab from the top of the page. There are four e-Learning modules that you will need to complete. You can pause and return to any of the modules, at any time.</p>
        <p>You will need to answer all the questions set out in each module, achieving a minimum of 80% in each of the four end-of-module self-assessments. You will also be required to complete a reflective journal, which follows each of the modules.</p>
        <p>Upon completion of all modules please indicate using the tick box, once this has been verified the box will turn green.</p>
      </div>
    </SectionPage>
  )
}

export default Elearning