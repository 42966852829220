import React, { Component } from 'react'
import { connect } from 'react-redux'
import { removeOrganisationMembership, setOrganisationActionSuccess, promoteUser, demoteUser, clearOrganisationErrors } from '../../actions/organisationActions'
import Button from '../common/Button';
import Modal from '../common/Modal'
// import AddUsers from './users/AddUsers'
import UserList from './users/UserList'
import PUPanel from '../layout/PUPanel'

class PageUsers extends Component {
    constructor() {
        super()
        this.state = {
            showRemoveUser: false,
            showPromoteUser: false,
            showDemoteUser: false,
            selectedUser: ''
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.organisations.organisationActionSuccess) {
            setTimeout(() => {
                this.setState({
                    showRemoveUser: false,
                    showPromoteUser: false,
                    showDemoteUser: false,
                    selectedUser: ''
                }, () => {
                    //additional timeout prevents button rerendering during modal close
                    setTimeout(() => {
                        this.props.setOrganisationActionSuccess(false)
                    }, 100)
                })
            }, 1000)
        }
    }

    render() {
        const { organisation, organisationActionSuccess, organisationActionLoading, errors } = this.props.organisations
        if (!organisation) return null
        return (
            <PUPanel title="Permissions">
                <p className="lead mb-4">Use this section to remove users and change permissions for this organisation.</p>
                <p>If you want to add new users please use the 'Add Users' section under each product in the navigation menu.</p>
                <div>
                    <UserList
                        title="Organisation Leads"
                        className='mb-4'
                        users={(organisation.owners || [])
                            .sort((a, b,) => {
                                if (a.lastName > b.lastName) {
                                    return 1;
                                }
                                if (b.lastName > a.lastName) {
                                    return -1;
                                }
                                return 0;
                            })
                            .map(o => {
                                const demote = String(o.id) !== this.props.auth.user.id
                                return { ...o, demote, minus: false }
                            })}
                        demoteAction={(userId) => { this.setState({ showDemoteUser: true, selectedUser: userId }) }}
                    />
                    <hr />
                    <UserList
                        title="Organisation Members"
                        promoteAction={(userId) => { this.setState({ showPromoteUser: true, selectedUser: userId }) }}
                        minusAction={(userId) => { this.setState({ showRemoveUser: true, selectedUser: userId }) }}
                        users={(organisation.users || [])
                            .filter(u => !organisation.owners.map(o => o.id).includes(u.id))
                            .sort((a, b,) => {
                                if (a.lastName > b.lastName) {
                                    return 1;
                                }
                                if (b.lastName > a.lastName) {
                                    return -1;
                                }
                                return 0;
                            })
                            .map(u => {
                                const promote = !organisation.owners.map(o => o.id).includes(u.id)
                                return { ...u, promote, minus: promote ? true : false }
                            })} />
                    {/* <AddUsers /> */}



                    <Modal
                        isOpen={this.state.showDemoteUser}
                        onRequestClose={() => {
                            this.setState({ showDemoteUser: false })
                            this.props.clearOrganisationErrors()
                        }}
                        size="small"
                    >
                        <div className="mb-3">I confirm I wish to remove this user a lead member of this organisation.</div>
                        <Button
                            center
                            fixedWidth
                            onClick={() => {
                                this.props.demoteUser({ user: this.state.selectedUser }, organisation.code)
                            }}
                            loading={organisationActionLoading}
                            done={organisationActionSuccess}
                        >Confirm</Button>
                        {errors.error && <small className="text-danger">{errors.error}</small>}
                    </Modal>


                    <Modal
                        isOpen={this.state.showRemoveUser}
                        onRequestClose={() => {
                            this.setState({ showRemoveUser: false })
                            this.props.clearOrganisationErrors()
                        }}
                        size="small"
                    >
                        <div className="mb-3">
                            I confirm I wish to remove this user from my organisation. This will not delete their account, but will prevent them from accessing services purchased by this organisation</div>
                        <Button
                            center
                            fixedWidth
                            onClick={() => {
                                this.props.removeOrganisationMembership(this.state.selectedUser, organisation.code)
                            }}
                            loading={organisationActionLoading}
                            done={organisationActionSuccess}
                        >confirm</Button>
                        {errors.error && <small className="text-danger">{errors.error}</small>}
                    </Modal>

                    <Modal
                        isOpen={this.state.showPromoteUser}
                        onRequestClose={() => {
                            this.setState({ showPromoteUser: false })
                            this.props.clearOrganisationErrors()
                        }}
                        size="small"
                    >
                        <div className="mb-3">
                            I confirm I wish to make this user a lead member of this organisation.
                        </div>
                        <Button
                            center
                            fixedWidth
                            onClick={() => {
                                this.props.promoteUser({ user: this.state.selectedUser }, organisation.code)
                            }}
                            loading={organisationActionLoading}
                            done={organisationActionSuccess}
                        >Confirm</Button>
                        {errors.error && <small className="text-danger">{errors.error}</small>}
                    </Modal>
                </div>
            </PUPanel>
        )
    }
}

const mapStateToProps = (state) => ({
    organisations: state.organisations,
    auth: state.auth
})

export default connect(mapStateToProps, { removeOrganisationMembership, setOrganisationActionSuccess, promoteUser, demoteUser, clearOrganisationErrors })(PageUsers);