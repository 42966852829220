import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import PracticeUnboundYellow from "../../img/PracticeUnboundYellow.png";
import PracticeUnboundYellowWhite from "../../img/PracticeUnboundYellowWhite.png";
import { connect } from "react-redux";
import Button from "../common/Button";
import Drawer from "./Drawer";

class Navbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showDraw: false
    };
    this.openDraw = this.openDraw.bind(this);
    this.closeDraw = this.closeDraw.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.location.pathname !== this.props.location.pathname) {
      this.closeDraw();
    }
  }

  openDraw() {
    this.setState({
      showDraw: true
    });
  }
  closeDraw() {
    this.setState({
      showDraw: false
    });
  }

  render() {
    const { isAuthenticated, ownedOrganisations } = this.props.auth;
    const { products } = this.props.products;


    const purchasedProducts = products.filter(
      p => p.purchased && p.comingSoon !== true
    );

    const authLinksSmall = (
      <div>
        <Button small center colour={this.props.onHomepage ? "white" : "none"} link="/account">
          Account
        </Button>
        {this.props.auth.contracts.length > 0 && (
          <Button small center colour={this.props.onHomepage ? "white" : "none"} link="/account/contracts">
            Contracts
          </Button>

        )}
        {ownedOrganisations && ownedOrganisations.length > 0 && (
          <Button small center colour={this.props.onHomepage ? "white" : "none"} link="/admin">
            Admin
          </Button>
        )}
        <Button small center colour={this.props.onHomepage ? "white" : "none"} link="/logout">
          Log out
        </Button>

        <hr />
      </div>
    );

    const anonLinksSmall = (
      <div>
        <Button small center colour={this.props.onHomepage ? "white" : "none"} link="/login">
          Login
        </Button>
        {/* <Button center colour='none' link='/sign-up'>Sign up</Button> */}
        <hr />
      </div>
    );
    const productLinksSmall = (
      <div>
        {purchasedProducts.length > 0 && (
          <div className="text-center">
            <small style={{ color: `${this.props.onHomepage && "#cbcbcb"}` }} className={`${!this.props.onHomepage && "text-muted"}`}><strong>My Products</strong></small>
            {purchasedProducts.map(p => {
              return (
                <Button
                  key={p.name}
                  center
                  small
                  colour={this.props.onHomepage ? "white" : "none"}
                  link={p.purchased ? p.productLink : `/product/${p.name}`}
                >
                  {p.title}
                </Button>
              );
            })}
          </div>
        )}
        <hr />
      </div>
    );

    const generalLinksSmall = (
      <div>
        <Button small center colour={this.props.onHomepage ? "white" : "none"} link="https://www.practiceunbound.org.uk">
          Home
        </Button>
        <Button small center colour={this.props.onHomepage ? "white" : "none"} link="/contact-us">
          Contact
        </Button>
        <hr />
      </div>
    );



    return (
      <div className="sticky-top">
        {this.state.showDraw &&
          <div
            className="backdrop"
            onClick={this.closeDraw}
            style={{
              position: 'fixed',
              width: '100%',
              height: '100%',
              top: '0',
              left: '0',
              background: 'rgba(180,180,180,0.5)',
              zIndex: '300',
            }}
          />}
        <nav className="navbar navbar-expand navbar-light sticky-top" style={{ backgroundColor: this.props.onHomepage ? '#193958' : 'white' }}>
          <ul className="navbar-nav mr-auto">
            <li className="nav-item  d-flex align-items-center">
              <a href="https://www.practiceunbound.org.uk">
                <img
                  alt="PracticeUnbound"
                  className="ml-2"
                  style={{
                    // IE10 bug: prevent navBar logo from stretching
                    maxWidth: '110px',
                    height: "38px",
                    marginBottom: "0px",
                    marginTop: "0px"
                  }}
                  src={this.props.onHomepage ? PracticeUnboundYellowWhite : PracticeUnboundYellow}
                />
              </a>
            </li>
          </ul>
          <ul className="navbar-nav ml-auto">
            {ownedOrganisations && ownedOrganisations.length > 0 && (
              <li className="nav-item">
                <Button
                  colour={this.props.onHomepage ? "white" : "none"}
                  noPadding
                  link={"/admin"}
                  icon="settings"
                  className="mr-4"
                >
                </Button>
              </li>
            )}
            <li className="nav-item">
              <Button
                role="presentation"
                icon="kebab"
                colour={this.props.onHomepage ? "white" : "none"}
                noPadding
                onClick={() => {
                  if (this.state.showDraw) {
                    this.closeDraw();
                  } else {
                    this.openDraw();
                  }
                }}
              />
            </li>
          </ul>
          <Drawer show={this.state.showDraw} modalClose={this.closeDraw} onHomepage={this.props.onHomepage}>
            {generalLinksSmall}
            {isAuthenticated === false && anonLinksSmall}
            {productLinksSmall}
            {isAuthenticated === true && authLinksSmall}
          </Drawer>
        </nav>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
  products: state.products
});

export default withRouter(
  connect(
    mapStateToProps,
    {}
  )(Navbar)
);
