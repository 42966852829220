import React, { Component } from 'react'

export default class Grid extends Component {
    constructor(props) {
        super(props);
        this.state = {
            width: 0,
            height: 0,
        };
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    }
    componentDidMount() {
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    updateWindowDimensions() {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
    }

    render() {
        let numberColumns = 1
        if (this.state.width >= 992) { numberColumns = 3 }
        else if (this.state.width >= 576) { numberColumns = 2 }

        const numberComponents = this.props.children ? this.props.children.length : 0
        const componentsPerColumn = Math.ceil(numberComponents / numberColumns)

        let columns = []
        let i = 0
        for (i; i < numberColumns; i++) {
            let components = []
            let row = 0
            for (row; row < componentsPerColumn; row++) {
                const childIndex = (row * numberColumns) + i
                components.push(this.props.children[childIndex])
            }

            columns.push(<div key={i} className="col-12 col-sm-6 col-md-6 col-lg-4 px-3">
                {components}
            </div>)
        }
        return (
            <div className="row">
                {columns}
            </div>
        )
    }
}
