import React from 'react'
import LoginSplash from '../layout/LoginSplash'
import plaster from '../../img/404 plaster.png'
import { Link } from 'react-router-dom'

const Error404Screen = () => {
    return (
        <LoginSplash>
            <div className="row">
                <div className="col-12 mb-4">
                    <h2><strong>404 not found</strong></h2>
                </div>
                <div className="col-6 mb-4">
                    <img alt="404 error" src={plaster} className="img-fluid" />
                </div>
                <div className="col-6 mb-4">
                    <h4><strong>That page is not available</strong></h4>

                </div>
                <div className="col-12 mb-4">
                    <p>Were you looking for one of our <Link to="/">products</Link>, or wanted to read the latest news and views from our <Link to="/blog">blog?</Link></p>
                    <p>You can <Link to="/contact-us">contact us</Link> if you need help</p>
                </div>
            </div>
        </LoginSplash>
    )
}
export default Error404Screen