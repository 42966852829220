import React, { Component } from 'react'
import Button from '../common/Button'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import Spinner from '../common/Spinner'
import PUPanel from './PUPanel';
import { Link } from 'react-router-dom'

export default class FormSection extends Component {
    constructor() {
        super()
        this.state = {

        }

        this.footerElement = React.createRef();
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (!nextProps.editActive && !nextProps.valid && !nextProps.loading) {
            if (this.props.editToggle) {

                this.props.editToggle()
                if (this.props.onUncomplete) {
                    this.props.onUncomplete()
                }
            }
        }
    }

    onComplete = () => {
        const timeOut = this.props.isComplete ? 0 : 1000
        setTimeout(() => {
            if (this.footerElement.current) {
                this.footerElement.current.scrollIntoView({ behavior: "smooth" })
            }
        }, timeOut);

        this.props.onComplete()
        if (!this.props.changesMade && this.props.valid && this.props.editActive) {
            this.props.editToggle()
        }
    }

    render() {
        const { locked,
            editActive,
            changesMade,
            onSubmit,
            editToggle,
            valid,
            loading,
            title,
            error,
            color
        } = this.props
        return (
            <PUPanel title={title} locked={locked} color={color}>
                <div style={{
                    position: 'relative'
                }}>
                    <TransitionGroup appear={false}>

                        {!locked && (
                            <CSSTransition
                                classNames="fade-delay"
                                timeout={{ enter: 1600, exit: 0 }}
                            >
                                {error ? (
                                    <div className="row">
                                        <div className="col-12 text-center">
                                            <h4><strong>Sorry, it looks like there is a problem.</strong></h4>
                                            <p>We will still be able to assist you with this - you just need to <Link to='/contact-us'>contact us directly</Link></p>
                                            <small className=" mb-3">Details: {error}</small>
                                        </div>
                                    </div>
                                ) : (
                                        <div className="row">
                                            <div
                                                className="col-md-9 col-lg-10 mb-2">
                                                {this.props.children}
                                            </div>
                                            <div className="col-md-3 col-lg-2">
                                                <div style={{ display: 'flex', height: '100%', justifyContent: 'flex-end', flexDirection: 'column' }}>
                                                    {editToggle && (
                                                        <Button
                                                            className="mb-3"
                                                            center
                                                            small
                                                            colour="yellow"
                                                            icon="edit"
                                                            disabled={editActive}
                                                            onClick={() => {
                                                                if (!editActive && this.props.onUncomplete) {
                                                                    this.props.onUncomplete()
                                                                }
                                                                editToggle()

                                                            }
                                                            }
                                                        >Edit</Button>
                                                    )}
                                                    {onSubmit && (
                                                        <Button
                                                            className="mb-3"
                                                            center
                                                            small
                                                            colour="pink"
                                                            icon="save"
                                                            disabled={!valid || !editActive || !changesMade}
                                                            onClick={onSubmit}
                                                        >
                                                            Save
                    </Button>
                                                    )}
                                                    <div style={{ height: '32px' }}>
                                                        <TransitionGroup appear={true}>
                                                            {(changesMade || !valid) && (
                                                                <CSSTransition
                                                                    classNames="exciting"
                                                                    timeout={1000}
                                                                >
                                                                    <Button
                                                                        small
                                                                        className="mb-3"
                                                                        center
                                                                        inline
                                                                        colour="yellow"
                                                                        link='/contact-us?contactType=sales'
                                                                    >Get Support</Button>
                                                                </CSSTransition>
                                                            )}
                                                        </TransitionGroup>
                                                    </div>
                                                    <div style={{ height: '54px' }}>
                                                        <TransitionGroup appear={true}>
                                                            {!changesMade && valid && (

                                                                <CSSTransition
                                                                    classNames="exciting"
                                                                    timeout={1000}
                                                                >
                                                                    <Button
                                                                        className="mb-3"
                                                                        center
                                                                        colour="green"
                                                                        icon="next"
                                                                        onClick={this.onComplete}
                                                                    >Next</Button>
                                                                </CSSTransition>
                                                            )}
                                                        </TransitionGroup>
                                                    </div>
                                                </div>

                                            </div>

                                            {this.props.showDebug && (<>
                                                <div>locked {locked ? "Y" : "N"}</div>
                                                <div>Edit active {editActive ? "Y" : "N"}</div>
                                                <div>changes Made {changesMade ? "Y" : "N"}</div>
                                                <div>valid {valid ? "Y" : "N"}</div>
                                                <div>loading {loading ? "Y" : "N"}</div>
                                            </>
                                            )}

                                            {loading && <div style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, backgroundColor: 'white' }}
                                                className="d-flex flex-column justify-content-center align-items-center">
                                                <Spinner image />
                                            </div>}
                                        </div>
                                    )}

                            </CSSTransition>
                        )}
                    </TransitionGroup>
                    <div ref={this.footerElement} style={{ position: 'absolute', bottom: 30 }} />
                </div>

            </PUPanel >
        )
    }
}

