import React, { Component } from 'react'
import Button from '../common/Button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
library.add(faChevronDown)

export default class DropDown extends Component {
    constructor() {
        super()
        this.state = {
            show: false
        }
        this.show = this.show.bind(this);
        this.close = this.close.bind(this);
        this.elementBody = React.createRef();
    }

    show(e) {
        e.preventDefault();
        this.setState({ show: true }, () => {
            document.addEventListener('mousedown', this.close);
        });
    }

    close(e) {

        if (this.props.preventCloseOnClick) {
            if (!this.elementBody.current.contains(e.target)) {
                this.setState({ show: false }, () => {
                    document.removeEventListener('mousedown', this.close);
                });
            }
        } else if (this.elementBody.current !== e.target) {
            this.setState({ show: false }, () => {
                document.removeEventListener('mousedown', this.close);
            });
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.promptClose === true) {
            this.setState({ show: false }, () => {
                document.removeEventListener('mousedown', this.close);
            });
        }
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.close);
    }

    render() {
        let Title
        if (typeof this.props.title === 'string') {
            Title = () => { return (<span>{this.props.title}</span>) }
        } else {
            Title = () => { return this.props.title }
        }
        return (
            <div
                style={{
                    position: 'relative',
                    marginRight: '2px'
                }}
            >
                <Button
                    colour={this.props.buttonColour}
                    noPadding={this.props.button ? false : true}
                    onClick={this.show}
                >
                    {this.props.button ? (
                        <Title />
                    ) : (
                            <div 
                                // style={{ height: '31px' }} 
                                className="d-flex align flex-row align-items-center"                            
                            >
                                <Title />
                                {this.props.hideChevron !== true && (
                                    <span style={{ marginLeft: '4px' }}>
                                        <FontAwesomeIcon icon="chevron-down" role='presentation' alt='drop down icon' color={this.props.buttonColour} />
                                    </span>
                                )}

                            </div>
                        )}
                </Button>
                <TransitionGroup appear={true}>
                    {
                        this.state.show && (
                            <CSSTransition
                                classNames="fadeGrow"
                                timeout={300}
                            >
                                <div
                                    ref={this.elementBody}
                                    style={{
                                        position: 'absolute',
                                        borderRadius: '4px',
                                        boxShadow: '0 1px 4px 0 rgba(0,0,0,.15)',
                                        backgroundColor: 'white',
                                        padding: '12px',
                                        marginTop: '12px',
                                        left: 'auto',
                                        right: this.props.right && '0',
                                        marginRight: '-10px',
                                        zIndex: 99999999999999,
                                        maxWidth: 'calc(100vw - 100px)',
                                        maxHeight: 'calc(100vh - 100px)',
                                        overflowY: 'auto',
                                        overflowX: 'hidden'
                                    }}
                                >
                                    {this.props.children}

                                </div>
                            </CSSTransition>
                        )
                    }

                </TransitionGroup>
            </ div >
        )
    }
}
