import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons'
library.add(faPlusCircle)

const Plus = (props) => {
    return (
        <FontAwesomeIcon icon="plus-circle" size={props.size} />
    );
}
export default Plus