import React, { Component } from "react";
import { connect } from "react-redux";
import LoginContent from "./LoginContent";
import RegisterContent from './RegisterContent'
import LoginSplash from "../layout/LoginSplash";
import Button from '../common/Button'

class LoginScreen extends Component {
  constructor(props) {
    super(props);

    if (props.location.state) {
      this.state = {
        showSignup: props.location.state.hash === '#signup'
      }
    } else {
      this.state = {
        showSignup: false
      }
    }


    this.redirect = this.redirect.bind(this);
  }
  componentDidMount() {
    if (this.props.auth.isAuthenticated) {
      this.redirect();
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.auth.isAuthenticated) {
      this.redirect();
    }
  }

  redirect() {
    const { location } = this.props;
    if (checkLocation(location)) {
      window.location.replace(location.state.from);
    } else {
      window.location.replace("/");
    }

    function checkLocation(location) {
      if (location.state) {
        if (location.state.from) {
          if (location.state.from !== "/login" && location.state.from !== "/sign-up") return true;
        }
      }
      return false;
    }
  }

  render() {
    return (
      <LoginSplash>
        <p className="mb-2">
          You need a Practice Unbound account to continue. Please log in or sign up below.
        </p>
        {this.state.showSignup ? (
          <div>
            <div className="d-flex align-items-center my-3">
              <p className="mb-0 mr-4">Already have an account?</p>
              <Button
                inline
                underline
                colour="none"
                onClick={() => { this.setState({ showSignup: false }) }}>
                login</Button>
            </div>
            <RegisterContent />

          </div>
        ) : (
            < div >
              < LoginContent />
              <div className="d-flex align-items-center my-4">
                <p className="mb-0 mr-4">Don't have an account?</p>
                <Button
                  inline
                  underline
                  colour="none"
                  onClick={() => { this.setState({ showSignup: true }) }}>
                  sign up</Button>
              </div>

            </div >
          )
        }

      </LoginSplash>
    );
  }
}
const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(
  mapStateToProps,
  {}
)(LoginScreen);
