import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import PUPanel from '../layout/PUPanel'

class PageOrganisationLandingScreen extends Component {

    render() {

        const { organisationProductMemberships, organisationProductMembershipsLoading } = this.props.organisations
        if (!organisationProductMembershipsLoading && organisationProductMemberships.length > 0) {

            if (sessionStorage.product) {
                const filtered = organisationProductMemberships.filter(p => p.organisationproductmembershipid === sessionStorage.product)
                if (filtered.length > 0) {
                    return <Redirect to={'/admin/' + filtered[0].organisationproductmembershipid} />
                }

            }
            return <Redirect to={'/admin/' + organisationProductMemberships[0].organisationproductmembershipid} />
        }


        return (
            <PUPanel title="Welcome!">
                <p className="lead">It looks like you don't have any products linked to this organisation yet - get in touch with someone from our team to get started.</p>
            </PUPanel>
        )
    }
}

const mapStateToProps = (state) => ({
    organisations: state.organisations
})

export default connect(mapStateToProps, {})(PageOrganisationLandingScreen);