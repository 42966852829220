import api from '../config/axios';
import { SET_PROFILE_ACTION_SUCCESS, SET_PROFILE_ACTION_LOADING, GET_CONSENTS, SET_CONSENTS_LOADING, SET_PROMPT_AUTH_PROFILE, GET_ANON_PROFILE, SET_PROMPT_ANON_PROFILE, SET_PROFILE_LOADING, GET_AUTH_PROFILE, GET_PROFILE_ERRORS, CLEAR_PROFILE_ERRORS } from './types';
import isEmpty from '../utils/is-empty'
import { logoutUser } from './authActions'

export const setPromptAuthProfile = (payload => {
    return {
        type: SET_PROMPT_AUTH_PROFILE,
        payload: payload
    }
})

export const setPromptAnonProfile = (payload => {
    return {
        type: SET_PROMPT_ANON_PROFILE,
        payload: payload
    }
})


export const getAuthProfile = () => dispatch => {
    dispatch({ type: SET_PROFILE_LOADING, payload: true });
    dispatch({ type: CLEAR_PROFILE_ERRORS });
    api.platformAPI
        .get('/api/platform/profile')
        .then(res => {
            dispatch({ type: GET_AUTH_PROFILE, payload: res.data })
        })
        .catch(err => {
            dispatch(handleError(err))
            dispatch({ type: SET_PROFILE_LOADING, payload: false });
        });
}

export const getAnonProfile = () => dispatch => {
    if (localStorage.anonProfile) {
        dispatch({ type: GET_ANON_PROFILE, payload: localStorage.anonProfile })
    } else {
        dispatch({ type: GET_ANON_PROFILE, payload: {} })
    }
}

export const updateAuthProfile = (profile) => dispatch => {
    dispatch({ type: SET_PROFILE_LOADING, payload: true });
    dispatch({ type: CLEAR_PROFILE_ERRORS });
    api.platformAPI
        .post('/api/platform/profile', profile)
        .then(res => {
            dispatch({ type: GET_AUTH_PROFILE, payload: res.data })
        })
        .catch(err => {
            dispatch(handleError(err))
            dispatch({ type: SET_PROFILE_LOADING, payload: false });
        });
}

export const getConsents = () => dispatch => {
    dispatch({ type: SET_CONSENTS_LOADING, payload: true })
    dispatch({ type: CLEAR_PROFILE_ERRORS });
    api.platformAPI
        .get('/api/platform/profile/consents')
        .then(res => {
            dispatch({ type: GET_CONSENTS, payload: res.data })
        })
        .catch(err => {
            dispatch(handleError(err))
            dispatch({ type: SET_CONSENTS_LOADING, payload: false })
        });
}
export const updateConsents = (data) => dispatch => {
    dispatch({ type: SET_PROFILE_ACTION_LOADING, payload: true })
    dispatch({ type: CLEAR_PROFILE_ERRORS });
    api.platformAPI
        .post('/api/platform/profile/consents', data)
        .then(res => {
            dispatch({ type: GET_CONSENTS, payload: res.data })
            dispatch({ type: SET_PROFILE_ACTION_SUCCESS, payload: true })
        })
        .catch(err => {
            dispatch(handleError(err))
            dispatch({ type: SET_PROFILE_ACTION_LOADING, payload: false })
        });
}

export const setProfileActionSuccess = (data) => dispatch => {
    dispatch({ type: SET_PROFILE_ACTION_SUCCESS, payload: data })
}

const handleError = (err) => dispatch => {
    let payload
    console.dir(err)

    //token expired
    if (err.response) {
        if (err.response.statusText === "Unauthorized" && err.response.status === 401 && err.response.data === "Unauthorized") {
            dispatch(logoutUser())
        }
    }


    if (isEmpty(err.response)) {
        payload = { error: err.message }
    } else {
        if (isEmpty(err.response.data)) {
            payload = { error: err.message }
        } else {
            payload = err.response.data
        }
    }

    dispatch({
        type: GET_PROFILE_ERRORS,
        payload: payload
    })
}

export const clearProfileErrors = () => dispatch => {
    dispatch({ type: CLEAR_PROFILE_ERRORS })
}