import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faGripHorizontal } from '@fortawesome/free-solid-svg-icons'
library.add(faGripHorizontal)

const MyProductsIcon = (props) => {

    return (
        <span
            className='btn-inherit'
            style={{
                fontSize: '23px',
                lineHeight: '38px',
                height: '38px',
                width: '38px',
                borderRadius: '50%',
                backgroundColor: props.colour === 'white' && 'white',
            }}>
            <FontAwesomeIcon color={props.colour !== 'white' ? "white" : "#193958"} icon="grip-horizontal" role="presentation" />
        </span>
    )
}
export default MyProductsIcon