import React, { Component } from 'react'
import { connect } from 'react-redux'
import LNA from '../LNA/LNA'
import Spinner from '../common/Spinner'
import Button from '../common/Button'
import moment from 'moment'
import { setOrganisationProductMembershipActionSuccess } from '../../actions/organisationActions'
import PUPanel from '../layout/PUPanel'
import NextButton from './NextButton'

class PageLNA extends Component {
    constructor() {
        super()
        this.state = {
            showForm: false
        }
    }
    componentDidMount() {
        this.props.setOrganisationProductMembershipActionSuccess(false)
    }
    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.organisations.organisationProductMembershipActionSuccess) {
            this.setState({ showForm: false })
            window.scrollTo(0, 0)
        }
    }


    render() {
        const { organisationProductMembership } = this.props.organisations
        const { lna } = organisationProductMembership

        if (!organisationProductMembership.lnaQuestions) { return <Spinner block /> }

        if (this.state.showForm || lna === null) {
            return (
                <div className="pb-5">
                    <p className="lead">
                        The following Learning Needs Analysis has been designed to enable you to make the best use of this e-learning package. It will provide tailored recommendations about which sections of the course you are likely to find most useful based on your responses. Completing the Learning Needs Analysis should take no more than 10 minutes.
                    </p>
                    <div className="row justify-content-center">
                        <div className="col-md-10 col-lg-9 col-xl-8 pt-4">

                            <PUPanel title="Learning Needs">
                                <LNA questions={organisationProductMembership.lnaQuestions.map(l => {
                                    return { questionNumber: l.questionNumber, questionName: l.questionName, networkQuestion: l.networkQuestion }
                                }
                                )} />
                            </PUPanel>
                        </div>
                    </div>

                </div>
            )
        }


        return (
            <div className="mb-4">
                <PUPanel title="Learning Needs">
                    <p className="lead">
                        All done! Thank you for completing your Learning Needs Analysis. Here are your recommendations:
                </p>
                    {lna.resultText.map((t, key) => {
                        if (t.type === "header") {
                            return <h6 key={key} className="mt-2"><strong>{t.content}</strong></h6>
                        }
                        if (t.type === "paragraph") {
                            return <p key={key}>{t.content}</p>
                        }
                        if (t.type === "list") {
                            return <ul key={key} className="mb-0"><li>{t.content}</li></ul>
                        }
                        return null
                    })}
                    <small className="text-muted">Completed {moment(lna.date).format('DD-MM-YY')}</small>
                    <Button
                        className="mt-3"
                        center
                        onClick={() => { this.setState({ showForm: true }) }}
                    >Complete another</Button>

                </PUPanel >

                <NextButton currentPage={'learning-needs-analysis'} />
            </div>
        )

    }
}

const mapStateToProps = (state) => ({
    organisations: state.organisations
})

export default connect(mapStateToProps, { setOrganisationProductMembershipActionSuccess })(PageLNA);