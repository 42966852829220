import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classnames from 'classnames'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faCheckCircle, faCheck } from '@fortawesome/free-solid-svg-icons'
library.add(faCheckCircle, faCheck)



const Check = (props) => {
    const icon = (
        <FontAwesomeIcon className={classnames(
            { "tc-dark-mint": props.color === "green" },
            props.className
        )}
            role="presentation"
            icon={props.plain ? "check" : "check-circle"}
            style={{
                fontSize: props.medium ? '24px' : props.large && '42px'
            }}
        />
    )
    if (props.wipeIn) {
        return (
            <div className="wipeIn" style={{ width: '42px' }}>
                {icon}
            </div>

        )
    }
    return icon
}
export default Check