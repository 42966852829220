import React, { Component } from "react";
import { connect } from "react-redux";
import Spinner from "../common/Spinner";
import ContentBlockPage from "../layout/ContentBlockPage";
import { Helmet } from "react-helmet";

class GetStartedScreen extends Component {
    render() {
        const { productGetStartedContentLoading } = this.props.pages;
        return (
            <div>
                {this.props.products.product && (
                    <Helmet>
                        <meta
                            name="twitter:image"
                            content={this.props.products.product.image} />
                        <meta name="twitter:card" content="summary_large_image" />
                    </Helmet>
                )}
                {productGetStartedContentLoading ? (
                    <Spinner block large color="yellow" />
                ) : (
                        <ContentBlockPage content={this.props.pages.productGetStartedContent} />
                    )}
            </div>
        );
    }
}
const mapStateToProps = state => ({
    products: state.products,
    pages: state.pages
});
export default connect(
    mapStateToProps,
    {}
)(GetStartedScreen);
