import BannerFlex from "../layout/BannerFlex";
import ImageTextBlock from "../layout/PLM/Comps/ImageTextBlock";
import TextFieldGroup from "../common/TextFieldGroup";
import Select from "../common/Select";
import puColours from "../../utils/puColours";
import { useLocation, useHistory } from 'react-router'
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { sendFormData } from "../../actions/pagesActions";
import { sendContact } from "../../actions/pagesActions";
import { withRouter } from "react-router-dom";
import queryString from "query-string";
import Button from "../common/Button";
import axios from '../../config/axios'
import Spinner from "../common/Spinner";
import Upload from "../common/Upload";
import { useScreenSize } from "../../utils/hooks";

const ContactScreen = (props) => {
  const location = useLocation()
  const history = useHistory()
  const screenSize = useScreenSize()
  const searchParams = new URLSearchParams(location.search)

  const [readOnlyFields, setReadOnlyFields] = useState({
    name: false,
    email: false,
  });
  const [contactType, setContactType] = useState({});
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [contact, setContact] = useState("");
  const [role, setRole] = useState("");
  const [organisation, setOrganisation] = useState("");
  const [listSize, setListSize] = useState("");
  const [presentingProblem, setPresentingProblem] = useState("");
  const [message, setMessage] = useState("");
  const [product, setProduct] = useState({});
  const [howDidYouHear, setHowDidYouHear] = useState({});
  const [showPleaseSpecify, setShowPleaseSpecify] = useState(false);
  const [otherSpecify, setOtherSpecify] = useState("");
  const [loadingOptions, setLoadingOptions] = useState(false)
  const [loadingOptionsError, setLoadingOptionsError] = useState(false)
  const [subject, setSubject] = useState({})
  const [file, setFile] = useState(null)

  const [mappedRole, setMappedRole] = useState({})
  const [mappedRoleOptions, setMappedRoleOptions] = useState([])
  const [subjectOptions, setSubjectOptions] = useState([])
  const [clinicalSystem, setClinicalSystem] = useState({})
  const [clinicalSystemOptions, setClinicalSystemOptions] = useState([])
  const [productOptions, setProductOptions] = useState([])
  const [activeFields, setActiveFields] = useState({})

  const products = [
    { label: "Workflow Go", value: "workflowGo" },
    { label: "Pathology Go", value: "pathologygo" },
    { label: "GP Billing", value: "gpBilling" },
    {
      label: "Prescribing Lifestyle Medicine Online",
      value: "plmOnline",
    },
    {
      label: "Certificate in Lifestyle Medicine",
      value: "certificateInLifestyleMedicine",
    },
    { label: "Prescribing Lifestyle Medicine Live", value: "plmLive" },
    { label: "N/A", value: "na" },
  ]

  const contactTypeOptions = {
    support: { label: 'Helpdesk Support', value: 'support' },
    sales: { label: 'Sales Enquiry', value: 'sales' },
    mail: { label: 'Join our mailing list', value: 'mail' },
  }

  async function getOptions() {
    setLoadingOptions(true)
    try {
      const response = await axios.platformAPI.get("/api/platform/contact/options")
      const options = response.data.options
      const [subjectsRes, clinicalSystemsRes, roleRes, productRes] = options
      setSubjectOptions(subjectsRes.value.map((subject) => (
        { label: subject.title, value: { title: subject.title, subjectid: subject.subjectid } }
      )))
      setMappedRoleOptions(roleRes)
      setProductOptions(productRes.value.map((product) => (
        { label: product.c9_name, value: product.c9_productid }
      )).filter(p => ['Workflow Go', 'Pathology Go', 'GP Billing', 'Prescribing Lifestyle Medicine', 'Certificate in Lifestyle Medicine', 'PLM Live'].includes(p.label)))
      setClinicalSystemOptions(clinicalSystemsRes.map(option => option.label === "Other" ? { label: "Other / N/A", value: 100000000 } : option))

      setLoadingOptions(false)
    } catch (error) {
      setLoadingOptions(false)
      setLoadingOptionsError(true)
    }
  }

  async function handleSubmit(e) {
    e.preventDefault();
    const data = {
      contactType,
      name,
      email,
      contact,
      mappedRole,
      role,
      organisation,
      listSize,
      product,
      clinicalSystem,
      subject,
      howDidYouHear: howDidYouHear.value,
      otherSpecify,
      presentingProblem,
      message,
    };
    const filteredData = {
      ...Object.fromEntries(Object.entries(data).filter(([key, _]) => activeFields[key])),
      contactType,
      name,
      email,
      organisation,
      message
    }
    const stringifyData = JSON.stringify(filteredData)
    const formData = new FormData()
    formData.append('data', stringifyData)
    formData.append('file', file)
    props.sendFormData(formData)
  }

  const { isAuthenticated } = props.auth

  useEffect(() => {
    setActiveFields({
      upload: contactType.value === 'support' || false,
      contact: contactType.value !== "mail",
      role: ["mail"].includes(contactType.value),
      mappedRole: contactType.value === 'support' || contactType.value === 'sales',
      product: true,
      listSize:
        contactType.value === "sales",
      // && ["Workflow Go", "Pathology Go", "GP Billing"].includes(product.label),
      clinicalSystem: (contactType.value === "support" || contactType.value === "sales"),
      // &&
      //   [
      //     "Prescribing Lifestyle Medicine",
      //     "Workflow",
      //     "Workflow at Scale",
      //     "Workflow Go",
      //     "Workflow Solo",
      //     "Workflow F2F (Old Offer)",
      //     "Workflow Network",
      //     "Pathology Go",
      //     "GP Billing",
      //   ].includes(product.label),
      subject: contactType.value === "support",
      howDidYouHear: ["sales", "mail"].includes(contactType.value),
      otherSpecify:
        contactType.value === "sales" &&
        (howDidYouHear.value === "Another website" ||
          howDidYouHear.value === "Other"),
      presentingProblem: contactType.value === 'support',
      message: ["sales", "support"].includes(contactType.value),
    })
  }, [
    contactType,
    loadingOptions,
    loadingOptionsError,
    product,
    howDidYouHear,
  ])

  useEffect(() => {
    getOptions()
    const parsed = queryString.parse(location.search);
    if (parsed.contactType) {
      setContactType(contactTypeOptions[parsed.contactType])
    } else setContactType(contactTypeOptions.support)
    if (parsed.product) {
      const selectedProduct = products.filter(
        (p) => p.value === parsed.product,
      );
      if (selectedProduct.length > 0) {
        setProduct(selectedProduct[0]);
      }
    }
  }, []);

  useEffect(() => {
    const { firstName, lastName, email } = props.auth.user;
    if (isAuthenticated && firstName && lastName) {
      setName(`${firstName} ${lastName}`);
      setReadOnlyFields((prev) => ({ ...prev, name: true }));
    }
    if (isAuthenticated && email) {
      setEmail(email);
      setReadOnlyFields((prev) => ({ ...prev, email: true }));
    }
  }, [props]);

  const { errors, contactUsLoading, contactUsSuccess } = props.pages;
  const [success, setSuccess] = useState(false)

  useEffect(() => {
  }, [success]);
  useEffect(() => {
    if (contactUsSuccess) setSuccess(true)
  }, [contactUsSuccess]);

  useEffect(() => {
    searchParams.set('contactType', contactType.value)
    history.replace({ search: searchParams.toString() })
    if (success) setSuccess(false)
  }, [contactType]);

  function clearForm() {
    setClinicalSystem({})
    setSubject({})
    setMappedRole({})
    setProduct({})
    setContact("")
    setHowDidYouHear("")
    setListSize("")
    setMessage("")
    setOrganisation("")
    setOtherSpecify("")
    setPresentingProblem("")
    setRole("")
  }
  useEffect(() => {
    if (success) {
      clearForm()
    }
  }, [contactUsSuccess, success])

  useEffect(() => {
    console.log("🚀 ~ subjectOptions:", subjectOptions)
  }, [subjectOptions])

  return (
    <div>
      <BannerFlex
        shapes={bannerShapes}
        classNames={{
          shapesSection: "d-none d-md-block",
          contentSection: "mb-3",
        }}
      >
        <div className="tw-pb-20 tw-pt-16 sm:tw-pt-28 lg:tw-pb-32">
          <h1 className="tw-mb-6 tw-font-bold" style={{ textWrap: "balance" }}>
            Got questions? Talk to us.
          </h1>
          <p>
            If you need support with one of our products, or you are in interested in embedding our e-learning programmes, complete the relevant section of the form below and we'll come back to you as soon as we can.
          </p>
          <p>Alternatively, give us a call on <strong>0300 303 8145</strong></p>
          <p>Our offices are open Monday-Friday, 9am - 5pm</p>
        </div>
      </BannerFlex>

      <ImageTextBlock
        imageUrl="https://practiceunbound.blob.core.windows.net/stock-images/Homepage_Block_Shutterstock_1050440903-min.jpg"
        contentSide="left"
        contentBackgroundColor={puColours.darkBlue}
        contentColWidth="8"
        imageTransitionElement={() => (
          <div className="tw-absolute tw-inset-0 -tw-right-[0px] tw-left-[56%] tw-bg-gradient-to-r tw-from-[#113B5B] min-[1590px]:tw-left-[55%] min-[1900px]:tw-left-[53%]" />
        )}
      >
        <div className="tw-relative tw-pb-4 tw-pl-0 sm:tw-pl-5 lg:tw-h-[775px]">
          <div
            className={`tw-absolute -tw-inset-y-14 -tw-left-[15px] tw-flex tw-h-14 tw-w-screen sm:tw-left-0  sm:tw-w-full`}
          >
            {/* {isAuthenticated && */}
            <Tab
              name="support"
              label="Helpdesk Support"
              contactTypeValue={contactType.value}
              setContactType={setContactType}
              activeColour={"#f4b321"}
              setShowPleaseSpecify={setShowPleaseSpecify}
              setHowDidYouHear={setHowDidYouHear}
              success={success}
            >
              <h6 className={`tw-text-center tw-font-bold tw-uppercase ${(success && ['sales', 'mail'].includes(contactType.value)) ? 'tw-text-[#0D2B42]' : 'text-white group-hover:tw-text-[#f4b321] group-focus:tw-text-[#f4b321]'} tw-transition-all tw-duration-200 `}>
                helpdesk support
              </h6>
            </Tab>
            {/* } */}
            <Tab
              name="sales"
              label="Sales Enquiry"
              contactTypeValue={contactType.value}
              setContactType={setContactType}
              activeColour={"#0C989D"}
              setShowPleaseSpecify={setShowPleaseSpecify}
              setHowDidYouHear={setHowDidYouHear}
              success={success}
            >
              <h6 className={`tw-text-center tw-font-bold tw-uppercase ${(success && ['support', 'mail'].includes(contactType.value)) ? 'tw-text-[#0D2B42]' : 'text-white group-hover:tw-text-[#f4b321] group-focus:tw-text-[#f4b321]'} tw-transition-all tw-duration-200 `}>
                sales enquiry
              </h6>
            </Tab>
            <Tab
              name="mail"
              label="Join our mailing list"
              contactTypeValue={contactType.value}
              setContactType={setContactType}
              activeColour={"#EB6915"}
              setShowPleaseSpecify={setShowPleaseSpecify}
              setHowDidYouHear={setHowDidYouHear}
              success={success}
            >
              <h6 className={`tw-text-center tw-font-bold tw-uppercase ${(success && ['sales', 'support'].includes(contactType.value)) ? 'tw-text-[#0D2B42]' : 'text-white group-hover:tw-text-[#f4b321] group-focus:tw-text-[#f4b321]'} tw-transition-all tw-duration-200 `}>
                mailing list
              </h6>
            </Tab>
          </div>
          <div className="tw-py-5" />
          {/* {contactType.value === 'mail' && <p className="tw-text-white">To make sure we give you the most relevant information, please let us know a little about yourself in the form below</p>} */}
          {contactType.value === 'support' && <p className="tw-text-white">Complete this form if you're an existing customer (you must be logged in) and you need some assistance from our Helpdesk</p>}
          {contactType.value === 'sales' && <p className="tw-text-white">Complete this form if you're interested in implementing one of our products or attending one of our live events</p>}
          {contactType.value === 'mail' && <p className="tw-text-white tw-h-[50px]">Complete this form if you'd like to be kept up to date with the latest from Practice Unbound</p>}
          <form
            onSubmit={handleSubmit}
            className={`tw-relative tw-grid tw-grid-cols-12 tw-gap-x-2 tw-pb-5 tw-pt-4`}
          >
            <div className="tw-col-span-12 lg:tw-col-span-6">
              <TextFieldGroup
                name="name"
                type="name"
                label="Name"
                disabled={success}
                labelStyle={{ color: "white" }}
                value={name}
                onChange={(e) => setName(e.target.value)}
                readOnly={readOnlyFields.name}
                error={errors.name}
                errorColor="white"
              />
            </div>

            <div className="tw-col-span-12 lg:tw-col-span-6">
              <TextFieldGroup
                name="email"
                type="email"
                label="Email"
                disabled={success}
                labelStyle={{ color: "white" }}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                readOnly={readOnlyFields.email}
                error={errors.email}
                errorColor="white"
              />
            </div>

            {/* {contactType.value !== 'mail' && ( */}
            {activeFields.contact &&
              <div className={`tw-col-span-12 ${['support', 'sales'].includes(contactType.value) && 'lg:tw-col-span-4'}`}>
                <TextFieldGroup
                  name="contact"
                  type="tel"
                  disabled={success}
                  label="Contact Number"
                  labelStyle={{ color: "white" }}
                  value={contact}
                  onChange={(e) => setContact(e.target.value)}
                  error={errors.contact}
                  errorColor="white"
                />
              </div>
            }
            {/* )} */}

            {/* {['sales', 'mail'].includes(contactType.value) && ( */}
            {activeFields.role && (
              <div className={`tw-col-span-12 ${['support', 'sales'].includes(contactType.value) ? 'lg:tw-col-span-4' : 'lg:tw-col-span-12'}`}>
                <TextFieldGroup
                  name="role"
                  type="text"
                  disabled={success}
                  label="Role"
                  labelStyle={{ color: "white" }}
                  value={role}
                  onChange={(e) => setRole(e.target.value)}
                  error={errors.role}
                  errorColor="white"
                />
              </div>
            )}
            {activeFields.mappedRole && (
              <div className={`tw-col-span-12 lg:tw-col-span-4`}>
                <SuspenseOptions loadingOptions={loadingOptions} loadingOptionsError={loadingOptionsError}>
                  <Select
                    label={loadingOptions ? 'Loading options...' : 'Role'}
                    labelStyle={{ color: "white" }}
                    style={{ zIndex: "601" }}
                    value={mappedRole}
                    readOnly={success}
                    onChange={(e) => {
                      setMappedRole({ label: e.label, value: e.value });
                    }}
                    options={mappedRoleOptions}
                    error={errors.mappedRole}
                    errorColor="white"
                  />
                </SuspenseOptions>
              </div>
            )}

            {/* ${contactType.value === 'mail' && 'max-[459px]:tw-pt-[1.58rem]'} */}
            <div className={`tw-col-span-12 ${['support', 'sales'].includes(contactType.value) ? 'lg:tw-col-span-4' : 'lg:tw-col-span-12'}`}>
              <TextFieldGroup
                name="organisation"
                label="Organisation"
                disabled={success}
                value={organisation}
                onChange={(e) => setOrganisation(e.target.value)}
                labelStyle={{ color: "white" }}
                type="text"
                error={errors.organisation}
                errorColor="white"
              />
            </div>

            {activeFields.product && (
              <div className={`tw-col-span-12 ${activeFields.clinicalSystem && 'lg:tw-col-span-4'} ${!activeFields.clinicalSystem && contactType.value === 'support' && 'lg:tw-col-span-6'}`}>
                <SuspenseOptions loadingOptions={loadingOptions} loadingOptionsError={loadingOptionsError}>
                  <Select
                    label={contactType.value === 'mail' ? 'Product Interest' : 'Product'}
                    labelStyle={{ color: "white" }}
                    // hideError
                    style={{
                      zIndex: "601",
                    }}
                    value={product}
                    readOnly={success}
                    onChange={(e) => {
                      setProduct(e);
                    }}
                    options={contactType.value === 'support' ? productOptions : products}
                    error={errors.product}
                    errorColor="white"
                  />
                </SuspenseOptions>
              </div>
            )}


            {/* {contactType.value === 'sales' && ["workflowGo", "pathologygo", "gpBilling"].includes(product.value) && ( */}
            {activeFields.listSize &&
              <div className={`tw-col-span-12 lg:tw-col-span-4`}>
                <TextFieldGroup
                  name="listSize"
                  readOnly={success}
                  label="List Size"
                  value={listSize}
                  onChange={(e) => setListSize(e.target.value)}
                  labelStyle={{ color: "white" }}
                  type="text"
                  error={errors.listSize}
                  errorColor="white"
                />
              </div>
            }
            {/* )} */}

            {activeFields.clinicalSystem && (
              <div className={`tw-col-span-12 lg:tw-col-span-4`}>
                <SuspenseOptions loadingOptions={loadingOptions} loadingOptionsError={loadingOptionsError}>
                  <Select
                    label={loadingOptions ? 'Loading options...' : 'Clinical System'}
                    labelStyle={{ color: "white" }}
                    style={{ zIndex: "601" }}
                    value={clinicalSystem}
                    readOnly={success}
                    onChange={(e) => {
                      setClinicalSystem(e);
                    }}
                    options={clinicalSystemOptions}
                    error={errors.clinicalSystem}
                    errorColor="white"
                  />
                </SuspenseOptions>
              </div>
            )}

            {activeFields.subject && (
              <div className={`tw-col-span-12 ${activeFields.clinicalSystem && 'lg:tw-col-span-4'} ${!activeFields.clinicalSystem && contactType.value === 'support' && 'lg:tw-col-span-6'}`}>
                <SuspenseOptions loadingOptions={loadingOptions} loadingOptionsError={loadingOptionsError}>
                  <Select
                    label={loadingOptions ? 'Loading options...' : 'Subject'}
                    labelStyle={{ color: "white" }}
                    style={{ zIndex: "601" }}
                    value={subject}
                    readOnly={success}
                    onChange={(e) => {
                      setSubject({ label: e.value.title, value: e.value.subjectid });
                    }}
                    options={
                      subjectOptions.filter(o => ![
                        "Template Query **",
                        "Reports **",
                        "Reporting Query **",
                        "Data Dashboard Query **",
                        "Request for Additional Account **",
                        "Account",
                        "Data Dashboard",
                        "Product resources",
                        "Template",
                        "Auditing",
                        "S1 Task Query **",
                        "Portal **",
                        "E-Learning",
                        "E-Learning Login Issue **",
                        "E-Learning Usage Query **",
                      ].includes(o.label))
                    }
                    error={errors.subject}
                    errorColor="white"
                  />
                </SuspenseOptions>
              </div>
            )}

            {/* {contactType && ["sales", "mail"].includes(contactType.value) && ( */}
            {activeFields.howDidYouHear &&
              <div className={`${activeFields.otherSpecify ? 'tw-col-span-6' : 'tw-col-span-12'}`}>
                <Select
                  label="How did you hear about us?"
                  labelStyle={{ color: "white" }}
                  readOnly={success}
                  value={howDidYouHear}
                  onChange={(e) =>
                    setHowDidYouHear({ label: e.value, value: e.value })
                  }
                  options={[
                    {
                      label: "Friend or colleague",
                      value: "Friend or colleague",
                    },
                    { label: "Browsing online", value: "Browsing online" },
                    { label: "Conference", value: "Conference" },
                    // { label: "Workshop", value: "Workshop" },
                    { label: "Email from us", value: "Email from us" },
                    { label: "Social media", value: "Social media" },
                    { label: "Another website", value: "Another website" },
                    { label: "Other", value: "Other" },
                  ]}
                />
              </div>
            }
            {/* )} */}

            {activeFields.otherSpecify && (
              <div
                className={`tw-col-span-6 tw-pt-[1.57rem] min-[460px]:tw-pt-0 `}
              >
                <TextFieldGroup
                  label="Please specify"
                  labelStyle={{ color: "white" }}
                  readOnly={success}
                  name="otherSpecify"
                  value={otherSpecify}
                  onChange={(e) => setOtherSpecify(e.target.value)}
                />
              </div>
            )}

            {activeFields.presentingProblem &&
              <div className={`tw-col-span-12 `}>
                <TextFieldGroup
                  name="presentingProblem"
                  readOnly={success}
                  value={presentingProblem}
                  onChange={(e) => setPresentingProblem(e.target.value)}
                  type="text"
                  label="Presenting Problem (short description)"
                  labelStyle={{ color: "white" }}
                  error={errors.presentingProblem}
                  errorColor="white"
                // height={
                //   !showPleaseSpecify && "230px"
                // }
                />
              </div>
            }

            {(activeFields.message && ["sales", "mail"].includes(contactType.value)) && (
              <div className={`tw-col-span-12`}>
                <TextFieldGroup
                  value={message}
                  readOnly={success}
                  name="message"
                  onChange={(e) => setMessage(e.target.value)}
                  noResize
                  type="textarea"
                  label="Message"
                  labelStyle={{ color: "white" }}
                  error={errors.message}
                  errorColor="white"
                // height={
                //   !showPleaseSpecify && "230px"
                // }
                /></div>
            )}

            {(activeFields.message && contactType.value === 'support') && (
              <div className={`tw-col-span-8`}>
                <TextFieldGroup
                  name="message"
                  disabled={success}
                  onChange={(e) => setMessage(e.target.value)}
                  noResize
                  type="textarea"
                  label="Message"
                  labelStyle={{ color: "white" }}
                  error={errors.message}
                  errorColor="white"
                // height={
                //   !showPleaseSpecify && "230px"
                // }
                />
              </div>
            )}

            {activeFields.upload &&
              <div className={`tw-col-span-4  tw-pt-[25px]`}>
                <div className="tw-bg-slate-200 tw-rounded-[4px] tw-h-[134px] tw-px-1 tw-py-1">
                  <Upload
                    onChange={e => {
                      setFile(e)
                    }}
                    value={file}
                    placeHolder={screenSize >= 1200 ? "Click or drag image" : "Add Image"}
                  />
                </div>
              </div>
            }

            {success ? (<div className="tw-text-white tw-col-span-12 tw-pt-8">
              {contactType.value === 'support' ? (
                <h5>Thank you for contacting us. A case will be raised for you and we will be in contact with you soon.</h5>
              ) : contactType.value === 'sales' ? (
                <h5>Thank you for your enquiry. We’ll be in touch within the next two working days.</h5>
              ) : contactType.value === 'mail' ? (
                <h5>Thank you for signing up for our emails. You will start receiving updates soon.</h5>
              ) : (null)
              }
            </div>) :
              (
                <div className="tw-col-span-12">
                  <div className="tw-py-4" />
                  <div className="tw-flex tw-text-white tw-items-center">
                    <Button
                      fixedWidth
                      type="submit"
                      large
                      done={success}
                      loading={contactUsLoading}
                      disabled={contactType.value === 'support' && !isAuthenticated}
                    >
                      send
                    </Button>
                    <div className="tw-px-2" />
                    <div>
                      <small className="text-danger">{errors.error}</small>
                    </div>
                    {contactType.value === 'support' && !isAuthenticated && <p className="tw-m-0 tw-block tw-h-fit">Please log in to submit this form</p>}
                  </div>
                </div>
              )}
          </form>
        </div>
      </ImageTextBlock>
    </div>
  );
};

const SuspenseOptions = ({ loadingOptions, loadingOptionsError, children }) => (
  <>
    {loadingOptions &&
      <div className="tw-h-[88.188px] tw-flex tw-justify-center tw-items-center tw-gap-2">
        <Spinner large color="yellow" /><span className="tw-text-white">Loading options...</span>
      </div>
    }
    {loadingOptionsError &&
      <div className="tw-h-[88.188px] tw-col-span-1 text-danger">
        <p className="tw-pt-5 tw-text-center tw-font-bold">Error. Please try again</p>
      </div>
    }
    {(!loadingOptions && !loadingOptionsError) &&
      <>{children}</>
    }
  </>
)


const Tab = ({
  label,
  name,
  contactTypeValue,
  activeColour,
  setContactType,
  success,
  children,
}) => {
  const isActive = contactTypeValue === name;
  return (
    <>
      <button
        disabled={(success)}
        className={`tw-group tw-flex tw-grow tw-flex-col tw-items-center  tw-justify-center tw-pt-1 tw-drop-shadow-2xl tw-transition-all tw-duration-300 sm:tw-rounded-t-lg tw-text-[#0D2B42]`}
        style={{
          backgroundColor: isActive ? puColours.darkBlue : "#0D2B42",
          borderTop: `solid ${isActive ? activeColour : "#0D2B42"} 7px`,
          borderBottom: "none",
          borderLeft: "none",
          borderRight: "none",
        }}
        onClick={() => {
          setContactType({ label, value: name });
        }}
      >
        {children}
      </button>
    </>
  );
};

const bannerShapes = [
  {
    color: puColours.darkBlue,
    parentClassName: "position-absolute tw-group tw-w-[100px] tw-animate-throb",
    childClassName: "",
    top: "10%",
    left: "72%",
  },
  {
    color: puColours.darkOrange,
    parentClassName: "position-absolute tw-group tw-w-[40px] tw-animate-throb",
    childClassName: "",
    top: "75%",
    left: "20%",
  },
  {
    color: puColours.teal,
    parentClassName: "position-absolute tw-group tw-w-[55px] tw-animate-throb",
    childClassName: "",
    top: "-5%",
    left: "5%",
  },
  {
    color: puColours.yellow,
    parentClassName: "position-absolute tw-group tw-w-[200px]",
    childClassName:
      "group-hover:tw-drop-shadow-xl tw-transition-all tw-duration-500",
    top: "5%",
    left: "15%",
  },
];

const mapStateToProps = (state) => ({
  auth: state.auth,
  pages: state.pages,
});
export default connect(mapStateToProps, { sendFormData, sendContact })(
  withRouter(ContactScreen),
);