import React from "react";

const Footer = () => {
  return (
    <footer className="bg-white">
      <div className="container py-3 text-center">
        <small className="text-muted">
          &copy; {new Date().getFullYear()}. Copyright Care Unbound Limited
        </small>
      </div>
    </footer>
  );
};
export default Footer