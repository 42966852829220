import React from 'react'

export default function Drawer(props) {
    return (
        <div >
            <div style={{
                position: 'fixed',
                backgroundColor: props.onHomepage ? '#193958' : 'white',
                top: '54px',
                left: 'auto',
                right: '0',
                bottom: '0',
                overflowY: 'auto',
                overflowX: 'hidden',
                width: '380px',
                transition: 'opacity 150ms, transform 150ms',
                WebkitTransform: props.show ? 'translate3d(0, 0, 0)' : 'translate3d(110%, 0, 0)',
                transform: props.show ? 'translate3d(0, 0, 0)' : 'translate3d(110%, 0, 0)',
                // IE10 bug fix
                zIndex: '300'
            }}
                className="shadow-sm REACTMODAL_DRAW">
                {props.children}
            </div>
        </div>
    )
}
