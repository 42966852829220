import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faSquare, faCheckSquare } from '@fortawesome/free-regular-svg-icons'
library.add(faSquare, faCheckSquare)


const CheckIcon = (props) => {

    let colour
    if (props.status) {
        switch (props.status) {
            case "yellow":
                colour = "tc-dark-yellow"
                break
            case "green":
                colour = "tc-dark-mint"
                break
            case "unchecked":
                colour = "tc-mid-grey"
                break
            default:
                colour = "tc-mid-grey"
        }
    } else {
        switch (props.checked) {
            case true:
                colour = "tc-dark-mint"
                break
            default:
                colour = "tc-mid-grey"
        }
    }

    return (
        <FontAwesomeIcon
            role="presentation"
            style={{
                WebkitTransition: 'color .15s ease-in-out',
                transition: 'color .15s ease-in-out'
            }}
            className={`${props.className} ${colour}`}
            icon={
                {
                    prefix: 'far',
                    iconName: (props.checked || (props.status && props.status !== "unchecked")) ? "check-square" : "square"
                    // iconName: props.checked ? "check-square" : "square"
                }
            }
            size={props.large ? "5x" : "3x"}
        />
    )
}
export default CheckIcon