
import api from '../config/axios';
import {
    GET_PRODUCT_CONTENT_BROWSE,
    GET_PRODUCT_CONTENT_BROWSE_LOADING,
    GET_PRODUCT_CONTENT_OVERVIEW,
    GET_PRODUCT_CONTENT_OVERVIEW_LOADING,
    GET_PRODUCT_CONTENT_GETSTARTED,
    GET_PRODUCT_CONTENT_GETSTARTED_LOADING,
    GET_PRODUCT_CONTENT_LEADS,
    GET_PRODUCT_CONTENT_LEADS_LOADING,
    GET_OURSTORY_CONTENT,
    GET_OURSTORY_CONTENT_LOADING,
    GET_CONTACTUS_CONTENT,
    GET_CONTACTUS_CONTENT_LOADING,
    GET_CAREERS_CONTENT,
    GET_CAREERS_CONTENT_LOADING,
    GET_CUSTOMERSTORIES_CONTENT,
    GET_CUSTOMERSTORIES_CONTENT_LOADING,
    GET_FAQ_CONTENT,
    GET_FAQ_CONTENT_LOADING,
    GET_PRIVACYNOTICE_CONTENT,
    GET_PRIVACYNOTICE_CONTENT_LOADING,
    GET_TERMSCONDITIONS_CONTENT,
    GET_TERMSCONDITIONS_CONTENT_LOADING,
    GET_NEWSLETTER_CONTENT,
    GET_NEWSLETTER_CONTENT_LOADING,
    SET_CONTACTUS_LOADING,
    SET_CONTACTUS_SUCCESS,
    SET_SUBPAGE_LOADING,
    SET_SUBPAGES_LOADING,
    GET_SUBPAGE,
    GET_SUBPAGES,
    GET_PAGE_ERRORS,
    CLEAR_PAGE_ERRORS,
    GET_PAGE,
    SET_PAGE_LOADING,
    GET_BANNER,
    CLEAR_CONTACTUS
} from './types';
import isEmpty from '../utils/is-empty'
import { logoutUser } from './authActions'

export const sendContact = (data) => dispatch => {
    dispatch({ type: SET_CONTACTUS_LOADING, payload: true });
    api.platformAPI
        .post('/api/platform/contact', data)
        .then(res => {
            dispatch({
                type: SET_CONTACTUS_SUCCESS
            })
        })
        .catch(err => {
            dispatch(handleError(err))
            dispatch({ type: SET_CONTACTUS_LOADING, payload: false });
        });
}

export const sendFormData = (data) => dispatch => {
    dispatch({ type: SET_CONTACTUS_LOADING, payload: true });
    api.platformAPI
        .post('/api/platform/contact', data, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          })
        .then(res => {
            dispatch({
                type: SET_CONTACTUS_SUCCESS
            })
        })
        .catch(err => {
            dispatch(handleError(err))
            dispatch({ type: SET_CONTACTUS_LOADING, payload: false });
        });
}


export const clearContactUs = () => dispatch => {
    dispatch({
        type: CLEAR_CONTACTUS,
    })


}


export const sendPathGoContact = (data) => dispatch => {
    dispatch({ type: SET_CONTACTUS_LOADING, payload: true });
    api.platformAPI
        .post('/api/platform/contact/pathology-go', data)
        .then(res => {
            dispatch({
                type: SET_CONTACTUS_SUCCESS
            })
        })
        .catch(err => {
            dispatch(handleError(err))
            dispatch({ type: SET_CONTACTUS_LOADING, payload: false });
        });
}



export const sendPLMBursary = (data) => dispatch => {
    dispatch({ type: SET_CONTACTUS_LOADING, payload: true });

    const formData = new FormData();
    for (var key in data) {
        formData.append(key, data[key]);
    }

    api.platformAPI
        .post('/api/platform/contact/plm-bursary', formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
        .then(res => {
            dispatch({
                type: SET_CONTACTUS_SUCCESS
            })
        })
        .catch(err => {
            dispatch(handleError(err))
            dispatch({ type: SET_CONTACTUS_LOADING, payload: false });
        });
}



export const getBrowseContent = () => dispatch => {
    dispatch({ type: GET_PRODUCT_CONTENT_BROWSE_LOADING, payload: true });
    api.platformAPI
        .get('/api/platform/content/browse')
        .then(res => {
            dispatch({
                type: GET_PRODUCT_CONTENT_BROWSE, payload: res.data
            })
        })
        .catch(err => {
            dispatch(handleError(err))
        });
}

export const getProductContent = (productcode) => dispatch => {
    dispatch({ type: GET_PRODUCT_CONTENT_OVERVIEW_LOADING, payload: true });
    dispatch({ type: GET_PRODUCT_CONTENT_GETSTARTED_LOADING, payload: true });
    dispatch({ type: GET_PRODUCT_CONTENT_LEADS_LOADING, payload: true });
    api.platformAPI
        .get('/api/platform/content/product/' + productcode)
        .then(res => {
            dispatch({
                type: GET_PRODUCT_CONTENT_OVERVIEW, payload: res.data
            })
        })
        .catch(err => {
            dispatch(handleError(err))
        });
    api.platformAPI
        .get('/api/platform/content/product-get-started/' + productcode)
        .then(res => {
            dispatch({
                type: GET_PRODUCT_CONTENT_GETSTARTED, payload: res.data
            })
        })
        .catch(err => {
            dispatch(handleError(err))
        });
    api.platformAPI
        .get('/api/platform/content/product-leads/' + productcode)
        .then(res => {
            dispatch({
                type: GET_PRODUCT_CONTENT_LEADS, payload: res.data
            })
        })
        .catch(err => {
            dispatch(handleError(err))
        });
}


export const getOurStoryContent = () => dispatch => {
    dispatch({ type: GET_OURSTORY_CONTENT_LOADING, payload: true });
    api.platformAPI
        .get('/api/platform/content/ourstory')
        .then(res => {
            dispatch({
                type: GET_OURSTORY_CONTENT, payload: res.data
            })
        })
        .catch(err => {
            dispatch(handleError(err))
        });
}

export const getContactUsContent = () => dispatch => {
    dispatch({ type: GET_CONTACTUS_CONTENT_LOADING, payload: true });
    api.platformAPI
        .get('/api/platform/content/contactus')
        .then(res => {
            dispatch({
                type: GET_CONTACTUS_CONTENT, payload: res.data
            })
        })
        .catch(err => {
            dispatch(handleError(err))
        });
}





export const getCareersContent = () => dispatch => {
    dispatch({ type: GET_CAREERS_CONTENT_LOADING, payload: true });
    api.platformAPI
        .get('/api/platform/content/careers')
        .then(res => {
            dispatch({
                type: GET_CAREERS_CONTENT, payload: res.data
            })
        })
        .catch(err => {
            dispatch(handleError(err))
        });
}


export const getCustomerStoriesContent = () => dispatch => {
    dispatch({ type: GET_CUSTOMERSTORIES_CONTENT_LOADING, payload: true });
    api.platformAPI
        .get('/api/platform/content/customerstories')
        .then(res => {
            dispatch({
                type: GET_CUSTOMERSTORIES_CONTENT, payload: res.data
            })
        })
        .catch(err => {
            dispatch(handleError(err))
        });
}

export const getFAQContent = () => dispatch => {
    dispatch({ type: GET_FAQ_CONTENT_LOADING, payload: true });
    api.platformAPI
        .get('/api/platform/content/faq')
        .then(res => {
            dispatch({
                type: GET_FAQ_CONTENT, payload: res.data
            })
        })
        .catch(err => {
            dispatch(handleError(err))
        });
}

export const getPrivacyNoticeContent = () => dispatch => {
    dispatch({ type: GET_PRIVACYNOTICE_CONTENT_LOADING, payload: true });
    api.platformAPI
        .get('/api/platform/content/privacynotice')
        .then(res => {
            dispatch({
                type: GET_PRIVACYNOTICE_CONTENT, payload: res.data
            })
        })
        .catch(err => {
            dispatch(handleError(err))
        });
}

export const getTermsConditionsContent = () => dispatch => {
    dispatch({ type: GET_TERMSCONDITIONS_CONTENT_LOADING, payload: true });
    api.platformAPI
        .get('/api/platform/content/termsconditions')
        .then(res => {
            dispatch({
                type: GET_TERMSCONDITIONS_CONTENT, payload: res.data
            })
        })
        .catch(err => {
            dispatch(handleError(err))
        });
}


export const getNewsLetterContent = () => dispatch => {
    dispatch({ type: GET_NEWSLETTER_CONTENT_LOADING, payload: true });
    api.platformAPI
        .get('/api/platform/content/newsletter')
        .then(res => {
            dispatch({
                type: GET_NEWSLETTER_CONTENT, payload: res.data
            })
        })
        .catch(err => {
            dispatch(handleError(err))
        });
}

export const getSubPageContent = (pageType, slug) => dispatch => {
    dispatch({ type: SET_SUBPAGE_LOADING, payload: true });
    dispatch({ type: CLEAR_PAGE_ERRORS });
    api.platformAPI
        .get('/api/platform/content/sub-pages/' + pageType + '/' + slug)
        .then(res => {
            dispatch({
                type: GET_SUBPAGE, payload: res.data
            })
        })
        .catch(err => {
            dispatch(handleError(err))
            dispatch({ type: SET_SUBPAGE_LOADING, payload: false });
        });
}

export const getSubPages = (pageType) => dispatch => {
    dispatch({ type: SET_SUBPAGES_LOADING, payload: true });
    dispatch({ type: CLEAR_PAGE_ERRORS });
    api.platformAPI
        .get('/api/platform/content/sub-pages/' + pageType)
        .then(res => {
            dispatch({
                type: GET_SUBPAGES, payload: res.data
            })
        })
        .catch(err => {
            dispatch(handleError(err))
            dispatch({ type: SET_SUBPAGES_LOADING, payload: false });
        });
}

export const getPageContent = (slug) => dispatch => {
    dispatch({ type: SET_PAGE_LOADING, payload: true });
    dispatch({ type: CLEAR_PAGE_ERRORS });
    api.platformAPI
        .get('/api/platform/content/pages/' + slug)
        .then(res => {
            dispatch({
                type: GET_PAGE, payload: res.data
            })
        })
        .catch(err => {
            dispatch(handleError(err))
            dispatch({ type: SET_PAGE_LOADING, payload: false });
        });
}



export const getBanner = () => dispatch => {
    api.platformAPI
        .get('/api/platform/content/banner')
        .then(res => {
            dispatch({
                type: GET_BANNER, payload: res.data
            })
        })
        .catch(err => {
            dispatch(handleError(err))
        });
}



const handleError = (err) => dispatch => {
    let payload
    console.dir(err)

    //token expired
    if (err.response) {
        if (err.response.statusText === "Unauthorized" && err.response.status === 401 && err.response.data === "Unauthorized") {
            dispatch(logoutUser())
        }
    }

    if (isEmpty(err.response)) {
        payload = { error: err.message }
    } else {
        if (isEmpty(err.response.data)) {
            payload = { error: err.message }
        } else {
            payload = err.response.data
        }
    }

    dispatch({
        type: GET_PAGE_ERRORS,
        payload: payload
    })
}

export const clearProductErrors = () => dispatch => {
    dispatch({ type: CLEAR_PAGE_ERRORS })
}
