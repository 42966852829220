import 'babel-polyfill'
import 'raf/polyfill';
import 'matchmedia-polyfill'
import 'matchmedia-polyfill/matchMedia.addListener';
import 'classlist-polyfill' //needed for IE 9
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';

ReactDOM.render(<App />, document.getElementById('root'));

serviceWorker.unregister();
