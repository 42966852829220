import React, { Component } from 'react'
import { connect } from 'react-redux';
import { Link } from 'react-router-dom'
import { withRouter } from 'react-router-dom'
import Button from '../common/Button'
import TextFieldGroup from '../common/TextFieldGroup'
import {
    sendPLMBursary,
    clearContactUs
} from '../../actions/pagesActions'
import Card from '../layout/Card'
import PracticeUnboundYellow from "../../img/PracticeUnboundYellow.png";
import Checkbox from '../common/Checkbox'
import Background from '../../img/circles_bg.svg'
import Upload from '../common/Upload'

class PLMBursaryForm extends Component {
    constructor() {
        super()
        this.state = {
            name: '',
            email: '',
            contact: '',
            address1: '',
            address2: '',
            city: '',
            postcode: '',
            courseName: '',
            student: false,
            comments: '',
            file: null,
            checkSearchEngine: false,
            checkPU: false,
            checkClinicalEducation: false,
            checkSocialMedia: false,
            checkWordOfMouth: false,
            checkOther: false,
            otherComments: '',
        }
        this.onChange = this.onChange.bind(this)
        this.sendContact = this.sendContact.bind(this)
    }

    componentDidMount() {
        this.props.clearContactUs()
        window.scrollTo(0, 0)
    }
    componentDidUpdate(prevProps) {
        if (this.props.pages.contactUsSuccess && !prevProps.pages.contactUsSuccess) {
            setTimeout(() => {
                this.props.history.push('/')
            }, 1000)
        }
    }

    onChange(e) {
        if (e.target.type === "checkbox") {
            this.setState({ [e.target.name]: e.target.checked })
        } else {
            this.setState({ [e.target.name]: e.target.value })
        }
    }

    sendContact(e) {
        e.preventDefault()

        const data = {
            ...this.state,
            email: this.props.auth.isAuthenticated ? this.props.auth.user.email : this.state.email,
            name: this.props.auth.isAuthenticated && this.props.auth.user.firstName && this.props.auth.user.lastName ? `${this.props.auth.user.firstName} ${this.props.auth.user.lastName}` : this.state.name,
        }
        this.props.sendPLMBursary(data)

    }

    render() {

        const { firstName, lastName, email } = this.props.auth.user
        const { isAuthenticated } = this.props.auth
        const { errors, contactUsLoading, contactUsSuccess } = this.props.pages
        return (
            <div style={{
                backgroundImage: 'url("' + Background + '")',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                overflow: 'hidden',
                backgroundPosition: 'center',

            }}>
                <nav
                    className="navbar navbar-light bg-white justify-content-center"
                    id="navbar">

                    <Link to="/">
                        <img
                            alt="PracticeUnbound"
                            className="ml-lg-3"
                            style={{
                                maxWidth: "110px",
                                height: "38px",
                            }}
                            src={PracticeUnboundYellow}
                        />
                    </Link>

                </nav>
                <div className="container pb-5" >
                    <div className="row justify-content-center">
                        <div className="col-lg-8 col-md-10">
                            <Card className="mt-5">
                                <Button
                                    colour="none"
                                    underline
                                    small
                                    icon="back" onClick={this.props.history.goBack}>Back</Button>
                                <h2 className="text-center px-md-4 mt-3 mb-4"><strong>Bursary Application Form</strong></h2>
                                <p>
                                Approved full-time medical students will receive up to 50% off of the Prescribing Lifestyle Medicine Online Course when purchasing a single license. Subject to suitability and availability. Not applicable with any other offers.
                                    </p>
                                <p>
                                    To apply for the bursary, you must:
                                </p>
                                <ul>
                                    <li><p>Be intending to practice in NHS primary care</p></li>
                                    <li><p>Submit a short personal statement for review by Gilly's Gift trustees stating your interest in Lifestyle Medicine and how you intend to use this in practice.</p></li>
                                </ul>
                                <p>
                                    The bursary opportunity is kindly being provided by <a href="http://gillysgift.org/" target="_blank" rel="noopener noreferrer">Gilly’s Gift</a> which was established as a charity in November 2009 in recognition of Gillian’s (Gilly’s) recovery from cancer by way of an integrated approach.
</p>
                                <p>
                                    In October 2000 Gilly was diagnosed with advanced ovarian cancer and the prognosis was bleak. As all that conventional medicine could offer was a limited increase in life expectation with a dramatic decrease in quality of life, Gilly decided to take an holistic approach. While her progress was monitored by an oncologist, she undertook a program of diet, complementary therapies and self-help techniques under the supervision of an experienced integrated medicine doctor. Today Gilly is well and enjoying a full life, with her tumour markers in the normal range.
    </p>
                                <p>
                                    The aim of Gilly’s Gift is to widen access to integrated medicine for the whole community in the United Kingdom. It particularly aims to initiate change by influencing the training of medical professionals, thus widening access to doctor led integrated medicine and providing the best opportunities for health and wellness.
</p>
                                <p>
                                    You can expect to hear back regarding your bursary application within 1 week. If you have any questions, please contact our <Link to='/contact-us'>helpdesk</Link>.
</p>


                                <div className="row">
                                    <div className="col-md-6">
                                        {isAuthenticated && firstName && lastName ? (
                                            <TextFieldGroup
                                                label="Your name"
                                                name='name'
                                                value={`${firstName} ${lastName}`}
                                                readOnly
                                            />
                                        ) : (
                                                <TextFieldGroup
                                                    label="Your name"
                                                    name='name'
                                                    value={this.state.name}
                                                    onChange={this.onChange}
                                                    error={errors.name}
                                                />
                                            )}

                                    </div>
                                    <div className="col-md-6">
                                        {isAuthenticated ? (
                                            <TextFieldGroup
                                                label="Your email"

                                                name='email'
                                                value={email}
                                                readOnly
                                            />
                                        ) : (
                                                <TextFieldGroup
                                                    label="Your email"

                                                    name='email'
                                                    value={this.state.email}
                                                    onChange={this.onChange}
                                                    error={errors.email}
                                                />
                                            )}

                                    </div>
                                    <div className="col-md-6">
                                        <TextFieldGroup
                                            label="Your phone number"
                                            name='contact'
                                            value={this.state.contact}
                                            onChange={this.onChange}
                                            error={errors.contact}
                                        />
                                    </div>

                                    <div className="col-12">
                                        <TextFieldGroup
                                            label="Address Line 1"
                                            info="You need to be living or studying in the UK to be eligible for funding"
                                            name='address1'
                                            value={this.state.address1}
                                            onChange={this.onChange}
                                            error={errors.address1}
                                        />
                                    </div>

                                    <div className="col-12">
                                        <TextFieldGroup
                                            label="Address Line 2"
                                            name='address2'
                                            value={this.state.address2}
                                            onChange={this.onChange}
                                            error={errors.address2}
                                        />
                                    </div>

                                    <div className="col-md-6">
                                        <TextFieldGroup
                                            label="City"
                                            name='city'
                                            value={this.state.city}
                                            onChange={this.onChange}
                                            error={errors.city}
                                        />
                                    </div>


                                    <div className="col-md-6">
                                        <TextFieldGroup
                                            label="Postcode"
                                            name='postcode'
                                            value={this.state.postcode}
                                            onChange={this.onChange}
                                            error={errors.postcode}
                                        />
                                    </div>


                                    <div className="col-12">
                                        <Checkbox
                                            icon
                                            name="student"
                                            value={this.state.student}
                                            onChange={this.onChange}
                                            info="Bursary funding is only available for medical students"
                                            error={errors.student}
                                        >
                                            I confirm I am a student
                                                </Checkbox>
                                    </div>
                                    <div className="col-md-6">
                                        <TextFieldGroup
                                            label="Course Name"
                                            name='courseName'
                                            value={this.state.courseName}
                                            onChange={this.onChange}
                                            error={errors.courseName}
                                        />
                                    </div>



                                    <div className="col-md-12">
                                        <TextFieldGroup
                                            label="Comments"
                                            type="textarea"
                                            name='comments'
                                            value={this.state.comments}
                                            onChange={this.onChange}
                                            error={errors.comments}
                                        />
                                    </div>


                                    <div className="col-12">
                                        <Upload
                                            isImage={false}
                                            placeHolder="Click or drag application essay here"
                                            onChange={e => this.setState({ file: e })}
                                            value={this.state.file}
                                            error={errors.file}
                                        />
                                    </div>
                                    <div className="col-12">
                                        <p><strong>How did you hear about this opportunity?</strong></p>
                                        <Checkbox
                                            icon
                                            name="checkSearchEngine"
                                            value={this.state.checkSearchEngine}
                                            onChange={this.onChange}
                                        >
                                            Search Engine
                                                </Checkbox>
                                        <Checkbox
                                            icon
                                            name="checkPU"
                                            value={this.state.checkPU}
                                            onChange={this.onChange}
                                        >
                                            Practice Unbound
                                                </Checkbox>
                                        <Checkbox
                                            icon
                                            name="checkClinicalEducation"
                                            value={this.state.checkClinicalEducation}
                                            onChange={this.onChange}
                                        >
                                            Clinical Education
                                                </Checkbox>

                                        <Checkbox
                                            icon
                                            name="checkSocialMedia"
                                            value={this.state.checkSocialMedia}
                                            onChange={this.onChange}
                                        >
                                            Social Media
                                                </Checkbox>

                                        <Checkbox
                                            icon
                                            name="checkWordOfMouth"
                                            value={this.state.checkWordOfMouth}
                                            onChange={this.onChange}
                                        >
                                            Word of Mouth
                                                </Checkbox>

                                        <Checkbox
                                            icon
                                            name="checkOther"
                                            value={this.state.checkOther}
                                            onChange={this.onChange}
                                        >
                                            Other
                                                </Checkbox>

                                        {this.state.checkOther && (
                                            <TextFieldGroup
                                                label="Comments"
                                                name='otherComments'
                                                value={this.state.otherComments}
                                                onChange={this.onChange}
                                                error={errors.otherComments}
                                            />
                                        )}


                                    </div>





                                    <div className="col-12 mb-4" >
                                        <Button
                                            fixedWidth
                                            onClick={this.sendContact}
                                            center
                                            large
                                            done={contactUsSuccess}
                                            loading={contactUsLoading}
                                        >Submit</Button>
                                        <small className="text-danger">{errors.error}</small>
                                        <small className="text-danger">{Object.keys(errors).length > 0 && 'It looks like some fields are missing, please take a look at the highlighted fields above'}</small>

                                    </div>




                                </div>

                            </Card>
                        </div>
                    </div>
                </div>

            </div >
        )

    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    pages: state.pages
})
export default connect(mapStateToProps, {
    sendPLMBursary,
    clearContactUs
})(withRouter(PLMBursaryForm));