import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const Lock = () => {
    return (
        <span style={{ fontSize: '54px' }}>
            <FontAwesomeIcon icon="lock" />
        </span>
    )
}
export default Lock