import React, { Component } from 'react'
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import RadioButton from '../common/RadioButton'
import Button from '../common/Button'
import { submitLNA } from '../../actions/organisationActions'
import { setOrganisationProductMembershipActionSuccess } from '../../actions/organisationActions'

class LNA extends Component {
    constructor(props) {
        super(props)
        this.state = {
            questions: []
        }
        this.onChange = this.onChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);

    }
    componentDidMount() {
        this.props.setOrganisationProductMembershipActionSuccess(false)
        const isNetwork = this.props.organisations.organisationProductMembership.organisation.type === "Network"
        if (!isNetwork) {
            this.setState({
                questions: this.props.questions.filter(q => q.networkQuestion === false)
            })
        } else {
            this.setState({
                questions: this.props.questions
            })
        }
    }

    onChange(e) {

        const index = this.state.questions.map(q => q.questionName).indexOf(e.target.name)
        const newState = this.state.questions.map((q, key) => {
            if (key !== index) {
                return q
            } else {
                return {
                    ...q,
                    score: e.target.value
                }
            }
        })
        this.setState({ questions: newState })
    }


    onSubmit() {
        const data = {
            organisationProductMembership: this.props.organisations.organisationProductMembership._id,
            organisation: this.props.organisations.organisationProductMembership.organisation._id,
            product: this.props.organisations.organisationProductMembership.product.name,
            questions: this.state.questions
        }
        this.props.submitLNA(
            this.props.organisations.organisationProductMembership.organisation.code,
            this.props.organisations.organisationProductMembership._id,
            data
        )
    }

    render() {
        const { organisationProductMembershipActionLoading, organisationProductMembershipActionSuccess } = this.props.organisations
        const completed = this.state.questions.filter(q => q.score === undefined).length === 0
        const options = [
            { label: 'Strongly Disagree', value: 1 },
            { label: 'Disagree', value: 2 },
            { label: 'Unsure', value: 3 },
            { label: 'Agree', value: 4 },
            { label: 'Strongly Agree', value: 5 },
        ]
        return (
            <div style={{ marginLeft: '-10px', marginRight: '-10px' }} className="pb-5">
                {this.state.questions.map(q =>
                    <div key={q.questionNumber}>
                        <RadioButton
                            buttons
                            large
                            options={options}
                            selectedValue={q.score}
                            name={q.questionName}
                            label={q.questionName}
                            onChange={this.onChange}
                            center

                        />
                        <div style={{ width: '85%', marginLeft: 'auto', marginRight: 'auto', marginBottom: '50px' }}>
                            <hr />
                        </div>
                    </div>
                )}
                <Button
                    center
                    fixedWidth
                    loading={organisationProductMembershipActionLoading}
                    done={organisationProductMembershipActionSuccess}
                    disabled={!completed}
                    onClick={this.onSubmit}
                >
                    Submit
                </Button>
            </div>
        )
    }
}
LNA.propTypes = {
    questions: PropTypes.array.isRequired
};

const mapStateToProps = (state) => ({
    organisations: state.organisations,
})

export default connect(mapStateToProps, { submitLNA, setOrganisationProductMembershipActionSuccess })(LNA);