import React, { Component } from 'react'
import { connect } from 'react-redux';
import { getResourcesContentPage } from '../../actions/productActions'
import processRichText from '../../utils/processRichText'
import Spinner from '../common/Spinner'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import PUPanel from '../layout/PUPanel'

const Page = ({ page }) => {
    if (!page) { return null }
    if (!page.fields) { return null }
    const { sections } = page.fields



    return (
        // This is the main section header at top of screen
        <div>
            {page.fields.showPageTitle !== false && (
                <>
                    <h1 className="font-weight-bold display-4">{page.fields.title}</h1>
                    <h4 className="font-weight-bold mb-5">{page.fields.subtitle}</h4>
                </>
            )}

            {/* All other sections */}
            {sections && sections.map((s, key) => <Section key={key} section={s} />)}
        </div>


    )
}

// Under the main MenuItem header these are the displayed subItems (Introduction and test for Getting Started)
const SubSection = ({ section }) => {
    if (!section) { return null }
    if (!section.fields) { return null }
    if (!section.fields.content) { return null }
    if (!section.fields.content.content) { return null }
    return (
        <>
            <div id={section.fields.title}
                // This is for the offset of the anchor tag links
                style={{
                    display: 'block',
                    position: 'relative',
                    top: '-260px',
                    visibility: 'hidden'
                }}
            />
            <div className="mt-3" >
                <h4><strong>{section.fields.title}</strong></h4>
                {processRichText(section.fields.content.content)}
            </div>

        </>
    )
}

const Section = ({ section }) => {
    if (!section) { return null }
    if (!section.fields) { return null }
    if (!section.fields.content) { return null }
    if (!section.fields.content.content) { return null }
    return (
        <div>
            <PUPanel
                color={section.fields.colour}
                className='mb-5'
                title={section.fields.title}
                children={
                    <>
                        <div id={section.fields.title}
                            // This is for the offset of the anchor tag links
                            style={{
                                // paddingTop: '260px', 
                                // marginTop: '-260px',
                                display: 'block',
                                position: 'relative',
                                top: '-260px',
                                visibility: 'hidden'
                            }}
                        />
                        <div>
                            {processRichText(section.fields.content.content)}
                            {section.fields.subSections && section.fields.subSections.map((s, key) =>
                                <SubSection key={key} section={s} />
                            )}
                        </div>
                    </>
                }
            />
        </div >
    )
}


class ResourcesPage extends Component {
    constructor() {
        super()
        this.scrollToTag = this.scrollToTag.bind(this)
    }

    scrollToTag(id) {
        if (document.getElementById(id)) {
            document.getElementById(id).scrollIntoView({
                behavior: 'smooth'
            });
        }

    }

    componentDidMount() {
        //TODO - handle some errors here
        const pageList = this.props.products.resourcesContentList.fields.pages
        const page = pageList.filter(p => p.fields.slug === this.props.match.params.slug)[0]
        if (page) this.props.getResourcesContentPage(page.sys.id, this.props.products.product.name)
    }
    componentDidUpdate(prevProps) {
        if (this.props.match.params.slug !== prevProps.match.params.slug) {
            const pageList = this.props.products.resourcesContentList.fields.pages
            const page = pageList.filter(p => p.fields.slug === this.props.match.params.slug)[0]
            if (page) this.props.getResourcesContentPage(page.sys.id, this.props.products.product.name)
        }
    }


    render() {
        const pageList = this.props.products.resourcesContentList.fields.pages
        const pageCheck = pageList.filter(p => p.fields.slug === this.props.match.params.slug)[0]

        if (!pageCheck) return <div>Page not found</div>

        if (pageCheck.fields.public === false && this.props.products.product.purchased === false) {
            return (<div>
                You need to purchase this product to view this content
            </div>)
        }

        const content = this.props.products.resourcesContentPage
        if (Object.keys(content).length === 0 || this.props.products.resourcesContentPageLoading) {
            return (<Spinner block />)
        }

        // Right - hand side subMenu for each menu item
        const subMenu = (
            <div
                style={{
                    borderLeftColor: 'rgba(0, 0, 0, 0.1)',
                    borderLeftStyle: 'solid',
                    borderLeftWidth: '1px',

                }}>
                {content.fields.sections && content.fields.sections.map((section, key) => {
                    return (
                        <div key={key}>
                            <div
                                className="buttonLink tc-dark-yellow font-weight-bold ml-3"
                                style={{ marginBottom: '8px' }}
                                onClick={() => { this.scrollToTag(section.fields.title) }}
                            >
                                {section.fields.title}
                            </div>
                            <div className="ml-3">
                                {section.fields.subSections && section.fields.subSections.map((subSection, key2) => {
                                    return (
                                        <div
                                            key={key2}
                                            style={{ marginBottom: '8px' }}
                                            className="buttonLink tc-dark-yellow font-weight-bold ml-3"
                                            onClick={() => { this.scrollToTag(subSection.fields.title) }}>
                                            {subSection.fields.title}
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    )
                })}
            </div>
        )

        return (
            <div className="row">

                <div className="col-lg-8 col-xl-9">
                    <TransitionGroup appear={true}>
                        <CSSTransition
                            classNames="fadeRouter"
                            timeout={300}
                        >
                            <Page page={content} />
                        </CSSTransition>
                    </TransitionGroup>
                </div>
                {content.fields.showSubNavigation === true && (
                    <div className="col-lg-4 col-xl-3 d-none d-lg-block" >
                        <div style={{
                            position: 'sticky', top: '160px'
                        }}>
                            {subMenu}

                        </div>
                    </div>
                )}

            </div>
        )
    }
}
const mapStateToProps = state => ({
    products: state.products,
})
export default connect(mapStateToProps, { getResourcesContentPage })(ResourcesPage);