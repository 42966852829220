import React from 'react'
import { Chart } from "react-google-charts";
import Spinner from './Spinner'
import classnames from 'classnames'

const Frame = (props) => {
    return (
        <div
            className="d-flex align-items-center justify-content-center"
            style={{
                height: '420px',
                width: '100%',
            }}>
            <div>
                {props.children}
            </div>
        </div>
    )
}

const GoogleChart = (props) => {
    const loadingDiv = (<Frame><h2><Spinner large /></h2></Frame>)
    const noDataDiv = (<Frame><small className="text-muted">No Data</small></Frame>)

    let data = props.data.slice() || []

    let content
    if (props.loading === true) {
        content = loadingDiv
    } else if (data.length === 0) {
        content = noDataDiv
    } else {
        if (props.dataHeaders) {
            data.unshift(props.dataHeaders)
        }
        // console.log(data)
        let seriesOptions = data[0].map(d => d) === "Blank" ? { visibleInLegend: false } : { visibleInLegend: true }
        seriesOptions = { ...seriesOptions, ...props.seriesOptions, }
        // console.log(data)
        content = (
            <div>
                {props.title && <h4 className="text-center p-3"><strong>{props.title}</strong></h4>}
                <Chart
                    chartType={props.type}
                    loader={loadingDiv}
                    height={props.height}
                    data={data}
                    options={{
                        series: seriesOptions,
                        pieHole: 0.4,
                        height: '100%',
                        width: '100%',
                        backgroundColor: { fill: 'transparent' },
                        legend: {
                            position: props.legend ? "top" : "none",
                            alignment: 'center',
                            maxLines: 3,
                        },
                        bar: {
                            groupWidth: '80%',
                        },
                        isStacked: props.isStacked,
                        curveType: props.curveType,
                        lineWidth: 3,
                        colors: props.colors,
                        pieSliceText: 'value',
                        chartArea: {
                            left: props.chartAreaLeft ? props.chartAreaLeft : 60,
                            bottom: props.chartAreaBottom ? props.chartAreaBottom : 90,
                            top: 40,
                            right: props.padRight || props.type === "PieChart" ? 60 : 5,
                            width: '100%',
                        },
                        animation: {
                            duration: 400,
                            easing: 'out',
                            startup: true,
                        },
                        hAxis: {
                            slantedTextAngle: props.slantedTextAngle,
                            gridlines: {
                                color: props.vGridlines ? '#e5e5e5' : 'white',
                            },
                            type: 'date',
                            baselineColor: '#eaeaea',
                            title: props.xTitle,
                            titleTextStyle: {
                                italic: false
                            }
                        },
                        vAxis: {
                            gridlines: {
                                color: props.hGridlines ? '#e5e5e5' : 'white',
                            },
                            baselineColor: '#eaeaea',
                            format: props.format === "percent" ? 'percent' : null,
                            viewWindow: {
                                min: 0,
                                max: props.maxV
                            }
                        },
                        tooltip: {
                            showColorCode: true
                        },
                        fontName: 'Quicksand',
                        ...props.options
                    }}
                />
            </div>
        )
    }

    return (
        <div className={classnames({ "card": props.card }, "mb-3")}>
            {content}
        </div>
    )
}
GoogleChart.defaultProps = {
    height: 400,
    type: 'ColumnChart',
    isStacked: true,
    slantedTextAngle: 30,
    hGridlines: true,
    vGridlines: true,
    card: true,
    padRight: false,
    legend: false,
    colors: ['#16c194', '#f4b321', '#e04f9a', '#169f7b', '#be8c1a', '#b33f7b'],
    curveType: 'function'
}
export default GoogleChart