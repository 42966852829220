module.exports = (dataArray, categoryTitle, rowIndexIn, colIndexIn, dataIndexIn) => {

    if (!dataArray) return []
    if (dataArray.length === 0) return [] 

    //init variables
    var rowIndex = rowIndexIn || 0
    var colIndex = colIndexIn || 1
    var dataIndex = dataIndexIn || 2
    
    //Code from https://techbrij.com

    var result = {};

    var ret = [];

    var newCols = [];

 

    dataArray.forEach(d => {

        if (!result[d[rowIndex]]) {

            result[d[rowIndex]] = {};

        }

        result[d[rowIndex]][d[colIndex]] = d[dataIndex];

 

        //To get column names

        if (newCols.indexOf(d[colIndex]) === -1) {

            newCols.push(d[colIndex]);

        }

    })

 

    newCols.sort();

    var item = [];

 

    //Add Header Row

    item.push(categoryTitle || 'Item');

    item.push.apply(item, newCols);

    ret.push(item);

 

    //Add content

    for (var key in result) {

        item = [];

        item.push(key);

        for (var i = 0; i < newCols.length; i++) {

            item.push(result[key][newCols[i]] || 0);

        }

        ret.push(item);

    }

    //Tidy up header names

    ret[0] = ret[0].map(r => {

        if (!r) return 'Blank'

        return r

    })

    return ret;

}