import React, { Component } from 'react'
import TextFieldGroup from '../common/TextFieldGroup'
import Checkbox from '../common/Checkbox'
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { registerUser } from '../../actions/authActions'
import Button from '../common/Button'


class RegisterScreen extends Component {
    constructor() {
        super();
        this.state = {
            firstName: '',
            lastName: '',
            email: '',
            password: '',
            password2: '',
            terms: false,
            // externalMarketingConsent: false,
        };

        this.onChange = this.onChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }
    onChange(e) {
        if (e.target.type === "checkbox") {
            this.setState({ [e.target.name]: e.target.checked })
        } else {
            this.setState({ [e.target.name]: e.target.value })
        }
    }
    onSubmit(e) {
        e.preventDefault();
        const userData = {
            firstName: this.state.firstName,
            lastName: this.state.lastName,
            email: this.state.email,
            password: this.state.password,
            password2: this.state.password2,
            // externalMarketingConsent: this.state.externalMarketingConsent
        }

        this.props.registerUser(userData)
    }

    render() {
        const { auth } = this.props;
        const { errors } = auth;
        return (
            <div style={{ padding: '5px' }}>
                <form onSubmit={this.onSubmit}>
                    <div style={{ maxWidth: '300px' }} className="mx-auto">
                        <TextFieldGroup
                            placeholder="First Name"
                            name='firstName'
                            value={this.state.firstName}
                            onChange={this.onChange}
                            error={errors.firstName}
                        />
                        <TextFieldGroup
                            placeholder="Last Name"
                            name='lastName'
                            value={this.state.lastName}
                            onChange={this.onChange}
                            error={errors.lastName}
                        />
                        <TextFieldGroup
                            placeholder="Email Address"
                            name='email'
                            type='email'
                            value={this.state.email}
                            onChange={this.onChange}
                            error={errors.email}
                        />
                        <TextFieldGroup
                            placeholder="Password"
                            name='password'
                            type='password'
                            value={this.state.password}
                            onChange={this.onChange}
                            error={errors.password}
                        />
                        <TextFieldGroup
                            placeholder="Repeat password"
                            name='password2'
                            type='password'
                            value={this.state.password2}
                            onChange={this.onChange}
                            error={errors.password2}
                        />
                    </div>
                    <small className="d-block text-muted">Minimum of 8 characters</small>
                    <small className="d-block text-muted">Must contain both upper & lower case</small>
                    <small className="mb-3 d-block text-muted">Must contain both letters & numbers</small>
                    {/* <Checkbox
                        name="externalMarketingConsent"
                        value={this.state.externalMarketingConsent}
                        className="mb-3"
                        onChange={this.onChange}
                    >
                        I am happy for partners to contact me about other products I might be interested in
                </Checkbox> */}
                    <Checkbox
                        name="terms"
                        value={this.state.terms}
                        className="mb-3"
                        onChange={() => { this.setState({ terms: !this.state.terms }) }}
                    >I agree to the <a target="_blank" href='/terms-conditions'>terms and conditions</a></Checkbox>

                    <div className="mb-3">
                        <Button
                            fixedWidth
                            disabled={!this.state.terms}
                            loading={auth.loading}
                            type="submit"
                            className="mx-auto"
                        >Sign Up
                </Button>
                    </div>
                    <small className="text-danger">{errors.error}</small>
                </form>
            </div>
        )
    }
}

RegisterScreen.propTypes = {
    auth: PropTypes.object.isRequired,
}
const mapStateToProps = state => ({
    auth: state.auth,
})

export default connect(mapStateToProps, { registerUser })(RegisterScreen);