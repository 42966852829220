import React from 'react'
import styles from './DoubleBlock.module.css'
import classnames from 'classnames'
import DivFade from './DivFade'

const DoubleBlock = () => {
    const paragraph = 'Time is the most precious commodity in Primary Care today. Forward-thinking GP practices, PCNs and other providers use our e-learning programmes to deliver transformational change, improving practice efficiency, saving GP time and embedding digital-driven best practice. Outcomes include better individual patient care, more effecient use of GP resources and greater engagement from practice staff.'
    const header = 'Giving Primary Care More Time'
    return (
        <DivFade
            style={{
                backgroundColor: 'white', 
                // backgroundColor: 'purple', 
                position: 'relative'
            }}
        >

            {/* grey bubbles svg container */}
            <div className={styles.greyBubbles}></div> 
            {/* green bubbles svg container */}
            <div className={styles.greenBubbles}>
            </div> 
            <div
                className={styles.blueBackground}
            ></div>       
            <div className='container'>
    
                <div className='row' style={{position: 'relative'}}>
    
                    <div className={`col-md-6 pb-5 pr-5 ${styles.space} ${styles.left}`}>
                        <div className=''>
                            
                            <div style={{}}>
                                <div className={classnames("row justify-content-center text-left"
                                    // { 'text-center': fields.textAlign === "Center" },
                                    // { 'text-right': fields.textAlign === "Right" },
                                    // { 'text-left': fields.textAlign === "Left" }
                                )}>
                                    <div className="col-12 pl-1">
                                        <h1 
                                            style={{
                                                color: '#16c194', 
                                                letterSpacing: '.02rem',
                                                lineHeight: '2rem',
                                                maxWidth: '330px',
                                                // fontSize: 'clamp(16px, 20%, 40px'
                                                fontSize: 'clamp(26px,2vw,30px)'
                                            }} 
                                            className={`mb-4 mr-4`}>
                                                <strong>{header}</strong>
                                        </h1>
                                    </div>
                                </div>
                                {/* Paragraph */}
                                <div style={{
                                    // keep consistent whitespace between paragraph and button
                                    minHeight: '198px', 
                                    maxWidth: '400px'
                                }} className={classnames("row justify-content-center"
                                    // { 'text-center': fields.textAlign === "Center" },
                                    // { 'text-right': fields.textAlign === "Right" }
                                )}
                                >
                                    <div className={classnames(
                                        // { "col-lg-8": fields.textAlign === "Center" },
                                        // { "col-12": fields.textAlign !== "Center" },
                                    )}>
                                        <p>{paragraph}</p>
                                    </div>
                                </div>
                            </div>
                            {/* Header */}
                            {/* <div className='container mt-2'><Button small colour='green-outline'>Want to know more?</Button></div> */}
                            <div className='row mt-2 justify-content-center'>
                                {/* <div 
                                    className="col-12"
                                >
                                    <Button small colour='green-outline'>Want to know more?</Button>
                                </div> */}
                            </div>
                        </div>
                    </div>
    
                    <div className={`col-md-6 pb-5 ${styles.bottomLeftCurve} ${styles.space} ${styles.right}`} style={{backgroundColor: '#193958', color: 'white'}}>
                        <div className=''>
                            <div className={styles.blockTwo}>
                                {/* Header */}
                                <div
                                    className="row justify-content-center"
                                >
                                    <div className="col-12">
                                        <h1 
                                            className={`mb-4 ml-0`}
                                            style={{
                                                fontSize: 'clamp(26px,2vw,30px)'
                                            }}
                                        ><strong>Why Choose Us?</strong></h1>
                                    </div>
                                </div>
                                {/* Paragraph */}
                                <div style={{}} className={classnames("row justify-content-center"
                                    // { 'text-center': fields.textAlign === "Center" },
                                    // { 'text-right': fields.textAlign === "Right" }
                                )}
                                >
                                    <div className="col-12">
                                        {/* <span>{fields.paragraph && processRichText(fields.paragraph.content)}</span> */}
                                        <span>
                                            <ul 
                                                className={`${styles.list} tick-list`}
                                                // style={{
                                                //     fontWeight: 'bold', 
                                                //     marginBottom: '2.5rem'
                                                // }}                                            
                                            >
                                                <li>Programmes developed by Clinicians</li>
                                                <li>Robust governance and audit process</li>
                                                <li>Easy to read Data Dashboards</li>
                                                <li>Helpdesk access Monday - Friday</li>
                                                <li>Trusted provider, on the <a className={styles.link} href='https://www.england.nhs.uk/hssf/' target="_blank" rel="noreferrer noopener">HSS framework</a></li>
                                            </ul>
                                        </span>
                                    </div>
                                </div>

                            </div>
                            <div className='row mt-2 justify-content-center'>
                                {/* <div 
                                    className={`col-12 ${styles.blockTwo}`}
                                >
                                    <Button small colour='green-outline'>get in touch</Button>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </DivFade>
    )
}

export default DoubleBlock