import React from 'react'
import { Link } from 'react-router-dom'
import styles from './PageProductLandingPage.module.css'
import classnames from 'classnames'
import Lock from '../common/Lock'
import CheckIcon from '../common/CheckIcon'

const ToDo = (props) => {
    const content = (
        <div className="row justify-content-between justify-content-md-around p-2"
            style={{ position: 'relative', width: '100%' }}
        >
            <div className={classnames("col-12 text-center", { "col-md-3": !props.small }, { "col-md-1": props.small })}>
                <CheckIcon large={!props.small} checked={props.done} />
            </div>
            <div className={classnames("col-12 text-center text-md-left", { "col-md-9": !props.small }, { "col-md-11": props.small })}>
                {props.small ? (
                    <h5><strong>{props.title}</strong></h5>
                ) : (
                    <h4><strong>{props.title}</strong></h4>
                )}
                <p className={classnames("mb-0", { "lead": !props.small })}>
                    {props.text}
                </p>
                {/* {!props.last && <hr />} */}
            </div>

            {
                props.disabled && (
                    <div style={{
                        position: 'absolute',
                        backgroundColor: 'rgba(255,255,255,0.85)',
                        top: '0',
                        right: '0',
                        left: '0',
                        bottom: '0',
                    }}>
                        <div style={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)'
                        }} className="text-muted">
                            <div title={props.disabledText} className="text-center">
                                <Lock />
                            </div>
                        </div>
                    </div>
                )
            }
        </div >

    )
    if (props.done || props.disabled) { return <div className={styles.tickItem}>{content}</div> }

    if (props.link) {
        return (
            <div className={classnames([styles.tickItemActive], [styles.tickItem])}>
                <Link to={props.link || ''}>
                    {content}
                </Link>
            </div >
        )
    } else {
        return (
            <div className={classnames([styles.tickItem])}>
                {content}
            </div >
        )
    }

}
export default ToDo