import {
    GET_BLOGS,
    GET_BLOG,
    CLEAR_BLOG,
    SET_BLOG_LOADING,
    SET_BLOGS_LOADING,
    GET_BLOG_ERROR,
    CLEAR_BLOG_ERROR,

} from '../actions/types'

const initialState = {
    blog: {},
    blogLoading: false,
    blogs: {},
    blogsLoading: false,
    errors: {}
}

export default function blogReducer(state = initialState, action) {
    switch (action.type) {
        case GET_BLOG:
            return {
                ...state,
                blog: action.payload,
                blogLoading: false
            }
        case CLEAR_BLOG:
            return {
                ...state,
                blog: {},
                blogLoading: false
            }

        case GET_BLOGS:
            return {
                ...state,
                blogs: action.payload,
                blogsLoading: false
            }
        case SET_BLOG_LOADING:
            return {
                ...state,
                blogLoading: action.payload
            }
        case SET_BLOGS_LOADING:
            return {
                ...state,
                blogsLoading: action.payload
            }
        case GET_BLOG_ERROR:
            return {
                ...state,
                errors: action.payload
            }
        case CLEAR_BLOG_ERROR:
            return {
                ...state,
                errors: {}
            }
        default:
            return state;
    }
}
