import axios from 'axios';
import parameters from './parameters'

const axiosAPIs = {
    authAPI: axios.create({
        baseURL: parameters.authApiURI
    }),
    platformAPI: axios.create({
        baseURL: parameters.platformApiURI,
        headers: { Pragma: 'no-cache' } //this is an IE fix
    }),
    elearningAPI: axios.create({
        baseURL: parameters.elearningApiURI,
        headers: { Pragma: 'no-cache' } //this is an IE fix
    }),
}
export default axiosAPIs