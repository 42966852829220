import React, { Component } from 'react'
import { connect } from 'react-redux';

class NLSWorkflowProtocol extends Component {


  render() {
    return (
      <>

        <div className="container pt-5" style={{ position: 'relative' }}>
          <p>
            The Workflow Go Protocol is a flow chart detailing which letter types should by processed by which role within your Practice team and is one of the key foundations of implementation.
          </p>
          <p>
            You will need to build your own Practice specific Workflow Go Protocol. You can amend the Protocol over time, based on your Practice needs.
          </p>
          <p>
            You will be able to view your current Workflow Go Protocol below or create a new one if you haven’t yet done so. Please note only a Workflow Go GP Lead should amend the Workflow Go Protocol.
          </p>
          <p><a target="_blank" rel="noopener noreferrer" href="https://practiceunbound.blob.core.windows.net/documents/workflow-go-protocol-user-guide.pdf">Download the protocol userguide</a></p>
          <p><a target="_blank" rel="noopener noreferrer" href="https://practiceunbound.blob.core.windows.net/documents/workflow-go-example-protocol.pdf">Download an example</a></p>
        </div>
        <div className="container">
          {this.props.organisations.organisation && <iframe
            style={{
              width: '100%',
              height: '2000px',
              border: 'none'
            }}
            title="Workflow Protocol"
            src={`/workflow-protocol?org=${this.props.organisations.organisation.code}`}></iframe>}
        </div>
      </>
    )
  }
}

const mapStateToProps = (state) => ({
  organisations: state.organisations,
})

export default connect(mapStateToProps, {})(NLSWorkflowProtocol);