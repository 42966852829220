import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faDolly } from '@fortawesome/free-solid-svg-icons'
library.add(faDolly)

const ComingSoonIcon = (props) => {
    return (
        <span style={{ fontSize: props.large && '54px' }}>
            <FontAwesomeIcon icon="dolly" size="2x" />
        </span>
    )
}
export default ComingSoonIcon