import React from 'react'
import { useState } from 'react'
import Button from '../../../common/Button'
import { animateScroll as scroll } from "react-scroll";
import plmQuestions from './plmQuestions'
import PlmAssessmentFormSection from './PlmAssessmentFormSection'

export default function PlmAssessmentForm({ submitAssessment, showForm }) {

    const [inputData, setInputData] = useState(plmQuestions)
    const [validationErr, setValidationErr] = useState(false)

    // ------- Create array to assign form components name based on question number -------
    // ====================================================================================
    // const questionNames = ['', ...Object.keys(inputData)]

    // ============================ Submit form ===========================================
    // ====================================================================================


    const onSubmit = () => {
        const inputDataArr = Object.values(inputData)
        const answers = inputDataArr.map(question => {
            const { questionNumber, questionText, answer } = question
            return ({
                questionNumber: questionNumber,
                questionText: questionText,
                answerValue: answer
            })
        })

        const submit = () => {
            submitAssessment('prescribinglifestylemedicine', {
                eventDate: new Date(),
                answers
            })
            scroll.scrollToTop(100)
        }

        const formIsComplete = answers.map(answer => answer.answerValue).every(item => item)

        !formIsComplete ? setValidationErr(true) : submit()
    }
    // ====================================================================================

    // const inputDataArr = Object.values(inputData)

    const sectionOne = {
        questionOne: inputData.questionOne,
        questionTwo: inputData.questionTwo,
        questionThree: inputData.questionThree,
        questionFour: inputData.questionFour,
        questionFive: inputData.questionFive
    }

    const sectionTwo = {
        questionOne: inputData.questionSix,
        questionTwo: inputData.questionSeven,
        questionThree: inputData.questionEight,
        questionFour: inputData.questionNine,
        questionFive: inputData.questionTen
    }

    const sectionThree = {
        questionOne: inputData.questionEleven,
        questionTwo: inputData.questionTwelve,
        questionThree: inputData.questionThirteen,
        questionFour: inputData.questionFourteen,
        questionFive: inputData.questionFifteen
    }

    const sectionFour = {
        questionOne: inputData.questionSixteen,
        questionTwo: inputData.questionSeventeen,
        questionThree: inputData.questionEighteen,
        questionFour: inputData.questionNinteen,
        questionFive: inputData.questionTwenty
    }

    return (
        <div className='row'>
            {/* <TransitionGroup> */}
            {/* <CSSTransition
                    key="carousel"
                    classNames="slide-vertical"
                    // timeout={{ enter: 1000, exit: 1000 }}
                    in={showForm}
                >                 */}
            <PlmAssessmentFormSection
                sectionInputData={sectionOne}
                setInputData={setInputData}
                color="pink"
            />
            <PlmAssessmentFormSection
                sectionInputData={sectionTwo}
                setInputData={setInputData}
                color="yellow"
            />
            <PlmAssessmentFormSection
                sectionInputData={sectionThree}
                setInputData={setInputData}
                color="teal"
            />
            <PlmAssessmentFormSection
                sectionInputData={sectionFour}
                setInputData={setInputData}
                color="green"
            />

            <div style={{ marginBottom: '1.8rem' }}>
                <div className='row justify-content-center' style={{ height: '2rem' }}>
                    <div className='col'>
                        {validationErr && <p style={{ color: 'red', textAlign: 'center' }}>Oops! Please answer all of the questions.</p>}
                    </div>
                </div>
                <Button
                    onClick={() => {
                        onSubmit()
                    }}
                    // onClick={() => setValidationErr(!validationErr)}
                    center
                    large
                    colour="teal"
                >Submit</Button>
            </div>
            {/* </CSSTransition> */}
            {/* </TransitionGroup> */}
        </div>
    )
}
