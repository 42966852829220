import React, { Component } from "react";
import { connect } from "react-redux";
import Spinner from "../common/Spinner";
import { Redirect } from 'react-router-dom'
import ContentBlockPage from "../layout/ContentBlockPage";
import { Helmet } from "react-helmet";

class LeadsScreen extends Component {
    render() {

        const { productLeadsContentLoading } = this.props.pages;

        let currentOwner = false
        if (this.props.organisations.organisation) {
            if (this.props.auth.ownedOrganisations.includes(this.props.organisations.organisation.code)) {
                currentOwner = true
            }
        }
        if (!currentOwner && this.props.products.product && this.props.organisations.organisation) {
            return <Redirect to={`/product/${this.props.products.product.name}`} />
        }

        return (
            <div>
                {this.props.products.product && (
                    <Helmet>
                        <meta
                            name="twitter:image"
                            content={this.props.products.product.image} />
                        <meta name="twitter:card" content="summary_large_image" />
                    </Helmet>
                )}
                {productLeadsContentLoading ? (
                    <Spinner block large color="yellow" />
                ) : (
                        <ContentBlockPage content={this.props.pages.productLeadsContent} />
                    )}
            </div>
        );
    }
}
const mapStateToProps = state => ({
    products: state.products,
    organisations: state.organisations,
    auth: state.auth,
    pages: state.pages
});
export default connect(
    mapStateToProps,
    {}
)(LeadsScreen);
