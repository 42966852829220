import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import TextFieldGroup from '../common/TextFieldGroup'
import Checkbox from '../common/Checkbox'
import Button from '../common/Button'
import Validator from 'validator'
import api from '../../config/axios'
import parameters from '../../config/parameters'
import Select from '../common/Select'
import PUPanel from '../layout/PUPanel'

export default class StripeOneTime extends Component {
    constructor() {
        super()
        this.state = {
            purchaserEmail: '',
            purchaserFirstName: '',
            purchaserLastName: '',
            purchaserRole: {},
            purchaserRoleOther: '',
            users: [{ firstName: '', lastName: '', email: '', role: {}, roleOther: '' }],
            purchasingFor: '',
            billingSame: false,
            errors: { users: [] },
            loading: false
        }
        this.onChange = this.onChange.bind(this)
        this.onUserChange = this.onUserChange.bind(this)
        this.validate = this.validate.bind(this)
    }
    componentDidMount() {
        const sessionItem = sessionStorage.getItem(`purchase${this.props.product}`)
        if (sessionItem) {
            const json = JSON.parse(sessionItem)
            this.setState({ ...json, loading: false, errors: { users: [] } })
        }
    }


    validate() {
        const { purchaserEmail, purchaserFirstName, purchaserLastName, users, purchaserRole } = this.state
        const userTemplate = users.map(u => { return {} })
        let errors = { users: userTemplate }
        if (!purchaserEmail) errors.purchaserEmail = 'Email is required'
        if (purchaserEmail) {
            if (!Validator.isEmail(purchaserEmail)) errors.purchaserEmail = 'Invalid email'
        }
        if (!purchaserFirstName) errors.purchaserFirstName = 'First name is required'
        if (!purchaserLastName) errors.purchaserLastName = 'Last name is required'
        if (!purchaserRole.value) errors.purchaserRole = 'Role is required'

        users.forEach((u, i) => {
            if (!u.email) errors.users[i].email = 'Email required'
            if (!Validator.isEmail(u.email)) errors.users[i].email = 'Invalid email'

            if (!u.firstName) errors.users[i].firstName = 'First Name required'
            if (!u.lastName) errors.users[i].lastName = 'Last Name required'
            if (!u.role || !u.role.value || (u.role.value === 'other' && !u.roleOther)) errors.users[i].role = 'Role required'

            if (this.props.dob && !u.dob) errors.users[i].dob = 'Date of birth required'
        })

        this.setState({ errors: errors }, () => {
            if (Object.keys(errors).filter(e => e !== 'users').length === 0 && errors.users.filter(u => Object.keys(u).length > 0).length === 0) {
                // console.log(this.state)
                this.submit()
            }
        })
    }

    submit() {
        this.setState({ loading: true })
        api.platformAPI.post(`${parameters.platformApiURI}/api/platform/stripe/getStripeSession`, { ...this.state, product: this.props.product })
            .then(response => {
                console.log(response)
                if (response.data) {
                    if (response.data.url) {
                        window.location.replace(response.data.url)
                    }
                }
            })
            .catch(err => {
                console.dir(err)
                if (err.response) {
                    this.setState({ errors: { error: err.response.data }, loading: false })
                } else {
                    return this.setState({ errors: { error: err.message }, loading: false })
                }

            })
    }

    onChange(e) {
        if (e.target.type === "checkbox") {
            this.setState({ [e.target.name]: e.target.checked })
        } else {
            this.setState({ [e.target.name]: e.target.value })
        }
    }
    onUserChange(name, value, i) {
        const newArr = [...this.state.users]
        newArr[i][name] = value
        this.setState({ users: newArr })
    }



    componentDidUpdate() {
        sessionStorage.setItem(`purchase${this.props.product}`, JSON.stringify(this.state))
    }

    render() {
        return (
            <>
                <div className="container pt-5">
                    <PUPanel title="How are you purchasing?" color="blue">
                        <div className="row mb-3">
                            <div className="col-12">
                                <Checkbox
                                    icon
                                    color="blue"
                                    value={this.state.purchasingFor === 'me'}
                                    onChange={e => {
                                        this.setState({ users: [{ firstName: this.state.purchaserFirstName, lastName: this.state.purchaserLastName, email: this.state.purchaserEmail }], purchasingFor: e.target.checked ? 'me' : '' })
                                    }}
                                >
                                    I'm just purchasing for me
                                </Checkbox>
                            </div>
                            <div className="col-12">
                                <Checkbox
                                    icon
                                    color="blue"
                                    value={this.state.purchasingFor === 'someone else'}
                                    onChange={(e) => {
                                        this.setState({
                                            purchasingFor: e.target.checked ? 'someone else' : '', billingSame: false,
                                            users: [{ ...this.state.users[0] }]
                                        })
                                    }}
                                >
                                    I'm purchasing for somebody else
                                </Checkbox>
                            </div>
                            <div className="col-12">
                                <Checkbox
                                    icon
                                    color="blue"
                                    value={this.state.purchasingFor === 'multiple'}
                                    onChange={(e) => {
                                        this.setState({ purchasingFor: e.target.checked ? 'multiple' : '', billingSame: false })
                                    }}
                                >
                                    I'm purchasing for multiple users
                                </Checkbox>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-12">
                                <h6 className="mb-0"><strong>Billing Account:</strong></h6>
                            </div>
                            <div className="col-6">
                                <TextFieldGroup
                                    label="First Name"
                                    name='purchaserFirstName'
                                    value={this.state.purchaserFirstName}
                                    onChange={e => {
                                        e.persist()
                                        if (this.state.billingSame) {
                                            this.setState({
                                                purchaserFirstName: e.target.value,
                                            }, () => {
                                                this.onUserChange('firstName', e.target.value, 0)
                                            })
                                        } else {
                                            this.setState({ purchaserFirstName: e.target.value })
                                        }
                                    }}
                                    error={this.state.errors.purchaserFirstName}
                                />
                            </div>
                            <div className="col-6">
                                <TextFieldGroup
                                    label="Last Name"
                                    name='purchaserLastName'
                                    value={this.state.purchaserLastName}
                                    onChange={e => {
                                        e.persist()
                                        if (this.state.billingSame) {
                                            this.setState({
                                                purchaserLastName: e.target.value,
                                            }, () => {
                                                this.onUserChange('lastName', e.target.value, 0)
                                            })
                                        } else {
                                            this.setState({ purchaserLastName: e.target.value })
                                        }
                                    }}
                                    error={this.state.errors.purchaserLastName}
                                />
                            </div>
                            <div className="col-6">
                                <TextFieldGroup
                                    label="Email"
                                    name='purchaserEmail'
                                    value={this.state.purchaserEmail}
                                    onChange={e => {
                                        e.persist()
                                        if (this.state.billingSame) {
                                            this.setState({
                                                purchaserEmail: e.target.value,
                                            }, () => {
                                                this.onUserChange('email', e.target.value, 0)
                                            })
                                        } else {
                                            this.setState({ purchaserEmail: e.target.value })
                                        }
                                    }}
                                    error={this.state.errors.purchaserEmail}
                                />
                            </div>
                            <div className="col-6">
                                <DropdownComp
                                    selected={this.state.purchaserRole || {}}
                                    other={this.state.purchaseRoleOther || ''}
                                    error={this.state.errors.purchaserRole}
                                    onRoleChange={(r) => {
                                        if (this.state.billingSame) {
                                            this.setState({
                                                purchaserRole: r
                                            }, () => {
                                                this.onUserChange('role', r, 0)
                                            })
                                        } else {
                                            this.setState({ purchaserRole: r })
                                        }
                                    }}
                                    onRoleOtherChange={(r) => {
                                        if (this.state.billingSame) {
                                            this.setState({
                                                purchaseRoleOther: r
                                            }, () => {
                                                this.onUserChange('roleOther', r, 0)
                                            })
                                        } else {
                                            this.setState({ purchaseRoleOther: r })
                                        }

                                    }}
                                />
                                <small className="text-muted">Select the option which best describes your role.</small>
                                <small className="text-muted">If you cannot find your role within the options available please select ‘Other (please specify)’ or contact us on info@practiceunbound.org.uk</small>

                            </div>

                            {this.state.purchasingFor === 'me' && (
                                <div className="col-12">
                                    <Checkbox
                                        icon
                                        color="blue"
                                        value={this.state.billingSame}
                                        onChange={(e) => {
                                            if (e.target.checked) {
                                                this.setState({ billingSame: true }, () => {
                                                    this.onUserChange('firstName', this.state.purchaserFirstName, 0)
                                                    this.onUserChange('lastName', this.state.purchaserLastName, 0)
                                                    this.onUserChange('email', this.state.purchaserEmail, 0)
                                                    this.onUserChange('role', this.state.purchaserRole, 0)
                                                    this.onUserChange('roleOther', this.state.purchaserRoleOther, 0)
                                                })
                                            } else {
                                                this.setState({ billingSame: false }, () => {
                                                    this.onUserChange('firstName', '', 0)
                                                    this.onUserChange('lastName', '', 0)
                                                    this.onUserChange('email', '', 0)
                                                    this.onUserChange('role', {}, 0)
                                                    this.onUserChange('roleOther', '', 0)
                                                })
                                            }

                                        }}

                                    >
                                        My billing details and user details are the same
                                    </Checkbox>
                                </div>
                            )}

                            {this.props.dob && this.state.purchasingFor === 'me' && this.state.billingSame && (
                                <div className="col-12">
                                    <DatePicker
                                        title="Date of Birth"
                                        subtitle="Date of birth is required by our certification partner and acts as confirmation that you are 18 or over."
                                        value={this.state.users[0].dob}
                                        onChange={(e) => this.onUserChange('dob', e, 0)}
                                        error={this.state.errors.users[0] && this.state.errors.users[0].dob}
                                    />
                                </div>
                            )}
                            {this.props.postNomLetters && this.state.purchasingFor === 'me' && this.state.billingSame && (
                                <div className="col-12">
                                    <small className="d-block mt-3">Please ensure your name is entered exactly as you would like it to appear on your certificate. Please add any post nominal letters you wish to include below.</small>
                                    <div style={{ maxWidth: '170px' }}>
                                        <TextFieldGroup
                                            label="Post Nominal Letters"
                                            name='postNomLetters'
                                            value={this.state.users[0].postNomLetters}
                                            onChange={(e) => this.onUserChange('postNomLetters', e.target.value, 0)}
                                            error={this.state.errors.users[0] && this.state.errors.users[0].postNomLetters}
                                        />
                                    </div>
                                </div>
                            )}

                            <div className="col-12" style={{ minHeight: '32px' }}>
                                {this.state.purchasingFor === 'multiple' && !this.state.users.map(u => u.email).includes(this.state.purchaserEmail) && (

                                    <Button
                                        small
                                        colour="orange"
                                        onClick={() => {
                                            const newArr = [...this.state.users]
                                            newArr.unshift({
                                                firstName: this.state.purchaserFirstName,
                                                lastName: this.state.purchaserLastName,
                                                email: this.state.purchaserEmail,
                                                role: this.state.purchaserRole,
                                                roleOther: this.state.purchaserRoleOther
                                            })
                                            this.setState({ users: newArr })
                                        }}>Add Me as a user</Button>

                                )}
                            </div>
                        </div>


                    </PUPanel>

                    {this.state.purchasingFor && (
                        <>
                            {!this.state.billingSame && this.state.purchasingFor && (
                                <div>

                                    {this.state.users.map((u, i) => <div style={{ position: 'relative' }} key={i}>
                                        <PUPanel color="blue" title={this.state.purchasingFor === 'me' ? 'Your user details' : this.state.purchasingFor === 'someone else' ? 'Who is the account for?' : `User ${i + 1}`}>
                                            <div className="row" >
                                                <div className="col-6">
                                                    <TextFieldGroup
                                                        label={`First Name`}
                                                        name='firstName'
                                                        disabled={this.state.billingSame}
                                                        value={this.state.users[i].firstName}
                                                        onChange={(e) => this.onUserChange(e.target.name, e.target.value, i)}
                                                        error={this.state.errors.users[i] && this.state.errors.users[i].firstName}
                                                    />
                                                </div>
                                                <div className="col-6">
                                                    <TextFieldGroup
                                                        label={`Last Name`}
                                                        name='lastName'
                                                        disabled={this.state.billingSame}
                                                        value={this.state.users[i].lastName}
                                                        onChange={(e) => this.onUserChange(e.target.name, e.target.value, i)}
                                                        error={this.state.errors.users[i] && this.state.errors.users[i].lastName}
                                                    />
                                                </div>
                                                <div className="col-6">
                                                    <TextFieldGroup
                                                        label={`Email`}
                                                        name='email'
                                                        disabled={this.state.billingSame}
                                                        value={this.state.users[i].email}
                                                        onChange={(e) => this.onUserChange(e.target.name, e.target.value, i)}
                                                        error={this.state.errors.users[i] && this.state.errors.users[i].email}
                                                    />
                                                </div>
                                                <div className="col-6">
                                                    <DropdownComp
                                                        selected={this.state.users[i].role || {}}
                                                        other={this.state.users[i].roleOther || ''}
                                                        onRoleChange={(state) => this.onUserChange('role', state, i)}
                                                        onRoleOtherChange={(state) => this.onUserChange('roleOther', state, i)}
                                                        error={this.state.errors.users[i] && this.state.errors.users[i].role}
                                                    />

                                                </div>
                                                {this.props.dob && <div className="col-12">
                                                    <DatePicker
                                                        title="Date of Birth"
                                                        subtitle="Date of birth is required by our certification partner and acts as confirmation that you are 18 or over."
                                                        value={this.state.users[i].dob}
                                                        onChange={(e) => this.onUserChange('dob', e, i)}
                                                        error={this.state.errors.users[i] && this.state.errors.users[i].dob} />
                                                </div>}
                                                {this.props.postNomLetters && <div className="col-12">
                                                    <small className="d-block mt-3">Please ensure the name is entered exactly as you would like it to appear on your certificate. Please add any post nominal letters you wish to include below.</small>
                                                    <div style={{ maxWidth: '170px' }}>
                                                        <TextFieldGroup
                                                            label="Post Nominal Letters"
                                                            name='postNomLetters'
                                                            value={this.state.users[i].postNomLetters}
                                                            onChange={(e) => this.onUserChange('postNomLetters', e.target.value, i)}
                                                            error={this.state.errors.users[i] && this.state.errors.users[i].postNomLetters}
                                                        />
                                                    </div>
                                                </div>}

                                                {this.state.users.length > 1 && this.state.purchasingFor === 'multiple' && (
                                                    <button
                                                        type="button"
                                                        className="close"
                                                        aria-label="Close"
                                                        style={{ position: 'absolute', top: '30px', right: '24px', zIndex: 10001 }}
                                                        onClick={() => {
                                                            const newArr = [...this.state.users]
                                                            newArr.splice(i, 1)
                                                            this.setState({ users: newArr })
                                                        }}>
                                                        <span aria-hidden="true">&times;</span>
                                                    </button>
                                                )}
                                            </div>
                                        </PUPanel>
                                    </div>
                                    )}

                                    {this.state.purchasingFor === 'multiple' && (
                                        <>
                                            <div className="row mb-3">
                                                <div className="col-12 text-center">
                                                    <Button
                                                        colour="orange"
                                                        inline
                                                        size="small"
                                                        onClick={() => {
                                                            this.setState(prevState => ({
                                                                users: [...prevState.users, { firstName: '', lastName: '', email: '' }]
                                                            }))
                                                        }}
                                                    >Add User</Button>
                                                </div>
                                            </div>
                                        </>
                                    )}


                                </div>
                            )}

                            <div className="row mb-5">
                                <div className="col-sm-3"></div>
                                <div className="col-sm-6 py-4 justify-content-center d-flex flex-column align-items-center">

                                    <Checkbox
                                        icon
                                        color="blue"
                                        value={this.state.agree}
                                        onChange={(e) => {
                                            this.setState({ agree: e.target.checked })
                                        }}
                                    >
                                        I agree to the <Link target='_blank' to='/terms-conditions'>terms and conditions</Link> and <Link target='_blank' to='/privacy-notice'>privacy policy</Link>
                                    </Checkbox>

                                    <Button disabled={!this.state.agree} className="mb-3" colour="orange" loading={this.state.loading} onClick={this.validate} large center>Checkout{this.state.purchasingFor === 'multiple' && ` ${this.state.users.length} ${this.state.users.length === 1 ? 'licence' : 'licences'}`}</Button>

                                </div>
                                {this.state.purchasingFor === "multiple" && this.props.product === 'prescribinglifestylemedicine' && (
                                    <div className="col-sm-3 justify-content-center d-flex"><Savings licences={this.state.users.length} /></div>
                                )}

                            </div>
                        </>
                    )}



                </div>

            </>
        )
    }

}

const DropdownComp = props => {

    if (props.selected.value === 'other') {
        return (
            <div style={{ position: 'relative' }}>
                <div style={{ position: 'absolute', left: '-17px', bottom: '30px' }}><Button
                    onClick={() => {
                        props.onRoleChange({})
                    }}
                    icon="back" colour='none' small></Button></div>

                <TextFieldGroup
                    error={props.error}
                    label="Role"
                    name="Role"
                    placeholder='Other (please specify)'
                    onChange={e => {
                        props.onRoleOtherChange(e.target.value)
                    }}
                    value={props.other}
                />
            </div>
        )
    }

    return (
        <Select
            error={props.error}
            label="Role"
            value={props.selected}
            onChange={e => {
                if (e.value !== 'other') {
                    props.onRoleOtherChange('')
                }
                props.onRoleChange(e)
            }}
            options={[
                { label: 'Acupuncturist', value: 'Acupuncturist' },
                { label: 'Advanced Nurse Practitioner', value: 'nurse' },
                { label: 'Chiropractor', value: 'Chiropractor' },
                { label: 'Clinical Consultant', value: 'NHS Contact' },
                { label: 'Clinical Director', value: 'Clinical Director' },
                { label: 'Clinical Mental Health ', value: 'ARRS-Mental Health Practitioner' },
                { label: 'Consultant Anaesthetist', value: 'Consultant Anaesthetist' },
                { label: 'Dental hygienist', value: 'Dental hygienist' },
                { label: 'Dentist', value: 'Dentist' },
                { label: 'Dietician - all', value: 'ARRS-Dieticians' },
                { label: 'Doctor', value: 'GP' },
                { label: 'First Contact Practitioner', value: 'ARRS-First Contact Physiotherapists' },
                { label: 'GP- all', value: 'GP' },
                { label: 'Health and Wellbeing Coach', value: 'ARRS-Health and Wellbeing Coach' },
                { label: 'Health Care Worker', value: 'Health Care Worker' },
                { label: 'Herbalist', value: 'Herbalist' },
                { label: 'Homeopath', value: 'Homeopath' },
                { label: 'Nurse - all', value: 'Nurse' },
                { label: 'Nutritional Therapist', value: 'Nutritional Therapist' },
                { label: 'Nutritionist ', value: 'Nutritionist ' },
                { label: 'Occupational therapist', value: 'ARRS-Occupational therapists' },
                { label: 'Optometrist', value: 'Optometrist' },
                { label: 'Osteopath', value: 'Osteopath' },
                { label: 'Other', value: 'other' },
                { label: 'Personal trainer', value: 'Personal trainer' },
                { label: 'Pharmacist', value: 'ARRS-Clinical Pharmacist' },
                { label: 'Physician', value: 'GP' },
                { label: 'Physician Associate', value: 'ARRS-Physician Associate' },
                { label: 'Physiologist', value: 'Physiologist' },
                { label: 'Physiotherapist', value: 'ARRS-First Contact Physiotherapists' },
                { label: 'Podiatrist', value: 'Podiatrist' },
                { label: 'Psychiatrist', value: 'Psychiatrist' },
                { label: 'Psychologist ', value: 'Psychologist ' },
                { label: 'Psychotherapist', value: 'Psychotherapist' },
                { label: 'Public Health - all', value: 'Public Health - all' },
                { label: 'Radiographer', value: 'Radiographer' },
                { label: 'Reflexologist', value: 'Reflexologist' },
                { label: 'Social Prescriber', value: 'ARRS-Social Prescribing Link Worker' },
                { label: 'Sonographer', value: 'Sonographer' },
                { label: 'Speech and Language Therapist', value: 'Speech and Language Therapist' },
                { label: 'Student - Medical', value: 'Student - Medical' },
                { label: 'Student other', value: 'Student other' },

            ]}

        />
    )
}


const Savings = ({ licences }) => {

    const SavingsCircle = props => {
        return <div className="d-flex flex-column align-items-center bg-dark-mint" style={{ borderRadius: '50%', height: '120px', width: '120px', color: 'white', fontWeight: 'bold', lineHeight: 1 }}>
            {props.children}
        </div>
    }
    if (licences < 5) {
        return <SavingsCircle>
            <div style={{ fontSize: '12px', marginTop: '12px' }}>Add</div>
            <div style={{ fontSize: '40px' }}>{5 - licences}</div>
            <div style={{ fontSize: '12px' }}>more users</div>
            <div style={{ fontSize: '17px' }}>save 10%!</div>
        </SavingsCircle>
    }
    if (licences < 10) {
        return <SavingsCircle>
            <div style={{ fontSize: '12px', marginTop: '12px' }}>You are</div>
            <div style={{ fontSize: '12px' }}>saving 10%!</div>
            <div style={{ fontSize: '32px' }}>Add {10 - licences}</div>
            <div style={{ fontSize: '12px' }}>more users</div>
            <div style={{ fontSize: '17px' }}>save 15%!</div>
        </SavingsCircle>
    }
    if (licences >= 10) {
        return <SavingsCircle>
            <div style={{ fontSize: '12px', marginTop: '34px' }}>You are saving</div>
            <div style={{ fontSize: '40px' }}>15%</div>

        </SavingsCircle>
    }
    return null

}

class DatePicker extends Component {
    constructor() {
        super()
        this.state = {
            day: {},
            month: {},
            year: {}
        }
    }
    componentDidMount() {

        if (this.props.value) {

            const valueYear = new Date(this.props.value).getFullYear()
            const valueMonth = new Date(this.props.value).getMonth() + 1
            const valueDay = new Date(this.props.value).getDate()
            const yearOption = this.years().filter(y => y.int === valueYear)[0]
            const monthOption = this.months.filter(m => m.int === valueMonth)[0]
            const dayOption = this.days.filter(d => d.int === valueDay)[0]

            this.setState({
                day: dayOption,
                month: monthOption,
                year: yearOption,
            })
        }

    }

    years = () => {
        const endYear = new Date().getFullYear() - 12
        let i = 1900
        let options = []
        while (i <= endYear) {
            options.unshift({ label: String(i), value: String(i), int: i })
            i++;
        }
        return options
    }

    months = [
        { label: 'January', value: '01', int: 1 },
        { label: 'February', value: '02', int: 2 },
        { label: 'March', value: '03', int: 3 },
        { label: 'April', value: '04', int: 4 },
        { label: 'May', value: '05', int: 5 },
        { label: 'June', value: '06', int: 6 },
        { label: 'July', value: '07', int: 7 },
        { label: 'August', value: '08', int: 8 },
        { label: 'September', value: '09', int: 9 },
        { label: 'October', value: '10', int: 10 },
        { label: 'November', value: '11', int: 11 },
        { label: 'December', value: '12', int: 12 }
    ]

    days = [
        { label: '1', value: '01', int: 1 },
        { label: '2', value: '02', int: 2 },
        { label: '3', value: '03', int: 3 },
        { label: '4', value: '04', int: 4 },
        { label: '5', value: '05', int: 5 },
        { label: '6', value: '06', int: 6 },
        { label: '7', value: '07', int: 7 },
        { label: '8', value: '08', int: 8 },
        { label: '9', value: '09', int: 9 },
        { label: '10', value: '10', int: 10 },
        { label: '11', value: '11', int: 11 },
        { label: '12', value: '12', int: 12 },
        { label: '13', value: '13', int: 13 },
        { label: '14', value: '14', int: 14 },
        { label: '15', value: '15', int: 15 },
        { label: '16', value: '16', int: 16 },
        { label: '17', value: '17', int: 17 },
        { label: '18', value: '18', int: 18 },
        { label: '19', value: '19', int: 19 },
        { label: '20', value: '20', int: 20 },
        { label: '21', value: '21', int: 21 },
        { label: '22', value: '22', int: 22 },
        { label: '23', value: '23', int: 23 },
        { label: '24', value: '24', int: 24 },
        { label: '25', value: '25', int: 25 },
        { label: '26', value: '26', int: 26 },
        { label: '27', value: '27', int: 27 },
        { label: '28', value: '28', int: 28 },
        { label: '29', value: '29', int: 29 },
        { label: '30', value: '30', int: 30 },
        { label: '31', value: '31', int: 31 },
    ]

    componentDidUpdate(prev) {
        // TODO - validate date before on

        const year = this.state.year.value
        const month = this.state.month.value
        const day = this.state.day.value
        const currentDate = year + '-' + month + '-' + day



        if (year && month && day && (prev.value !== currentDate)) {
            this.props.onChange(year + '-' + month + '-' + day)
        }
    }

    render() {
        return <>
            <p className="pl-1" style={{ fontWeight: 'bold', marginBottom: '0px' }}>{this.props.title}</p>
            {this.props.subtitle && <small className="pl-1">{this.props.subtitle}</small>}
            <div className='d-flex flex-row flex-wrap'>
                <div className='mr-2' style={{ width: '72px' }}>
                    <Select
                        label="Day"
                        value={this.state.day}
                        onChange={(e) => this.setState({ day: e })}
                        options={this.days}

                    />

                </div>
                <div className='mr-2' style={{ width: '130px' }}>
                    <Select
                        label="Month"
                        value={this.state.month}
                        onChange={(e) => this.setState({ month: e })}
                        options={this.months}

                    />

                </div>
                <div style={{ width: '90px' }}>
                    <Select
                        label="Year"
                        value={this.state.year}
                        onChange={(e) => this.setState({ year: e })}
                        options={this.years()}

                    />

                </div>

            </div>
            <div style={{ marginTop: '-21px' }}><small className="text-danger">{this.props.error}</small></div>
        </>
    }
}

