import React, { Component } from 'react'
import SideNavItem from './SideNavItem'
import '../AdminEnviroment.css'
import BarsIcon from '../../common/BarsIcon'



class SideNav extends Component {

    render() {

        const listItem = this.props.listItems.map((li, index) =>
            <SideNavItem
                toggleSideNav={this.props.toggleSideNav}
                closeSideNavs={this.props.closeSideNavs}
                key={index} open={this.props.sidebarOpen}
                {...li}
                isMobile={this.props.isMobile} />
        );

        return (
            <nav
                role="navigation"
                aria-label="Sidebar admin navigation menu"
                className={this.props.sidebarOpen ? "sidebar" : "sidebar-small"}
                style={{
                    transition: 'width 0.15s ease-out',
                    height: 'calc(100% - 54px)',
                    background: '#fff',
                    // opacity: '0.8',
                    boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.3)',
                    color: 'black',
                    position: 'fixed',
                    top: '54px',
                    bottom: '0px',
                    listStyleType: 'none',
                    textDecoration: 'none !important',
                }}
            >
                <div style={{ positiom: 'fixed' }}>
                    <div>
                        <button
                            type="button"
                            className="btn mt-2 ml-1 mb-2" onClick={this.props.toggleSideNav}
                            style={{ color: 'black' }}>
                            <BarsIcon />
                        </button>
                        <div style={{
                            display: !this.props.sidebarOpen && 'none',
                            width: '230px',
                            margin: '14px'
                        }}>
                            {this.props.headerComponent}
                        </div>
                    </div>

                    {listItem}
                </div>
            </nav>
        )
    }
}

export default SideNav;