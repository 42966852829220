import React from 'react'
import Logo from '../../img/PracticeUnboundYellow.png'

export default function LoginSplash(props) {
    return (
        <div className="container">
            <div className="row justify-content-center py-5">
                <div className="card col-12 col-sm-9 col-md-8 col-lg-7 col-xl-6">
                    <div className="card-body">
                        <div className="text-center mb-3">
                            <img src={Logo} className="img-fluid" alt="pu logo" />
                        </div>
                        {props.children}
                    </div>

                </div>
            </div>
        </div>
    )
}
