import React from 'react'
import styles from './Avatar.module.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faUserCircle } from '@fortawesome/free-solid-svg-icons'
library.add(faUserCircle)


export default function Avatar(props) {

    let fontSize = ''
    if (props.size === 'small') {
        fontSize = '38px'
    }
    if (props.size === '60') {
        fontSize = '60px'
    }
    if (props.size === 'large') {
        fontSize = '64px'
    }
    return (
        <div style={{
            height: fontSize,
            width: fontSize,
            position: 'relative'
        }}>
            {props.onClick && (
                <div
                    onClick={props.onClick}
                    className={[styles.avatarHover]}
                    style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        bottom: 0,
                        right: 0,
                        textAlign: 'center',
                        paddingTop: '20%'
                    }} >
                    <strong >EDIT</strong>
                </div>
            )}
            {props.image ? (
                <div style={{
                    backgroundImage: `url(${props.image})`,
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                    borderRadius: '50%',
                    height: '100%',
                    width: '100%', /* 1:1 Aspect Ratio */
                }} />
            ) : (
                    <span style={{
                        fontSize: fontSize,
                        lineHeight: '0'
                    }}><FontAwesomeIcon aria-label="image of User" icon="user-circle" /></span>
                )}
        </div >
    )
}


