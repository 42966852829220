module.exports = (data, groupBy, values) => {

    //feed in an array of objects for data, and the names of the keys to group by/sum as an array in groupBy and values

    var result = [];

    if (!data) return []
    if (!groupBy) return []
    if (!values) return []

    data.reduce((res, i) => {
        let aggkey = groupBy.map(g => i[g]).join()
// console.log(aggkey)
        //If the end result doesn't have the current aggregation key, add one
        if (!res[aggkey]) {

            res[aggkey] = 'AggKey'
            let obj = {}

            groupBy.forEach(g => {
                obj[g] = i[g]
            })

            values.forEach(v => {
                obj[v] = 0.0
                obj[v+'count'] = 0
            })

            res[aggkey] = obj;
            result.push(obj)

        }

        values.forEach(v => {
            if (i[v]) {
                res[aggkey][v] += parseFloat(i[v]);
                res[aggkey][v+'count'] += 1;
            }
        })
        return res;
    }, {});

    return result
}