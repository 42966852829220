import React, { Component } from 'react'
import { connect } from 'react-redux';
import { Route, Switch, Redirect, withRouter } from 'react-router-dom'
import ResourcesPage from './ResourcesPage'
import { searchResources, setSearchResourcesLoading } from '../../actions/productActions'
import Spinner from '../common/Spinner'
import ResourcesSearch from './ResourcesSearch'
import BarsIcon from '../common/BarsIcon'
import ResourcesTab from './ResourcesTab'
import PUPanel from '../layout/PUPanel';


class ResourcesRouter extends Component {
    constructor(props) {
        super(props);
        this.state = { isMobile: false, width: 0, smallNavOpen: false }
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
        // this.scrollToTag = this.scrollToTag.bind(this)
    }

    componentDidMount() {
        this.updateWindowDimensions();
        window.addEventListener("resize", this.updateWindowDimensions);
    }
    componentWillUnmount() {
        window.removeEventListener("resize", this.updateWindowDimensions);
    }

    updateWindowDimensions() {
        this.setState({
            width: window.innerWidth, height: window.innerHeight,
            isMobile: window.innerWidth <= 768
        })
    }


    // scrollToTag(id) {
    //     if (document.getElementById(id)) {
    //         document.getElementById(id).scrollIntoView({
    //             behavior: 'smooth'
    //         });
    //     }

    // }


    render() {
        const content = this.props.products.resourcesContentList
        if (Object.keys(content).length === 0 || this.props.products.resourcesContentListLoading) {
            return <Spinner fullScreen />
        }

        const pages = content.fields.pages.filter(p => (p.fields.platformTab || 'Resources') === this.props.platformTab)
        const { match } = this.props


        const { purchased } = this.props.products.product

        // let subList = []
        // const { resourcesContentPage } = this.props.products

        // if (resourcesContentPage) {
        //     if (resourcesContentPage.fields) {
        //         if (resourcesContentPage.fields.sections) {
        //             subList = resourcesContentPage.fields.sections.map((section, key) => {
        //                 return (
        //                     <div key={key}>
        //                         <div
        //                             className="buttonLink tc-dark-pink font-weight-bold ml-3"
        //                             style={{ marginBottom: '8px' }}
        //                             onClick={() => { this.scrollToTag(section.fields.title) }}
        //                         >
        //                             {section.fields.title}
        //                         </div>
        //                         <div className="ml-3">
        //                             {section.fields.subSections && section.fields.subSections.map((subSection, key2) => {
        //                                 return (
        //                                     <div
        //                                         key={key2}
        //                                         style={{ marginBottom: '8px' }}
        //                                         className="buttonLink tc-dark-pink font-weight-bold ml-3"
        //                                         onClick={() => { this.scrollToTag(subSection.fields.title) }}>
        //                                         {subSection.fields.title}
        //                                     </div>
        //                                 )
        //                             })}
        //                         </div>
        //                     </div>
        //                 )
        //             }
        //             )
        //         }
        //     }
        // }

        const Pages = () => {
            return (
                <div
                    className="text-center text-md-left"
                >
                    {pages.map(p => {
                        let locked = true
                        if (purchased) { locked = false }
                        else if (p.fields.public) { locked = false }
                        return <ResourcesTab
                            disabled={locked}
                            key={p.sys.id}
                            title={p.fields.title}
                            link={match.url + '/' + p.fields.slug}
                        />
                    })}
                </div>
            )
        }

        return (

            <div>
                {/* Sidenav on mobile turns into topNav with hamburger  */}
                {
                    this.state.isMobile &&
                    <nav
                        className="navbar navbar-expand-lg navbar-dark align-items-center p-0"
                        style={{
                            transition: 'all 0.5s',
                            //backgroundColor: '#169f7b',
                        }}
                    >
                        <div style={{
                            paddingLeft: '12px',
                            paddingTop: '16px'
                        }} >
                            <ResourcesSearch />
                        </div>

                        <button
                            type="button"
                            className={!this.state.smallNavOpen ? "btn btn-lg text-white collapsed" : "btn btn-lg text-white"}
                            data-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup"
                            aria-expanded="false" aria-label="Toggle navigation"
                            onClick={() => { this.setState({ smallNavOpen: !this.state.smallNavOpen }) }}
                            style={{
                                backgroundColor: '#169f7b',
                                color: '#fff',
                                outline: 'none',
                                boxShadow: 'none',
                                marginRight: '12px'
                            }}>
                            <BarsIcon />
                        </button>
                        <div
                            className={!this.state.smallNavOpen ? "collapse navbar-collapse" : "collapse navbar-collapse show"}
                            id="navbarNavAltMarkup">
                            <div className="navbar-nav pt-3">
                                <Pages />
                            </div>
                        </div>
                    </nav>
                }
                {/* End of sideNav mobile  */}


                <div className="container-fluid">
                    <div className="row py-5" style={{ minHeight: '100vh' }}>

                        {/* Start of sideNav desktop  */}
                        {!this.state.isMobile &&
                            <div
                                className="col-5 col-sm-5 col-md-3 col-lg-3 col-xl-2 "

                            >
                                <div style={{
                                    position: 'sticky',
                                    zIndex: '300',
                                    top: '160px'
                                }}>
                                    <ResourcesSearch />
                                    <Pages />
                                </div>
                            </div>

                        }

                        <div className="col-12 col-sm-12 col-md-9 col-lg-9 col-xl-10">
                            <Switch>
                                <Route exact path={match.url + '/:slug'} component={ResourcesPage} />
                                <Route exact path={match.url + '/'} >
                                    {pages[0].fields.public === true || purchased ? <Redirect to={match.url + '/' + pages[0].fields.slug} /> : (
                                        <div className="row">

                                            <div className="col-lg-8 col-xl-9">
                                                <PUPanel title="Purchase Required">
                                                    You will need to purchase this product in order to view any of the resources in detail, however the navigation menu should give you an idea of what this product has to offer.
                                                </PUPanel>
                                            </div>
                                        </div>
                                    )}

                                </Route>
                            </Switch>
                        </div>

                    </div>
                </div >
            </div >

        )
    }
}
const mapStateToProps = state => ({
    products: state.products,
})
export default connect(mapStateToProps, { searchResources, setSearchResourcesLoading })(withRouter(ResourcesRouter));