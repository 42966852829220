import React, { Component } from 'react'
import { connect } from 'react-redux';
import FormSection from '../layout/FormSection'
import Select from '../common/Select'
import SelectSearch from '../common/SelectSearch'
import Button from '../common/Button'
import { getCrmOrganisation, organisationSearch, getCrmContract } from '../../actions/purchaseActions'
import { Link } from 'react-router-dom'

class OrganisationSelect extends Component {
    constructor() {
        super()
        this.state = {
            organisation: null,
            myOrganisationsLoaded: false,
            showSearch: false
        }
    }
    UNSAFE_componentWillReceiveProps(nextProps) {
        if (!this.state.myOrganisationsLoaded) {
            if (!nextProps.purchase.myOrganisationsLoading && nextProps.purchase.myOrganisations.length === 0) {
                this.setState({
                    showSearch: true,
                    myOrganisationsLoaded: true
                })
            }
        }
    }

    render() {
        const { errors, myOrganisations, myOrganisationsLoading, organisationLoading, contractLoading, searchOrganisationsLoading, searchOrganisations } = this.props.purchase

        const orgSelected = !organisationLoading && !contractLoading && Object.keys(this.state.organisation || {}).length > 0
        const orgErrors = errors.organisations || {}
        return (

            <FormSection
                title="Find your organisation"
                locked={this.props.locked}
                loading={myOrganisationsLoading}
                valid={orgSelected}
                onComplete={this.props.onComplete}
                isComplete={this.props.isComplete}
                error={orgErrors.organisations}
                color="pink"
            >
                <p>
                You are purchasing one or more licenses that will be linked to a UK GP practice. Please indicate the UK GP practice you wish the licenses to be linked to from the drop down menu below. If you wish to make a purchase at a Network or CCG/Health board level please <Link to="/contact-us">contact us</Link> directly.
                    
                    </p>
                {this.state.showSearch ? (
                    <div className="row justify-content-center">
                        <div className="col-12 mb-3">
                            <p>
                                You can search by name or ODS code
                               </p>
                        </div>
                        <div className="col" style={{ maxWidth: '300px' }}>
                            <SelectSearch
                                className="mb-3"
                                optionSearch={this.props.organisationSearch}
                                loading={searchOrganisationsLoading}
                                placeholder="Type here to search..."
                                isSearchable
                                value={this.state.organisation}
                                options={searchOrganisations.map(o => {
                                    return {
                                        label: o.name + ' - ' + o.code,
                                        value: o.code
                                    }
                                })}
                                onChange={(e) => {
                                    this.setState({ organisation: e })
                                    this.props.getCrmOrganisation(e.value)
                                    this.props.getCrmContract(e.value, this.props.purchase.product.name)
                                }}
                            />
                        </div>
                        <div className="col-12">
                            <p>
                                Can't find the organisation your are looking for? <Link to="/contact-us">Get in touch</Link> with our team directly
                            </p>

                            {myOrganisations.length > 0 && <Button
                                icon="back"
                                colour="none"
                                onClick={() => { this.setState({ showSearch: false }) }}
                            >back</Button>}
                        </div>


                    </div>
                ) : (
                        <div className="row justify-content-center">
                            <div className="col" style={{ maxWidth: '300px' }}>
                                <Select
                                    options={myOrganisations.map(o => {
                                        return {
                                            label: o.name + ' - ' + o.code,
                                            value: o.code
                                        }
                                    })}
                                    value={this.state.organisation}
                                    onChange={(e) => {
                                        this.setState({ organisation: e })
                                        this.props.getCrmOrganisation(e.value)
                                        this.props.getCrmContract(e.value, this.props.purchase.product.name)
                                    }}
                                />
                            </div>
                            <div className="col-12">
                                <p>
                                    Want to make a purchase for an organisation that's not listed?

                                <Button
                                        className="ml-3"
                                        inline
                                        small
                                        onClick={() => { this.setState({ showSearch: true }) }}
                                    >Click Here</Button>
                                </p>
                            </div>


                        </div>

                    )}



            </FormSection>
        )
    }
}
const mapStateToProps = state => ({
    purchase: state.purchase,
})
export default connect(mapStateToProps, { getCrmOrganisation, organisationSearch, getCrmContract })(OrganisationSelect);