import React from 'react'
import classnames from 'classnames'
import IconBack from '../../img/grey_ellipse.svg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons'
library.add(faCheckCircle)

const Checkbox = ({
    className,
    value,
    onChange,
    name,
    inline,
    readOnly,
    children,
    icon,
    info,
    error,
    color,
    disabled
}) => {
    if (icon) {
        return (

            <button
                disabled={disabled}
                className={className}
                type="button"
                onClick={() => {
                    onChange({
                        target: {
                            type: 'checkbox',
                            name: name,
                            checked: !value
                        }
                    })
                }}
                style={{
                    background: 'none',
                    border: 'none',
                    outline: 'none',
                }}
            >
                <div style={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                }}>


                    <div style={{
                        flexShrink: 0,
                        backgroundImage: 'url("' + IconBack + '")',
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 'contain',
                        overflow: 'hidden',
                        backgroundPosition: 'center',
                        width: '20px',
                        height: '20px',
                        position: 'relative'
                    }}>
                        {Boolean(value) && (
                            <FontAwesomeIcon
                                style={{
                                    position: 'absolute',
                                    left: 0,
                                    top: 0,
                                    right: 0,
                                    bottom: 0,
                                    width: '100%',
                                    height: '100%',
                                    opacity: disabled ? '0.3' : ''
                                }}
                                className={color === 'green' ? "tc-dark-mint" : color === "blue" && "tc-plm-blue"}
                                icon={"check-circle"}
                            />
                        )}
                    </div>
                    <div
                        className="raleway"
                        style={{
                            textAlign: 'left',
                            paddingLeft: '12px',
                            lineHeight: '20px'
                        }}>
                        {children}
                    </div>
                </div>
                {info && <small className='d-block form-text text-muted'>{info}</small>}
                {error ? <div style={{ fontSize: '80%' }} className="text-left text-danger mt-0">{error}</div> : (
                    <div style={{ fontSize: '80%' }}>&nbsp;</div>
                )}
            </button >


        )
    }

    return (
        <div className={classnames(
            "mb-1",
            { "form-check-inline": inline },
            { "form-check": !inline },
            className
        )}>
            <input
                type="checkbox"
                className='form-check-input'
                name={name}
                id={name}
                checked={Boolean(value)}
                onChange={onChange}
                readOnly={readOnly}
            />
            <label htmlFor={name} className="form-check-label pl-2">
                {children}
            </label>
        </div>
    )
}
Checkbox.defaultProps = {
    color: 'green'
}
export default Checkbox