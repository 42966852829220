import {
    SET_ASSESSMENTS_LOADING,
    GET_ASSESSMENTS,
    CLEAR_ASSESSMENTS,
    GET_ASSESSMENTS_ERRORS,
    CLEAR_ASSESSMENTS_ERRORS,

} from '../actions/types'


const initialState = {
    assessments: [],
    loading: false,
    errors: {}
}


export default function assessmentReducer(state = initialState, action) {
    switch (action.type) {
        case SET_ASSESSMENTS_LOADING:
            return {
                ...state,
                loading: action.payload
            }
        case GET_ASSESSMENTS:
            return {
                ...state,
                assessments: action.payload,
                loading: false
            }
        case CLEAR_ASSESSMENTS:
            return {
                ...state,
                assessments: [],
            }
        case GET_ASSESSMENTS_ERRORS:
            return {
                ...state,
                errors: action.payload,
            }
        case CLEAR_ASSESSMENTS_ERRORS:
            return {
                ...state,
                errors: {},
            }
        default:
            return state;
    }
}