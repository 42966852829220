import React from 'react'
import classnames from 'classnames'
import Backsplash from '../../img/pu-backsplash.jpg'
import Circles from '../../img/circles.svg'
import detectIe from '../../utils/detectIe'
import ImageAbsolute from './ImageAbsolute'

export default function Block(props) {
    let backgroundColor = ""
    let textColor = ""
    if (props.textColor) { textColor = props.textColor.toLowerCase() }
    if (props.color) { backgroundColor = props.color.toLowerCase() }
    const ieVersion = detectIe();
    return (
        <div
            data-aos={(ieVersion === false || ieVersion > 10) && "fade"}
            className={classnames(
                { 'bg-white': backgroundColor === "white" },
                { "bg-light-mint": backgroundColor === "green" },
                { "bg-light-pink": backgroundColor === "pink" },
                { "bg-light-yellow": backgroundColor === "yellow" },
            )}
            style={{
                backgroundColor: backgroundColor ? backgroundColor : '',
                backgroundImage: props.backsplash ? 'url("' + Backsplash + '")' : props.backcircles && 'url("' + Circles + '")',
                backgroundRepeat: 'no-repeat',
                backgroundSize: props.backcircles ? 'auto 100%' : 'cover',
                position: 'relative',
                overflow: 'hidden',
                zIndex: '0',
                backgroundPosition: props.backcircles ? 'right' : props.backgroundImagePosition ? props.backgroundImagePosition : 'center',
            }}
        >
            {props.image && (
                <ImageAbsolute
                    imageSrc={props.image}
                    backgroundPosition={props.backgroundImagePosition}
                />
            )}
            {props.accentColor &&

                <div
                    className={classnames(
                        { "bg-light-mint-trans": props.accentColor === "green" },
                        { "bg-light-pink-trans": props.accentColor === "pink" },
                        { "bg-light-yellow-trans": props.accentColor === "yellow" },
                    )}
                    style={{
                        position: 'absolute',
                        height: '100%',
                        width: '50%'
                    }} />}
            <div className={classnames(
                { "container": !props.noContainer },
                { "container-medium": props.size === 'medium' },
                { 'tc-mid-grey': textColor === 'grey' },
                { "tc-white": textColor === '' && (backgroundColor === "green" || backgroundColor === "pink" || backgroundColor === "yellow") },
            )} style={{
                paddingTop: props.largePadding ? '80px' : (props.hideTopPadding ? '12px' : '48px'),
                paddingBottom: props.largePadding ? '80px' : (props.hideBottomPadding ? '12px' : '48px'),

            }}>

                {props.children}

            </div>


        </div>
    )
}
