import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import Checkbox from '../common/Checkbox'
import Button from '../common/Button';
import { setOrganisationProductMembershipActionSuccess, setAuditChecked } from '../../actions/organisationActions'
import PUPanel from '../layout/PUPanel'
import moment from 'moment'
import NextButton from './NextButton'

class PageAuditCheck extends Component {
    constructor() {
        super()
        this.state = {
            auditChecked: false
        }
        this.onChange = this.onChange.bind(this);
    }

    componentDidMount() {
        this.setState({
            auditChecked: this.props.organisations.organisationProductMembership.auditChecked
        })
    }

    componentWillUnmount() {
        this.props.setOrganisationProductMembershipActionSuccess(false)
    }

    onChange(e) {
        if (e.target.type === "checkbox") {
            this.setState({ [e.target.name]: e.target.checked })
        } else {
            this.setState({ [e.target.name]: e.target.value })
        }
    }


    render() {
        const { organisationProductMembership, organisation, organisationProductMembershipActionLoading } = this.props.organisations

        return (
            <div>

                <PUPanel title="Check Audit">

                    {(organisationProductMembership.product.enforceAuditCheckBeforeCourse || organisationProductMembership.product.showCheckAudit) && (
                        < div >
                            <div className="mb-3">

                                <p>
                                    GMC duties of a doctor requires any delegation of work to be done safely to trained individuals, with appropriate oversight. The clinical team remain responsible for the work completed when delegating. For fuller guidance on this, please <a href="https://www.gmc-uk.org/ethical-guidance/ethical-guidance-for-doctors/delegation-and-referral/delegation-and-referral" target="_blank" rel="noopener noreferrer">click here</a>.
                                </p>
                                <p>
                                    The ability to audit retrospectively is a key component of the governance when delegating and we advise all Practices to check this is functioning prior to commencing training and go live. Please <Link to={`/product/${organisationProductMembership.product.name}/resources/Clinical_System_Setup`}>click here</Link> for guidance on how to test the audit functionality prior to commencing training.
                                </p>
                                <p>
                                    Please check the box below to confirm
                                </p>
                                <ul>
                                    <li>
                                        <p>
                                            You have tested the audit functionality
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            You understand GMC guidance on this area
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            Your intention to implement in line with the training and GMC guidance”
                                        </p>
                                    </li>
                                </ul>



                            </div>

                            {!organisationProductMembership.auditChecked ? (

                                <div >
                                    <Checkbox
                                        onChange={this.onChange}
                                        name="auditChecked"
                                        value={this.state.auditChecked}
                                        className="mb-3"
                                    > I confirm all of the above</Checkbox>
                                    <Button
                                        fixedWidth
                                        center
                                        disabled={!this.state.auditChecked}
                                        loading={organisationProductMembershipActionLoading}
                                        onClick={() => {
                                            this.props.setAuditChecked(
                                                organisation.code,
                                                organisationProductMembership._id,
                                                { auditChecked: this.state.auditChecked }
                                            )
                                        }}
                                    >Submit</Button>
                                </div>
                            ) : (
                                <div>
                                    <h6 className="mb-3">
                                        Audit check confirmed by <strong>{organisationProductMembership.auditCheckedBy}</strong><small className="text-muted"> - {organisationProductMembership.auditCheckedDate && moment(organisationProductMembership.auditCheckedDate).format('DD-MMMM-YY')}</small>
                                    </h6>
                                    <Button
                                        className="ml-auto"
                                        small
                                        colour="pink"
                                        loading={organisationProductMembershipActionLoading}
                                        onClick={() => {
                                            this.props.setAuditChecked(
                                                organisation.code,
                                                organisationProductMembership._id,
                                                { auditChecked: false }
                                            )
                                        }}
                                    >undo</Button>
                                </div>
                            )}
                        </div>
                    )}

                </PUPanel>
                {organisationProductMembership.auditChecked && <NextButton currentPage={'check-audit'} />}
            </div >
        )
    }
}

const mapStateToProps = (state) => ({
    organisations: state.organisations
})

export default connect(mapStateToProps, { setOrganisationProductMembershipActionSuccess, setAuditChecked })(PageAuditCheck);