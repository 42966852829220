if (process.env.REACT_APP_ENV === "prod") {
    module.exports = {
        authApiURI: 'https://practiceunbound-auth-api.azurewebsites.net',
        platformApiURI: 'https://practiceunbound-platform-api.azurewebsites.net',
        elearningApiURI: 'https://elearning.practiceunbound.org.uk',
        dataFrontendURI: 'https://data.practiceunbound.org.uk',
        contractURI: 'https://practiceunbound-summary-generator.azurewebsites.net',
        portalURL: 'https://practiceunboundportal.co.uk',
        billsbyEnv: 'practiceunbound',
        billsbyODSId: 671,
        contactUsEmail: 'info@practiceunbound.org.uk',
        helpdeskEmail: 'support@practiceunbound.org.uk',
        contactUsPhone: '0300 303 8145',
        enviroment: 'prod'
    }
} else if (process.env.REACT_APP_ENV === "authoring") {
    module.exports = {
        authApiURI: 'https://practiceunbound-auth-api.azurewebsites.net',
        platformApiURI: 'https://practiceunbound-platform-api-authoring.azurewebsites.net',
        elearningApiURI: 'https://elearning.practiceunbound.org.uk',
        dataFrontendURI: 'https://data.practiceunbound.org.uk',
        contractURI: 'https://practiceunbound-summary-generator.azurewebsites.net',
        portalURL: 'https://practiceunboundportal.co.uk',
        billsbyEnv: 'practiceunbound',
        billsbyODSId: 671,
        contactUsEmail: 'info@practiceunbound.org.uk',
        helpdeskEmail: 'support@practiceunbound.org.uk',
        contactUsPhone: '0300 303 8145',
        enviroment: 'authoring'
    }
} else if (process.env.REACT_APP_ENV === "beta") {
    module.exports = {
        authApiURI: 'https://practiceunbound-auth-api-beta.azurewebsites.net',
        platformApiURI: 'https://practiceunbound-platform-api-beta.azurewebsites.net',
        elearningApiURI: 'https://practiceunbound-elearning-api-beta.azurewebsites.net',
        dataFrontendURI: 'https://practiceunbound-data-frontend-beta.azurewebsites.net',
        contractURI: 'https://practiceunbound-summary-generator.azurewebsites.net',
        portalURL: 'https://development.practiceunboundportal.co.uk',
        billsbyEnv: 'practiceunbound',
        billsbyODSId: 671,
        contactUsEmail: 'info@practiceunbound.org.uk',
        helpdeskEmail: 'support@practiceunbound.org.uk',
        contactUsPhone: '0300 303 8145',
        enviroment: 'beta'
    }
} else if (process.env.REACT_APP_ENV === "dev") {
    module.exports = {
        authApiURI: 'https://practiceunbound-auth-api-dev.azurewebsites.net',
        platformApiURI: 'https://practiceunbound-platform-api-dev.azurewebsites.net',
        elearningApiURI: '',
        dataFrontendURI: 'https://data-dev.practiceunbound.org.uk',
        contractURI: 'https://practiceunbound-summary-generator.azurewebsites.net',
        portalURL: 'https://development.practiceunboundportal.co.uk',
        billsbyEnv: 'practiceunboundsandbox',
        billsbyODSId: 1079,
        contactUsEmail: 'info@practiceunbound.org.uk',
        helpdeskEmail: 'support@practiceunbound.org.uk',
        contactUsPhone: '0300 303 8145',
        enviroment: 'dev'
    }
} else {
    module.exports = {
        // authApiURI: 'http://localhost:5000',
        // authApiURI: 'https://practiceunbound-auth-api-dev.azurewebsites.net',
        authApiURI: 'https://practiceunbound-auth-api-beta.azurewebsites.net',

        // platformApiURI: 'http://localhost:5009',
        // platformApiURI: 'https://practiceunbound-platform-api-dev.azurewebsites.net',
        platformApiURI: 'https://practiceunbound-platform-api-beta.azurewebsites.net',
        // platformApiURI: 'https://practiceunbound-platform-api.azurewebsites.net',

        // elearningApiURI: 'http://localhost:5012',
        // elearningApiURI: '',
        elearningApiURI: 'https://practiceunbound-elearning-api-beta.azurewebsites.net',

        // dataFrontendURI: 'http://localhost:3002',
        // dataFrontendURI: 'https://data-dev.practiceunbound.org.uk',
        dataFrontendURI: 'https:practiceunbound-data-frontend-beta.azurewebsites.net',

        // contractURI: 'https://practiceunbound-summary-generator.azurewebsites.net',
        contractURI: 'http://localhost:5013',

        billsbyEnv: 'practiceunboundsandbox',
        billsbyODSId: 1079,
        portalURL: 'http://localhost:8000',
        contactUsEmail: 'info@practiceunbound.org.uk',
        helpdeskEmail: 'support@practiceunbound.org.uk',
        contactUsPhone: '0300 303 8145',
        enviroment: 'dev'
    }
}
