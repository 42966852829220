import React, { Component } from 'react'
import '../AdminEnviroment.css'
import BarsIcon from '../../common/BarsIcon'


class SideNavMobile extends Component {
    constructor(props) {
        super(props)
        this.state = { sidebarOpen: true }
    }

    render() {
        return (

            <button
                type="button"
                className="btn btn-lg mt-2 ml-4 text-white "
                onClick={this.props.click}
                style={{
                    backgroundColor: '#169f7b',
                    color: '#fff',
                    position: 'fixed',
                    marginTop: '45px',
                    marginLeft: '-20px',
                    zIndex: '999'
                }}
            >
                <BarsIcon />
            </button>

        )
    }
}

export default SideNavMobile;