import React from 'react';
import styles from './HomepageImage.module.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DivFade from './DivFade'

export default function HomepageImage(props) {
  return (
    <DivFade>
      <div className={`row ${styles.row}`}>
        <div className={`col-md-5 ${styles.col}`}>
          <div 
            className={`${styles.container}`}
            // style={{
              //   backgroundImage: `url(/testimonial.png)`,
            //   height: '300px',
            //   width: '300px'
            // }}
          >
            <div className={styles.whiteTop}></div>
            {/* <img className={styles.smileyDr} src={smileyDoctorImg} alt='smiling doctor wearing stethoscope'/> */}
            <div className={styles.imageBox}>
              <span role="img" aria-label="Doctor Jenkinson talking"> </span>
              <div className={styles.absoluteBubbleBox}>
                <div className={styles.relativeChildBubbleBox}>
                  <div className={styles.bubblePink}></div>
                  <div className={styles.bubbleGreen}></div>
                  <div className={styles.bubbleOrange}></div>
                </div>
              </div>
            </div>
            <div className={styles.whiteBottom}></div>
            
          </div>
        </div>
        <blockquote className={`blockquote col-md-7 ${styles.quote}`}>
          <p className={styles.p} style={{ fontSize: '20px', fontWeight: 'bold', letterSpacing: '1.2px' }}>
              <FontAwesomeIcon className="tc-light-pink mr-3 mb-2" size="3x" icon="quote-left" role="presentation" />
              The impact on the administrative workload for GPs has been fantastic to see. We’re getting the detailed information we need to look after our patients more safely.”
              {/* {c.fields.quote} */}
          </p>
          <div className="blockquote-footer text-right">{'Dr Dan Jenkinson'}, {'GP and Workflow GP Lead'}</div>
          {/* {(c.fields.author || c.fields.authorDetail) && <div className="blockquote-footer text-right">{c.fields.author}, {c.fields.authorDetail}</div>} */}
        </blockquote>
      </div>
    </DivFade>
  )   
}
