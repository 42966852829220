import React, { Component } from 'react'
import { connect } from 'react-redux';
import { updateElearningResults } from '../../actions/elearningActions'
import queryString from 'query-string'

class ElearningRedirect extends Component {
    componentDidMount() {
        const parsed = queryString.parse(this.props.location.search);
        if (parsed.module) {
            this.props.updateElearningResults(this.props.products.product.name, parsed.module)
        }
    }
    render() {
        return (
            <div>

            </div>
        )
    }
}

const mapStateToProps = state => ({
    elearning: state.elearning,
    products: state.products
})
export default connect(mapStateToProps, { updateElearningResults })(ElearningRedirect);
