import React, { Component } from 'react'
import { connect } from 'react-redux';

class NLSWorkflowTimeline extends Component {

  componentDidMount() {
    if (this.props.organisations.organisation) {
      this.appendCode()
    }
  }

  componentDidUpdate(lastProps) {
    const lastCode = lastProps.organisations.organisation ? lastProps.organisations.organisation.code : ''
    const thisCode = this.props.organisations.organisation ? this.props.organisations.organisation.code : ''
    if (thisCode !== lastCode) this.appendCode()
  }

  appendCode() {
    if (this.props.organisations.organisation) {
      var scriptElement = document.createElement('script');
      scriptElement.innerHTML = `startTimeline("${this.props.organisations.organisation.code}")`;
      document.getElementsByTagName('body')[0].appendChild(scriptElement);
    }
  }


  render() {
    return (
      <>
        <div className="container pt-5" style={{ position: 'relative' }}>
          <p>
            The Workflow Go Timeline details the order in which letter types will be delegated to the Workflow Administrator(s) during the implementation of Workflow Go.
          </p>
          <p>
            The Workflow Go Timeline is a digital document which both the Workflow Go Administrator(s) and the Workflow Go GP Lead have access to. It is used to plan which letter types will be delegated and when. It can then be used to verify that these letter types have been signed off under the Audit process.
          </p>
          <p>
            As a Workflow Go Administrator you will be able to view your own Workflow Go Timeline below. As a Workflow Go GP Lead you can view, create and edit any Timeline.
          </p>
          <p><a target="_blank" rel="noopener noreferrer" href="https://practiceunbound.blob.core.windows.net/documents/workflow-go-timeline-user-guide.pdf">Download the timeline userguide</a></p>
          <p><a target="_blank" rel="noopener noreferrer" href="https://practiceunbound.blob.core.windows.net/documents/workflow-go-example-timeline.pdf">Download an example</a></p>
        </div>
        <div className="container">
          {this.props.organisations.organisation && <iframe
            style={{
              width: '100%',
              height: '2000px',
              border: 'none'
            }}
            title="Workflow Timeline"
            src={`/workflow-timeline?org=${this.props.organisations.organisation.code}`}></iframe>}
        </div>

      </>
    )
  }
}

const mapStateToProps = (state) => ({
  organisations: state.organisations,
})

export default connect(mapStateToProps, {})(NLSWorkflowTimeline);