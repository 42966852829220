import React from 'react'
import { connect } from 'react-redux'
import Button from '../common/Button'



const NextButton = (props) => {
    const currentPage = props.currentPage
    const { organisationProductMembership } = props.organisations
    const { inviteCollaborators, showIg, learningNeeds } = organisationProductMembership.product


    let pageSequence = []
    if (learningNeeds) pageSequence.push('learning-needs-analysis')
    pageSequence.push('licences')
    if (inviteCollaborators) pageSequence.push('invite')
    if (showIg) pageSequence.push('ig')
    pageSequence.push('')

    const url = `/admin/${organisationProductMembership.organisationproductmembershipid}/${pageSequence[pageSequence.indexOf(currentPage) + 1]}`

    return (
        <Button
            link={url}
            colour="none"
            underline
            inline
            icon="next"
            iconRight
        >Next
        </Button>
    )
}



const mapStateToProps = (state) => ({
    organisations: state.organisations
})

export default connect(mapStateToProps, {})(NextButton);