import api from '../config/axios';
import {
    GET_PRODUCT,
    CLEAR_PRODUCT,
    GET_PRODUCTS,
    SET_PRODUCTS_LOADING,
    GET_PRODUCT_MEMBERSHIP,
    SET_PRODUCT_MEMBERSHIP_LOADING,
    SET_PRODUCT_LOADING,
    CLEAR_PRODUCT_MEMBERSHIP,
    GET_PRODUCT_ERRORS,
    CLEAR_PRODUCT_ERRORS,
    GET_PRODUCT_CONTENT_RESOURCES_LIST_LOADING,
    GET_PRODUCT_CONTENT_RESOURCES_LIST,
    GET_PRODUCT_CONTENT_RESOURCES_PAGE_LOADING,
    GET_PRODUCT_CONTENT_RESOURCES_PAGE,
    GET_PRODUCT_CONTENT_RESOURCES_SEARCH_LOADING,
    GET_PRODUCT_CONTENT_RESOURCES_SEARCH,
    SET_LEARNER_OPM_DATA_LOADING,
    GET_LEARNER_OPM_DATA,
    CLEAR_LEARNER_OPM_DATA,
    CLEAR_RESOURCES,
    CLEAR_RESOURCE,
    GET_LNA,
    CLEAR_LNA,
    SET_LNA_LOADING,
    PRODUCT_NOT_FOUND,
    SET_PRODUCT_SUB_PAGE_LOADING,
    CLEAR_PRODUCT_SUB_PAGE_ERRORS,
    GET_PRODUCT_SUB_PAGE
} from './types';
import isEmpty from '../utils/is-empty'
import { logoutUser } from './authActions'

export const getProduct = (prod) => dispatch => {
    dispatch({ type: PRODUCT_NOT_FOUND, payload: false });
    dispatch({ type: SET_PRODUCT_LOADING, payload: true });
    dispatch({ type: CLEAR_PRODUCT_ERRORS });
    api.platformAPI.get('/api/platform/products/' + prod)
        .then(res => {
            dispatch({
                type: GET_PRODUCT,
                payload: res.data
            });
        })
        .catch(err => {
            if (err.response && err.response.status === 404) {
                dispatch({ type: PRODUCT_NOT_FOUND, payload: true });
            }
            dispatch(handleError(err))
            dispatch({ type: SET_PRODUCT_LOADING, payload: false });
        })
}

export const clearProduct = () => dispatch => {
    dispatch({ type: CLEAR_PRODUCT });
}

export const getProducts = () => dispatch => {
    //dispatch({ type: SET_PRODUCTS_LOADING, payload: true }); -rerender didn;t look great
    dispatch({ type: CLEAR_PRODUCT_ERRORS });
    api.platformAPI.get('/api/platform/products')
        .then(res => {
            dispatch({
                type: GET_PRODUCTS,
                payload: res.data
            });
        })
        .catch(err => {
            dispatch({ type: SET_PRODUCTS_LOADING, payload: false });
            dispatch(handleError(err))
        })
}

export const getPendingProductMembership = (productCode) => dispatch => {
    dispatch({ type: SET_PRODUCT_MEMBERSHIP_LOADING, payload: true });
    dispatch({ type: CLEAR_PRODUCT_ERRORS });
    api.platformAPI
        .get('/api/platform/productmemberships/pending/' + productCode)
        .then(res => {
            dispatch({ type: GET_PRODUCT_MEMBERSHIP, payload: res.data })
            dispatch({ type: CLEAR_PRODUCT_ERRORS })
        })
        .catch(err => {
            dispatch(handleError(err))
            dispatch({ type: SET_PRODUCT_MEMBERSHIP_LOADING, payload: false });
        });
}

export const buyProduct = (data) => dispatch => {
    dispatch({ type: SET_PRODUCT_MEMBERSHIP_LOADING, payload: true });
    dispatch({ type: CLEAR_PRODUCT_ERRORS });
    api.platformAPI
        .post('/api/platform/productmemberships/buy', data)
        .then(res => {
            dispatch(getProduct(data.product))
            dispatch(getProducts())
            dispatch(getPendingProductMembership(data.product))
            dispatch({ type: CLEAR_PRODUCT_ERRORS })
        })
        .catch(err => {
            dispatch(handleError(err))
            dispatch({ type: SET_PRODUCT_MEMBERSHIP_LOADING, payload: false });
        });
}

export const clearProductMembership = () => dispatch => {
    dispatch({ type: CLEAR_PRODUCT_MEMBERSHIP });
}


export const getResourcesContentList = (product) => dispatch => {
    dispatch({ type: CLEAR_RESOURCES });
    dispatch({ type: GET_PRODUCT_CONTENT_RESOURCES_LIST_LOADING, payload: true });
    api.platformAPI
        .get('/api/platform/resources/product/' + product)
        .then(res => {
            dispatch({
                type: GET_PRODUCT_CONTENT_RESOURCES_LIST, payload: res.data
            })
        })
        .catch(err => {
            dispatch({ type: GET_PRODUCT_CONTENT_RESOURCES_LIST_LOADING, payload: false });
            dispatch(handleError(err))
        });
}

export const getResourcesContentPage = (id, product) => dispatch => {
    dispatch({ type: CLEAR_RESOURCE });
    dispatch({ type: GET_PRODUCT_CONTENT_RESOURCES_PAGE_LOADING, payload: true });
    api.platformAPI
        .get('/api/platform/resources/product/' + product + '/' + id)
        .then(res => {
            dispatch({
                type: GET_PRODUCT_CONTENT_RESOURCES_PAGE, payload: res.data
            })
        })
        .catch(err => {
            dispatch({ type: GET_PRODUCT_CONTENT_RESOURCES_PAGE_LOADING, payload: false });
            dispatch(handleError(err))
        });
}

export const searchResources = (product, search) => dispatch => {
    dispatch({ type: GET_PRODUCT_CONTENT_RESOURCES_SEARCH_LOADING, payload: true });
    api.platformAPI
        .post('/api/platform/resources/product/' + product + '/search', { search })
        .then(res => {
            dispatch({
                type: GET_PRODUCT_CONTENT_RESOURCES_SEARCH, payload: res.data
            })
        })
        .catch(err => {
            dispatch({ type: GET_PRODUCT_CONTENT_RESOURCES_SEARCH_LOADING, payload: false });
            dispatch(handleError(err))
        });
}

export const setSearchResourcesLoading = () => dispatch => {
    dispatch({ type: GET_PRODUCT_CONTENT_RESOURCES_SEARCH_LOADING, payload: true });
}

export const getLNA = (orgCode, product) => dispatch => {
    dispatch({ type: SET_LNA_LOADING, payload: true })
    api.platformAPI.get(`/api/platform/lnas/learner/${orgCode}/${product}`)
        .then(res => {
            dispatch({ type: GET_LNA, payload: res.data })
        })
        .catch(err => {
            dispatch({ type: SET_LNA_LOADING, payload: false })
            dispatch(handleError(err))
        })
}


export const clearLNA = () => dispatch => {
    dispatch({ type: CLEAR_LNA });
}

export const getOpmData = (orgCode, product) => dispatch => {
    dispatch({ type: SET_LEARNER_OPM_DATA_LOADING, payload: true })
    api.platformAPI.get(`/api/platform/organisations/opm-data/${orgCode}/${product}`)
        .then(res => {
            dispatch({ type: GET_LEARNER_OPM_DATA, payload: res.data })
        })
        .catch(err => {
            dispatch({ type: SET_LEARNER_OPM_DATA_LOADING, payload: false })
            dispatch(handleError(err))
        })
}
export const clearOpmData = () => dispatch => {
    dispatch({ type: CLEAR_LEARNER_OPM_DATA });
}


export const clearProductSubPageErrors = () => dispatch => {
    dispatch({ type: CLEAR_PRODUCT_SUB_PAGE_ERRORS });
}
export const getProductSubPageContent = (slug) => dispatch => {
    dispatch({ type: SET_PRODUCT_SUB_PAGE_LOADING, payload: true });
    dispatch({ type: CLEAR_PRODUCT_SUB_PAGE_ERRORS });
    api.platformAPI
        .get('/api/platform/content/product-subpage/' + slug)
        .then(res => {
            dispatch({
                type: GET_PRODUCT_SUB_PAGE, payload: res.data
            })
        })
        .catch(err => {
            dispatch(handleError(err))
            dispatch({ type: SET_PRODUCT_SUB_PAGE_LOADING, payload: false });
        });
}


const handleError = (err) => dispatch => {
    let payload
    console.dir(err)

    //token expired
    if (err.response) {
        if (err.response.statusText === "Unauthorized" && err.response.status === 401 && err.response.data === "Unauthorized") {
            dispatch(logoutUser())
        }
    }

    if (isEmpty(err.response)) {
        payload = { error: err.message }
    } else {
        if (isEmpty(err.response.data)) {
            payload = { error: err.message }
        } else {
            payload = err.response.data
        }
    }

    dispatch({
        type: GET_PRODUCT_ERRORS,
        payload: payload
    })
}

export const clearProductErrors = () => dispatch => {
    dispatch({ type: CLEAR_PRODUCT_ERRORS })
}
