import { connect } from 'react-redux'
import Accreditation from './Accreditation'
import Certification from '../Certification'

function AccreditationAuth({ match, auth }) {
  const products = auth.products.map(p => p.product)
  const hasPlmAcc = products.includes('plmaccreditationonly')
  const hasPlm = products.includes('prescribinglifestylemedicine') || products.includes('prescribinglifestylemedicinepractice')  

  return hasPlmAcc ? <Accreditation match={match}/> : <Certification hasPlm={hasPlm}/>
}

const mapStateToProps = state => ({
    auth: state.auth
})
export default connect(mapStateToProps, {})(AccreditationAuth);
