import React, { Component } from 'react'
import { connect } from 'react-redux'
import CheckboxCard from '../common/CheckboxCard'
import Button from '../common/Button';
import { changeOrganisationProductMembershipUsers, setOrganisationProductMembershipActionSuccess, usersOptOut } from '../../actions/organisationActions'
import AddUsers from './users/AddUsers'
import PUPanel from '../layout/PUPanel'
import Avatar from '../common/Avatar'
import NextButton from './NextButton';
import { CSSTransition, TransitionGroup } from 'react-transition-group'


class PageAssignLicences extends Component {
    constructor() {
        super()
        this.state = {
            users: [],
        }
        this.onChange = this.onChange.bind(this);
        this.submitUsers = this.submitUsers.bind(this);
    }

    componentDidMount() {
        this.setState({
            users: this.props.organisations.organisationProductMembership.users.map(u => String(u.id))
        })
    }
    componentWillUnmount() {
        this.props.setOrganisationProductMembershipActionSuccess(false)
    }

    onChange(e) {
        if (e.target.type === "checkbox") {
            this.setState({ [e.target.name]: e.target.checked })
        } else {
            this.setState({ [e.target.name]: e.target.value })
        }
    }

    submitUsers() {
        this.props.changeOrganisationProductMembershipUsers(
            this.props.organisations.organisation.code,
            this.props.organisations.organisationProductMembership._id,
            { users: this.state.users }
        )
    }

    render() {
        const { organisationProductMembership, organisation, organisationProductMembershipActionLoading, organisationProductMembershipActionSuccess, errors } = this.props.organisations


        const { userLimitActive, userLimit } = organisationProductMembership

        const changes = JSON.stringify(organisationProductMembership.users.map(u => String(u.id))) !== JSON.stringify(this.state.users)
        return (
            <div className="mb-4">

                <PUPanel title="Add Users">
                    <p className="lead">Use this page to manage which members of your organisation have access to this product. </p>
                    <p>
                        You can add new or existing users using the box below, and enable/disable licences using the checkboxes.
                    </p>
                    <p>
                        If you set up a user that doesn't already have an account, they will receive an email with directions on how to get set up.
                    </p>
                    <div className="p-2" style={{ maxWidth: '400px' }}>


                        {userLimitActive && (
                            <p>
                                You have assigned {organisationProductMembership.users.length} of    {userLimit} licences
                            </p>
                        )}

                        <AddUsers
                            orgProdMemId={this.props.organisations.organisationProductMembership._id}
                            disabled={userLimitActive && organisationProductMembership.users.length >= userLimit}
                        />

                        {organisation.users
                            .sort((a, b,) => {
                                if (a.lastName > b.lastName) {
                                    return 1;
                                }
                                if (b.lastName > a.lastName) {
                                    return -1;
                                }
                                return 0;
                            })
                            .map(u =>

                                <CheckboxCard
                                    disabled={!this.state.users.includes(String(u.id)) && (userLimitActive && this.state.users.length >= userLimit)}
                                    className='mb-2'
                                    key={u.id}
                                    name={u.id}
                                    value={this.state.users.includes(String(u.id))}
                                    onChange={(e) => {
                                        this.props.setOrganisationProductMembershipActionSuccess(false)
                                        const index = this.state.users.indexOf(e.target.name)
                                        let newArray = this.state.users.slice(0)
                                        if (index === -1) {
                                            newArray.push(e.target.name)
                                        } else {
                                            newArray.splice(index, 1)
                                        }
                                        this.setState({ users: newArray })
                                    }}
                                >
                                    {/* User row here has been replaced with custom row */}
                                    <div className="row mx-0" >
                                        <div className="d-none d-sm-flex px-0 col-12 col-sm-2 justify-content-center align-items-center">
                                            <Avatar size="small" image={u.avatar} />
                                        </div>
                                        <div className="col-12 col-sm-10 mt-2 mt-sm-0 mx-auto justify-content-center d-flex flex-column"
                                            style={{ position: 'relative', overflow: 'hidden', minHeight: '50px' }}
                                        >
                                            {/* For the overflow issues; */}
                                            <div style={{ position: 'absolute', left: 0, right: 0 }}>
                                                <h6 className="mb-0 text-sm-left text-center">{`${u.firstName || ''} ${u.lastName || ''}`}</h6>
                                                <small className="d-block text-muted  text-sm-left text-center">{u.email}</small>
                                            </div>

                                        </div>

                                    </div>
                                </CheckboxCard>
                            )}
                        {errors.error && <small className="text-danger">{errors.error}</small>}

                        <div style={{ height: '54px' }}>
                            <TransitionGroup appear={true}>
                                {(changes || organisationProductMembershipActionSuccess) && (

                                    <CSSTransition
                                        classNames="exciting"
                                        timeout={1000}
                                    >
                                        <Button
                                            onClick={this.submitUsers}
                                            className="my-3"
                                            center
                                            fixedWidth
                                            loading={organisationProductMembershipActionLoading}
                                            done={organisationProductMembershipActionSuccess}
                                        >
                                            save changes
                                        </Button>
                                    </CSSTransition>
                                )}
                            </TransitionGroup>
                        </div>


                        {
                            (organisationProductMembership.users.length <= 1 && organisationProductMembership.usersOptOut !== true) && (
                                <Button
                                    onClick={() => {
                                        // this.props.usersOptOut(organisation.code, organisationProductMembership._id)
                                        this.props.history.push('/admin/' + organisationProductMembership._id)
                                    }}
                                    colour="none"
                                    className="mt-5 mb-3"
                                    underline
                                >
                                    No thanks, maybe later
                                </Button>
                            )
                        }

                    </div>
                </PUPanel>
                <NextButton currentPage={'licences'} />
            </div>

        )

    }
}

const mapStateToProps = (state) => ({
    organisations: state.organisations
})

export default connect(mapStateToProps, { usersOptOut, changeOrganisationProductMembershipUsers, setOrganisationProductMembershipActionSuccess })(PageAssignLicences);