import React, { Component } from 'react'
import Modal from '../common/Modal'
import { connect } from 'react-redux';
import { updateName, clearAuthErrors, setAuthActionSuccess } from '../../actions/authActions'
import Button from '../common/Button'
import TextEditHover from '../common/TextEditHover'
import TextFieldGroup from '../common/TextFieldGroup'


class NameModal extends Component {
    constructor(props) {
        super(props)
        this.state = {
            firstName: '',
            lastName: '',
            showModal: false
        }
        this.onSubmit = this.onSubmit.bind(this);
        this.onChange = this.onChange.bind(this);
    }

    onChange(e) {
        if (e.target.type === "checkbox") {
            this.setState({ [e.target.name]: e.target.checked })
        } else {
            this.setState({ [e.target.name]: e.target.value })
        }
    }

    onSubmit(e) {
        e.preventDefault();
        const data = {
            firstName: this.state.firstName,
            lastName: this.state.lastName
        }
        this.props.updateName(data)
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.auth.actionSuccess) {
            setTimeout(() => {
                this.setState({
                    showModal: false
                }, () => {
                    //additional timeout prevents button rerendering during modal close
                    setTimeout(() => {
                        this.props.setAuthActionSuccess(false)
                    }, 100)
                })
            }, 1000)
        }
    }

    render() {
        const { auth } = this.props
        const { errors, user } = auth
        return (
            <div>
                <TextEditHover
                    active={(!user.firstName && !user.lastName)}
                    activeText={<h3 className="text-muted my-0">Edit Name</h3>}
                    inactiveText={<h3 className="my-0">{user.firstName + ' ' + user.lastName}</h3>}
                    onClick={() => {
                        this.setState({
                            showModal: true,
                            firstName: user.firstName,
                            lastName: user.lastName,
                        });
                        this.props.setAuthActionSuccess(false)
                    }}
                />
                <Modal
                    size="small"
                    isOpen={this.state.showModal}
                    onRequestClose={() => {
                        this.props.clearAuthErrors()
                        this.setState({ showModal: false })
                    }}
                >
                    <div className="p-2">
                        <form onSubmit={this.onSubmit}>
                            <div className="row">
                                <div className="col-sm-6">
                                    <TextFieldGroup
                                        label="First Name"
                                        name='firstName'
                                        value={this.state.firstName}
                                        onChange={this.onChange}
                                        error={errors.firstName}
                                    />
                                </div>
                                <div className="col-sm-6">
                                    <TextFieldGroup
                                        label="Last Name"
                                        name='lastName'
                                        value={this.state.lastName}
                                        onChange={this.onChange}
                                        error={errors.lastName}
                                    />
                                </div>
                                <div className="col-12">
                                    <Button
                                        fixedWidth
                                        center
                                        loading={auth.actionLoading}
                                        done={auth.actionSuccess}
                                        type="submit"
                                    >save
                </Button>
                                </div>
                            </div>
                            <small className="text-danger">{errors.error}</small>
                        </form>
                    </div>
                </Modal>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    auth: state.auth,
})

export default connect(mapStateToProps, { updateName, clearAuthErrors, setAuthActionSuccess })(NameModal);