import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faSyncAlt } from '@fortawesome/free-solid-svg-icons'
library.add(faSyncAlt)

const RefreshIcon = () => {
    return (
        <FontAwesomeIcon icon="sync-alt" />
    )
}
export default RefreshIcon