import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

const AdminRoute = ({ component: Component, auth, ...rest }) => (
    // This admin route will redirect any non admin to homepage
    <Route
        {...rest}
        render={props => {
            if (auth.isAuthenticated === true && auth.isAdmin === true) {
                return <Component {...props} />
            } else {
                return <Redirect to="/" />
            }
        }

        }
    />
);

AdminRoute.propTypes = {
    auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth
});

export default connect(mapStateToProps)(AdminRoute);