import React, { Component } from 'react'
import { connect } from 'react-redux'
import Button from '../common/Button'
import UpdatePasswordModal from './UpdatePasswordModal'
import DeleteAccountModal from './DeleteAccountModal'
import AvatarModal from './AvatarModal'
// import ConsentModal from './ConsentModal'
import Card from '../layout/Card'
//import CheckboxArray from '../common/CheckboxArray'
import { updateAuthProfile, setProfileActionSuccess } from '../../actions/profileActions'
import { setAuthActionSuccess } from '../../actions/authActions'
import Spinner from '../common/Spinner'
import NameModal from './NameModal'

class AccountScreen extends Component {
    constructor() {
        super()
        this.state = {
            showUpdatePassword: false,
            showDeleteAccount: false,
            showName: false,
            roles: [],
            interests: [],
            funding: [],
        }
        this.showUpdatePassword = this.showUpdatePassword.bind(this);
        this.closeUpdatePassword = this.closeUpdatePassword.bind(this);
        this.changeAndSubmit = this.changeAndSubmit.bind(this);
        this.showDeleteAccount = this.showDeleteAccount.bind(this);
        this.closeDeleteAccount = this.closeDeleteAccount.bind(this);
    }
    componentDidMount() {
        //added slice to clone array - avoid redux mutability
        this.setState({ roles: this.props.profile.roles.slice() })
        this.setState({ interests: this.props.profile.interests.slice() })
        this.setState({ funding: this.props.profile.funding.slice() })
        this.props.setAuthActionSuccess(false)
        this.props.setProfileActionSuccess(false)
    }

    UNSAFE_componentWillReceiveProps(props) {
        if (props.profile.roles !== this.props.profile.roles) { this.setState({ roles: props.profile.roles.slice() }) }
        if (props.profile.interests !== this.props.profile.interests) { this.setState({ interests: props.profile.interests.slice() }) }
        if (props.profile.funding !== this.props.profile.funding) { this.setState({ funding: props.profile.funding.slice() }) }

    }

    showUpdatePassword() {
        this.setState({
            showUpdatePassword: true
        })
    }
    closeUpdatePassword() {
        this.setState({
            showUpdatePassword: false
        })
    }

    showDeleteAccount() {
        this.setState({
            showDeleteAccount: true
        })
    }
    closeDeleteAccount() {
        this.setState({
            showDeleteAccount: false
        })
    }


    showName() {
        this.setState({
            showName: true
        })
    }
    closeName() {
        this.setState({
            showName: false
        })
    }



    changeAndSubmit(e) {
        this.setState({ [e.target.name]: e.target.value })
        this.props.updateAuthProfile({
            roles: this.state.roles,
            interests: this.state.interests,
            funding: this.state.funding,
        })
    }


    render() {
        const { user } = this.props.auth
        const {
            //rolesOptions, 
            //fundingOptions, 
            //interestsOptions, 
            loading
        } = this.props.profile

        if (loading) {
            return <Spinner fullScreen color='yellow' large />
        }
        return (
            <div className="container pt-5">
                <div className="row justify-content-center">
                    <div className="col-md-8 col-lg-6">
                        <Card>
                            <div className="row py-3 mx-0" >
                                <div className="col-12 col-sm-2 d-flex justify-content-center align-items-center">
                                    <AvatarModal />
                                </div>
                                <div className="col-12 col-sm-10 mt-2 mt-sm-0 text-sm-left text-center justify-content-center d-flex flex-column">                                    <NameModal />
                                    <h6>{user.email}</h6>
                                </div>
                            </div>



                            <div className="col-12">
                                <hr />
                            </div>

                            {/*
                    <div className="row">
                        <div className="col-md-6 col-lg-4 pt-3">
                            <h6>What roles do you play in the health sector?</h6>
                            <CheckboxArray
                                onChange={this.changeAndSubmit}
                                className="mb-2"
                                name='roles'
                                value={this.state.roles}
                                options={rolesOptions}
                            />

                        </div>
                        <div className="col-md-6 col-lg-4 pt-3">
                            <h6>What are your interests?</h6>
                            <CheckboxArray
                                onChange={this.changeAndSubmit}
                                className="mb-2"
                                name='interests'
                                value={this.state.interests}
                                options={interestsOptions}
                            />

                        </div>
                        <div className="col-md-6 col-lg-4 pt-3">
                            <h6>What funding do you have access to?</h6>
                            <CheckboxArray
                                onChange={this.changeAndSubmit}
                                className="mb-2"
                                name='funding'
                                value={this.state.funding}
                                options={fundingOptions}
                            />

                        </div>

                    </div>
                    <hr />
                    */}

                            <div className="row">
                                <div className="col-12">

                                    {/* <ConsentModal /> */}
                                    <UpdatePasswordModal />
                                    <Button center colour='none' onClick={this.showDeleteAccount}>Delete Account</Button>
                                </div>

                            </div>
                        </Card>
                    </div>
                </div>


                <DeleteAccountModal
                    show={this.state.showDeleteAccount} modalClose={this.closeDeleteAccount}
                />
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    auth: state.auth,
    profile: state.profile
})

export default connect(mapStateToProps, { updateAuthProfile, setProfileActionSuccess, setAuthActionSuccess })(AccountScreen);