import {
    SET_PURCHASE_PRODUCT_LOADING,
    CLEAR_PURCHASE_ERROR,
    GET_PURCHASE_PRODUCT,
    GET_PURCHASE_ERROR,
    SET_PURCHASE_CONTACT_LOADING,
    GET_PURCHASE_CONTACT,
    SET_PURCHASE_MY_ORGANISATIONS_LOADING,
    GET_PURCHASE_MY_ORGANISATIONS,
    SET_PURCHASE_SEARCH_ORGANISATIONS_LOADING,
    GET_PURCHASE_SEARCH_ORGANISATIONS,
    SET_PURCHASE_ORGANISATION_LOADING,
    GET_PURCHASE_ORGANISATION,
    SET_PURCHASE_CONTRACT_LOADING,
    GET_PURCHASE_CONTRACT,
    SET_PURCHASE_ORGANISATION_CONFIRMED,
    SET_PURCHASE_SUBMIT_LOADING,
    SET_PURCHASE_SUBMIT_SUCCESS,
    SET_PURCHASE_OPTOUT_LOADING,
    SET_PURCHASE_OPTOUT_SUCCESS,
    FIRE_BILLSBY
} from '../actions/types'

const initialState = {
    product: {},
    productLoading: false,
    contact: {},
    contactLoading: false,
    searchOrganisations: [],
    searchOrganisationsLoading: false,
    myOrganisations: [],
    myOrganisationsLoading: false,
    organisation: null,
    organisationLoading: false,
    organisationValid: false,
    organisationConfirmed: false,
    contract: null,
    contractLoading: false,
    submitLoading: false,
    submitSuccess: false,
    optOutLoading: false,
    optOutSuccess: false,
    billsbySuccess: {},
    errors: {}
}

export default function purchaseReducer(state = initialState, action) {
    switch (action.type) {

        case SET_PURCHASE_PRODUCT_LOADING:
            return {
                ...state,
                productLoading: action.payload
            }

        case GET_PURCHASE_PRODUCT:
            return {
                ...state,
                product: action.payload,
                productLoading: false
            }

        case SET_PURCHASE_CONTACT_LOADING:
            return {
                ...state,
                contactLoading: action.payload,
            }

        case GET_PURCHASE_CONTACT:
            return {
                ...state,
                contact: action.payload,
                contactLoading: false
            }


        case SET_PURCHASE_MY_ORGANISATIONS_LOADING:
            return {
                ...state,
                myOrganisationsLoading: action.payload,
            }

        case GET_PURCHASE_MY_ORGANISATIONS:
            return {
                ...state,
                myOrganisations: action.payload,
                myOrganisationsLoading: false
            }




        case SET_PURCHASE_SEARCH_ORGANISATIONS_LOADING:
            return {
                ...state,
                searchOrganisationsLoading: action.payload,
            }

        case GET_PURCHASE_SEARCH_ORGANISATIONS:
            return {
                ...state,
                searchOrganisations: action.payload,
                searchOrganisationsLoading: false
            }


        case SET_PURCHASE_ORGANISATION_LOADING:
            return {
                ...state,
                organisationLoading: action.payload,
            }

        case GET_PURCHASE_ORGANISATION:

            let organisationValid = true
            if (!action.payload) {
                organisationValid = false
            }
            if (action.payload) {
                if (!action.payload.population || action.payload.population < 1000) {
                    organisationValid = false
                }
            }

            return {
                ...state,
                organisation: action.payload,
                organisationLoading: false,
                organisationValid
            }


        case SET_PURCHASE_CONTRACT_LOADING:
            return {
                ...state,
                contractLoading: action.payload,
            }

        case GET_PURCHASE_CONTRACT:
            return {
                ...state,
                contract: action.payload,
                contractLoading: false
            }

        case SET_PURCHASE_ORGANISATION_CONFIRMED:
            return {
                ...state,
                organisationConfirmed: action.payload
            }


        case SET_PURCHASE_SUBMIT_LOADING:
            return {
                ...state,
                submitLoading: action.payload
            }

        case SET_PURCHASE_SUBMIT_SUCCESS:
            return {
                ...state,
                submitSuccess: action.payload,
                submitLoading: false
            }


        case SET_PURCHASE_OPTOUT_LOADING:
            return {
                ...state,
                optOutLoading: action.payload
            }

        case SET_PURCHASE_OPTOUT_SUCCESS:
            return {
                ...state,
                optOutSuccess: action.payload,
                optOutLoading: false
            }


        case CLEAR_PURCHASE_ERROR:
            return {
                ...state,
                errors: {
                    ...state.errors,
                    [action.payload]: null
                }
            }

        case FIRE_BILLSBY:
            return {
                ...state,
                billsbySuccess: action.payload
            }

        case GET_PURCHASE_ERROR:
            console.error(action.payload)
            if (typeof action.payload.message === "object") {
                return {
                    ...state,
                    errors: {
                        ...state.errors,
                        [action.payload.type]: action.payload.message
                    }
                }
            } else {
                return {
                    ...state,
                    errors: {
                        ...state.errors,
                        [action.payload.type]: { [action.payload.type]: action.payload.message }
                    }
                }
            }


        default:
            return state;
    }
}