//AUTH
export const SET_AUTH_CURRENT_USER = 'SET_AUTH_CURRENT_USER';
export const SET_AUTH_FORGOTEMAIL_SENT = 'SET_AUTH_FORGOTEMAIL_SENT';
export const SET_AUTH_LOADING = 'SET_AUTH_LOADING';
export const SET_PASSWORD_EXPIRED = 'SET_PASSWORD_EXPIRED';
export const SET_EMAIL_VERIFIED = 'SET_EMAIL_VERIFIED';
export const SET_REQUEST_VERIFIED_SENT = 'SET_REQUEST_VERIFIED_SENT';
export const GET_AUTH_ERRORS = 'GET_AUTH_ERRORS';
export const CLEAR_AUTH_ERRORS = 'CLEAR_AUTH_ERRORS';
export const SET_AUTH_ACTION_LOADING = 'SET_AUTH_ACTION_LOADING';
export const SET_AUTH_ACTION_SUCCESS = 'SET_AUTH_ACTION_SUCCESS';

export const SET_PERSONAL_CONTRACTS_LOADING = 'SET_PERSONAL_CONTRACTS_LOADING';
export const GET_PERSONAL_CONTRACTS = 'GET_PERSONAL_CONTRACTS';

//PROFILE
export const SET_PROFILE_LOADING = 'SET_PROFILE_LOADING';
export const SET_PROMPT_AUTH_PROFILE = 'SET_PROMPT_AUTH_PROFILE';
export const SET_PROMPT_ANON_PROFILE = 'SET_PROMPT_ANON_PROFILE';
export const GET_AUTH_PROFILE = 'GET_AUTH_PROFILE';
export const GET_ANON_PROFILE = 'GET_ANON_PROFILE';
export const CLEAR_PROFILE = 'CLEAR_PROFILE';
export const GET_PROFILE_ERRORS = 'GET_PROFILE_ERRORS';
export const CLEAR_PROFILE_ERRORS = 'CLEAR_PROFILE_ERRORS';

export const SET_CONSENTS_LOADING = 'SET_CONSENTS_LOADING';
export const GET_CONSENTS = 'GET_CONSENTS';
export const SET_PROFILE_ACTION_LOADING = 'SET_PROFILE_ACTION_LOADING';
export const SET_PROFILE_ACTION_SUCCESS = 'SET_PROFILE_ACTION_SUCCESS';


//PRODUCT
export const SET_PRODUCT_LOADING = 'SET_PRODUCT_LOADING';
export const SET_PRODUCTS_LOADING = 'SET_PRODUCTS_LOADING';
export const GET_PRODUCT = 'GET_PRODUCT';
export const CLEAR_PRODUCTS = 'CLEAR_PRODUCTS';
export const CLEAR_PRODUCT = 'CLEAR_PRODUCT';
export const GET_PRODUCTS = 'GET_PRODUCTS'
export const GET_PRODUCT_MEMBERSHIP = 'GET_PRODUCT_MEMBERSHIP';
export const SET_PRODUCT_MEMBERSHIP_LOADING = 'SET_PRODUCT_MEMBERSHIP_LOADING';
export const CLEAR_PRODUCT_MEMBERSHIP = 'CLEAR_PRODUCT_MEMBERSHIP';
export const GET_PRODUCT_ERRORS = 'GET_PRODUCT_ERRORS';
export const CLEAR_PRODUCT_ERRORS = 'CLEAR_PRODUCT_ERRORS';
export const GET_PRODUCT_CONTENT_RESOURCES_PAGE_LOADING = 'GET_PRODUCT_CONTENT_RESOURCES_PAGE_LOADING';
export const GET_PRODUCT_CONTENT_RESOURCES_PAGE = 'GET_PRODUCT_CONTENT_RESOURCES_PAGE';
export const GET_PRODUCT_CONTENT_RESOURCES_LIST = 'GET_PRODUCT_CONTENT_RESOURCES_LIST';
export const GET_PRODUCT_CONTENT_RESOURCES_LIST_LOADING = 'GET_PRODUCT_CONTENT_RESOURCES_LIST_LOADING';
export const GET_PRODUCT_CONTENT_RESOURCES_SEARCH = 'GET_PRODUCT_CONTENT_RESOURCES_SEARCH';
export const GET_PRODUCT_CONTENT_RESOURCES_SEARCH_LOADING = 'GET_PRODUCT_CONTENT_RESOURCES_SEARCH_LOADING';
export const CLEAR_RESOURCES = 'CLEAR_RESOURCES';
export const CLEAR_RESOURCE = 'CLEAR_RESOURCE';
export const PRODUCT_NOT_FOUND = 'PRODUCT_NOT_FOUND';
export const GET_PRODUCT_SUB_PAGE = 'GET_PRODUCT_SUB_PAGE';
export const CLEAR_PRODUCT_SUB_PAGE_ERRORS = 'CLEAR_PRODUCT_SUB_PAGE_ERRORS';
export const GET_PRODUCT_SUB_PAGE_ERRORS = 'GET_PRODUCT_SUB_PAGE_ERRORS';
export const SET_PRODUCT_SUB_PAGE_LOADING = 'SET_PRODUCT_SUB_PAGE_LOADING';

//ORGANISATIONS
export const GET_ORGANISATIONS = 'GET_ORGANISATIONS';
export const GET_ORGANISATIONS_SET_SINGLE = 'GET_ORGANISATIONS_SET_SINGLE';
export const GET_ORGANISATION = 'GET_ORGANISATION';
export const SET_ORGANISATION_LOADING = 'SET_ORGANISATION_LOADING';

export const GET_ORGANISATION_CONTRACTS = 'GET_ORGANISATION_CONTRACTS';
export const SET_ORGANISATION_CONTRACTS_LOADING = 'SET_ORGANISATION_CONTRACTS_LOADING';

export const CLEAR_ORGANISATIONS = 'CLEAR_ORGANISATIONS';
export const CLEAR_ORGANISATION = 'CLEAR_ORGANISATION';

export const GET_ORGANISATION_ERRORS = 'GET_ORGANISATION_ERRORS';
export const CLEAR_ORGANISATION_ERRORS = 'CLEAR_ORGANISATION_ERRORS';
export const GET_ORGANISATION_PRODUCT_MEMBERSHIPS = 'GET_ORGANISATION_PRODUCT_MEMBERSHIPS';
export const GET_ORGANISATION_PRODUCT_MEMBERSHIP = 'GET_ORGANISATION_PRODUCT_MEMBERSHIP';
export const SET_ORGANISATION_PRODUCT_MEMBERSHIP_LOADING = 'SET_ORGANISATION_PRODUCT_MEMBERSHIP_LOADING';
export const SET_ORGANISATION_PRODUCT_MEMBERSHIPS_LOADING = 'SET_ORGANISATION_PRODUCT_MEMBERSHIPS_LOADING';
export const CLEAR_ORGANISATION_PRODUCT_MEMBERSHIP = 'CLEAR_ORGANISATION_PRODUCT_MEMBERSHIP';
export const UPDATE_ORGANISATION_PRODUCT_MEMBERSHIP = 'UPDATE_ORGANISATION_PRODUCT_MEMBERSHIP';


export const CHECK_USER_EXISTS_LOADING = 'CHECK_USER_EXISTS_LOADING';
export const SET_CHECK_USER_EXISTS = 'SET_CHECK_USER_EXISTS';

export const SET_ORGANISATION_ACTION_LOADING = 'SET_ORGANISATION_ACTION_LOADING';
export const SET_ORGANISATION_ACTION_SUCCESS = 'SET_ORGANISATION_ACTION_SUCCESS';
export const SET_ORGANISATION_PRODUCT_MEMBERSHIP_ACTION_SUCCESS = 'SET_ORGANISATION_PRODUCT_MEMBERSHIP_ACTION_SUCCESS';
export const SET_ORGANISATION_PRODUCT_MEMBERSHIP_ACTION_LOADING = 'SET_ORGANISATION_PRODUCT_MEMBERSHIP_ACTION_LOADING';

export const GET_LNA = 'GET_LNA';
export const SET_LNA_LOADING = 'SET_LNA_LOADING';
export const CLEAR_LNA = 'CLEAR_LNA';

export const SET_LEARNER_OPM_DATA_LOADING = 'SET_LEARNER_OPM_DATA_LOADING';
export const GET_LEARNER_OPM_DATA = 'GET_LEARNER_OPM_DATA';
export const CLEAR_LEARNER_OPM_DATA = 'CLEAR_LEARNER_OPM_DATA';

//ELEARNING
export const GET_ELEARNING_LAUNCH_URL = 'GET_ELEARNING_LAUNCH_URL';
export const SET_ELEARNING_LAUNCH_URL_LOADING = 'SET_ELEARNING_LAUNCH_URL_LOADING';
export const GET_ELEARNING_RESULTS = 'GET_ELEARNING_RESULTS';
export const ADD_ELEARNING_RESULT = 'ADD_ELEARNING_RESULT';
export const SET_ELEARNING_RESULTS_LOADING = 'SET_ELEARNING_RESULTS_LOADING';
export const GET_ELEARNING_ERRORS = 'GET_ELEARNING_ERRORS';
export const CLEAR_ELEARNING_ERRORS = 'CLEAR_ELEARNING_ERRORS';


//BLOGS
export const GET_BLOGS = 'GET_BLOGS';
export const GET_BLOG = 'GET_BLOG';
export const CLEAR_BLOG = 'CLEAR_BLOG';
export const SET_BLOG_LOADING = 'SET_BLOG_LOADING';
export const SET_BLOGS_LOADING = 'SET_BLOGS_LOADING';
export const GET_BLOG_ERROR = 'GET_BLOG_ERROR';
export const CLEAR_BLOG_ERROR = 'CLEAR_BLOG_ERROR';



//PAGES
export const GET_PRODUCT_CONTENT_BROWSE = 'GET_PRODUCT_CONTENT_BROWSE';
export const GET_PRODUCT_CONTENT_BROWSE_LOADING = 'GET_PRODUCT_CONTENT_BROWSE_LOADING';
export const GET_PRODUCT_CONTENT_OVERVIEW = 'GET_PRODUCT_CONTENT_OVERVIEW';
export const GET_PRODUCT_CONTENT_OVERVIEW_LOADING = 'GET_PRODUCT_CONTENT_OVERVIEW_LOADING';

export const GET_PRODUCT_CONTENT_GETSTARTED = 'GET_PRODUCT_CONTENT_GETSTARTED';
export const GET_PRODUCT_CONTENT_GETSTARTED_LOADING = 'GET_PRODUCT_CONTENT_GETSTARTED_LOADING';
export const GET_PRODUCT_CONTENT_LEADS = 'GET_PRODUCT_CONTENT_LEADS';
export const GET_PRODUCT_CONTENT_LEADS_LOADING = 'GET_PRODUCT_CONTENT_LEADS_LOADING';
export const GET_OURSTORY_CONTENT = 'GET_OURSTORY_CONTENT';
export const GET_OURSTORY_CONTENT_LOADING = 'GET_OURSTORY_CONTENT_LOADING';
export const GET_CONTACTUS_CONTENT = 'GET_CONTACTUS_CONTENT';
export const GET_CONTACTUS_CONTENT_LOADING = 'GET_CONTACTUS_CONTENT_LOADING';
export const GET_CAREERS_CONTENT = 'GET_CAREERS_CONTENT';
export const GET_CAREERS_CONTENT_LOADING = 'GET_CAREERS_CONTENT_LOADING';
export const GET_CUSTOMERSTORIES_CONTENT = 'GET_CUSTOMERSTORIES_CONTENT';
export const GET_CUSTOMERSTORIES_CONTENT_LOADING = 'GET_CUSTOMERSTORIES_CONTENT_LOADING';
export const GET_FAQ_CONTENT = 'GET_FAQ_CONTENT';
export const GET_FAQ_CONTENT_LOADING = 'GET_FAQ_CONTENT_LOADING';
export const GET_PRIVACYNOTICE_CONTENT = 'GET_PRIVACYNOTICE_CONTENT';
export const GET_PRIVACYNOTICE_CONTENT_LOADING = 'GET_PRIVACYNOTICE_CONTENT_LOADING';
export const GET_TERMSCONDITIONS_CONTENT = 'GET_TERMSCONDITIONS_CONTENT';
export const GET_TERMSCONDITIONS_CONTENT_LOADING = 'GET_TERMSCONDITIONS_CONTENT_LOADING';
export const CLEAR_PAGE_ERRORS = 'CLEAR_PAGE_ERRORS';
export const GET_PAGE_ERRORS = 'GET_PAGE_ERRORS';
export const SET_CONTACTUS_LOADING = 'SET_CONTACTUS_LOADING';
export const SET_CONTACTUS_SUCCESS = 'SET_CONTACTUS_SUCCESS';
export const GET_SUBPAGE = 'GET_SUBPAGE';
export const GET_SUBPAGES = 'GET_SUBPAGES';
export const CLEAR_SUBPAGES = 'CLEAR_SUBPAGES';
export const CLEAR_SUBPAGE = 'CLEAR_SUBPAGE';
export const SET_SUBPAGES_LOADING = 'SET_SUBPAGES_LOADING';
export const SET_SUBPAGE_LOADING = 'SET_SUBPAGE_LOADING';
export const GET_NEWSLETTER_CONTENT = 'GET_NEWSLETTER_CONTENT';
export const GET_NEWSLETTER_CONTENT_LOADING = 'GET_NEWSLETTER_CONTENT_LOADING';
export const SET_PAGE_LOADING = 'SET_PAGE_LOADING';
export const GET_PAGE = 'GET_PAGE';
export const CLEAR_CONTACTUS = 'CLEAR_CONTACTUS';


export const GET_BANNER = 'GET_BANNER';


export const SET_PURCHASE_PRODUCT_LOADING = 'SET_PURCHASE_PRODUCT_LOADING';
export const CLEAR_PURCHASE_ERROR = 'CLEAR_PURCHASE_ERROR';
export const GET_PURCHASE_PRODUCT = 'GET_PURCHASE_PRODUCT';
export const GET_PURCHASE_CONTACT = 'GET_PURCHASE_CONTACT';
export const GET_PURCHASE_ERROR = 'GET_PURCHASE_ERROR';
export const SET_PURCHASE_CONTACT_LOADING = 'SET_PURCHASE_CONTACT_LOADING';
export const SET_PURCHASE_MY_ORGANISATIONS_LOADING = 'SET_PURCHASE_MY_ORGANISATIONS_LOADING';
export const GET_PURCHASE_MY_ORGANISATIONS = 'GET_PURCHASE_MY_ORGANISATIONS';
export const SET_PURCHASE_SEARCH_ORGANISATIONS_LOADING = 'SET_PURCHASE_SEARCH_ORGANISATIONS_LOADING';
export const GET_PURCHASE_SEARCH_ORGANISATIONS = 'GET_PURCHASE_SEARCH_ORGANISATIONS';
export const SET_PURCHASE_ORGANISATION_LOADING = 'SET_PURCHASE_ORGANISATION_LOADING';
export const GET_PURCHASE_ORGANISATION = 'GET_PURCHASE_ORGANISATION';
export const SET_PURCHASE_CONTRACT_LOADING = 'SET_PURCHASE_CONTRACT_LOADING';
export const GET_PURCHASE_CONTRACT = 'GET_PURCHASE_CONTRACT';
export const SET_PURCHASE_ORGANISATION_CONFIRMED = 'SET_PURCHASE_ORGANISATION_CONFIRMED';
export const SET_PURCHASE_SUBMIT_LOADING = 'SET_PURCHASE_SUBMIT_LOADING';
export const SET_PURCHASE_SUBMIT_SUCCESS = 'SET_PURCHASE_SUBMIT_SUCCESS';
export const SET_PURCHASE_OPTOUT_LOADING = 'SET_PURCHASE_OPTOUT_LOADING';
export const SET_PURCHASE_OPTOUT_SUCCESS = 'SET_PURCHASE_OPTOUT_SUCCESS';


// Assessment
export const SET_ASSESSMENTS_LOADING = 'SET_ASSESSMENTS_LOADING';
export const GET_ASSESSMENTS = 'GET_ASSESSMENTS';
export const CLEAR_ASSESSMENTS = 'CLEAR_ASSESSMENTS';
export const GET_ASSESSMENTS_ERRORS = 'GET_ASSESSMENTS_ERRORS';
export const CLEAR_ASSESSMENTS_ERRORS = 'CLEAR_ASSESSMENTS_ERRORS';

export const FIRE_BILLSBY = 'FIRE_BILLSBY';