import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faFacebookSquare, faLinkedin, faTwitterSquare, faInstagramSquare, faFacebook } from '@fortawesome/free-brands-svg-icons'
import { faGlobe } from '@fortawesome/free-solid-svg-icons'
library.add(faLinkedin, faTwitterSquare, faGlobe, faFacebookSquare, faInstagramSquare, faFacebook)

const SocialsIcon = (props) => {

    let icon
    switch (props.social) {
        case "linkedin":
            icon = {
                prefix: 'fab',                     
                iconName: "linkedin"
            }
            break
        case "twitter":
            icon = {
                prefix: 'fab',                     
                iconName: "twitter-square"
            }
            break
        case "facebook":
            icon = {
                prefix: 'fab',                     
                iconName: "facebook-square"
            }
            break
        case "facebook-round":
            icon = {
                prefix: 'fab',                     
                iconName: "facebook"
            }
            break
        case "instagram":
            icon = {
                prefix: 'fab',                     
                iconName: "instagram-square"
            }
            break
        case "globe":
            icon = "globe"
            break
        default:
            break
    }

    return (
        <a href={props.link} target="_blank" rel="noopener noreferrer" >
            <FontAwesomeIcon
                className={`${props.className}`}
                role="presentation"
                style={{
                    // color: props.color, 
                    marginLeft: '6px', 
                    marginRight: '6px',                    
                }}
                icon={icon}
                size="2x"
            />
        </a>
    )

}

SocialsIcon.defaultProps = {
    color: 'white',
    social: 'globe'
}

export default SocialsIcon