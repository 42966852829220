import {
    GET_ORGANISATIONS,
    GET_ORGANISATION,
    SET_ORGANISATION_LOADING,
    GET_ORGANISATION_CONTRACTS,
    SET_ORGANISATION_CONTRACTS_LOADING,
    GET_ORGANISATION_PRODUCT_MEMBERSHIPS,
    GET_ORGANISATION_PRODUCT_MEMBERSHIP,
    CLEAR_ORGANISATION_PRODUCT_MEMBERSHIP,
    SET_ORGANISATION_PRODUCT_MEMBERSHIP_LOADING,
    SET_ORGANISATION_PRODUCT_MEMBERSHIPS_LOADING,
    CLEAR_ORGANISATIONS,
    CLEAR_ORGANISATION,
    GET_ORGANISATION_ERRORS,
    CLEAR_ORGANISATION_ERRORS,
    SET_CHECK_USER_EXISTS,
    CHECK_USER_EXISTS_LOADING,
    SET_ORGANISATION_ACTION_SUCCESS,
    SET_ORGANISATION_ACTION_LOADING,
    SET_ORGANISATION_PRODUCT_MEMBERSHIP_ACTION_SUCCESS,
    SET_ORGANISATION_PRODUCT_MEMBERSHIP_ACTION_LOADING,
    GET_ORGANISATIONS_SET_SINGLE,
    UPDATE_ORGANISATION_PRODUCT_MEMBERSHIP
} from '../actions/types'


const initialState = {
    organisations: [],
    organisation: null,
    organisationLoading: false,
    organisationContracts: [],
    organisationContractsLoading: false,
    organisationProductMemberships: [],
    organisationProductMembership: null,
    organisationProductMembershipLoading: false,
    organisationProductMembershipsLoading: false,
    organisationProductMembershipSuccess: false,
    newUserLoading: false,
    newUserExists: null,
    newUserValid: null,
    actionLoading: false,
    actionSuccess: false,
    errors: {}
}

export default function organisationReducer(state = initialState, action) {
    switch (action.type) {
        case GET_ORGANISATIONS:
            return {
                ...state,
                organisations: action.payload,
                organisationLoading: false
            }
        case GET_ORGANISATIONS_SET_SINGLE:
            let organisation = null
            if (action.payload.length > 0 && sessionStorage.organisation && sessionStorage.organisation !== 'undefined' && action.payload.map(a => a.code).includes(JSON.parse(sessionStorage.organisation).code)) {
                organisation = JSON.parse(sessionStorage.organisation)
            } else {
                organisation = action.payload.length > 0 ? action.payload[0] : null
            }

            return {
                ...state,
                organisations: action.payload,
                organisation: organisation,
                organisationLoading: false
            }
        case GET_ORGANISATION:
            return {
                ...state,
                organisation: action.payload,
                organisationLoading: false
            }
        case SET_ORGANISATION_LOADING:
            return {
                ...state,
                organisationLoading: action.payload
            }


        case GET_ORGANISATION_CONTRACTS:
            return {
                ...state,
                organisationContracts: action.payload,
                organisationContractsLoading: false
            }
        case SET_ORGANISATION_CONTRACTS_LOADING:
            return {
                ...state,
                organisationContractsLoading: action.payload
            }


        case CLEAR_ORGANISATIONS:
            return initialState
        case CLEAR_ORGANISATION:
            return {
                ...state,
                organisation: null,
                organisationLoading: false,
                organisationContracts: [],
                organisationContractsLoading: false
            }
        case GET_ORGANISATION_ERRORS:
            return {
                ...state,
                errors: action.payload
            }
        case GET_ORGANISATION_PRODUCT_MEMBERSHIPS:
            return {
                ...state,
                organisationProductMemberships: action.payload,
                organisationProductMembershipsLoading: false,
            }
        case GET_ORGANISATION_PRODUCT_MEMBERSHIP:
            return {
                ...state,
                organisationProductMembership: action.payload,
                organisationProductMembershipLoading: false,
            }
        case UPDATE_ORGANISATION_PRODUCT_MEMBERSHIP:
            const addIndex = state.organisationProductMemberships.map(x => { return x._id }).indexOf(action.payload._id)
            if (addIndex === -1) {
                state.organisationProductMemberships.push(action.payload)
                return { ...state }
            } else {
                state.organisationProductMemberships.splice(addIndex, 1, action.payload)
                return { ...state }
            }

        case SET_ORGANISATION_PRODUCT_MEMBERSHIP_LOADING:
            return {
                ...state,
                organisationProductMembershipLoading: action.payload
            }
        case SET_ORGANISATION_PRODUCT_MEMBERSHIPS_LOADING:
            return {
                ...state,
                organisationProductMembershipsLoading: action.payload
            }

        case CLEAR_ORGANISATION_PRODUCT_MEMBERSHIP: {
            return {
                ...state,
                organisationProductMembership: null,
            }
        }
        case CLEAR_ORGANISATION_ERRORS:
            return {
                ...state,
                errors: {}
            };
        case CHECK_USER_EXISTS_LOADING:
            return {
                ...state,
                newUserLoading: action.payload
            }
        case SET_CHECK_USER_EXISTS:
            return {
                ...state,
                newUserLoading: false,
                newUserExists: action.payload.exists,
                newUserValid: action.payload.valid
            }

        case SET_ORGANISATION_ACTION_SUCCESS:
            return {
                ...state,
                organisationActionSuccess: action.payload,
                organisationActionLoading: false
            }
        case SET_ORGANISATION_ACTION_LOADING:
            return {
                ...state,
                organisationActionLoading: action.payload
            }

        case SET_ORGANISATION_PRODUCT_MEMBERSHIP_ACTION_SUCCESS:
            return {
                ...state,
                organisationProductMembershipActionSuccess: action.payload,
                organisationProductMembershipActionLoading: false
            }
        case SET_ORGANISATION_PRODUCT_MEMBERSHIP_ACTION_LOADING:
            return {
                ...state,
                organisationProductMembershipActionLoading: action.payload
            }

        default:
            return state;
    }
}