import React, { Component } from 'react'
import { Redirect, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import Background from './img/circles_bg.svg'
import PUPanel from './components/layout/PUPanel'
import NavItem from './components/layout/NavItem';

class HomePageRedirect extends Component {
    render() {
        const products = this.props.auth.products.map(p => p.product).filter(p => p !== 'plmaccreditationonly')
        if (products.length === 1) {
            return <Redirect to={{
                pathname: '/product/' + products[0],
            }} />
        }
        if (products.length > 1) {
            const fullProdFiltered = this.props.products.products.filter(p => products.includes(p.name))
            return (
                <div style={{
                    backgroundImage: 'url("' + Background + '")',
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                    overflow: 'hidden',
                    backgroundPosition: 'center',

                }}>
                    <div className="container pb-5 pt-5" >
                        <div className="row justify-content-center">
                            <PUPanel col="col-xl-6 col-lg-8 col-md-9 col-sm-10 col-12" color="blue" title="Choose Your Product">
                                <p className="lead">Welcome to the Practice Unbound platform. Please select a product to continue.</p>
                                <div style={{ width: '300px', justifySelf: 'center' }}>
                                    <NavItem width={300} items={fullProdFiltered.map(p => {
                                        return {
                                            link: `/product/${p.name}`,
                                            image: p.iconUrl,
                                            title: p.title,
                                            // subtitle: p.subtitle,
                                            // purchased: p.purchased

                                        }
                                    })
                                    } />
                                </div>
                            </PUPanel>
                        </div>
                    </div>
                </div>
            )
        }

        return (
            <div style={{
                backgroundImage: 'url("' + Background + '")',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                overflow: 'hidden',
                backgroundPosition: 'center',

            }}>
                <div className="container pb-5 pt-5" >
                    <div className="row justify-content-center">
                        <PUPanel color="blue" title="No Active Products">
                            You do not currently have any active products associated with your account. This may be because the licence date has expired, or your organisation lead has not assigned you a licence.
                            Please <Link to="/contact-us">contact us</Link> if you require any support.
                        </PUPanel>
                    </div>
                </div>
            </div>
        )
    }
}
const mapStateToProps = state => ({
    auth: state.auth,
    products: state.products
});

export default connect(mapStateToProps)(HomePageRedirect);