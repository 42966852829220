import React, { Component } from 'react'
import { connect } from 'react-redux';
import { updateCrmOrganisation, setPurchaseOrganisationConfirmed } from '../../actions/purchaseActions'
import FormSection from '../layout/FormSection'
import TextFieldGroup from '../common/TextFieldGroup'
import Select from '../common/Select'
import Checkbox from '../common/Checkbox'
import numberWithComma from '../../utils/numberWithCommas'
import Validator from 'validator'
import isEmpty from '../../utils/is-empty'


class OrganisationConfirm extends Component {
    constructor() {
        super()
        this.state = {
            edit: false,
            population: 0,
            address1: '',
            postcode: '',
            jobTitle: {},
            clinicalSystem: {},
            pathologySystem: {}
        }
    }
    UNSAFE_componentWillReceiveProps(nextProps) {
        if (this.props.purchase.organisationLoading && !nextProps.purchase.organisationLoading && nextProps.purchase.organisation) {
            // We want population to revert to pre-error value, but not jobtitle
            if (!nextProps.purchase.errors.organisation && nextProps.purchase.organisation.jobTitle) {


                this.setState({
                    jobTitle: { label: nextProps.purchase.organisation.jobTitle, value: nextProps.purchase.organisation.jobTitle },
                    clinicalSystem: { label: nextProps.purchase.organisation.clinicalSystem, value: nextProps.purchase.organisation.clinicalSystem },
                    pathologySystem: { label: nextProps.purchase.organisation.pathologySystem, value: nextProps.purchase.organisation.pathologySystem },
                    address1: nextProps.purchase.organisation.address1,
                    postcode: nextProps.purchase.organisation.postcode,
                })
            }
            this.setState({
                population: nextProps.purchase.organisation.population,

            })
        }
    }


    validate = (input) => {
        const { organisation } = this.props.purchase
        let errors = {};
        let data = { ...input } //spread for immutability

        data.population = !isEmpty(data.population) ? data.population : '';
        data.jobTitle = !isEmpty(data.jobTitle.value) ? data.jobTitle.value : '';
        data.clinicalSystem = !isEmpty(data.clinicalSystem.value) ? String(data.clinicalSystem.value) : '';
        data.address1 = !isEmpty(data.address1) ? data.address1 : '';
        data.postcode = !isEmpty(data.postcode) ? data.postcode : '';


        if (organisation) {
            const tenPerc = organisation.population * 0.1
            if (organisation.population - this.state.population > tenPerc) {
                errors.population = "Population sizes can only be decreased within 10% of our held records. Please contact us directly if a more significant change is needed"
            }
        }


        //jobTitle
        if (!Validator.isLength(data.jobTitle, { min: 1, max: 30 })) {
            errors.jobTitle = 'Job title must be between 1 and 30 characters'
        }
        if (Validator.isEmpty(data.jobTitle)) {
            errors.jobTitle = 'Job title is required'
        }


        //clinical System
        if (!Validator.isLength(data.clinicalSystem, { min: 1, max: 30 })) {
            errors.clinicalSystem = 'Clinical system must be between 1 and 30 characters'
        }
        if (Validator.isEmpty(data.clinicalSystem)) {
            errors.clinicalSystem = 'Clinical system is required'
        }


        //address 1
        if (!Validator.isLength(data.address1, { min: 1, max: 30 })) {
            errors.address1 = 'Address line must be between 1 and 30 characters'
        }
        if (Validator.isEmpty(data.address1)) {
            errors.address1 = 'Address line is required'
        }


        //postcode
        if (!Validator.isPostalCode(data.postcode, 'GB')) {
            errors.postcode = 'Must be valid post code'
        }
        if (Validator.isEmpty(data.postcode)) {
            errors.postcode = 'Post code is required'
        }

        return {
            errors,
            isValid: isEmpty(errors)
        }
    }

    render() {
        const { organisationLoading, organisation, organisationValid, contractLoading, organisationConfirmed, product } = this.props.purchase

        const validation = this.validate(this.state)
        const errors = {
            ...validation.errors,
            ...this.props.purchase.errors.organisation ? this.props.purchase.errors.organisation : {}
        }

        const Problem = () => {
            const { organisation } = this.props.purchase

            if (errors.organisation) {
                return <FormSection
                    title="Update organisation details"
                    locked={this.props.locked}
                    loading={organisationLoading}
                    error={errors.organisation}
                />

            }

            if (organisation) {
                if (!organisation.population || organisation.population < 1000) {
                    return <FormSection
                        title="Update organisation details"
                        locked={this.props.locked}
                        loading={organisationLoading}
                        error=' This organisation does not appear to have a valid registered patient population on our records.'
                    />
                }
            }
            return <FormSection
                title="Update organisation details"
                locked={this.props.locked}
                loading={organisationLoading}
                error={errors.organisation}
            />



        }



        if (!organisationValid) return <Problem />


        let changesMade = false
        if (organisation) {
            if (
                (organisation.population !== this.state.population)
                || (organisation.jobTitle !== this.state.jobTitle.value)
                || (organisation.clinicalSystem !== this.state.clinicalSystem.value)
                || (organisation.pathologySystem !== this.state.pathologySystem.value)
                || (organisation.address1 !== this.state.address1)
                || (organisation.postcode !== this.state.postcode)
            ) {
                changesMade = true
            }
        }




        let jobOptions = [
            { label: 'Practice Manager', value: 'Practice Manager' },
            { label: 'GP', value: 'GP' },
            { label: 'Clinical Lead', value: 'Clinical Lead' },
        ]
        if (organisation) {
            if (organisation.jobTitle) {
                if (!jobOptions.map(o => o.value).includes((organisation.jobTitle))) {
                    jobOptions.push({ label: organisation.jobTitle, value: organisation.jobTitle })
                }
            }
        }

        return (


            <FormSection
                title="Update organisation details"
                locked={this.props.locked}
                loading={organisationLoading || contractLoading}
                onComplete={this.props.onComplete}
                isComplete={this.props.isComplete}
                onUncomplete={this.props.onUncomplete}
                editToggle={() => {
                    this.setState({ edit: !this.state.edit })
                }}
                editActive={this.state.edit}
                valid={validation.isValid && organisationConfirmed}
                changesMade={changesMade}
                onSubmit={() => {
                    this.props.updateCrmOrganisation(organisation.code, {
                        population: this.state.population,
                        clinicalSystem: this.state.clinicalSystem.value,
                        pathologySystem: this.state.pathologySystem.value,
                        jobTitle: this.state.jobTitle.value,
                        address1: this.state.address1,
                        postcode: this.state.postcode,
                    })
                }}
                error={errors.organisation}
                color="green"
            >
                {organisation && (
                    <div className="row">
                        <div className="col-12">
                            <p>
                                Please ensure all of the organisation fields below are accurate including the practices patient population size since this can only be updated once.
                            </p>

                        </div>
                        <div className="col-md-6">
                            <TextFieldGroup
                                name="name"
                                label="Organisation Name"
                                readOnly
                                value={organisation.name}
                            />
                        </div>
                        <div className="col-sm-6">
                            <TextFieldGroup
                                name="code"
                                label="ODS Code"
                                readOnly
                                value={organisation.code}
                            />

                        </div>
                        <div className="col-sm-6">
                            <TextFieldGroup
                                name="population"
                                label="Population Size"
                                readOnly={!this.state.edit}
                                value={String(numberWithComma(this.state.population))}
                                onChange={e => { this.setState({ population: Number(e.target.value.replace(/\D/g, '')) }) }}
                                error={errors.population}
                            />
                        </div>
                        <div className="col-lg-6">
                            <TextFieldGroup
                                name="address1"
                                label="First line of address"
                                readOnly={!this.state.edit}
                                value={this.state.address1}
                                onChange={e => { this.setState({ address1: e.target.value }) }}
                                error={errors.address1}
                            />
                        </div>
                        <div className="col-lg-6">
                            <TextFieldGroup
                                name="postcode"
                                label="Postcode"
                                readOnly={!this.state.edit}
                                value={this.state.postcode}
                                onChange={e => { this.setState({ postcode: e.target.value }) }}
                                error={errors.postcode}
                            />
                        </div>

                        <div className="col-md-6">
                            <Select
                                name="clinicalSystem"
                                label="Clinical System"
                                readOnly={!this.state.edit}
                                value={this.state.clinicalSystem}
                                options={organisation.clinicalSystemOptions.map(o => {
                                    return {
                                        value: o.label,
                                        label: o.label
                                    }
                                })}
                                onChange={e => {
                                    this.setState({ clinicalSystem: e })
                                }}
                                error={errors.clinicalSystem}
                            />
                        </div>

                        {['pathologygo'].includes(product.name) && (
                            <div className="col-md-6">
                                <Select
                                    name="pathologySystem"
                                    label="Pathology System"
                                    readOnly={!this.state.edit}
                                    value={this.state.pathologySystem}
                                    options={organisation.pathologySystemOptions.map(o => {
                                        return {
                                            value: o.label,
                                            label: o.label
                                        }
                                    })}
                                    onChange={e => {
                                        this.setState({ pathologySystem: e })
                                    }}
                                    error={errors.pathologySystem}
                                />
                            </div>
                        )}

                        <div className="col-md-6">
                            {/* <TextFieldGroup
                                name="jobTitle"
                                label="Role within Organisation"
                                readOnly={!this.state.edit}
                                value={this.state.jobTitle}
                                onChange={e => { this.setState({ jobTitle: e.target.value }) }}
                                error={errors.jobTitle}
                            /> */}
                            <Select
                                creatable
                                name="jobTitle"
                                label="Role within Organisation"
                                readOnly={!this.state.edit}
                                value={this.state.jobTitle}
                                options={jobOptions}
                                onChange={e => {
                                    this.setState({ jobTitle: e })
                                }}
                                error={errors.jobTitle}
                            />
                        </div>
                        <div className="col-sm-12 text-center">
                            <div className="card p-3 justify-content-center d-flex flex-row">
                                <Checkbox
                                    name="confirmed"
                                    icon
                                    value={organisationConfirmed}
                                    onChange={e => { this.props.setPurchaseOrganisationConfirmed(e.target.checked) }}
                                >
                                    I confirm that these details are correct
                </Checkbox>
                            </div>

                        </div>

                    </div>

                )}


            </FormSection >

        )
    }
}
const mapStateToProps = state => ({
    purchase: state.purchase,
    auth: state.auth
})
export default connect(mapStateToProps, { updateCrmOrganisation, setPurchaseOrganisationConfirmed })(OrganisationConfirm);