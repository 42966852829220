import React, { Component } from 'react'
import Button from './components/common/Button'
import { Link } from 'react-router-dom'

export default class CookieConsent extends Component {
    constructor() {
        super()
        this.state = {
            cookiesConsented: null
        }
        this.recordConsent = this.recordConsent.bind(this)
    }

    componentDidMount() {
        if (localStorage.cookiesConsented === 'true') {
            this.setState({
                cookiesConsented: true
            })
        } else if (localStorage.cookiesConsented === 'false') {
            this.setState({
                cookiesConsented: true
            })
        }
    }

    recordConsent(consent) {
        this.setState({ cookiesConsented: consent }, () => {
            localStorage.setItem('cookiesConsented', consent)
        })
    }

    render() {
        if (this.state.cookiesConsented === null) {
            return (
                <div
                    role="contentinfo" aria-label="cookie consent"
                    style={{
                        position: 'fixed',
                        boxShadow: '1px 0px 7px rgba(0, 0, 0, 0.5)',
                        bottom: '0',
                        left: '0',
                        right: '0',
                        backgroundColor: 'white',
                        zIndex: '9999999999999'
                    }}
                >
                    <div className="container py-3">
                        <div className="row">
                            <div className="col-sm-8 my-2 my-sm-0 text-center text-sm-left">
                                Our site uses cookies - before you continue please agree to the use of cookies outlined in our <Link to='/privacy-notice'>privacy notice</Link>.
                        </div>
                            <div className="col-sm-4">
                                <div className="d-flex h-100 align-items-center justify-content-center">
                                    <Button
                                        onClick={() => {
                                            this.recordConsent(true)
                                        }}
                                    >
                                        I agree
                            </Button>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            )
        } else {
            return null
        }

    }
}
