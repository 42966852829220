import React, { Component } from 'react'
import { connect } from 'react-redux'
import { setOrganisationProductMembershipActionSuccess, sendInvites, invitesOptOut } from '../../actions/organisationActions'
import TextFieldGroup from '../common/TextFieldGroup'
import Button from '../common/Button'
import PUPanel from '../layout/PUPanel'
import NextButton from './NextButton'

class PageInvite extends Component {
    constructor() {
        super()
        this.state = {
            email: '',
            showSendSection: true
        }
        this.onChange = this.onChange.bind(this)
        this.submitAction = this.submitAction.bind(this)
    }
    onChange(e) {
        if (e.target.type === "checkbox") {
            this.setState({ [e.target.name]: e.target.checked })
        } else {
            this.setState({ [e.target.name]: e.target.value })
        }
    }
    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.organisations.organisationProductMembershipActionSuccess) {
            this.setState({
                email: '',
            }, () => {
                this.props.setOrganisationProductMembershipActionSuccess(false)
            })
        }
    }
    submitAction(e) {
        e.preventDefault()
        const { organisation, organisationProductMembership } = this.props.organisations
        this.props.sendInvites(organisation.code, organisationProductMembership._id, this.state)
    }

    render() {
        const { organisationProductMembership, organisationProductMembershipActionSuccess, organisationProductMembershipActionLoading, errors, organisation } = this.props.organisations
        const promptLna = organisationProductMembership.product.enforceLna && !organisationProductMembership.lna
        if (promptLna) {
            return (
                <div>
                    Please do LNA
                </div>
            )
        }

        return (
            <div>
                <PUPanel title="Invite others">
                    <p className="lead">
                        If think that any other practices you work closely with would benefit from this programme you can send them an email to get set up by using the field below.
                    </p>
                    <div style={{ maxWidth: '300px' }}>
                        {organisationProductMembership.invites.map((i, index) =>

                            <TextFieldGroup
                                key={index}
                                value={i.email}
                                readOnly={true}
                                name={i.email}
                            />
                        )}
                        {this.state.showSendSection && (
                            <form onSubmit={this.submitAction}>
                                <TextFieldGroup
                                    placeholder="Email"
                                    name='email'
                                    readOnly={organisationProductMembershipActionLoading}
                                    value={this.state.email}
                                    onChange={this.onChange}
                                    error={errors.email}
                                />
                                <Button
                                    done={organisationProductMembershipActionSuccess}
                                    loading={organisationProductMembershipActionLoading}
                                    type='submit'
                                    center
                                    fixedWidth
                                    className="mb-2"
                                >Send
                                </Button>

                            </form>
                        )}

                        {
                            (organisationProductMembership.invites.length === 0 && organisationProductMembership.invitesOptOut !== true) && (
                                <Button
                                    onClick={() => {
                                        this.props.invitesOptOut(organisation.code, organisationProductMembership._id)
                                        this.setState({ showSendSection: false })
                                        this.props.history.push('/admin/' + organisationProductMembership.organisationproductmembershipid)
                                    }}
                                    colour="none"
                                    underline
                                >
                                    No thanks, maybe later
                                </Button>
                            )
                        }
                    </div>
                </PUPanel>
                <NextButton currentPage={'invite'} />
            </div>
        )
    }
}
const mapStateToProps = (state) => ({
    organisations: state.organisations
})

export default connect(mapStateToProps, { setOrganisationProductMembershipActionSuccess, invitesOptOut, sendInvites })(PageInvite);