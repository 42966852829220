import SectionPage from './SectionPage'


const Interactions = ({ progress, updateProgress, updating, setUpdating, postErrorRes, errorRes }) => {
  return (
    <SectionPage 
      progress={progress}
      updateProgress={updateProgress}
      updating={updating} 
      setUpdating={setUpdating}
      postErrorRes={postErrorRes}
      errorRes={errorRes}
      title="Log 85 hours of Lifestyle Medicine Consultations"        
      sectionName="interactions"  
      uploadText="Click or drag your interactions log file here"      
      fileType="spreadsheet"
      fileTypeError="file type needs to be a spreadsheet"
    >
      <div className="mb-4 mt-3">
        <div className="mb-4 mt-3">
          <h5 className="font-weight-bold">Introduction</h5>
          <p>As part of the certification, you need to demonstrate that you are engaging in lifestyle medicine consultations actively. These may form part of a traditional medical appointment or be a stand-alone consultation and the user guide will explain how to record these hours.</p>
          <p>As you engage in these consultations you will be required to keep a log. This will evidence that you have undertaken the necessary number of hours to be certified. You will then be asked to provide this log for approval.</p>
          <h5 className="font-weight-bold">What do I need to do?</h5>
          <p>First<a href="https://practiceunbound.blob.core.windows.net/documents/user-guide-85-hour-log-v5.pdf"> download and read the user guide</a>, this will provide guidance on what constitutes a lifestyle medicine consultation and explain how to record and upload your hours.</p>
          <p>You should then download the <a href="https://practiceunbound.blob.core.windows.net/documents/85-hour-record-log-download-v4.xlsx"> 85 hour record log</a> and input the required information into the log as you undertake each consultation. This should be completed within your 12 month enrolment period.</p>
          <p>Once the satisfactory number of hours have been met your log should be countersigned by a suitable colleague and uploaded for approval, using the submission box below. You will find further information within the user guide.</p>
        </div>
      </div>
    </SectionPage>
  )
}

export default Interactions

// https://practiceunbound.blob.core.windows.net/documents/plm-purpose-and-goals-worksheet.docx