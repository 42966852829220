import React, { Component } from 'react'

export default class Block extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isMounted: false,
            backgroundImageLoaded: false
        }
        this.image = new Image()
        this.image.src = props.imageSrc
        this.image.onload = () => {
            if (this.state.isMounted) {
                this.setState({ backgroundImageLoaded: true })
            }
        }
    }
    componentDidMount() {
        this.setState({ isMounted: true })
    }
    componentWillUnmount() {
        this.setState({ isMounted: false })
        this.image.onload = () => { }
        delete this.image
    }
    render() {
        const { props } = this
        if (this.image && this.state.backgroundImageLoaded) {
            return (
                <div
                    style={{
                        ...props,
                        backgroundImage: props.backgroundImage ? props.backgroundImage + ', url("' + this.image.src + '")' : 'url("' + this.image.src + '")',
                        backgroundRepeat: props.backgroundRepeat || 'no-repeat',
                        backgroundSize: props.backgroundSize || 'cover',
                        position: 'absolute',
                        height: '100%',
                        width: '100%',
                        animation: 'background-zoom 2s',
                        backgroundPosition: this.props.backgroundPosition ? this.props.backgroundPosition : 'center',
                        zIndex: '-1'
                    }}
                />
            )
        }

        return null
    }
}
