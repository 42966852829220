import React, { useEffect, useRef } from 'react';
import styles from './TextFieldGroup.module.css'
import classnames from 'classnames'
import { useLocation } from 'react-router-dom'
export default function WorkflowCapacityCalc() {
    const calcRef = useRef(null)
    const location = useLocation();
    const params = new URLSearchParams(location.hash);
    const executeScroll = () => calcRef.current.scrollIntoView({ behavior: "smooth" })
    useEffect(() => {
        if ([...params.entries()] && [...params.entries()].length > 0) {
            if ([...params.entries()][0][0] === '#wf-cap-calc') {
                executeScroll()
            }
        }
    }, [])
    function wfCalc(e) {
        const popValue = e.target.value
        var wfcalcLetDay = document.getElementById('wfcalcLetDay');
        var wfcalcAdHr = document.getElementById('wfcalcAdHr');
        var wfcalcWte = document.getElementById('wfcalcWte');
        var wfcalcWte85 = document.getElementById('wfcalcWte85');
        var wfcalcWte23 = document.getElementById('wfcalcWte23');
        var wfcalcGpLet = document.getElementById('wfcalcGpLet');
        var wfcalcGpHr = document.getElementById('wfcalcGpHr');
        var wfcalcLetDayValue = popValue * (3.01 / 52 / 5);
        var wfcalcAdHrValue = (wfcalcLetDayValue * 3) / 60;
        var wfcalcWteValue = wfcalcAdHrValue / 7.5;
        var wfcalcWte85Value = wfcalcWteValue / 0.85;
        var wfcalcWte23Value = wfcalcWte85Value / 0.77;
        var wfcalcGpLetValue = wfcalcLetDayValue * 0.8;
        var wfcalcGpHrValue = (wfcalcGpLetValue * 1.5) / 60;
        wfcalcLetDay.innerHTML = Math.round(wfcalcLetDayValue);
        wfcalcAdHr.innerHTML = Number(Math.round(wfcalcAdHrValue + 'e2') + 'e-2');
        wfcalcWte.innerHTML = Number(Math.round(wfcalcWteValue + 'e2') + 'e-2');
        wfcalcWte85.innerHTML = Number(Math.round(wfcalcWte85Value + 'e2') + 'e-2');
        wfcalcWte23.innerHTML = Number(Math.round(wfcalcWte23Value + 'e2') + 'e-2');
        wfcalcGpLet.innerHTML = Math.round(wfcalcGpLetValue);
        wfcalcGpHr.innerHTML = Number(Math.round(wfcalcGpHrValue + 'e2') + 'e-2');
    }
    return (
        <div style={{ maxWidth: '600px' }} >
            <div ref={calcRef}
                // This is for the offset of the anchor tag links
                style={{
                    display: 'block',
                    position: 'relative',
                    top: '-220px',
                    visibility: 'hidden'
                }}
            />
            <table className="table table-sm">
                <thead>
                    <tr>
                        <th></th>
                        <th className="text-center">Your Practice</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>
                            <strong>Enter Population Size:</strong>
                        </td>
                        <td className="text-center">
                            <input className={classnames(styles.textFocus, "form-control text-center")} type="text" id="wfcalcInput" placeholder="..." onChange={wfCalc} ></input>
                        </td>
                    </tr>
                    <tr>
                        <td>Letters Per Patient Per Year:</td>
                        <td className="text-center">3.01</td>
                    </tr>
                    <tr>
                        <td>Total Letters Per Day:</td>
                        <td className="text-center" id="wfcalcLetDay">-</td>
                    </tr>
                    <tr>
                        <td>Estimated Time per Letter (mins):</td>
                        <td className="text-center">3.00</td>
                    </tr>
                    <tr>
                        <td>Workflow Administrator Hrs Required/Day:</td>
                        <td className="text-center" id="wfcalcAdHr">-</td>
                    </tr>
                    <tr>
                        <td>WTE Required:</td>
                        <td className="text-center" id="wfcalcWte">-</td>
                    </tr>
                    <tr>
                        <td>WTEs Required if @ 85% Productivity:</td>
                        <td className="text-center" id="wfcalcWte85">-</td>
                    </tr>
                    <tr>
                        <td>WTEs Required Including 23% uplift for sickness/AL/study on top of productivity uplift:
                        </td>
                        <td className="text-center" id="wfcalcWte23">-</td>
                    </tr>
                </tbody>
            </table>
            <table className="table table-sm">
                <thead>
                    <tr>
                        <th></th>
                        <th className="text-center">Your Practice</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Est Percentage shifted away from GPs:</td>
                        <td className="text-center">80%</td>
                    </tr>
                    <tr>
                        <td>Letters shifted away from GPs:</td>
                        <td className="text-center" id="wfcalcGpLet">-</td>
                    </tr>
                    <tr>
                        <td>Estimated Time per Letter for GPs (mins):</td>
                        <td className="text-center">1.5</td>
                    </tr>
                    <tr>
                        <td>GP Hours released per day (hrs):</td>
                        <td className="text-center" id="wfcalcGpHr">-</td>
                    </tr>
                </tbody>
            </table>
        </div >
    )
}