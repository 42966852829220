import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import classnames from 'classnames'
library.add(faInfoCircle)


export default function InfoIcon(props) {
    return (
        <h4 
            className={classnames(
                { "tc-dark-mint": props.color === "green" },
                { "tc-dark-pink": props.color === "pink" },
                { "tc-dark-yellow": props.color === "yellow" },
                { "tc-mid-grey": props.color === "grey" },
            )}
            style={{
                position: 'relative',
                lineHeight: '0px',
            }}
        >
            <FontAwesomeIcon 
                icon={{
                    prefix: 'fas',
                    iconName: "info-circle"
                }}
            />
            <div 
                style={{
                    position: 'absolute',
                    top: '1px',
                    left: '1px',
                    bottom: '1px',
                    right: '1px',
                    borderRadius: '50%',
                    background: 'white',
                    boxShadow: !props.noShadow ? '0 8px 12px -5px rgba(0,0,0,.4)' : '',
                }}
            />
            <FontAwesomeIcon
                style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                }}
                icon={{
                    prefix: 'fas',
                    iconName: "info-circle"
                }}
            />
            {props.onClick && <button 
                onClick={props.onClick}
                style={{
                    position: 'absolute',
                    inset: '-3px 0 0 -3px',
                    background: 'none',
                    border: 'none',
                    borderRadius: '50%',
                    height: '30px',
                    width: '30px',
                }}
            />
            }
        </h4>
    )
}
