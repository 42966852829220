import React from 'react'
import styles from './ImageTextBlock.module.css'
import ImageFadeIn from '../../ImageFadeIn'
import DivFade from '../../DivFade'

export default function ImageTextBlock(props) {
    return (
        <DivFade style={{ position: 'relative' }}>
            <div style={{
                position: 'absolute',
                top: 0,
                right: 0,
                bottom: 0,
                left: 0
            }}>
                <div className="d-flex h-100" style={{backgroundColor: props.contentBackgroundColor}}>
                    <div className={`${styles.colWidth75} ${props.contentSide === 'right' && 'order-last'}`}
                        style={{
                            position: 'relative',
                            overflow: 'hidden',
                            backgroundColor: props.contentBackgroundColor,
                        }}
                    >        
                                    
                        <div
                            style={{
                                position: 'absolute',
                                top: props.contentBackgroundTop,
                                left: props.contentBackgroundLeft,
                                right: props.contentBackgroundRight,
                                bottom: props.contentBackgroundBottom,
                                width: '100%',
                                height: '100%',
                                backgroundRepeat: 'no-repeat',
                                backgroundImage: `url(${props.contentBackgroundImage})`,
                                backgroundSize: props.contentBackgroundSize,
                                backgroundPosition: props.contentBackgroundPosition
                            }} 
                        />
                    </div>
                    <div className={`${styles.imageGrow} d-md-block d-none`}>
                        <ImageFadeIn
                            imageSrc={props.imageUrl}
                            style={{
                                backgroundRepeat: 'no-repeat',
                                backgroundSize: props.imageBackgroundSize ? props.imageBackgroundSize : 'cover',
                                backgroundPosition: props.imageBackgroundPosition
                            }}
                        />
                        {props.imageTransitionElement && <>{props.imageTransitionElement()}</>}
                    </div>
                </div>
            </div>
            <div className="container">
                < div className="row">
                    <div
                        className={`col-md-${props.contentColWidth ? props.contentColWidth : '7'} ${props.contentSide === 'right' && 'order-last'}`} >
                        <div>
                            {props.children}
                        </div>
                    </div>
                    <div className="col-md-5" />
                </div>
            </div>
        </DivFade>
    )
}
ImageTextBlock.defaultProps = {
    contentSide: 'left',
    contentBackgroundColor: 'white',
    contentBackgroundSize: 'cover',
    contentBackgroundImage: undefined,
    contentBackgroundTop: undefined,
    contentBackgroundLeft: undefined,
    contentBackgroundRight: undefined,
    contentBackgroundBottom: undefined,
    contentBackgroundPosition: '0% 0%',
    imageUrl: undefined,
    imageBackgroundPosition: 'center'
};