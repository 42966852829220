import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

const PrivateRoute = ({ component: Component, auth, children, location, ...rest }) => {
    if (auth.isAuthenticated !== true) {
        return <Redirect to={{
            pathname: '/login',
            state: { from: location.pathname, hash: location.hash }
        }} />
    }

    if (children) {
        return <Route    {...rest}>{children}</Route>
    }

    return <Route
        {...rest}
        render={props => {
            return <Component {...props} />
        }
        }
    />
}

PrivateRoute.propTypes = {
    auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth
});

export default connect(mapStateToProps)(PrivateRoute);