import React, { Component } from 'react'
import Button from '../common/Button'
import Modal from '../common/Modal'
import { withRouter } from 'react-router-dom'

class HoverLinkButton extends Component {

    constructor(props) {
        super(props);
        this.state = { hoverBox: false, isMobile: false, width: 0, showModal: false }
    }

    componentDidMount() {
        this.updateWindowDimensions();
        window.addEventListener("resize", this.updateWindowDimensions);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.updateWindowDimensions);
    }

    updateWindowDimensions = () => {
        this.setState({ width: window.innerWidth, isMobile: window.innerWidth <= 768 })
        //console.log(this.state)
    }

    onMouseOver = () => {
        this.setState({ hoverBox: true })
    }

    onMouseOut = () => {
        this.setState({ hoverBox: false })
    }

    showModal = () => {
        this.setState({ showModal: true })
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (this.props.location.pathname !== nextProps.location.pathname) {
            this.setState({ showModal: false })
        }
    }

    render() {


        const Hoverbox = () => {
            return (
                <div
                    style={{
                        borderRadius: '4px',
                        boxShadow: '0 1px 4px 0 rgba(0,0,0,.15)',
                        position: 'absolute',
                        zIndex: '700',
                        marginTop: '8px',
                        textAlign: 'center',
                        backgroundColor: '#fff',
                        justifyContent: 'center',
                        padding: '8px',
                        minWidth: '200px',
                        maxWidth: '300px',
                        left: '-60px'
                    }}
                >
                    {this.props.hoverBoxText}
                </div>
            )
        }

        return (
            <div style={{ position: 'relative' }}  >
                <li
                    className="nav-item" colour="none"
                    onClick={this.showModal}
                    onMouseMove={this.onMouseOver}
                    onMouseOver={this.onMouseOver}
                    onMouseOut={this.onMouseOut}
                >
                    <Button
                        hoverDisable
                        className="mr-3"
                        link={this.props.buttonURL}
                        colour="none"
                    >{this.props.buttonTitle}
                    </Button>
                </li>
                {(this.state.hoverBox && !this.state.isMobile) && <Hoverbox />}
                {this.props.modalContent && <Modal
                    size="medium"
                    title={this.props.modalTitle}
                    isOpen={this.state.showModal}
                    onRequestClose={() => {
                        this.setState({ showModal: false })
                    }}
                >
                    {this.props.modalContent}
                </Modal>}

            </div>
        )
    }
}
export default withRouter(HoverLinkButton)