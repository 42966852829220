import {
    SET_PROFILE_LOADING,
    SET_PROMPT_AUTH_PROFILE,
    SET_PROMPT_ANON_PROFILE,
    GET_AUTH_PROFILE,
    GET_ANON_PROFILE,
    CLEAR_PROFILE,
    GET_PROFILE_ERRORS,
    CLEAR_PROFILE_ERRORS,
    SET_CONSENTS_LOADING,
    GET_CONSENTS,
    SET_PROFILE_ACTION_LOADING,
    SET_PROFILE_ACTION_SUCCESS,
} from '../actions/types'

const initialState = {
    loading: true,
    marketingConsents: [],
    marketingConsentsLoading: false,
    promptAuthProfile: false,
    promptAnonProfile: false,
    anonProfile: {},
    authProfileUpdatedAt: null,
    actionLoading: false,
    actionSuccess: false,
    interests: [],
    roles: [],
    funding: [],
    rolesOptions: [
        { label: 'Practice Manager', value: 'Practice Manager' },
        { label: 'Commissioner', value: 'Commissioner' },
        { label: 'Other', value: 'Other' },
    ],
    interestsOptions: [
        { label: 'Increasing Revenue', value: 'Increasing Revenue' },
        { label: 'Saving GP Time', value: 'Saving GP Time' },
        { label: 'Increasing Team Capacity', value: 'Increasing Team Capacity' },
    ],
    fundingOptions: [
        { label: 'I don\'t have access', value: 'I don\'t have access' },
    ],
    errors: {}
}

export default function profileReducer(state = initialState, action) {
    switch (action.type) {
        case CLEAR_PROFILE:
            return initialState
        case GET_AUTH_PROFILE:

            if (action.payload === null) {
                return {
                    ...state,
                    loading: false,
                    //promptAuthProfile: true,
                    promptAuthProfile: false,
                }
            } else {
                let expireDate = new Date(action.payload.updatedAt)
                //expireDate = expireDate.getTime() + 1000 * 10 //set number of seconds
                expireDate.setDate(expireDate.getDate() + 90); //set number of days until expires

                /*
                let authExpired = false
                if (expireDate < new Date()) {
                    authExpired = true
                }
                */
                return {
                    ...state,
                    loading: false,
                    //promptAuthProfile: authExpired,
                    promptAuthProfile: false,
                    authProfileUpdatedAt: action.payload.updatedAt,
                    interests: action.payload.interests || [],
                    roles: action.payload.roles || [],
                    funding: action.payload.funding || [],
                }
            }
        case GET_ANON_PROFILE:
            return {
                ...state,
                promptAnonProfile: Object.keys(action.payload).length === 0,
                anonProfile: action.payload,
            }
        case SET_PROMPT_ANON_PROFILE:
            return {
                ...state,
                promptAnonProfile: action.payload
            }
        case SET_PROMPT_AUTH_PROFILE:
            return {
                ...state,
                promptAuthProfile: false,
                //promptAuthProfile: action.payload
            }
        case SET_PROFILE_LOADING:
            return {
                ...state,
                loading: action.payload
            }
        case GET_PROFILE_ERRORS:
            return {
                ...state,
                errors: action.payload
            }
        case CLEAR_PROFILE_ERRORS:
            return {
                ...state,
                errors: {}
            };

        case SET_CONSENTS_LOADING:
            return {
                ...state,
                marketingConsentsLoading: action.payload
            };
        case GET_CONSENTS:
            return {
                ...state,
                marketingConsents: action.payload,
                marketingConsentsLoading: false
            };
        case SET_PROFILE_ACTION_LOADING:
            return {
                ...state,
                actionLoading: action.payload
            }
        case SET_PROFILE_ACTION_SUCCESS:
            return {
                ...state,
                actionSuccess: action.payload,
                actionLoading: false
            }

        default:
            return state;
    }
}