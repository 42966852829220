import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

const AuthRoute = ({ component: Component, auth, ...rest }) => (
    //purpose of auth route is to redirect to authenticated area once authenticated
    <Route
        {...rest}
        render={props => {
            if (auth.isAuthenticated === true) {
                return <Redirect push to="/" />
            } else {
                return <Component {...props} />
            }
        }
        }
    />
);

AuthRoute.propTypes = {
    auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth
});

export default connect(mapStateToProps)(AuthRoute);