import { useState, useEffect } from 'react'
import Checkbox from '../../../../common/Checkbox'
import Button from '../../../../common/Button'
import Upload from '../../../../common/Upload'
import PUPanel from '../../../../layout/PUPanel'
import { useHistory } from 'react-router-dom'
import Spinner from '../../../../common/Spinner'

const SectionPage = ({
  progress, 
  updateProgress, 
  tickboxText, 
  children, 
  sectionName, 
  title, 
  uploadText, 
  fileType, 
  fileTypeError, 
  updating, 
  setUpdating, 
  postError, 
  postErrorRes
}) => {  
  
  const history = useHistory()
  
  const [complete, setComplete] = useState(true)
  const [validated, setValidated] = useState(false)
  const [changed, setChanged] = useState(false)
  const [file, setFile] = useState(null)

  const statusField = `${sectionName}_status`
  
  useEffect(() => {
    setComplete(progress[statusField] === 'complete' ? true : false)
    setValidated(progress[statusField] === 'validated' ? true : false)
  },[])

  

  const  fileTypes = [...textFileTypes, ...spreadsheetFileTypes, ...pdfFileTypes, ...powerpointFileTypes]
  // switch (fileType) {
  //   case "spreadsheet":
  //     fileTypes = spreadsheetFileTypes
  //     break
  //   case "text":
  //     fileTypes = textFileTypes
  //   case "casestudy":
  //     fileTypes = [...textFileTypes, ...spreadsheetFileTypes]
  //     break
  // }

  return <>
    <div className='pt-4 pl-4'>
      <PUPanel
        title={title}
        color='pink'
    >
      <div className="row">
        <div className="col-12">
          {children}
        </div>
        <>
          {tickboxText &&
          <>
            <h3 className="res-tab-tab col-9 text-uppercase ml-2" style={{maxWidth: '375px'}}>
              {tickboxText}
            </h3>
            <div className="pt-2">
              <Checkbox
                disabled={validated}
                icon 
                className="col-3" 
                value={complete || validated}
                onChange={() => {
                  setComplete(prev => !prev)
                  setChanged(prev => !prev)
                }}
              />
            </div>
            <div className="mt-2 mb-3 col-12 pl-0 ml-2 d-flex">
              <BackButton history={history} />
              {!validated &&
              <div className="ml-2">
                <SubmitButton 
                  changed={changed}
                  sectionName={sectionName}
                  complete={complete}
                  file={file}
                  updateProgress={updateProgress}
                  setChanged={setChanged}
                  history={history}
                  updating={updating}
                  postError={postError}
                  postErrorRes={postErrorRes}
                />
              </div>
              }
            </div>
            {/* {postErrorRes?.data && !updating && */}
            <div className='ml-2 mt-3'>
              <ErrorMessage postErrorRes={postErrorRes}/>        
            </div>
            {/* } */}
            
            {/* <div style={{height: '30px'}} className='d-flex flex-column justify-content-center pl-3 mt-3'>
              <div>
                {updating && <Spinner/>}
              </div>
            </div> */}
            {/* <ErrorMessage /> */}
          </>  
          }

          {uploadText &&
          <>
            <div className="col-md-8 d-flex flex-column justify-content-center" style={{minHeight: !validated && '170px'}}>
              {!validated ?
              <Upload
                isImage={false}
                placeHolder={uploadText}              
                onChange={e => {
                  setComplete(prev => !prev)
                  setChanged(prev => !prev)
                  setFile(e)
                }}
                value={file}
                displayImage
                hideAfterSelection
                fileTypes={fileTypes}
                fileTypeError={fileTypeError}
              /> :
              <ValidatedUpload />          
              }
            </div>
            <div className="mt-2 col-12 pl-0 ml-2 d-flex">
              <BackButton history={history} />
              {!validated &&
              <div className="ml-2">
                <SubmitButton 
                  changed={changed}
                  sectionName={sectionName}
                  complete={complete}
                  file={file}
                  updateProgress={updateProgress}
                  setChanged={setChanged}
                  history={history}
                  updating={updating}
                  postError={postError}
                  postErrorRes={postErrorRes}
                  type={'submission'}
                />
              </div>
              }
            </div>
            {postErrorRes?.data && !updating &&
            <div className='ml-2 mt-3'>
              <ErrorMessage postErrorRes={postErrorRes}/>        
            </div>
            }
          </>
          }
        </>

      </div>
    </PUPanel>
    </div>
  </>
}

const BackButton = ({ history }) => {
  return <Button colour="hollow-green" onClick={() => history.push('/product/prescribinglifestylemedicine/accreditation')} icon="back" className=""><span className="ml-1">back</span></Button>  
}

const SubmitButton = ({ changed, sectionName, complete, type, file, updateProgress, setChanged, history, updating, postError, postErrorRes }) => { 
  return <Button 
  colour="green-outline"
  disabled={changed ? false : true} 
  onClick={async () => {
    const res = await updateProgress(`${sectionName}`, complete || type === 'submission' ? 'complete' : 'incomplete', file && file)
    
    if (res === undefined) {      
        setChanged(prev => !prev)
        history.push('/product/prescribinglifestylemedicine/accreditation')  
      }
    }}
  >submit {updating ? <Spinner /> : <span>...</span>}</Button>
}

const ErrorMessage = ({postErrorRes}) => {


  
  return <>
    {postErrorRes?.data?.error && <p className='font-weight-bold mb-1'>{postErrorRes?.data?.error}</p>}
    {postErrorRes?.statusText && <p>(Status: {postErrorRes?.status} - {postErrorRes?.statusText}{postErrorRes?.data.file && ` - ${postErrorRes?.data.file}`})</p>}      
  </>
}

const ValidatedUpload = () => (
  <div className='d-flex flex-column justify-content-center'
    style={{                
      height: '120px',
      marginBottom: '26px',
      marginTop: '12px',
      padding:'12px',
      borderRadius: '4px',
      borderStyle: 'solid',
      borderWidth: '1px',
      borderColor: 'white',
      boxShadow: '0 0 0 0.2rem rgba(153, 142, 116, 0.5)',
      outline: 'none',
      textAlign: 'center',
    }}
  >
    <div>
      <p className='mb-0' style={{fontSize: '14px', lineHeight:'14px'}}>Thank you, your document has been submitted and approved.</p>
      <p className='mb-0' style={{fontSize: '14px'}}><a href='/contact-us' target='_blank'>Contact us</a> if you have any further queries</p>
      {/* <p className='mb-0 font-weight-bold' style={{fontSize: '14px'}}>0300 303 8145</p>
      <p className='mb-0' style={{fontSize: '14px'}}><a href='mailto:support@practiceunbound.org.uk'>support@practiceunbound.org.uk</a></p> */}
    </div>
  </div>
)

const spreadsheetFileTypes = [
  'application/vnd.ms-excel',
  'application/msexcel',
  'application/x-msexcel',
  'application/x-ms-excel',
  'application/x-excel',
  'application/x-dos_ms_excel',
  'application/xls',
  'application/x-xls',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
]

const textFileTypes = [
  'application/msword',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.template',
  'application/vnd.ms-word.document.macroEnabled.12',
  'application/vnd.ms-word.template.macroEnabled.12',
]

const pdfFileTypes = [
  'application/pdf'
]

const powerpointFileTypes = [
  'application/vnd.ms-powerpoint',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation',
]

export default SectionPage