import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faMinusCircle } from '@fortawesome/free-solid-svg-icons'
library.add(faMinusCircle)

const MinusIcon = () => {
    return (
        <FontAwesomeIcon aria-label="minus icon" icon="minus-circle" />
    )
}
export default MinusIcon