import React from 'react'
import classnames from 'classnames'
import styles from './CheckboxCard.module.css'
import CheckIcon from '../common/CheckIcon'

const CheckboxCard = ({
    className,
    value,
    onChange,
    name,
    children,
    disabled
}) => {
    return (

        <button
            className={classnames(
                "mb-2",
                "d-flex",
                "flex-wrap",
                "align-items-center",
                "justify-content-center",
                styles.card,
                { [styles.cardHover]: !disabled },
                { [styles.disabled]: disabled },
                { [styles.checked]: Boolean(value) },
                className
            )}
            onClick={() => {
                onChange({
                    target: {
                        name: String(name),
                    }
                })
            }}
            disabled={disabled}
        >

            <div className="flex-grow-1 px-2">
                {children}
            </div>
            <CheckIcon checked={Boolean(value)} />
        </button>
    )
}
export default CheckboxCard