import axios from '../config/axios';
//this is why we use axios, so header of each request can be predefined

const setAuthToken = token => {
    if (token) {
        //if it exists then apply to every request
        axios.authAPI.defaults.headers.common['Authorization'] = token
        axios.platformAPI.defaults.headers.common['Authorization'] = token
        axios.elearningAPI.defaults.headers.common['Authorization'] = token
    } else {
        //delete auth header
        delete axios.authAPI.defaults.headers.common['Authorization']
        delete axios.platformAPI.defaults.headers.common['Authorization']
        delete axios.elearningAPI.defaults.headers.common['Authorization']
    }
}

export default setAuthToken