import React from 'react'
import { NavLink } from 'react-router-dom'
import './ResourcesTab.css'
import classnames from 'classnames'

export default function ResourcesTab(props) {
    const classString = classnames('res-tab-tab',
        { 'res-tab-disabled': props.disabled },
    )
    if (props.disabled) {
        return (
            <div title="Purchase required" className={classString}>
                {props.title}
            </div>
        )
    }
    return (
        <NavLink
            className={classString}
            to={props.link}
        >
            {props.title}
        </NavLink>
    )
}
