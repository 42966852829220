import React, { Component } from 'react'
import SelectR from 'react-select'

let searchTimeout = null
export default class Select extends Component {
    constructor(props) {
        super(props)
        this.state = {
            typing: false
        }
    }
    componentWillUnmount() {
        clearTimeout(searchTimeout);
    }
    render() {
        const { props } = this
        const loading = this.props.loading || this.state.typing
        return (
            <div style={{ paddingTop: '3px', paddingBottom: '3px' }}
                className={props.className}
            >
                {props.label && <p className="mb-0 pl-1" style={{ fontWeight: 'bold' }}>{props.label}</p>}
                <SelectR
                    className={props.className}
                    styles={
                        {
                            control: (provided, state) => {
                                return {
                                    ...provided,
                                    boxShadow: props.error ? '0 0 0 0.2rem rgba(220,53,69,.25);' : state.isFocused ? '0 0 0 0.2rem rgba(244,179,33,0.5);' : '',
                                    border: props.error ? '1px solid #dc3545' : state.isFocused || state.isSelected ? '1px solid #be8c1a' : '',
                                    '&:hover': {
                                        borderColor: props.error ? '#dc3545' : '#be8c1a'
                                    }
                                }
                            },
                            option: (provided, state) => {
                                return {
                                    ...provided,
                                    backgroundColor: state.isSelected ? '#be8c1a' : 'transparent',
                                    '&:hover': {
                                        backgroundColor: 'rgba(244,179,33,0.5)',
                                        color: 'black'
                                    }
                                }
                            },
                        }
                    }
                    value={props.value}
                    placeholder={props.placeholder}
                    onChange={props.onChange}
                    isSearchable
                    options={props.options}
                    isLoading={loading}
                    onInputChange={(inputValue) => {
                        if (this.props.optionSearch) {
                            this.setState({ typing: true })
                            clearTimeout(searchTimeout);
                            searchTimeout = setTimeout(() => {
                                this.props.optionSearch(inputValue)
                                this.setState({ typing: false })
                            }, 800);
                        }
                    }}
                />
                {props.hideError ? null : props.error ? <div style={{ fontSize: '80%' }} className="text-danger mt-0">{props.error}</div> : (
                    <div style={{ fontSize: '80%' }}>&nbsp;</div>
                )}
            </div>
        )
    }
}