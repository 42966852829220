import React, { Component } from 'react'
import { connect } from 'react-redux'
import TextFieldGroup from '../../common/TextFieldGroup'
import { checkMemberEmail, setOrganisationActionSuccess, addOrganisationMembership, checkMemberEmailLoading, createNewUser } from '../../../actions/organisationActions'
import Button from '../../common/Button';
import Modal from '../../common/Modal'

let searchTimeout = null
class AddUsers extends Component {
    constructor() {
        super()
        this.state = {
            email: '',
            firstName: '',
            lastName: '',
            jobTitle: '',
            showNewUser: false,
            showAddUser: false,
            showRemoveUser: false,
            selectedUser: ''
        }
        this.onChange = this.onChange.bind(this);
        this.onNewUserSubmit = this.onNewUserSubmit.bind(this);
        this.onAddOrganisationMembershipSubmit = this.onAddOrganisationMembershipSubmit.bind(this);
        this.onEmailType = this.onEmailType.bind(this);
    }
    onChange(e) {
        this.setState({ [e.target.name]: e.target.value })
    }
    onNewUserSubmit() {
        this.props.createNewUser(this.state, this.props.organisations.organisation.code, this.props.orgProdMemId, this.state.jobTitle)
    }
    onAddOrganisationMembershipSubmit() {
        this.props.addOrganisationMembership(this.state.email, this.props.organisations.organisation.code, this.props.orgProdMemId, this.state.jobTitle)
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.organisations.organisationActionSuccess) {
            setTimeout(() => {
                this.setState({
                    email: '',
                    firstName: '',
                    lastName: '',
                    jobTitle: '',
                    showNewUser: false,
                    showAddUser: false,
                    showRemoveUser: false,
                    selectedUser: ''
                }, () => {
                    this.props.setOrganisationActionSuccess(false)
                })
            }, 1000)
        }
    }

    onEmailType(e) {
        e.persist()
        this.setState({ email: e.target.value })
        this.props.checkMemberEmailLoading()
        clearTimeout(searchTimeout);
        searchTimeout = setTimeout(() => {
            this.props.checkMemberEmail(e.target.value, this.props.organisations.organisation.code)
        }, 800);
    }


    render() {
        const { newUserLoading, newUserExists, organisationActionSuccess, organisationActionLoading, newUserValid, errors } = this.props.organisations
        let status = ''
        if (newUserLoading) { status = '' }
        else if (newUserExists === true && this.state.email !== '' && newUserValid) { status = 'This user already has an account, you can add them into this organisation' }
        else if (newUserExists === false && this.state.email !== '' && newUserValid) { status = 'This user does not yet have an account. You can create one for them' }

        return (
            // Added flex-wrap so that 'add' button sits under input field on mobile 
            <div className="d-flex flex-wrap align-items-start mb-3">

                <div style={{ flex: '0 0 200px' }} className="mr-3">
                    <TextFieldGroup
                        placeholder="Email Address"
                        name='email'
                        info={this.props.disabled ? 'You must free up some licences first' : status}
                        value={this.state.email}
                        onChange={this.onEmailType}
                        error={(!newUserValid && this.state.email !== '') ? 'Not a valid email' : ''}
                        disabled={this.props.disabled}
                    />
                </div>
                <Button
                    loading={newUserLoading}
                    disabled={(newUserExists === null || !newUserValid) || this.props.disabled}
                    fixedWidth
                    center
                    onClick={() => {
                        this.setState({
                            showNewUser: newUserExists === false,
                            showAddUser: newUserExists === true
                        })
                    }}
                >
                    add
                </Button>


                <Modal
                    isOpen={this.state.showNewUser}
                    onRequestClose={() => { this.setState({ showNewUser: false }) }}
                    size="small"
                >
                    <div className="p-3">
                        <p>Add in the details of the new user below.</p>
                        <TextFieldGroup
                            placeholder="Email Address"
                            name='email'
                            value={this.state.email}
                            error={errors.email}
                            readOnly
                        />
                        <TextFieldGroup
                            placeholder="First Name"
                            name='firstName'
                            onChange={this.onChange}
                            value={this.state.firstName}
                            error={errors.firstName}
                        />
                        <TextFieldGroup
                            placeholder="Last Name"
                            name='lastName'
                            onChange={this.onChange}
                            value={this.state.lastName}
                            error={errors.lastName}
                        />
                        <TextFieldGroup
                            placeholder="Role within organisation"
                            name='jobTitle'
                            onChange={this.onChange}
                            value={this.state.jobTitle}
                            error={errors.jobTitle}
                        />
                        <Button
                            fixedWidth
                            center
                            onClick={this.onNewUserSubmit}
                            loading={organisationActionLoading}
                            done={organisationActionSuccess}
                        >Create</Button>
                    </div>
                </Modal>
                <Modal
                    isOpen={this.state.showAddUser}
                    onRequestClose={() => { this.setState({ showAddUser: false }) }}
                    size="small"
                >
                    <div className="mb-3">Please confirm that you wish to add <strong>{this.state.email}</strong> to your organisation.</div>
                    <TextFieldGroup
                        placeholder="Role within organisation"
                        name='jobTitle'
                        onChange={this.onChange}
                        value={this.state.jobTitle}
                        error={errors.jobTitle}
                    />
                    <Button
                        center
                        fixedWidth
                        onClick={this.onAddOrganisationMembershipSubmit}
                        loading={organisationActionLoading}
                        done={organisationActionSuccess}
                    >Confirm</Button>

                </Modal>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    organisations: state.organisations
})

export default connect(mapStateToProps, { checkMemberEmail, setOrganisationActionSuccess, addOrganisationMembership, checkMemberEmailLoading, createNewUser })(AddUsers);