import React, { Component } from 'react'

export default class ScrollUp extends Component {
    shouldComponentUpdate(newProps) {
        /*
        const process = (url) => {
            if (url.length === 0) { return url }
            const index = url.indexOf('/', 1)
            if (index === -1) { return url }
            return url.substring(0, index)
        }
        
        if (process(this.props.location.pathname) !== process(newProps.location.pathname)) {
            return true
        }
        */
        if (this.props.location.pathname !== newProps.location.pathname) {
            return true
        }

        return false
    }
    render() {
        window.scrollTo(0, 0)
        return (
            <div>

            </div>
        )
    }
}
