import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classnames from 'classnames'

const InProgress = (props) => {
    return (
        <FontAwesomeIcon
            icon="tasks"
            className={classnames(
                { "tc-mid-grey": props.color === "grey" },
            )}
        />
    )
}
export default InProgress