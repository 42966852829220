import {
    GET_PRODUCT_CONTENT_BROWSE,
    GET_PRODUCT_CONTENT_BROWSE_LOADING,
    GET_PRODUCT_CONTENT_OVERVIEW,
    GET_PRODUCT_CONTENT_OVERVIEW_LOADING,

    GET_PRODUCT_CONTENT_GETSTARTED,
    GET_PRODUCT_CONTENT_GETSTARTED_LOADING,
    GET_PRODUCT_CONTENT_LEADS,
    GET_PRODUCT_CONTENT_LEADS_LOADING,
    GET_OURSTORY_CONTENT,
    GET_OURSTORY_CONTENT_LOADING,
    GET_CONTACTUS_CONTENT,
    GET_CONTACTUS_CONTENT_LOADING,
    GET_CAREERS_CONTENT,
    GET_CAREERS_CONTENT_LOADING,
    GET_CUSTOMERSTORIES_CONTENT,
    GET_CUSTOMERSTORIES_CONTENT_LOADING,
    GET_FAQ_CONTENT,
    GET_FAQ_CONTENT_LOADING,
    GET_PRIVACYNOTICE_CONTENT,
    GET_PRIVACYNOTICE_CONTENT_LOADING,
    GET_TERMSCONDITIONS_CONTENT,
    GET_TERMSCONDITIONS_CONTENT_LOADING,
    GET_NEWSLETTER_CONTENT,
    GET_NEWSLETTER_CONTENT_LOADING,
    SET_CONTACTUS_LOADING,
    SET_CONTACTUS_SUCCESS,
    CLEAR_CONTACTUS,
    GET_SUBPAGES,
    GET_SUBPAGE,
    CLEAR_SUBPAGES,
    CLEAR_SUBPAGE,
    SET_SUBPAGES_LOADING,
    SET_SUBPAGE_LOADING,
    CLEAR_PAGE_ERRORS,
    GET_PAGE_ERRORS,
    SET_PAGE_LOADING,
    GET_PAGE,
    GET_BANNER

} from '../actions/types'

const initialState = {
    browseContent: {},
    browseContentLoading: false,
    productContent: {},
    productContentLoading: false,
    productGetStartedContent: {},
    productGetStartedContentLoading: false,
    productLeadsContent: {},
    productLeadsContentLoading: false,
    ourStoryContent: {},
    ourStoryContentLoading: false,
    contactUsContent: {},
    contactUsContentLoading: false,
    careersContent: {},
    careersContentLoading: false,
    customerStoriesContent: {},
    customerStoriesContentLoading: false,
    faqContent: {},
    faqContentLoading: false,
    privacyNoticeContent: {},
    privacyNoticeContentLoading: false,
    termsConditionsContent: {},
    termsConditionsContentLoading: false,
    newsLetterContent: {},
    newsLetterContentLoading: false,
    contactUsLoading: false,
    contactUsSuccess: false,
    subPage: {},
    subPageLoading: false,
    subPages: {},
    subPagesLoading: false,
    page: {},
    pageLoading: false,
    banner: [],
    errors: {},
}

export default function pagesReducer(state = initialState, action) {
    switch (action.type) {
        case GET_PRODUCT_CONTENT_BROWSE:
            return {
                ...state,
                browseContent: action.payload,
                browseContentLoading: false
            }
        case GET_PRODUCT_CONTENT_BROWSE_LOADING:
            return {
                ...state,
                browseContentLoading: action.payload
            }
        case GET_PRODUCT_CONTENT_OVERVIEW:
            return {
                ...state,
                productContent: action.payload,
                productContentLoading: false
            }
        case GET_PRODUCT_CONTENT_OVERVIEW_LOADING:
            return {
                ...state,
                productContentLoading: action.payload
            }



        case GET_PRODUCT_CONTENT_GETSTARTED:
            return {
                ...state,
                productGetStartedContent: action.payload,
                productGetStartedContentLoading: false
            }
        case GET_PRODUCT_CONTENT_GETSTARTED_LOADING:
            return {
                ...state,
                productGetStartedContentLoading: action.payload
            }

        case GET_PRODUCT_CONTENT_LEADS:
            return {
                ...state,
                productLeadsContent: action.payload,
                productLeadsContentLoading: false
            }
        case GET_PRODUCT_CONTENT_LEADS_LOADING:
            return {
                ...state,
                productLeadsContentLoading: action.payload
            }


        case GET_OURSTORY_CONTENT:
            return {
                ...state,
                ourStoryContent: action.payload,
                ourStoryContentLoading: false
            }
        case GET_OURSTORY_CONTENT_LOADING:
            return {
                ...state,
                ourStoryContentLoading: action.payload
            }
        case GET_CONTACTUS_CONTENT:
            return {
                ...state,
                contactUsContent: action.payload,
                contactUsContentLoading: false
            }
        case GET_CONTACTUS_CONTENT_LOADING:
            return {
                ...state,
                contactUsContentLoading: action.payload
            }
        case GET_CAREERS_CONTENT:
            return {
                ...state,
                careersContent: action.payload,
                careersContentLoading: false
            }
        case GET_CAREERS_CONTENT_LOADING:
            return {
                ...state,
                careersContentLoading: action.payload
            }
        case GET_CUSTOMERSTORIES_CONTENT:
            return {
                ...state,
                customerStoriesContent: action.payload,
                customerStoriesContentLoading: false
            }
        case GET_CUSTOMERSTORIES_CONTENT_LOADING:
            return {
                ...state,
                customerStoriesContentLoading: action.payload
            }
        case GET_FAQ_CONTENT:
            return {
                ...state,
                faqContent: action.payload,
                faqContentLoading: false
            }
        case GET_FAQ_CONTENT_LOADING:
            return {
                ...state,
                faqContentLoading: action.payload
            }
        case GET_PRIVACYNOTICE_CONTENT:
            return {
                ...state,
                privacyNoticeContent: action.payload,
                privacyNoticeContentLoading: false
            }
        case GET_PRIVACYNOTICE_CONTENT_LOADING:
            return {
                ...state,
                privacyNoticeContentLoading: action.payload
            }
        case GET_TERMSCONDITIONS_CONTENT:
            return {
                ...state,
                termsConditionsContent: action.payload,
                termsConditionsContentLoading: false
            }
        case GET_TERMSCONDITIONS_CONTENT_LOADING:
            return {
                ...state,
                termsConditionsContentLoading: action.payload
            }


        case GET_NEWSLETTER_CONTENT:
            return {
                ...state,
                newsLetterContent: action.payload,
                newsLetterContentLoading: false
            }
        case GET_NEWSLETTER_CONTENT_LOADING:
            return {
                ...state,
                newsLetterContentLoading: action.payload
            }

        case SET_CONTACTUS_LOADING:
            return {
                ...state,
                contactUsLoading: action.payload
            }
        case SET_CONTACTUS_SUCCESS:
            return {
                ...state,
                contactUsSuccess: true,
                contactUsLoading: false,
                errors: {}
            }
        case CLEAR_CONTACTUS:
            return {
                ...state,
                contactUsSuccess: false,
                contactUsLoading: false,
                errors: {}
            }
        case GET_PAGE_ERRORS:
            return {
                ...state,
                errors: action.payload
            }
        case CLEAR_PAGE_ERRORS:
            return {
                ...state,
                errors: {}
            };
        case GET_SUBPAGES:
            return {
                ...state,
                subPages: action.payload,
                subPagesLoading: false,
                errors: {}
            };

        case GET_SUBPAGE:
            return {
                ...state,
                subPage: action.payload,
                subPageLoading: false,
                errors: {}
            };
        case CLEAR_SUBPAGES:
            return {
                ...state,
                subPages: {},
                errors: {}
            };
        case CLEAR_SUBPAGE:
            return {
                ...state,
                subPage: {},
                errors: {}
            };
        case SET_SUBPAGES_LOADING:
            return {
                ...state,
                subPagesLoading: action.payload,
                errors: {}
            };
        case SET_SUBPAGE_LOADING:
            return {
                ...state,
                subPageLoading: action.payload,
                errors: {}
            };
        case SET_PAGE_LOADING:
            return {
                ...state,
                pageLoading: action.payload,
                errors: {}
            };
        case GET_PAGE:
            return {
                ...state,
                page: action.payload,
                pageLoading: false,
                errors: {}
            };
        case GET_BANNER:
            return {
                ...state,
                banner: action.payload
            };




        default:
            return state;
    }
}